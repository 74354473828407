import React, { useRef, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import Scroll from 'react-scroll/modules';
import qs from 'querystring';
import isEmpty from 'lodash-es/isEmpty';

import { Form, InputGroup, formActions } from '@evdy/web-core/dist/components/shared/Input';
import TagFilterTypeahead from '@evdy/web-core/dist/components/shared/elements/TagFilterTypeahead';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';
import { ListSkeleton } from '../SkeletonLoader';
import AnnouncementList from './AnnouncementList';
import renderPagination from '../renderPagination';
import useAnncFilter from './useAnncFilter';
import useInternalAnncFilter from './useInternalAnncFilter';
import DateRangeInput from '@evdy/web-core/dist/components/shared/elements/DateRangeInput';
import {
  fetchCompanyMemorials,
  loadNext
} from '@evdy/web-redux/dist/actions/dash/fetchCompanyMemorials';
import { fetchClientConfig } from '@evdy/web-redux/dist/actions/fetchClientConfig';
import { fetchAnnouncementsStatsByDate } from '@evdy/web-redux/dist/actions/internalDash/fetchStats';
import { fetchDemoAnnouncements } from '@evdy/web-redux/dist/lib/demoMode';
import { renderDemoToast } from '@evdy/web-core/dist/lib/utils';
import { useURLParamSync } from '@evdy/web-core/dist/customHooks/index';
import searchIcon from '../assets/search-icon-black-small.svg';
import './Announcements.scss';

const Announcements = props => {
  const {
    client,
    dash,
    isExternalMode,
    isEmployee,
    location,
    fetchCompanyMemorials,
    fetchAnnouncementsStatsByDate,
    fetchClientConfig,
    searchText,
    user,
    fetchDemoAnnouncements,
    change,
    isDemoModeOn
  } = props;

  const [reducerIsLoaded, setReducerIsLoaded] = useState(false);

  const { themes = [], backgrounds = [] } = client?.config?.data;
  const fetchedCompanyId = dash.fetchedCompany?.company?.data?._id;
  //if only one rep... client.config.data.reps is {} not []
  //can also err if undefined is pushed in array
  const reps = Array.isArray(client?.config?.data?.reps)
    ? [...client?.config?.data?.reps].filter(Boolean)
    : [client?.config?.data?.reps].filter(Boolean) || [];
  /*

    filter which home's are being used in the filter process with locations

    TODO filter the memorials that are fetched
    - fetchCompanymemorials?
    - is this ep done?
  */

  const { users: unfilteredDirectors = [], funeralHomes: allLocations = [] } =
    dash?.fetchedCompany?.company?.data || {};

  const store = {
    external: {
      filter: useAnncFilter(),
      filterArgs: [unfilteredDirectors, allLocations, dash],
      searchTags: [],
      memorials: dash?.companyMemorials?.memorials?.data,
      memorialNext: dash?.companyMemorials?.memorials?.next,
      memorialPrev: dash?.companyMemorials?.memorials?.prev
    },
    internal: {
      filter: useInternalAnncFilter(),
      filterArgs: [reps],
      searchTags: ['home', 'family', 'director', 'state'],
      memorials: dash?.announcementStats?.memorials?.data,
      memorialNext: dash?.announcementStats?.memorials?.next,
      memorialPrev: dash?.announcementStats?.memorials?.prev
    }
  };

  const [
    anncFilter = {},
    setAnncFilter = () => {},
    getFilters = () => {},
    filterType = {},
    dropdownOptions = {}
  ] = store[isExternalMode ? 'external' : 'internal'].filter;

  const filters = getFilters(...store[isExternalMode ? 'external' : 'internal'].filterArgs);

  let activeFilters = [];

  const { query } = location;
  const noHidden = true;
  const searchInput = useRef(null);
  const prevSearchInput = useRef('');
  const pageFilterValue = anncFilter.page;

  // scroll to top on mount
  // update page state from url params
  // reset search & sort inputs on unmount
  useEffect(() => {
    if (global.window) window.scrollTo(0, 0);
    setAnncFilter({ type: filterType.SORT, value: query.sort });

    if (isExternalMode) {
      query.completion &&
        setAnncFilter({
          type: filterType.COMPLETION,
          id: query.completion,
          value: query.completionName
        });
      query.directorFilter &&
        setAnncFilter({
          type: filterType.DIRECTOR,
          id: query.directorFilter,
          value: query.directorFilterName
        });
      query.locationFilter &&
        setAnncFilter({
          type: filterType.LOCATION,
          id: query.locationFilter,
          value: query.locationFilterName
        });
      setAnncFilter({
        type: filterType.ONLOAD,
        value: {
          searchQuery: query.searchQuery,
          rawSearchQuery: query.rawSearchQuery,
          startDateFilter: query.startDateFilter,
          endDateFilter: query.endDateFilter,
          page: query.page,
          dateFrom: Number(query.dateFrom) || undefined,
          dateTo: Number(query.dateTo) || undefined,
          dateValue: query.dateValue,
          customDateRange: query.customDateRange
        }
      });
    } else {
      query.completed &&
        setAnncFilter({
          type: filterType.FILTERS,
          id: query.completed,
          value: query.completedName
        });
      query.creation &&
        setAnncFilter({
          type: filterType.CREATION_TYPES,
          id: query.creation,
          value: query.creationName
        });
      query.repSearch &&
        setAnncFilter({
          type: filterType.ACC_MANAGERS,
          id: query.repSearch,
          value: query.repSearchName
        });
      setAnncFilter({
        type: filterType.ONLOAD,
        value: {
          searchQuery: query.searchQuery,
          rawSearchQuery: query.rawSearchQuery,
          homeSearch: query.homeSearch === 'true',
          directorSearch: query.directorSearch === 'true',
          familySearch: query.familySearch === 'true',
          stateSearch: query.stateSearch === 'true',
          page: query.page,
          dateFrom: Number(query.dateFrom) || undefined,
          dateTo: Number(query.dateTo) || undefined,
          dateValue: query.dateValue,
          customDateRange: query.customDateRange
        }
      });
    }
    setReducerIsLoaded(true);

    return () => {
      props.reset('forms.dashAnncs.searchText');
      props.reset('forms.dashAnncs.sort');
    };
  }, []);

  // reset page query params if the user clicks navigation link
  useEffect(() => {
    if (isEmpty(query)) setAnncFilter({ type: filterType.RESET });
  }, [filterType.RESET, query, setAnncFilter]);

  // clear search query state when the user clears the search input
  useEffect(() => {
    if (
      searchText === '' && // search input is cleared
      !!prevSearchInput.current // there is previous input
    ) {
      setAnncFilter({ type: filterType.SEARCH, value: '' });
      setAnncFilter({ type: filterType.SEARCH_FILTERED, value: '' });
    }

    prevSearchInput.current = searchText;
  }, [searchText]);

  // keep the page url param in sync with reducer hook when switching professional modes
  // in addition to interacting with page buttons
  // feature is used when returning to announcement list from annc details

  const memoizedAnncFilter = useMemo(
    () => ({
      ...anncFilter,
      isExternalMode
    }),
    [anncFilter, isExternalMode]
  );
  //TODO WebCleanUp2020 - memoizedAccountsFilter doesn't need isExternal, dashnav handling
  useURLParamSync(memoizedAnncFilter, location, ['selected']);

  //get client config data
  useEffect(() => {
    if (!client.config.data) fetchClientConfig({ includeReps: isEmployee });
  }, [client, fetchClientConfig, isEmployee, user]);

  // update memorials when filter, search, or sort change
  useEffect(() => {
    change('forms.dashAnncs.searchText', anncFilter.rawSearchQuery);
    change('forms.dashAnncs.filteredSearchText', anncFilter.searchQuery);
    change('forms.dashAnncs.sort', anncFilter.sort);
    change('forms.dashAnncs.date', anncFilter.dateValue);

    if (reducerIsLoaded && isExternalMode && dash.companyMemorials.lastUpdated < Date.now() - 5e2) {
      if (isDemoModeOn) {
        fetchDemoAnnouncements();
      } else {
        fetchCompanyMemorials({
          ...anncFilter,
          noHidden,
          id: fetchedCompanyId === 'demo-company' ? user?.company?._id : fetchedCompanyId,
          isLimitedAccess: true
        });
      }
    }

    if (reducerIsLoaded && !isExternalMode) {
      const {
        page,
        sort: sortFilter,
        dateFrom,
        dateTo,
        completed: completedFilter,
        creation: creationFilter,
        searchQuery,
        repSearch,
        homeSearch,
        familySearch,
        directorSearch,
        stateSearch
      } = anncFilter;
      const query = {
        creationFilter: creationFilter || undefined,
        sortFilter: sortFilter || undefined,
        completedFilter: completedFilter || undefined,
        searchQuery: searchQuery || undefined,
        fromDate: dateFrom || undefined,
        toDate: dateTo || undefined,
        repSearch: repSearch || undefined,
        homeSearch: homeSearch ? searchQuery : undefined,
        directorSearch: directorSearch ? searchQuery : undefined,
        familySearch: familySearch ? searchQuery : undefined,
        stateSearch: stateSearch ? searchQuery : undefined,
        page,
        limit: 10
      };
      fetchAnnouncementsStatsByDate(query);
    }
  }, [
    anncFilter,
    change,
    dash.companyMemorials.lastUpdated,
    fetchAnnouncementsStatsByDate,
    fetchCompanyMemorials,
    fetchDemoAnnouncements,
    fetchedCompanyId,
    isDemoModeOn,
    isExternalMode,
    location,
    noHidden,
    user
  ]);

  const renderButton = ({ type, value, id = '', image, images, showCharacterProfileImage }) => {
    const key = `${type}-${id}`;

    const selectedFilters = Object.values(anncFilter.selected).map(({ value }) => value);
    const active = selectedFilters.includes(key);
    const buttonClasses = classNames('button-text', { active });
    const _image = image || (!!images && images[`${active ? '' : 'in'}active`]);

    return (
      <button
        key={key}
        className="filter"
        onClick={() =>
          isDemoModeOn
            ? renderDemoToast({ id: 'annc-list-filter-button-demo' })
            : setAnncFilter({ type, value, id })
        }
      >
        {_image ? (
          <div
            className="filter-icon"
            style={{ backgroundImage: `url(${_image})`, borderRadius: id ? '10rem' : '0' }}
          />
        ) : showCharacterProfileImage ? (
          <CharacterProfileImage customClasses={['filter-icon']} name={value} />
        ) : null}
        <span className={buttonClasses}>{value}</span>
      </button>
    );
  };

  const renderFilterMenu = filters.map(({ header, collapsible, options }, i) => (
    <div key={`filter-group-${i}`} className="filter-section">
      {header && <div className="filter-section-header">{header}</div>}
      {options
        .slice(0, collapsible && (anncFilter[collapsible.state] ? undefined : collapsible.amt))
        .map(renderButton)}
      {collapsible && options.length > collapsible.amt && (
        <button onClick={() => setAnncFilter({ type: collapsible.type })}>
          Show {anncFilter[collapsible.state] ? 'Less' : 'All'}
        </button>
      )}
    </div>
  ));

  const renderFilterStatus = () => {
    const { searchQuery, sortName, selected } = anncFilter;
    const { memorials, memorialNext, memorialPrev } = store[
      isExternalMode ? 'external' : 'internal'
    ];
    const anncCount = qs.parse(memorialNext || memorialPrev)?.total || memorials?.length;

    activeFilters = Object.values(selected)
      .map(({ name }) => name)
      .filter(Boolean);

    const filterText = activeFilters.length && `Filtering by ${activeFilters.join(', ')}`;
    const sortText = sortName && `Sorting by ${sortName}`;
    const searchText = searchQuery && `Searching by "${searchQuery}"`;

    const statusText = [filterText, sortText, searchText].filter(Boolean).join(', ');

    if (!filterText && !sortText && !searchText) return null;

    return (
      <div className="annc-filter-status" onClick={clearFilters}>
        {statusText} ({anncCount} in List)
      </div>
    );
  };

  const clearFilters = () => {
    setAnncFilter({ type: filterType.RESET });
  };

  const handleSort = ({ target: { value } }) => {
    if (isDemoModeOn) return renderDemoToast({ id: 'annc-list-sort-demo' });
    setAnncFilter({ type: filterType.SORT, value });
  };

  const handleDateRangeSelection = ({ target: { value } }) => {
    if (isDemoModeOn) return renderDemoToast({ id: 'annc-list-daterange-demo' });
    setAnncFilter({ type: filterType.DATE, value });
  };

  const handleSearchSubmit = ({ searchText, filteredSearchText, inlineSearchTag }) => {
    if (isDemoModeOn) return renderDemoToast({ id: 'annc-list-filter-demo' });
    setAnncFilter({ type: filterType.SEARCH, value: searchText });
    setAnncFilter({ type: filterType.SEARCH_FILTERED, value: filteredSearchText });
    setAnncFilter({ type: filterType.INLINE_TAG, value: inlineSearchTag });
    searchInput.current.changeFocus();
  };

  // this feature will be expanded upon in the future to include other data, currently
  // it is used to display the user's current search text in a dropdown/typeahead with
  // the text 'Hit Enter'
  const suggestionArray = [props.searchText || '.']; // '.' is used to hide the suggestions on empty input

  const handleArrowClick = direction => {
    let newPage = 0;
    const scroll = Scroll.animateScroll;
    return () => {
      const page = parseInt(pageFilterValue);
      if (direction === 'prev') {
        newPage = page && page - 1;
      } else if (direction === 'next') {
        newPage = page + 1;
      }
      setAnncFilter({ type: 'PAGE', value: newPage });
      scroll.scrollToTop({ duration: 400 });
    };
  };

  const currentPage = parseInt(pageFilterValue);
  const selectCustomDateRange = anncFilter.customDateRange;

  const { memorials, memorialNext, memorialPrev } = store[isExternalMode ? 'external' : 'internal'];
  const totalMemorials =
    memorials && (qs.parse(memorialNext || memorialPrev)?.total || memorials.length);

  const anncDetailsClasses = classNames('announcements-v3-container');

  return (
    <div className={anncDetailsClasses}>
      <div className="announcements-body">
        {/*<div className="announcements-sidebar">{renderFilterMenu}</div>*/}
        <div className="annc-main">
          <div className="search-dropdown-container">
            <div className="title-container">
              <h1 className="title">Home</h1>
            </div>
            <Form
              className="annc-search-dropdown"
              model="forms.dashAnncs"
              onSubmit={handleSearchSubmit}
            >
              <TagFilterTypeahead
                placeholder="Search"
                customClass="annc-search-input"
                modelBase="forms.dashAnncs"
                searchInputModel=".searchText"
                filteredInputModel=".filteredSearchText"
                tagFilterModel=".inlineSearchTag"
                data={suggestionArray}
                pre={searchIcon}
                typeaheadRef={searchInput}
                tags={store[isExternalMode ? 'external' : 'internal'].searchTags}
                onSelect={() =>
                  handleSearchSubmit({
                    searchText: props.searchText,
                    filteredSearchText: props.filteredSearchText,
                    inlineSearchTag: props.inlineSearchTag
                  })
                }
              />
            </Form>
            {/* {renderFilterStatus()} */}
          </div>
          <div className="announcements-list-container">
            <div className="announcements-list">
              {!memorials ? (
                <ListSkeleton />
              ) : (
                <AnnouncementList
                  {...{
                    backgrounds,
                    themes,
                    memorials,
                    activeFilters,
                    searchText,
                    isExternalMode,
                    query,
                    isDemoModeOn
                  }}
                />
              )}
              {memorials &&
                !!memorials.length &&
                renderPagination({
                  currentPage,
                  startsAtZero: anncFilter.pageStartsAtZero,
                  handleArrowClick,
                  content: memorials,
                  next: memorialNext,
                  previous: memorialPrev,
                  total: totalMemorials,
                  pageStep: 10
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ client, dash, forms, authUser }) => ({
    client,
    dash,
    searchText: forms.dashAnncs.searchText,
    filteredSearchText: forms.dashAnncs.filteredSearchText,
    inlineSearchTag: forms.dashAnncs.inlineSearchTag,
    user: dash.user.data,
    isDemoModeOn: authUser?.data?.isDemoModeOn,
    isExternalMode: dash.dashNav.isExternalMode,
    isEmployee: dash.dashNav.isEmployee
  }),
  {
    fetchCompanyMemorials,
    fetchAnnouncementsStatsByDate,
    loadNext,
    change: formActions.change,
    reset: formActions.reset,
    fetchClientConfig,
    fetchDemoAnnouncements
  }
)(Announcements);
