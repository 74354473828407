import React, { useEffect, useCallback } from 'react';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';

import {
  ModalOverlay,
  ModalFooter,
  GradientModalHeader,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import {
  Form,
  InputGroup,
  isRequired,
  formActions,
  isEmail,
  isValidPhone,
  isValidZip,
  parsePhone
} from '@evdy/web-core/dist/components/shared/Input';

import { companyTypes } from '@evdy/web-etc/dist/lib/companyTypes';
import { abbreviatedStatesArray, abbreviatedProvincesArray } from '@evdy/web-etc/dist/lib/repZones';
import { normalizeStateProvince } from '@evdy/web-core/dist/lib/utils';
import {
  getSpecialStatesArray,
  getDirectorNumberStatesArray,
  getInsuranceLicenseNumberStatesArray,
  representativeIsNotMeStates
} from './helperFuncs';

import './HomeDetailsModal.scss';

const taxClasses = ['Sole Proprietor', 'Partnership', 'Corporation'];

const HomeDetailsModal = ({
  homeLocation,
  model,
  modalVisible,
  handleCloseModal,
  handleUpdateLocation,
  isValid,
  isInternal
}) => {
  const dispatch = useDispatch();
  const { reset, change, setFieldsValidity } = formActions;

  const { locationFormData, funeralHomeLicenseInfo = {}, user = {} } = useSelector(
    ({ funeralHome, client, authUser }) => ({
      locationFormData: funeralHome.forms.homeDetails,
      funeralHomeLicenseInfo: client.config.data.funeralHomeLicenseInfo,
      user: authUser.data
    })
  );
  const { isCompanyAdmin } = user || {};
  const {
    representativeIsNotMe: repIsNotMeFormData,
    address: formAddress = {},
    homeHasOwnTaxInfo: homeHasOwnTaxInfoFormData = false
  } = locationFormData || {};
  const { state: currentFormState } = formAddress || {};

  // client config data
  const clientHomeLicenseInfoArray = Object.entries(funeralHomeLicenseInfo);

  // specialStateLicense info visible conditionals && vars
  const specialStates = getSpecialStatesArray(clientHomeLicenseInfoArray);
  const isSpecialState = specialStates.includes(currentFormState);
  const labelText = `${funeralHomeLicenseInfo[currentFormState]?.specialStateLicenseInfo} *`;
  const labelText2 =
    funeralHomeLicenseInfo[currentFormState]?.specialStateLicenseInfo2 &&
    `${funeralHomeLicenseInfo[currentFormState]?.specialStateLicenseInfo2} *`;

  // funeralDirectorNum visible conditionals && vars
  const directorNumberStates = getDirectorNumberStatesArray(clientHomeLicenseInfoArray);
  const isFuneralDirectorStateSelected = !!directorNumberStates.find(
    ([state]) => state === currentFormState
  );
  const isFuneralDirectorNumberVisible =
    (currentFormState && isFuneralDirectorStateSelected && isCompanyAdmin) ||
    (isInternal && currentFormState && isFuneralDirectorStateSelected);

  // insuranceLicenseNumber visible conditionals && vars
  const insuranceLicenseNumberStates = getInsuranceLicenseNumberStatesArray(
    clientHomeLicenseInfoArray
  );
  const insuranceLicenseStateSelected = insuranceLicenseNumberStates.find(
    ([state]) => state === currentFormState
  );
  const isInsuranceLicenseNumberVisible =
    (currentFormState && insuranceLicenseStateSelected && isCompanyAdmin) ||
    (isInternal && currentFormState && insuranceLicenseStateSelected);

  // repIsNotMe visible conditionals && vars
  const isRepresentativeState = representativeIsNotMeStates.includes(currentFormState);
  const isRepresentativeNotMeVisible =
    (currentFormState && isRepresentativeState && isCompanyAdmin) ||
    (currentFormState && isRepresentativeState && isInternal);

  // funeralHome vars
  const {
    name,
    websiteUrl,
    type,
    address = {},
    phone,
    email,
    casesPerYear,
    placeId,
    yelpUrl,
    eFuneralHomeId,
    description,
    displayPublicly,
    facebookUrl,
    specialStateLicenseInfo = {},
    specialStateLicenseInfo2 = {},
    funeralDirectorNumber = '',
    insuranceLicenseNumber = '',
    representativeIsNotMe = false,
    taxID = '',
    taxClassification = '',
    homeHasOwnTaxInfo = false,
    representative = {},
    radiusServed,
    ownerInformedOfSpecialStateInfo = false
  } = homeLocation;
  const { street, street2, city, state, zip } = address || {};
  const { name: repName = {}, phone: repPhone, email: repEmail } = representative || {};
  const { first = '', last = '' } = repName || {};
  const { value: stateLicenseInfoValue = '' } = specialStateLicenseInfo || {};
  const { value: stateLicenseInfoValue2 = '' } = specialStateLicenseInfo2 || {};

  const compTypes = Object.entries(companyTypes);
  const regionArray = [...abbreviatedStatesArray, ...abbreviatedProvincesArray];

  const mergeFormValues = useCallback(() => {
    name && dispatch(change(`${model}.name`, name));
    websiteUrl && dispatch(change(`${model}.websiteUrl`, websiteUrl));
    type && dispatch(change(`${model}.type`, type));
    street && dispatch(change(`${model}.address.street`, street));
    street2 && dispatch(change(`${model}.address.street2`, street2));
    city && dispatch(change(`${model}.address.city`, city));
    state && dispatch(change(`${model}.address.state`, normalizeStateProvince(state)));
    zip && dispatch(change(`${model}.address.zip`, zip));
    phone && dispatch(change(`${model}.phone`, phone));
    email && dispatch(change(`${model}.email`, email));
    casesPerYear && dispatch(change(`${model}.casesPerYear`, casesPerYear));
    radiusServed && dispatch(change(`${model}.radiusServed`, radiusServed));
    stateLicenseInfoValue &&
      dispatch(change(`${model}.specialStateLicenseInfo`, stateLicenseInfoValue));
    stateLicenseInfoValue2 &&
      dispatch(change(`${model}.stateLicenseInfoValue2`, stateLicenseInfoValue2));
    funeralDirectorNumber &&
      dispatch(change(`${model}.funeralDirectorNumber`, funeralDirectorNumber));
    insuranceLicenseNumber &&
      dispatch(change(`${model}.insuranceLicenseNumber`, insuranceLicenseNumber));
    representativeIsNotMe &&
      dispatch(change(`${model}.representativeIsNotMe`, representativeIsNotMe));
    first && dispatch(change(`${model}.representative.name.first`, first));
    last && dispatch(change(`${model}.representative.name.last`, last));
    repPhone && dispatch(change(`${model}.representative.phone`, repPhone));
    repEmail && dispatch(change(`${model}.representative.email`, repEmail));
    taxID && dispatch(change(`${model}.taxID`, taxID));
    taxClassification && dispatch(change(`${model}.taxClassification`, taxClassification));
    homeHasOwnTaxInfo && dispatch(change(`${model}.homeHasOwnTaxInfo`, homeHasOwnTaxInfo));
    facebookUrl && dispatch(change(`${model}.facebookUrl`, facebookUrl));
    placeId && dispatch(change(`${model}.placeId`, placeId));
    yelpUrl && dispatch(change(`${model}.yelpUrl`, yelpUrl));
    eFuneralHomeId && dispatch(change(`${model}.eFuneralHomeId`, eFuneralHomeId));
    description && dispatch(change(`${model}.description`, description));
    displayPublicly && dispatch(change(`${model}.displayPublicly`, displayPublicly));
    ownerInformedOfSpecialStateInfo &&
      dispatch(change(`${model}.ownerInformedOfSpecialStateInfo`, ownerInformedOfSpecialStateInfo));
  }, [
    name,
    dispatch,
    change,
    model,
    websiteUrl,
    type,
    street,
    street2,
    city,
    state,
    zip,
    phone,
    email,
    casesPerYear,
    radiusServed,
    stateLicenseInfoValue,
    stateLicenseInfoValue2,
    funeralDirectorNumber,
    insuranceLicenseNumber,
    representativeIsNotMe,
    first,
    last,
    repPhone,
    repEmail,
    facebookUrl,
    placeId,
    yelpUrl,
    eFuneralHomeId,
    description,
    displayPublicly,
    taxID,
    taxClassification,
    homeHasOwnTaxInfo,
    ownerInformedOfSpecialStateInfo
  ]);

  // merge and reset form on setVisible
  useEffect(() => {
    if (modalVisible) {
      mergeFormValues();
    } else {
      dispatch(reset(model));
    }
  }, [dispatch, mergeFormValues, modalVisible, model, reset]);

  // reset specialStateLicenseInfo, funeralDirectorNumber, and insuranceLicenseNumber on state change
  useEffect(() => {
    if (state && state !== currentFormState) {
      dispatch(reset(`${model}.specialStateLicenseInfo`));
      dispatch(reset(`${model}.specialStateLicenseInfo2`));
      dispatch(reset(`${model}.funeralDirectorNumber`));
      dispatch(reset(`${model}.insuranceLicenseNumber`));
      dispatch(
        setFieldsValidity(model, {
          '.specialStateLicenseInfo': true,
          '.specialStateLicenseInfo2': true,
          '.funeralDirectorNumber': true,
          '.insuranceLicenseNumber': true
        })
      );
    } else if (state && state === currentFormState) {
      dispatch(change(`${model}.specialStateLicenseInfo`, stateLicenseInfoValue));
      dispatch(change(`${model}.specialStateLicenseInfo2`, stateLicenseInfoValue2));
      dispatch(change(`${model}.funeralDirectorNumber`, funeralDirectorNumber));
      dispatch(change(`${model}.insuranceLicenseNumber`, insuranceLicenseNumber));
    }
  }, [
    change,
    currentFormState,
    dispatch,
    model,
    reset,
    state,
    stateLicenseInfoValue,
    stateLicenseInfoValue2,
    funeralDirectorNumber,
    insuranceLicenseNumber,
    setFieldsValidity
  ]);

  // revalidate conditional fields after unchecking boxes for special fields
  useEffect(() => {
    if (!repIsNotMeFormData) {
      dispatch(
        setFieldsValidity(model, {
          '.representative.name.first': true,
          '.representative.name.last': true,
          '.representative.phone': true,
          '.representative.email': true
        })
      );
      //reset form values in case submit happens after fields were filled && checkbox unchecked
      dispatch(reset(`${model}.representative.name.first`));
      dispatch(reset(`${model}.representative.name.last`));
      dispatch(reset(`${model}.representative.phone`));
      dispatch(reset(`${model}.representative.email`));
      // else if box is checked again and back end data exists, merge
    } else if (repIsNotMeFormData && representativeIsNotMe) {
      dispatch(change(`${model}.representativeIsNotMe`, representativeIsNotMe));
      dispatch(change(`${model}.representative.name.first`, first));
      dispatch(change(`${model}.representative.name.last`, last));
      dispatch(change(`${model}.representative.phone`, repPhone));
      dispatch(change(`${model}.representative.email`, repEmail));
    }
    if (!homeHasOwnTaxInfoFormData) {
      dispatch(
        setFieldsValidity(model, {
          '.taxID': true,
          '.taxClassification': true
        })
      );
      //reset form values in case submit happens after fields were filled && checkbox unchecked
      dispatch(reset(`${model}.taxID`));
      dispatch(reset(`${model}.taxClassification`));
      // else if box is checked again and back end data exists, merge
    } else if (homeHasOwnTaxInfoFormData && homeHasOwnTaxInfo) {
      dispatch(change(`${model}.homeHasOwnTaxInfo`, homeHasOwnTaxInfo));
      dispatch(change(`${model}.taxID`, taxID));
      dispatch(change(`${model}.taxClassification`, taxClassification));
    }
  }, [repIsNotMeFormData, homeHasOwnTaxInfoFormData, representativeIsNotMe, homeHasOwnTaxInfo]);

  return (
    <ModalOverlay {...{ modalVisible }} closeModal={handleCloseModal}>
      <ResponsiveContent>
        <GradientModalHeader closeModal={handleCloseModal} headerCopy="Edit Location" />
        <div className="home-details-modal">
          <Form model={model}>
            <div className="input-row">
              <InputGroup
                model=".name"
                labelText="Location's Public Name *"
                validators={{ isRequired }}
                messages={{ isRequired: 'Name is required' }}
              />
              <InputGroup model=".websiteUrl" labelText="Location's Website" />
            </div>
            <div className="input-row">
              <InputGroup model=".type" type="select" labelText="Type of Business">
                <option />
                {compTypes.map(([key, val]) => (
                  <option key={key} value={val}>
                    {startCase(key)}
                  </option>
                ))}
              </InputGroup>
            </div>
            <div className="input-row">
              <InputGroup
                model=".address.street"
                labelText="Address 1 *"
                validators={{ isRequired }}
                messages={{ isRequired: 'Address 1 is required' }}
              />
              <InputGroup model=".address.street2" labelText="Address 2" />
            </div>
            <div className="input-row">
              <InputGroup
                model=".address.city"
                labelText="City *"
                validators={{ isRequired }}
                messages={{ isRequired: 'City is required' }}
              />
              <InputGroup
                inline
                full
                type="select"
                model=".address.state"
                labelText="State/Province *"
                validators={{ isRequired }}
                messages={{ isRequired: 'State/Province is required' }}
              >
                <option />
                {regionArray.map((region, i) => (
                  <option value={region} key={i}>
                    {region}
                  </option>
                ))}
              </InputGroup>
            </div>
            <div className="input-row">
              <InputGroup
                model=".address.zip"
                labelText="Postal Code *"
                validators={{ isRequired, isValidZip }}
                messages={{
                  isRequired: 'Postal Code is required',
                  isValidZip: 'Please enter a valid zip code'
                }}
              />
              {isSpecialState && (
                <InputGroup
                  {...{ labelText }}
                  model=".specialStateLicenseInfo"
                  validators={{ isRequired }}
                  messages={{ isRequired: 'Required in your state' }}
                />
              )}
            </div>
            {isSpecialState && labelText2 && (
              <div className="input-row">
                <InputGroup
                  model=".specialStateLicenseInfo2"
                  labelText={labelText2}
                  validators={{ isRequired }}
                  messages={{ isRequired: 'Required in your state' }}
                />
              </div>
            )}
            <div className="input-row">
              <InputGroup
                type="tel"
                model=".phone"
                labelText="Location's Phone Number *"
                validators={{ isRequired, isValidPhone }}
                parser={parsePhone}
                messages={{
                  isRequired: 'Phone Number is required',
                  isValidPhone: 'Please enter a valid phone number'
                }}
              />
              <InputGroup
                type="email"
                model=".email"
                labelText="Location's General Email *"
                validators={{ isRequired, isEmail }}
                messages={{
                  isRequired: 'Email is required',
                  isEmail: 'Please enter a valid email address'
                }}
              />
            </div>
            {(isFuneralDirectorNumberVisible || isInsuranceLicenseNumberVisible) && (
              <div className="input-row">
                {isFuneralDirectorNumberVisible && (
                  <InputGroup
                    model=".funeralDirectorNumber"
                    labelText="Funeral Director Number *"
                    validators={!isInternal ? { isRequired } : {}}
                    messages={{
                      isRequired: 'Required in your state'
                    }}
                  />
                )}
                {isInsuranceLicenseNumberVisible && (
                  <InputGroup
                    model=".insuranceLicenseNumber"
                    labelText="Insurance License Number *"
                    validators={!isInternal ? { isRequired } : {}}
                    messages={{
                      isRequired: 'Required in your state'
                    }}
                  />
                )}
              </div>
            )}
            <div className="input-row">
              <InputGroup model=".casesPerYear" labelText="Estimated Cases Per Year" />
            </div>
            {isRepresentativeNotMeVisible && (
              <div className="input-row">
                <InputGroup
                  type="checkbox"
                  model=".representativeIsNotMe"
                  labelText="My Funeral Home Representative is not me"
                />
              </div>
            )}
            {isCompanyAdmin && (
              <div className="input-row">
                <InputGroup
                  type="checkbox"
                  model=".homeHasOwnTaxInfo"
                  labelText="This Location uses a different Tax ID than my Account"
                />
              </div>
            )}
            {isInternal && (
              <div className="input-row">
                <InputGroup
                  type="checkbox"
                  model=".ownerInformedOfSpecialStateInfo"
                  labelText="I informed the owner of state regulations and forms"
                />
              </div>
            )}
            {repIsNotMeFormData && (
              <>
                <div className="input-row">
                  <InputGroup
                    model=".representative.name.first"
                    labelText="Funeral Representative First Name *"
                    validators={{ isRequired }}
                    messages={{
                      isRequired: 'Required'
                    }}
                  />
                  <InputGroup
                    model=".representative.name.last"
                    labelText="Funeral Representative Last Name *"
                    validators={{ isRequired }}
                    messages={{
                      isRequired: 'Required'
                    }}
                  />
                </div>
                <div className="input-row">
                  <InputGroup
                    model=".representative.phone"
                    labelText="Funeral Representative Mobile Phone *"
                    parser={parsePhone}
                    validators={{ isRequired, isValidPhone }}
                    messages={{
                      isRequired: 'Required',
                      isValidPhone: 'Please enter a valid phone number'
                    }}
                  />
                  <InputGroup
                    model=".representative.email"
                    labelText="Funeral Representative Email *"
                    validators={{ isRequired, isEmail }}
                    messages={{
                      isRequired: 'Required',
                      isEmail: 'Please enter a valid email address'
                    }}
                  />
                </div>
              </>
            )}
            {homeHasOwnTaxInfoFormData && (
              <div className="input-row">
                <InputGroup
                  model=".taxID"
                  labelText="Tax ID Number *"
                  validators={{ isRequired }}
                  messages={{ isRequired: 'Fields is required' }}
                />
                <InputGroup
                  validators={{ isRequired }}
                  messages={{ isRequired: 'Field is required' }}
                  model=".taxClassification"
                  type="select"
                  labelText="Tax Classificiation *"
                >
                  <option />
                  {taxClasses.map(c => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </InputGroup>
              </div>
            )}
            {isInternal && (
              <div className="input-row">
                <InputGroup model=".facebookUrl" labelText="Facebook Page URL" />
                <InputGroup model=".placeId" labelText="Google Place ID" />
              </div>
            )}
            {isInternal && (
              <div className="input-row">
                <InputGroup model=".yelpUrl" labelText="Yelp Page URL" />
                <InputGroup model=".eFuneralHomeId" labelText="eFuneral Home ID" />
              </div>
            )}
            {isInternal && (
              <div className="input-row single-full">
                <InputGroup type="textarea" model=".description" labelText="Bio/About" />
              </div>
            )}
            {isInternal && (
              <InputGroup
                model=".displayPublicly"
                type="checkbox"
                customClass="display-public-checkbox"
                labelText="Display Publicly"
              />
            )}
          </Form>
        </div>
        <ModalFooter
          mainButtonText="Save"
          mainButtonFn={handleUpdateLocation}
          disabled={!isValid}
          secondaryButtonText="Cancel"
          secondaryButtonFn={handleCloseModal}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default HomeDetailsModal;
