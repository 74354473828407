import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Form,
  InputGroup,
  formActions,
  parsePhone,
  isRequired,
  isValidPhone,
  isEmail
} from '@evdy/web-core/dist/components/shared/Input';
import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import InfoWindow from '@evdy/web-core/dist/components/shared/elements/InfoWindow';
import { authUserOperations } from '@evdy/web-redux/dist/lib/authUser';

import './PersonalInfoTab.scss';
const model = 'dash.profile.forms.personalInfo';

const tshirtSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'];
const jobTitles = {
  administrativeAssistant: 'Administrative Assistant',
  apprentice: 'Apprentice',
  assistant: 'Assistant/Support',
  caregiver: 'Caregiver',
  celebrant: 'Celebrant',
  CEO: 'CEO',
  chaplain: 'Chaplain',
  clergy: 'Clergy',
  director: 'Director',
  embalmer: 'Embalmer',
  familyServicesCounselor: 'Family Services Counselor',
  funeralArranger: 'Funeral Arranger',
  funeralDirector: 'Funeral Director',
  generalManager: 'General Manager',
  griefCounselor: 'Grief Counselor',
  intern: 'Intern',
  manager: 'Manager',
  mortician: 'Mortician',
  nurse: 'Nurse',
  officeAdministrator: 'Office Administrator',
  other: 'Other',
  owner: 'Owner',
  preneedCounselor: 'Preneed Counselor',
  president: 'President',
  socialWorker: 'Social Worker',
  vicePresident: 'Vice President'
};

const { editUserProfile, resetMeta } = authUserOperations;

const PersonalInfoTab = ({
  user,
  //redux actions
  resetMeta,
  editUserProfile,
  //rrf actions
  change,
  merge,
  personalInfo,
  isValid
}) => {
  const {
    name = {},
    email = '',
    shellEmail = '',
    phone = '',
    title = '',
    bio = '',
    shirtSize = '',
    image = ''
  } = user?.data;
  const { lastUpdated: userLastUpdated, error: { message: errorMessage = '' } = {} } = user;

  const successToastId = 'crisp-toast';
  const errorToastId = 'burnt-toast';

  const toastInfo = (id, copy, type = 'info') => {
    toast(copy, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      type: type === 'error' ? toast.TYPE.ERROR : toast.TYPE.INFO,
      toastId: id
    });
  };

  const userPersonalInfo = useMemo(() => {
    return {
      name,
      email: email || shellEmail,
      phone,
      title,
      bio,
      shirtSize,
      image
    };
  }, [bio, email, image, name, phone, shellEmail, shirtSize, title]);

  useEffect(() => {
    merge(model, userPersonalInfo);
  }, [merge, userPersonalInfo]);

  // display toast on success/save confirmation
  useEffect(() => {
    if (userLastUpdated >= Date.now() - 50 && !errorMessage && !toast.isActive(successToastId)) {
      toastInfo(successToastId, 'Successfully saved changes');
      resetMeta();
    }
  }, [errorMessage, resetMeta, userLastUpdated]);

  // display toast when edit fails to save
  useEffect(() => {
    if (errorMessage) {
      toastInfo(errorToastId, errorMessage, 'error');
      resetMeta();
    }
  }, [errorMessage, resetMeta]);

  const handleSubmit = () => {
    editUserProfile(personalInfo);
  };

  const handleRemoveImage = () => {
    editUserProfile({ image: '' });
  };

  const handleUploadFinish = url => {
    editUserProfile({ image: url });
  };

  return (
    <div className="personal-info-tab">
      <ImageHandler
        {...{ handleUploadFinish, handleRemoveImage }}
        currentImg={personalInfo.image}
        modalTitle="Profile Image"
      />
      <Form model={model} onSubmit={handleSubmit} className="personal-info">
        <div className="input-row">
          <InputGroup required inline full model=".name.first" labelText="First Name" />
          <InputGroup required inline full model=".name.last" labelText="Last Name" />
        </div>
        <div className="input-row">
          <InputGroup
            required
            inline
            full
            model=".email"
            labelText="Email Address"
            validators={{ isRequired, isEmail }}
            messages={{
              isRequired: 'An email address is required',
              isEmail: 'Please enter a valid email address'
            }}
          />
          <InputGroup
            required
            inline
            full
            type="tel"
            model=".phone"
            labelText="Mobile Phone Number"
            parser={parsePhone}
            validators={{ isRequired, isValidPhone }}
            messages={{
              isRequired: 'A phone number is required',
              isValidPhone: 'Please enter a valid phone number'
            }}
          />
        </div>
        <div className="input-row">
          <InputGroup inline full type="select" labelText="Role" model=".title">
            <option />
            {Object.entries(jobTitles).map(([value, titleName], i) => (
              <option key={i} value={value}>
                {titleName}
              </option>
            ))}
          </InputGroup>
          <div className="input-with-question-mark">
            <InputGroup inline full model=".shirtSize" labelText="T-Shirt Size" type="select">
              <option />
              {tshirtSizes.map((size, i) => (
                <option key={i}>{size}</option>
              ))}
            </InputGroup>
            <InfoWindow noClose outsideText="?" windowClass="question-box">
              We’d love to send you an Everdays T-Shirt as a way to thank you for being a valued
              partner!
            </InfoWindow>
          </div>
        </div>
        <div className="input-row half-row">
          <InputGroup type="textarea" inline full model=".bio" labelText="Bio/About" />
        </div>
        <div className="controls">
          <button disabled={!isValid} type="submit">
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default connect(
  ({ dash, authUser }) => ({
    user: authUser,
    personalInfo: dash.profile.forms.personalInfo,
    isValid: dash.profile.forms.profileMeta.personalInfo.$form.valid
  }),
  {
    change: formActions.change,
    merge: formActions.merge,
    editUserProfile,
    resetMeta
  }
)(PersonalInfoTab);
