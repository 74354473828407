import { useReducer } from 'react';
import merge from 'lodash/merge';
import moment from 'moment/moment';
import { nameString, getStartDate } from '@evdy/web-core/dist/lib/utils';

const sortFilterOptions = [
  { name: 'Sort by...', value: '' },
  { name: 'Recency', value: '-creationDate' },
  { name: 'Views', value: '-visitStats.aggregate.total.all' }
];

const dateFilterOptions = [
  { name: 'All Dates', value: 'all-dates' },
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Last 7 Days', value: '7-days' },
  { name: 'Last 30 Days', value: '30-days' },
  { name: 'Last 12 Months', value: '12-months' },
  { name: 'Custom', value: 'custom' }
];

const dropdownOptions = {
  sortFilterOptions,
  dateFilterOptions
};

const filterType = {
  ONLOAD: 'ONLOAD',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  FILTERS: 'FILTERS',
  ALL_CREATION_TYPES: 'ALL_CREATION_TYPES',
  CREATION_TYPES: 'CREATION_TYPES',
  ACC_MANAGERS_SHOWALL: 'ACC_MANAGERS_SHOWALL',
  CLEAR_ACC_MANAGERS: 'CLEAR_ACC_MANAGERS',
  ACC_MANAGERS: 'ACC_MANAGERS',
  SEARCH: 'SEARCH',
  SEARCH_FILTERED: 'SEARCH_FILTERED',
  INLINE_TAG: 'INLINE_TAG',
  SORT: 'SORT',
  DATE: 'DATE',
  PAGE: 'PAGE',
  RESET: 'RESET'
};

const getFilters = reps => {
  return [
    {
      header: 'Filter by',
      options: [
        {
          id: '',
          type: filterType.CLEAR_FILTERS,
          value: 'All'
        },
        {
          id: 'Completed',
          type: filterType.FILTERS,
          value: 'Completed'
        },
        {
          id: 'Incomplete',
          type: filterType.FILTERS,
          value: 'Incomplete'
        },
        {
          id: 'Active',
          type: filterType.FILTERS,
          value: 'Active'
        },
        {
          id: 'Has_FH',
          type: filterType.FILTERS,
          value: 'Has Funeral Home'
        },
        {
          id: 'Precoa',
          type: filterType.FILTERS,
          value: 'Precoa'
        },
        {
          id: 'Premium',
          type: filterType.FILTERS,
          value: 'Premium'
        }
      ]
    },
    {
      header: 'Creation Types',
      options: [
        {
          id: '',
          type: filterType.ALL_CREATION_TYPES,
          value: 'All'
        },
        {
          id: 'Dashboard',
          type: filterType.CREATION_TYPES,
          value: 'Dashboard'
        },
        {
          id: 'Smart_Director_Integration',
          type: filterType.CREATION_TYPES,
          value: 'Smart Director'
        },
        {
          id: 'SRS_Integration',
          type: filterType.CREATION_TYPES,
          value: 'SRS'
        },
        {
          id: 'Passare_Integration',
          type: filterType.CREATION_TYPES,
          value: 'Passare'
        },
        {
          id: 'All_Channel',
          type: filterType.CREATION_TYPES,
          value: 'All Channel'
        },
        {
          id: 'App_(DTC)',
          type: filterType.CREATION_TYPES,
          value: 'App (DTC)'
        },
        {
          id: 'Employee_Created',
          type: filterType.CREATION_TYPES,
          value: 'Employee Created'
        }
      ]
    },
    {
      header: 'Account Managers',
      collapsible: {
        type: filterType.ACC_MANAGERS_SHOWALL,
        amt: 6,
        state: 'repFilterShowAll'
      },
      options: [
        {
          type: filterType.CLEAR_ACC_MANAGERS,
          value: 'All Reps'
        },
        ...reps
          .slice(0)
          .sort((a, b) => nameString(a.name).localeCompare(nameString(b.name)))
          .map(({ _id, name, image }) => ({
            id: _id,
            type: filterType.ACC_MANAGERS,
            value: nameString(name),
            image,
            showCharacterProfileImage: true
          }))
      ]
    }
  ];
};

const initialState = {
  page: 1,
  pageStartsAtZero: false,
  sort: '',
  sortName: '',
  dateFrom: '',
  dateTo: '',
  customeDateRange: false,
  dateValue: 'all-dates',
  completed: '',
  completedName: '',
  creation: '',
  creationName: '',
  rawSearchQuery: '',
  searchQuery: '',
  repSearch: '',
  repSearchName: '',
  homeSearch: false,
  familySearch: false,
  directorSearch: false,
  stateSearch: false,
  selected: {
    filters: {
      value: `${filterType.CLEAR_FILTERS}-`,
      name: ''
    },
    createTypes: {
      value: `${filterType.ALL_CREATION_TYPES}-`,
      name: ''
    },
    accountManagers: {
      value: `${filterType.CLEAR_ACC_MANAGERS}-`,
      name: ''
    }
  }
};

const resetPage = {
  page: 1
};

const resetOverlapingFilters = {
  selected: {
    filters: {
      value: `${filterType.CLEAR_FILTERS}-`,
      name: ''
    }
  },
  completed: '',
  completedName: '',
  page: 1
};

const resetOverlapingCreationTypes = {
  selected: {
    createTypes: {
      value: `${filterType.ALL_CREATION_TYPES}-`,
      name: ''
    }
  },
  creation: '',
  creationName: '',
  page: 1
};

const resetOverlapingAccManagers = {
  selected: {
    accountManagers: {
      value: `${filterType.CLEAR_ACC_MANAGERS}-`,
      name: ''
    }
  },
  repSearch: '',
  page: 1
};

const resetInlineTags = {
  homeSearch: false,
  familySearch: false,
  directorSearch: false,
  stateSearch: false
};

const resetSearch = {
  rawSearchQuery: '',
  searchQuery: ''
};

const resetSort = {
  sort: '',
  sortName: ''
};

const resetDate = {
  dateFrom: '',
  dateTo: '',
  customDateRange: false,
  dateValue: 'all-dates'
};

const filterReducer = (state, { type, value, id, name }) => {
  const today = moment();

  switch (type) {
    case filterType.ONLOAD:
      return {
        ...merge(state, value)
      };

    case filterType.PAGE:
      return {
        ...merge(state, {
          page: value
        })
      };

    case filterType.CLEAR_FILTERS:
      return {
        ...merge(state, resetOverlapingFilters)
      };

    case filterType.FILTERS: {
      const selectionProperty = {
        selected: {
          filters: {
            value: `${type}-${id}`, // used for the filter menu
            name: value // used for the status text
          }
        }
      };
      return {
        ...merge(state, resetOverlapingFilters, selectionProperty),
        completed: id, // used for the submission
        completedName: value
      };
    }

    case filterType.ALL_CREATION_TYPES: {
      return {
        ...merge(state, resetOverlapingCreationTypes)
      };
    }

    case filterType.CREATION_TYPES: {
      const selectionProperty = {
        selected: {
          createTypes: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingCreationTypes, selectionProperty),
        creation: id,
        creationName: value
      };
    }

    case filterType.CLEAR_ACC_MANAGERS: {
      return {
        ...merge(state, resetOverlapingAccManagers),
        creation: 'Employee_Created'
      };
    }

    case filterType.ACC_MANAGERS: {
      const selectionProperty = {
        selected: {
          accountManagers: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingAccManagers, selectionProperty),
        repSearch: id,
        repSearchName: value
      };
    }

    case filterType.ACC_MANAGERS_SHOWALL:
      return {
        ...state,
        repFilterShowAll: !state.repFilterShowAll
      };

    case filterType.SEARCH:
      return {
        ...merge(state, resetPage),
        rawSearchQuery: value
      };

    case filterType.SEARCH_FILTERED:
      return {
        ...merge(state, resetPage),
        searchQuery: value
      };

    case filterType.INLINE_TAG:
      switch (value) {
        case 'home':
          return {
            ...state,
            ...resetInlineTags,
            homeSearch: true
          };
        case 'family':
          return {
            ...state,
            ...resetInlineTags,
            familySearch: true
          };
        case 'director':
          return {
            ...state,
            ...resetInlineTags,
            directorSearch: true
          };
        case 'state':
          return {
            ...state,
            ...resetInlineTags,
            stateSearch: true
          };
        default:
          return {
            ...state,
            ...resetInlineTags
          };
      }

    case filterType.SORT:
      let sortName;
      switch (value) {
        case '':
          sortName = '';
          break;
        case '-creationDate':
          sortName = 'Recency';
          break;
        case '-visitStats.aggregate.total.all':
          sortName = 'Views';
          break;
        default:
          sortName = '';
      }
      return {
        ...merge(state, resetPage),
        sort: value,
        sortName
      };

    case filterType.DATE:
      // let range;
      let date;
      switch (value) {
        case 'all-dates':
          date = {
            dateFrom: '',
            dateTo: '',
            customDateRange: false,
            dateValue: value
          };
          break;
        case 'today':
          date = {
            dateFrom: today.startOf('day').valueOf(),
            dateTo: today.endOf('day').valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case 'yesterday':
          date = {
            dateFrom: getStartDate('day').valueOf(),
            dateTo: getStartDate('day')
              .endOf('day')
              .valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case '7-days':
          date = {
            dateFrom: getStartDate('day', 7).valueOf(),
            dateTo: today.endOf('day').valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case '30-days':
          date = {
            dateFrom: getStartDate('month').valueOf(),
            dateTo: today.valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case '12-months':
          date = {
            dateFrom: getStartDate('month', 12).valueOf(),
            dateTo: today.valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case 'custom':
          date = {
            dateFrom: state.dateFrom,
            dateTo: state.dateTo,
            customDateRange: true,
            dateValue: value
          };
          break;
        default:
          // value from custom range selector
          date = {
            dateFrom: moment(value.from)
              .startOf('days')
              .valueOf(),
            dateTo: moment(value.to)
              .endOf('days')
              .valueOf(),
            customDateRange: true,
            dateValue: 'custom'
          };
      }
      return {
        ...merge(state, resetPage),
        ...date
      };

    case filterType.RESET:
      return {
        ...merge(
          state,
          resetOverlapingFilters,
          resetOverlapingCreationTypes,
          resetOverlapingAccManagers,
          resetSearch,
          resetInlineTags,
          resetSort,
          resetDate,
          resetPage
        )
      };

    default:
      return state;
  }
};

const useInternalAnncFilter = () => {
  const [internalAnncFilter, setInternalAnncFilter] = useReducer(filterReducer, initialState);
  return [internalAnncFilter, setInternalAnncFilter, getFilters, filterType, dropdownOptions];
};

export default useInternalAnncFilter;
