import React from 'react';

import './Stepper.scss';

const ProgressBar = ({ stepCount, stepIdx, stepPercentage }) => {
  return (
    <div className="progress-bar">
      <p className="step-count">
        {stepIdx + 1} of {stepCount}
      </p>
      <div className="progress-line">
        <div
          className="progress-fill"
          style={{
            width: `${stepPercentage * 100}%`
          }}
        />
      </div>
    </div>
  );
};

const Stepper = ({
  finishButtonText,
  handleBack,
  handleFinish,
  handleNext,
  isBackDisabled,
  nextButtonText = 'Next',
  stepIdx,
  steps = []
}) => {
  const stepComponents = steps
    .map(({ Component }, i) => <Component {...{ handleNext }} key={i} />)
    .filter(Boolean);
  const stepCount = stepComponents?.length;
  const stepPercentage = (stepIdx + 1) / stepCount;
  const step = stepComponents[stepIdx];

  const isLastStep = stepIdx + 1 === steps?.length;

  const customNextButtonTexts = steps.map(({ nextBtnText }) => nextBtnText);

  const nextButtonCopy = isLastStep
    ? finishButtonText
    : customNextButtonTexts[stepIdx] || nextButtonText;
  const nextFn = () => {
    isLastStep ? handleFinish() : window.requestAnimationFrame(() => handleNext());
  };

  return (
    <div className="dash-stepper">
      <div className="dash-stepper-content">
        <div className="dash-stepper-body">{step}</div>
        <div className="dash-stepper-bar">
          <div className="stepper-bar-container">
            {stepIdx && !isBackDisabled ? (
              <button className="back-button" onClick={handleBack}>
                Back
              </button>
            ) : (
              <div className="back-button-placeholder-for-spacing" />
            )}
            <ProgressBar {...{ stepCount, stepIdx, stepPercentage }} />
            <button className="next-button" onClick={nextFn}>
              {nextButtonCopy}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
