import React from 'react';
import { connect } from 'react-redux';

import ObituaryForm from '../../forms/ObituaryForm';
import {
  ModalOverlay,
  ResponsiveContent,
  SolidColorModalHeader,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';

import './ObituaryModal.scss';

const ObituaryModal = props => {
  const {
    modalVisible,
    handleCloseModal,
    model,
    handleSubmit,
    placeholder,
    isValid,
    hasObit
  } = props;

  const infoCopy = 'Obituary';

  return (
    <ModalOverlay {...{ modalVisible }} closeModal={handleCloseModal}>
      <ResponsiveContent>
        <SolidColorModalHeader
          closeModal={handleCloseModal}
          headerCopy={`${hasObit ? 'Edit Obituary' : 'Add Obituary'}`}
        />
        <div className="info-copy">{infoCopy}</div>
        <ObituaryForm {...{ model, placeholder, modalVisible }} />
        <ModalFooter
          mainButtonText="Save"
          mainButtonFn={handleSubmit}
          secondaryButtonText="Cancel"
          secondaryButtonFn={handleCloseModal}
          disabled={!isValid}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(({ inviteCreation }) => ({
  isValid: inviteCreation.forms.formsMeta.obituary.$form.valid
}))(ObituaryModal);
