import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import Stepper from '../AnncBuilderSteps/Stepper';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import ConfirmBackButton from '@evdy/web-core/dist/components/shared/utility/ConfirmBackButton';

import { initUserAction } from '@evdy/web-redux/dist/actions/dash/onboarding/initUser';
import { checkPhoneAction } from '@evdy/web-redux/dist/actions/dash/onboarding/checkPhone';
import { verifyPhoneAction } from '@evdy/web-redux/dist/actions/dash/onboarding/verifyPhone';
import { addEmailAction } from '@evdy/web-redux/dist/actions/dash/onboarding/addEmail';
import { updateEmailAction } from '@evdy/web-redux/dist/actions/dash/onboarding/updateEmail';
import { addCompanyInfoAction } from '@evdy/web-redux/dist/actions/dash/onboarding/addCompanyInfo';
import { finishOnboardAction } from '@evdy/web-redux/src/actions/dash/onboarding/finishOnboard';
import { appLogin } from '@evdy/web-redux/dist/actions/dash/user';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { useStepper } from '../../lib/dashHooks';
import { onboardSteps, onboardStepOrder } from './onboardingSteps';

import onboardPersona from './images/director-onboarding-persona-lisa.png';

import './Onboarding.scss';

const Onboarding = props => {
  const { dispatch, location, onboarding, user, fetchedGoogleAddress } = props;
  const { onboardCompany, onboardUser, forms: onboardForms } = onboarding;
  const {
    data: onboardUserData,
    error: onboardUserError,
    checkPhone,
    phoneVerified,
    emailAdded,
    emailUpdated,
    companyInfoAdded,
    isFinished
  } = onboardUser;
  const { data: userData = {} } = user;
  const { query } = location;

  const userOnboardType =
    onboardUserData?.onboardInfo?.onboardType || userData?.onboardInfo?.onboardType;
  const isInvitedUser = userOnboardType === 'invited-user';

  const [stepIdx, setStepIdx] = useStepper(onboardStepOrder);

  // add/remove this class to help with hiding intercom in Onboarding
  // initialize facebook login
  useEffect(() => {
    document.body.classList.add('onboarding-stepper');

    return () => document.body.classList.remove('onboarding-stepper');
  }, []);

  // Handle an auth'd user entering Onboarding
  useEffect(() => {
    const {
      _id = '',
      fbId = '',
      email = '',
      shellEmail = '',
      fbEmail = '',
      company = '',
      phone = '',
      onboardInfo: { lastOnboardStep = 'check_phone' } = {}
    } = userData || {};

    // if user has begun/completed signup process
    if (_id) {
      if ((email || fbEmail) && phone && !query?.userId && !isInvitedUser) {
        // if complete user exists (a userId query denotes an invited user)
        if (company) {
          browserHistory.push('/dash/a/dashboard');
        } else {
          setStep('company_name');
        }
      } else if ((email || fbEmail) && query?.userId) {
        // if a logged in user reaches onboard via the invited user link
        company ? browserHistory.push('/dash/a/dashboard') : (window.location.href = '/');
      } else if (fbId) {
        // if user is authenticating from facebook
        if (email || shellEmail || fbEmail) {
          setStep(lastOnboardStep);
        } else {
          setStep('request_email');
        }
      } else {
        setStep(lastOnboardStep);
      }
    }
  }, [userData, setStep]);

  // FB Users that have added an email to their account
  useEffect(() => {
    if (emailAdded) setStep('check_phone');
  }, [emailAdded, setStep]);

  // Start step: handle form submission
  useEffect(() => {
    // check for start step (if onboard user has an id and has not hit any other steps yet)
    if (onboardUserData?._id && !phoneVerified && !companyInfoAdded && !isFinished && !emailAdded) {
      // if complete user exists (a userId query denotes an invited user)
      if (onboardUserData.email && !query?.userId) {
        // prefill login email when coming from start step
        dispatch(
          formActions.change('dash.onboarding.forms.accountExists.email', onboardUserData.email)
        );
        setStep('account_exists');
      } else {
        // if new shell user is created, or existing in-progress shell user
        // re-enters the same email
        const usersLastStep = onboardUserData?.onboardInfo?.lastOnboardStep;
        setStep(usersLastStep || 'check_phone');
      }
    }
  }, [onboardUserData, phoneVerified, companyInfoAdded, isFinished, query, setStep, emailAdded]);

  // Check Phone step
  useEffect(() => {
    if (checkPhone) {
      setStep('verify_phone');
    }
  }, [checkPhone, setStep]);

  // Verify Phone step
  useEffect(() => {
    if (phoneVerified && isInvitedUser) {
      dispatch(finishOnboardAction());
    } else if (phoneVerified && !companyInfoAdded) {
      onboardUserData?.onboardInfo?.emailMismatch
        ? setStep('update_email')
        : setStep('company_name');
    }
  }, [phoneVerified, onboardUserData, dispatch, isInvitedUser, companyInfoAdded, setStep]);

  // if user tries to verify a number that belongs to an existing non-shell user
  // if user via fb adds an email that is already registered
  useEffect(() => {
    const userWithPhoneExists = 112;
    const emailAlreadyRegistered = 113;
    if (
      onboardUserError?.code === userWithPhoneExists ||
      onboardUserError?.code === emailAlreadyRegistered
    ) {
      if (onboardUserError?.code === emailAlreadyRegistered) {
        // prefill login email when coming from FB add email step
        dispatch(
          formActions.change(
            'dash.onboarding.forms.accountExists.email',
            onboardForms.requestEmail.email
          )
        );
      }
      setStep('account_exists');
    }
  }, [onboardUserError?.code]);

  // Update Email Step
  useEffect(() => {
    if (emailUpdated) {
      setStep('company_name');
    }
  }, [emailUpdated, setStep]);

  // Add Company Info Step
  useEffect(() => {
    if (companyInfoAdded) {
      dispatch(finishOnboardAction());
    }
  }, [companyInfoAdded, setStep]);

  // Handle Finish
  useEffect(() => {
    if (isFinished) {
      setStep('finish');
    }
  }, [isFinished, setStep]);

  const setStep = (direction = 'forward') => {
    let stepDirection;

    if (direction === 1) stepDirection = 'forward';
    else if (direction === -1) stepDirection = 'back';
    else stepDirection = direction;

    setStepIdx(stepDirection);
  };

  const isNextDisabled = () => {
    // for dev purposes
    // return false;

    switch (onboardStepOrder[stepIdx]) {
      case 'onboard_start':
        return !onboarding.forms.onboardingMeta.start.$form.valid;
      case 'account_exists':
        return !onboarding.forms.onboardingMeta.accountExists.$form.valid;
      case 'check_phone':
        return !onboarding.forms.onboardingMeta.checkPhone.$form.valid;
      case 'verify_phone':
        return !onboarding.forms.onboardingMeta.verifyPhone.$form.valid;
      case 'update_email':
        return !onboarding.forms.updateEmail.selectedEmail;
      case 'company_name':
        return !onboarding.forms.onboardingMeta.companyName.$form.valid;
      case 'company_info':
        return !onboarding.forms.onboardingMeta.companyInfo.$form.valid;
      default:
        // Image, CoverIdx, Obit
        return false;
    }
  };

  const handleNext = () => {
    const { forms } = props.onboarding;
    const {
      start,
      accountExists,
      checkPhone,
      verifyPhone,
      updateEmail,
      companyName,
      companyInfo,
      requestEmail
    } = forms;
    const currentStep = onboardStepOrder[stepIdx];
    const { fbId = '' } = userData || {};
    const { email: emailToAdd } = requestEmail;

    switch (currentStep) {
      case 'onboard_start':
        const { firstName, lastName, email, password: startPassword, tosAgree } = start;
        dispatch(
          initUserAction({
            name: { first: firstName, last: lastName },
            email,
            password: startPassword,
            tosAgree
          })
        );
        break;
      case 'request_email':
        dispatch(addEmailAction(emailToAdd, fbId));
        break;
      case 'account_exists':
        const { email: accountEmail, password } = accountExists;
        dispatch(appLogin({ email: accountEmail, password }));
        break;
      case 'check_phone':
        const { phone } = checkPhone;
        dispatch(checkPhoneAction(phone));
        break;
      case 'verify_phone':
        const { code } = verifyPhone;
        dispatch(verifyPhoneAction(code, checkPhone.phone));
        break;
      case 'update_email':
        const { selectedEmail } = updateEmail;
        const uPhone = checkPhone?.phone || userData.phone;
        dispatch(updateEmailAction(selectedEmail, uPhone));
        break;
      case 'company_name':
        const {
          companyName: { companyExists }
        } = forms;

        companyExists ? setStepIdx('company_exists') : setStepIdx('company_info');
        break;
      case 'company_info':
        const { type, title } = companyName;
        const {
          address: street1,
          city,
          country,
          name,
          phone: companyPhone,
          state,
          website,
          zip
        } = companyInfo;
        const address = {
          street1,
          city,
          state,
          zip,
          country
        };
        const checkWebsite = website === 'https://' ? '' : website;
        dispatch(
          addCompanyInfoAction({
            name,
            address,
            phone: companyPhone,
            website: checkWebsite,
            type,
            title
          })
        );
        break;
      default:
    }
  };

  // TODO: figure out why using browserHistory redirects to login as if user is not auth'd
  const handleFinish = () => (window.location.href = '/dash/a/dashboard');

  const aboveHeaderContent = () => (
    <div
      className="onboard-profile-image"
      style={{ backgroundImage: `url('${onboardPersona}')` }}
    />
  );

  const renderLoader = propsToCheck => {
    const shouldRender = Object.values(propsToCheck)
      .filter(Boolean)
      .find(prop => prop.isFetching);
    return shouldRender && <LoaderComponent />;
  };

  return (
    <>
      <Stepper
        {...{ stepIdx, handleFinish, isNextDisabled }}
        stepOrder={onboardStepOrder}
        type="onboarding"
        aboveHeader={aboveHeaderContent()}
        isBackDisabled={true}
        hideNext={onboardStepOrder[stepIdx] === 'company_exists'}
        setStep={handleNext}
        finishBtnText="Finish"
        forcePercentage={onboardStepOrder[stepIdx] === 'company_exists' && 1}
      >
        {onboardSteps.map(({ Component, properties }, i) => (
          <div key={i} step={properties}>
            <Component {...{ handleNext, location }} />
          </div>
        ))}
      </Stepper>
      {renderLoader({ ...onboarding, user, fetchedGoogleAddress })}
      <ConfirmBackButton route={props.route} />
    </>
  );
};

export default connect(({ dash, fetchedGoogleAddress }) => ({
  onboarding: dash.onboarding,
  user: dash.user,
  fetchedGoogleAddress
}))(Onboarding);
