import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import SubNav from '@evdy/web-core/dist/components/shared/elements/SubNav';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import LocationAboutBody from './components/LocationAboutBody';
import LocationPhotosBody from './components/LocationPhotosBody';

import './LocationModalBody.scss';

import { normalizeStateProvince } from '@evdy/web-core/dist/lib/utils';

const LocationModalBody = props => {
  const { change, reset, model, fhLocation = {}, isAddHome, isInternal, form } = props;

  const subNavRef = useRef(null);
  const locationModalBodyRef = useRef(null);

  const {
    profileImage = '',
    photos,
    name,
    websiteUrl,
    type,
    address = {},
    phone,
    email,
    casesPerYear,
    placeId,
    flowers,
    yelpUrl,
    description,
    displayPublicly,
    facebookUrl,
    premiumProgram
  } = fhLocation;

  const { street, street2, city, state, zip } = address;

  // when the fhLocation changes, reset the tab back to About and scroll to top
  useEffect(() => {
    // use requestAnimationFrame to give the browser a frame to display the modal before scrolling,
    // otherwise it won't scroll at all
    // confirm fhLocation is not empty before scrolling, otherwise every time a form
    // value changes the modal scrolls to top
    window.requestAnimationFrame(() => {
      if (fhLocation?._id) {
        locationModalBodyRef.current.scrollTo({ top: 0 });
        subNavRef.current.handleTabSelect(0);
      }
    });
  }, [fhLocation]);

  useEffect(() => {
    name && change(`${model}.name`, name);
    websiteUrl && change(`${model}.websiteUrl`, websiteUrl);
    type && change(`${model}.type`, type);
    street && change(`${model}.address.street`, street);
    street2 && change(`${model}.address.street2`, street2);
    city && change(`${model}.address.city`, city);
    state && change(`${model}.address.state`, normalizeStateProvince(state));
    zip && change(`${model}.address.zip`, zip);
    flowers?.url && change(`${model}.flowers.url`, flowers.url);
    placeId && change(`${model}.placeId`, placeId);
    casesPerYear && change(`${model}.casesPerYear`, casesPerYear);
    phone && change(`${model}.phone`, phone);
    email && change(`${model}.email`, email);
    yelpUrl && change(`${model}.yelpUrl`, yelpUrl);
    description && change(`${model}.description`, description);
    change(`${model}.displayPublicly`, displayPublicly);
    facebookUrl && change(`${model}.facebookUrl`, facebookUrl);
    profileImage && change(`${model}.profileImage`, profileImage);
    photos && photos.length && change(`${model}.photos`, photos);
    premiumProgram && change(`${model}.premiumProgram`, premiumProgram);
    return () => {
      reset(model);
    };
  }, [
    name,
    websiteUrl,
    type,
    street,
    street2,
    city,
    state,
    zip,
    flowers,
    placeId,
    casesPerYear,
    phone,
    email,
    yelpUrl,
    description,
    displayPublicly,
    facebookUrl,
    change,
    model,
    photos,
    profileImage,
    reset,
    premiumProgram
  ]);

  const tabs = () => {
    if (isAddHome) {
      return ['About'];
    } else {
      return ['About', 'Photos'];
    }
  };

  return (
    <div className="location-modal-body" ref={locationModalBodyRef}>
      <SubNav ref={subNavRef} tabs={tabs()}>
        <LocationAboutBody {...{ model, fhLocation, isAddHome, isInternal, form }} />
        <LocationPhotosBody {...{ model, fhLocation, isAddHome, form }} />
      </SubNav>
    </div>
  );
};

export default connect(null, { change: formActions.change, reset: formActions.reset })(
  LocationModalBody
);
