import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import DecedentInfoForm from '../forms/DecedentInfoForm';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import { formatTime } from '@evdy/web-core/dist/lib/utils';

import { useScrollToTopOfStepperPage } from '../CustomHook';

import './DecedentInfo.scss';

const model = 'inviteCreation.forms.decedentInfo';

const DecedentInfo = ({
  memorialId,
  decedentInfo,
  formData,
  formMetaData,
  merge,
  editDecedentDetailsAction
}) => {
  // scroll to top on mount
  useScrollToTopOfStepperPage('edit-decedent-details');

  // merge previous form values
  useEffect(() => {
    const mappedDecedentInfo = {
      ...decedentInfo,
      dateOfBirth: formatTime(decedentInfo?.dateOfBirth),
      dateOfDeath: formatTime(decedentInfo?.dateOfDeath)
    };

    merge(model, mappedDecedentInfo);
  }, [decedentInfo, merge]);

  return (
    <div className="edit-decedent-details">
      <h1>Decedent information</h1>
      <DecedentInfoForm
        {...{ model, formData, decedentInfo, formMetaData, memorialId }}
        uniqueTags={['from-decedentStepper-first', 'from-decedentStepper-second']}
      />
    </div>
  );
};

export default connect(
  ({ inviteCreation }) => ({
    formData: inviteCreation.forms.decedentInfo,
    formMetaData: inviteCreation.forms.formsMeta.decedentInfo,
    memorialId: inviteCreation.decedentDetails.data?._id,
    decedentInfo: inviteCreation.decedentDetails.data
  }),
  { merge: formActions.merge }
)(DecedentInfo);
