import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Stepper from '../Stepper';

import { useStepper } from '../../lib/dashHooks';

import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';

import { inviteBuilderSteps, inviteBuilderStepOrder } from './inviteBuilderSteps';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import FamilyValueModal from './components/modals/FamilyValueModal';
import BuilderOutroModal from './components/modals/BuilderOutroModal';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import './InviteBuilder.scss';

// Destructure actions
const {
  fetchUnfinishedMemorialAction,
  editDecedentDetailsAction,
  finishCreationAction,
  resetInviteCreation,
  resetDirectorVideoUpload
} = creationOperations;

const InviteBuilder = ({
  inviteCreation,
  inviteSentState,
  params,
  fetchUnfinishedMemorialAction,
  finishCreationAction,
  resetInviteCreation,
  resetDirectorVideoUpload,
  editDecedentDetailsAction,
  decedentFormInfo,
  decedentFormMeta,
  isFetchingDecedentDetails,
  isFetchingAccessors,
  isFetchingServices,
  isFetchingObituary,
  isFetchingFuneralInfo,
  isFetchingTheme,
  isFetchingInviteSent,
  isFetchingPreviewSent,
  reset,
  setTouched
}) => {
  // state
  const [familyModalVisible, setFamilyModalVisible] = useState(false);
  const [isOutroModalVisible, setOutroModalVisible] = useState(false);
  const [stepIdx, setStepIdx] = useStepper(inviteBuilderStepOrder);

  // variables
  const { memorialId } = params;
  const { decedentDetails, accessors } = inviteCreation || {};
  const { inviteSent } = inviteSentState;
  const isFetching =
    isFetchingDecedentDetails ||
    isFetchingAccessors ||
    isFetchingServices ||
    isFetchingObituary ||
    isFetchingFuneralInfo ||
    isFetchingTheme ||
    isFetchingInviteSent ||
    isFetchingPreviewSent;
  const isNextDisabled = !decedentFormMeta.valid;
  const firstNameModel = 'inviteCreation.forms.decedentInfo.name.first';
  const lastNameModel = 'inviteCreation.forms.decedentInfo.name.last';

  const { array: accessorArray } = accessors || {};

  // on mount, fetch the announcement/invite that is being built if the data
  // hasn't already been fetched
  useEffect(() => {
    if (!decedentDetails?.data?._id && !decedentDetails?.isFetching) {
      const showAllEvents = true;
      fetchUnfinishedMemorialAction({ memorialId, showAllEvents });
    }
  }, [fetchUnfinishedMemorialAction, decedentDetails, memorialId]);

  // after submit, display outro modal
  useEffect(() => {
    if (inviteSent) {
      setOutroModalVisible(true);
    }
  }, [inviteSent, setOutroModalVisible]);

  // reset inviteCreation and directorVideoUpload on unmount
  useEffect(() => {
    return () => {
      resetInviteCreation();
      resetDirectorVideoUpload();
      // reset decedentInfo form on unmount -- other form data is merged/reset on modal close/open
      reset('inviteCreation.forms.decedentInfo');
    };
  }, []);

  const handleFinish = () => {
    finishCreationAction({ memorialId });
  };

  const handleBack = () => {
    const backStepIdx = 'back';

    setStepIdx(backStepIdx);
  };

  const handleNext = () => {
    const nextStepIdx = 'forward';

    if (inviteBuilderStepOrder[stepIdx] === 'decedent_info') {
      //set off form validation errors
      setTouched(firstNameModel);
      setTouched(lastNameModel);
      if (isNextDisabled) return;

      editDecedentDetailsAction({ memorialId, decedent: decedentFormInfo });
    }
    // if we are at the ACCESSORS step with less than 4 added, prompt the user to add more.
    if (inviteBuilderStepOrder[stepIdx] === 'accessors' && accessorArray?.length < 4) {
      switchFamilyModalVisible();
      return;
    }

    setStepIdx(nextStepIdx);
  };

  const switchFamilyModalVisible = () => {
    setFamilyModalVisible(currentState => !currentState);
  };

  return (
    <div className="invite-builder">
      {isFetching && <LoaderComponent />}
      <Stepper
        {...{ stepIdx, isNextDisabled, handleBack, handleFinish, handleNext }}
        steps={inviteBuilderSteps}
        nextButtonText="Next"
        finishButtonText="Send to family"
      />
      <FamilyValueModal
        accessorCount={accessorArray?.length}
        closeModal={switchFamilyModalVisible}
        modalVisible={familyModalVisible}
        nextStep={() => setStepIdx('forward')}
      />
      <BuilderOutroModal
        closeModal={() => setOutroModalVisible(false)}
        modalVisible={isOutroModalVisible}
      />
    </div>
  );
};

export default connect(
  ({ inviteCreation, forms }) => ({
    decedentFormInfo: inviteCreation.forms.decedentInfo,
    decedentFormMeta: inviteCreation.forms.formsMeta.decedentInfo.$form,
    inviteCreation,
    inviteSentState: inviteCreation?.inviteSent,
    isFetchingDecedentDetails: inviteCreation.decedentDetails.isFetching,
    isFetchingAccessors: inviteCreation.accessors.isFetching,
    isFetchingServices: inviteCreation.services.isFetching,
    isFetchingObituary: inviteCreation.obituary.isFetching,
    isFetchingFuneralInfo: inviteCreation.funeralInfo.isFetching,
    isFetchingTheme: inviteCreation.theme.isFetching,
    isFetchingInviteSent: inviteCreation?.inviteSent?.isFetching,
    isFetchingPreviewSent: inviteCreation?.invitePreview?.isFetching
  }),
  {
    fetchUnfinishedMemorialAction,
    editDecedentDetailsAction,
    finishCreationAction,
    resetInviteCreation,
    resetDirectorVideoUpload,
    reset: formActions.reset,
    setTouched: formActions.setTouched
  }
)(InviteBuilder);
