"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadNext = exports.loadNextSuccess = exports.fetchCompanyMemorials = exports.fetchCompanyMemorialsFailure = exports.fetchCompanyMemorialsSuccess = exports.fetchCompanyMemorialsRequest = void 0;

var _moment = _interopRequireDefault(require("moment/moment"));

var types = _interopRequireWildcard(require("../../constants/dash/fetchCompanyMemorials"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var fetchCompanyMemorialsRequest = function fetchCompanyMemorialsRequest() {
  return {
    type: types.FETCH_COMPANY_MEMORIALS_REQUEST
  };
};

exports.fetchCompanyMemorialsRequest = fetchCompanyMemorialsRequest;

var fetchCompanyMemorialsSuccess = function fetchCompanyMemorialsSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_COMPANY_MEMORIALS_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchCompanyMemorialsSuccess = fetchCompanyMemorialsSuccess;

var fetchCompanyMemorialsFailure = function fetchCompanyMemorialsFailure(error) {
  return {
    type: types.FETCH_COMPANY_MEMORIALS_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fetchCompanyMemorialsFailure = fetchCompanyMemorialsFailure;

var fetchCompanyMemorials = function fetchCompanyMemorials(props) {
  return function (dispatch) {
    var _ref2 = props || {},
        directorFilter = _ref2.directorFilter,
        locationFilter = _ref2.locationFilter,
        searchQuery = _ref2.searchQuery,
        startDateFilter = _ref2.startDateFilter,
        endDateFilter = _ref2.endDateFilter,
        dateFrom = _ref2.dateFrom,
        dateTo = _ref2.dateTo,
        completion = _ref2.completion,
        page = _ref2.page,
        sort = _ref2.sort,
        noHidden = _ref2.noHidden,
        id = _ref2.id,
        isLimitedAccess = _ref2.isLimitedAccess;

    dispatch(fetchCompanyMemorialsRequest());
    var completed = completion === 'completed';
    var upcoming = completion === 'upcoming' ? (0, _moment.default)().valueOf() : '';
    return instance.get(id ? "/v2/private/company/".concat(id, "/memorials") : '/v2/private/company/memorials', {
      params: {
        director: directorFilter,
        funeralHome: locationFilter,
        mname: searchQuery,
        fromdate: dateFrom || startDateFilter,
        todate: dateTo || endDateFilter,
        completed: completed,
        upcoming: upcoming,
        page: page,
        sort: sort,
        noHidden: noHidden,
        isLimitedAccess: isLimitedAccess
      }
    }).then(function (res) {
      return dispatch(fetchCompanyMemorialsSuccess(res));
    }).catch(function (error) {
      return dispatch(fetchCompanyMemorialsFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.fetchCompanyMemorials = fetchCompanyMemorials;

var loadNextSuccess = function loadNextSuccess(_ref3) {
  var data = _ref3.data;
  return {
    type: types.LOAD_MORE_MEMORIALS_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.loadNextSuccess = loadNextSuccess;

var loadNext = function loadNext(nextUrl) {
  return function (dispatch) {
    dispatch(fetchCompanyMemorialsRequest());
    return instance.get(nextUrl).then(function (res) {
      return dispatch(loadNextSuccess(res));
    }).catch(function (error) {
      return dispatch(fetchCompanyMemorialsFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.loadNext = loadNext;