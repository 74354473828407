"use strict";

var companyTypes = {
  cemetary: 'cemetery',
  crematory: 'crematory',
  funeralHome: 'funeral-home',
  homeHealth: 'home-health',
  hospice: 'hospice',
  other: 'other'
};
module.exports = {
  companyTypes: companyTypes
};