import React from 'react';

const NoUsers = () => {
  return (
    <div className="no-user">
      <div className="no-user-desc">
        <h1>Use search and filters to find users</h1>
        <p>The User(s) may not exist or you may need to adjust your filters.</p>
      </div>
    </div>
  );
};

export default NoUsers;
