"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.activitiesViewed = exports.fetchActivities = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _querystring = _interopRequireDefault(require("querystring"));

var types = _interopRequireWildcard(require("./constants"));

var _constants2 = require("../../constants");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants2.API_URL
});

var fetchActivitiesRequest = function fetchActivitiesRequest() {
  return {
    type: types.FETCH_ACTIVITIES_REQUEST
  };
};

var fetchActivitiesSuccess = function fetchActivitiesSuccess(data) {
  return {
    type: types.FETCH_ACTIVITIES_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

var fetchActivitiesFailure = function fetchActivitiesFailure(error) {
  return {
    type: types.FETCH_ACTIVITIES_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

var fetchActivities = function fetchActivities(_ref) {
  var user = _ref.user,
      query = _ref.query;
  return function (dispatch) {
    var last = query.last,
        limit = query.limit,
        source = query.source;
    dispatch(fetchActivitiesRequest());

    var qs = _querystring.default.stringify({
      last: last,
      limit: limit,
      source: source
    });

    return instance.get("/activity?".concat(qs), {
      user: user,
      query: query
    }).then(function (res) {
      return dispatch(fetchActivitiesSuccess(res.data));
    }, function (error) {
      return dispatch(fetchActivitiesFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.fetchActivities = fetchActivities;

var activitiesViewedRequest = function activitiesViewedRequest() {
  return {
    type: types.ACTIVITIES_VIEWED_REQUEST
  };
};

var activitiesViewedFailure = function activitiesViewedFailure(error) {
  return {
    type: types.ACTIVITIES_VIEWED_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

var activitiesViewedSuccess = function activitiesViewedSuccess() {
  return {
    type: types.ACTIVITIES_VIEWED_SUCCESS,
    receivedAt: Date.now()
  };
};

var activitiesViewed = function activitiesViewed() {
  return function (dispatch) {
    dispatch(activitiesViewedRequest());
    return instance.post("/activity/viewed").then(function (res) {
      return dispatch(activitiesViewedSuccess());
    }, function (error) {
      return dispatch(activitiesViewedFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.activitiesViewed = activitiesViewed;