import React from 'react';
import classNames from 'classnames';

import './DashCardHeader.scss';

import Button from '@evdy/web-core/dist/components/Button';

const DashCardHeader = ({
  headerCopy,
  buttonCopy,
  buttonType = 'primary',
  headerBtnAction,
  disabled,
  customClass
}) => {
  const buttonClasses = classNames('standard-blue-solid-btn', {
    delete: buttonType === 'delete'
  });
  return (
    <div className={['card-header-container', customClass].join(' ')}>
      <h1 className="card-header">{headerCopy}</h1>
      {buttonCopy && (
        <Button
          {...{ disabled }}
          copy={buttonCopy}
          style={buttonClasses}
          action={headerBtnAction}
        />
      )}
    </div>
  );
};

export default DashCardHeader;
