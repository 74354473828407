import React from 'react';

import './NotPermitted.scss';

const NotPermitted = props => {
  const {
    showContent = true,
    text = 'Sorry, you do not have permission to view this page.'
  } = props;
  return <div className="user-not-permitted">{showContent && text}</div>;
};

export default NotPermitted;
