import React from 'react';

import {
  ModalOverlay,
  ModalFooter,
  ResponsiveContent,
  GradientModalHeader
} from '@evdy/web-core/dist/components/shared/Modal2020';

import './ConfirmationModal.scss';

const ConfirmationModal = ({
  closeModal,
  modalVisible,
  confirmText = 'Are you sure?',
  headerCopy = 'Confirm',
  mainButtonText = 'Save',
  mainButtonFn = () => {},
  secondaryButtonText = 'Cancel',
  secondaryButtonFn = closeModal
}) => {
  return (
    <ModalOverlay {...{ closeModal, modalVisible }} customClass="confirmation-modal">
      <ResponsiveContent>
        <GradientModalHeader {...{ closeModal, headerCopy }} type="delete" />
        <div className="confirmation-modal-body">{confirmText}</div>
        <ModalFooter
          {...{
            mainButtonText,
            mainButtonFn,
            secondaryButtonText,
            secondaryButtonFn
          }}
          type="delete"
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
