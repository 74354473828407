import React from 'react';

import {
  ModalOverlay,
  SolidColorModalHeader,
  ResponsiveContent,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';

import './CreationExitModal.scss';

const CreationExitModal = ({
  children,
  isModalVisible = true,
  closeModal,
  handleExit,
  headerCopy,
  mainBtnCopy,
  secondaryButtonAction = () => {},
  secondaryButtonCopy = 'Cancel'
}) => {
  return (
    <ModalOverlay
      {...{ closeModal }}
      modalVisible={isModalVisible}
      customClass="creation-exit-modal"
    >
      <ResponsiveContent>
        <SolidColorModalHeader {...{ closeModal }} headerCopy={headerCopy} />
        <div className="creation-exit-modal-content">{children}</div>
        <ModalFooter
          mainButtonText={mainBtnCopy}
          mainButtonFn={handleExit}
          secondaryButtonText={secondaryButtonCopy}
          secondaryButtonFn={secondaryButtonAction}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default CreationExitModal;
