import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import qs from 'querystring';

import SubNav from '@evdy/web-core/dist/components/shared/elements/SubNav';
import EditAccountProfile from './AccountsDetailProfile';
import AccOverview from './AccOverview';
import AccLocations from './AccLocations';
import EditAccountsMembers from './AccMembers';

import { useURLParamSync } from '@evdy/web-core/dist/customHooks/index';

import { fetchAccounts, fetchAccountsReset } from '@evdy/web-redux/dist/actions/dash/fetchAccounts';

import chevron from '../assets/chevron-right-blue.svg';

import './AccountDetails.scss';

const AccountDetails = props => {
  const {
    isExternalMode,
    account = {},
    params: { accountId },
    fetchAccounts,
    fetchAccountsReset,
    location,
    location: { query }
  } = props;

  const subNavRef = useRef(null);

  const [originLocation, setOriginPath] = useState({
    originPathName: null,
    originDisplayName: null
  });
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = ({ selectedTabIndex }) => setTabIndex(selectedTabIndex);

  const params = useMemo(() => ({ tabIndex, isExternalMode }), [tabIndex, isExternalMode]);

  //TODO WebCleanUp2020 - memoizedAccountsFilter doesn't need isExternal, dashnav handling
  useURLParamSync(params, location);

  // page setup
  useEffect(() => {
    // scroll to top on mount
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
    if (subNavRef?.current && query.tabIndex) subNavRef.current.handleTabSelect(+query.tabIndex);
  }, []);

  // fetch and clear funeral home data on load
  useEffect(() => {
    if (accountId) fetchAccounts(accountId);
    return () => {
      fetchAccountsReset();
    };
  }, [accountId, fetchAccounts, fetchAccountsReset]);

  // effect that checks the url params for 'originPathName' and if truthy then
  // breadcrumb back button will go to the previous page, otherwise defaults
  // to accounts
  useEffect(() => {
    const { originPathName, originDisplayName } = query;
    const currentPathname = `/dash/a/accounts?${qs.stringify({ ...query, tabIndex: undefined })}`;

    setOriginPath({
      originPathName: originPathName ? originPathName : currentPathname,
      originDisplayName: originDisplayName
        ? `Back to ${originDisplayName}`
        : 'Back to Accounts List'
    });
  }, []);

  // this relies on there being a query param of originPathName & originDisplayName in the url
  // ie: (&originPathName=${location.pathname}&originDisplayName='Accounts'`} )
  // needs to be in the location that the link is coming from so that we can push
  // the correct pathname into browserHistory
  const handleGoBack = () => {
    browserHistory.push(originLocation.originPathName);
  };

  const detailModalClasses = classNames('applied-modal-styles');
  return (
    <div className="dash-account-details-container employee-mode">
      <div className="back-link" onClick={handleGoBack}>
        <img src={chevron} alt="" />
        {originLocation.originDisplayName}
      </div>
      {isExternalMode ? (
        <div>This tab is not available in Professional Mode</div>
      ) : (
        <div className={detailModalClasses}>
          <div className="title-container">
            <h1>{account.name}</h1>
          </div>
          <SubNav
            ref={subNavRef}
            tabs={['Overview', 'Profile', 'Members', 'Locations']}
            onChange={handleTabChange}
          >
            <AccOverview {...{ account, accountId }} />
            <EditAccountProfile {...{ account }} />
            <EditAccountsMembers {...{ account, location }} />
            <AccLocations {...{ account }} locations={account.funeralHomes} />
          </SubNav>
        </div>
      )}
    </div>
  );
};

export default connect(
  ({ dash, funeral }) => ({
    isExternalMode: dash.dashNav.isExternalMode,
    account: dash.fetchAccounts.account.data
  }),
  { fetchAccounts, fetchAccountsReset }
)(AccountDetails);
