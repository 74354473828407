import React from 'react';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import get from 'lodash-es/get';
import classNames from 'classnames';

import ActivitySection from './ActivitySection';
import ResourcesSection from './ResourcesSection';
import RecentAnncs from './shared/RecentAnncs';
import StatsSection from './StatsSection';

import '../styles/Dashboard.scss';

const makeGreeting = () => {
  const currentHour = moment().hour();
  let greeting = 'Good Morning';

  if (currentHour >= 12 && currentHour < 18) greeting = 'Good Afternoon';
  if (currentHour >= 18) greeting = 'Good Evening';

  return greeting;
};

@connect(({ dash }) => ({ user: dash.user.data }))
export default class Dashboard extends React.Component {
  static renderCompletionSection() {
    // extract this into a Component if necessary
    return <div className="completion-steps-section">This is the completion bar section.</div>;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { user, showCompletionSection = false } = this.props;
    const firstName = get(user, 'name.first', '');

    const dashboardClasses = classNames('dashboard');

    return (
      <div className={dashboardClasses}>
        <div className="dash-greeting">
          <h1>
            {makeGreeting()}, {firstName}
          </h1>
          <p>
            You have a new feature ready for you — Director Care. Access the bell located on the top
            right of your screen to see updates like when your home receives a new review, Invites
            build large communities and team members reach new milestones.
          </p>
        </div>
        {showCompletionSection && Dashboard.renderCompletionSection()}
        <ResourcesSection />
        <StatsSection />
        <RecentAnncs />
        {/*<ActivitySection />*/}
      </div>
    );
  }
}
