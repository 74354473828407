"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDemoMemorial = exports.fetchDemoActivities = exports.fetchDemoMemorialStats = exports.fetchDemoAnnouncement = exports.fetchDemoAnnouncements = exports.fetchDemoDirectors = exports.fetchDemoHomes = exports.fetchDemoCompany = exports.fetchDemoReports = exports.fetchDemoLead = exports.fetchDemoCrmLeads = void 0;

var types = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var fetchDemoCrmLeads = function fetchDemoCrmLeads() {
  return {
    type: types.FETCH_DEMO_CRM_LEADS
  };
};

exports.fetchDemoCrmLeads = fetchDemoCrmLeads;

var fetchDemoLead = function fetchDemoLead(id) {
  return {
    type: types.FETCH_DEMO_LEAD,
    id: id
  };
};

exports.fetchDemoLead = fetchDemoLead;

var fetchDemoReports = function fetchDemoReports() {
  return {
    type: types.FETCH_DEMO_REPORTS
  };
};

exports.fetchDemoReports = fetchDemoReports;

var fetchDemoCompany = function fetchDemoCompany() {
  return {
    type: types.FETCH_DEMO_COMPANY
  };
};

exports.fetchDemoCompany = fetchDemoCompany;

var fetchDemoHomes = function fetchDemoHomes() {
  return {
    type: types.FETCH_DEMO_HOMES
  };
};

exports.fetchDemoHomes = fetchDemoHomes;

var fetchDemoDirectors = function fetchDemoDirectors() {
  return {
    type: types.FETCH_DEMO_DIRECTORS
  };
};

exports.fetchDemoDirectors = fetchDemoDirectors;

var fetchDemoAnnouncements = function fetchDemoAnnouncements() {
  return {
    type: types.FETCH_DEMO_ANNOUNCEMENTS
  };
};

exports.fetchDemoAnnouncements = fetchDemoAnnouncements;

var fetchDemoAnnouncement = function fetchDemoAnnouncement(id) {
  return {
    type: types.FETCH_DEMO_ANNOUNCEMENT,
    id: id
  };
};

exports.fetchDemoAnnouncement = fetchDemoAnnouncement;

var fetchDemoMemorialStats = function fetchDemoMemorialStats() {
  return {
    type: types.FETCH_DEMO_MEMORIAL_STATS
  };
};

exports.fetchDemoMemorialStats = fetchDemoMemorialStats;

var fetchDemoActivities = function fetchDemoActivities() {
  return {
    type: types.FETCH_DEMO_ACTIVITIES
  };
};

exports.fetchDemoActivities = fetchDemoActivities;

var createDemoMemorial = function createDemoMemorial(memorial) {
  return {
    type: types.CREATE_DEMO_MEMORIAL,
    memorial: memorial
  };
};

exports.createDemoMemorial = createDemoMemorial;