export const closeLeadOptions = ['purchased', 'not-qualified', 'not-interested'];

export const lifecycleStages = ['new', 'meeting-set', 'meeting-held', 'closed'];

export const modalTitles = {
  'lifecycle-stage': 'Lead Stage',
  'new-contact': 'New Contact',
  'add-note': 'Add Note',
  closed: 'Lead Stage',
  'meeting-held': 'Lead Stage',
  'meeting-set': 'Lead Stage'
};

export const getStageTitle = currentStage => {
  switch (currentStage) {
    case 'new':
      return 'Call to setup preplanning meeting';
    case 'meeting-set':
      return 'Meeting held or attempted';
    case 'meeting-held':
      return 'Close or update the lead';
    case 'closed':
      return 'Lead Closed';
    default:
  }
};

export const getActivityCompletionCopy = currentStage => {
  switch (currentStage) {
    case 'new':
      return ' completed call';
    case 'meeting-set':
      return ' held meeting';
    case 'meeting-held':
      return ' closed sale';
    default:
  }
};

export const getMostRecentAttemptCount = (activityArray = [], currentStage) => {
  const lastActivityOfStage =
    activityArray
      .filter(activity => activity.currentStage === currentStage)
      .sort((a, b) => b.attempt - a.attempt)[0] || {};

  return lastActivityOfStage.attempt || 0;
};

// custom formatting for moment's .calendar() function
export const customCalendarFormatting = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'MM/DD/YYYY'
};
