import React from 'react';

const SVG = ({ closedColor }) => {
  return (
    <svg width="188" height="50" viewBox="0 0 188 50" xmlns="http://www.w3.org/2000/svg">
      <g id="CRM" fill="none" fillRule="evenodd">
        <g
          id="CRM/Details/Activity2"
          transform="translate(-1116 -405)"
          fill={closedColor || '#F7F7F7'}
          fillRule="nonzero"
          stroke={closedColor || '#B3B3B3'}
          strokeWidth="2"
        >
          <g id="Right-Side" transform="translate(554 186)">
            <g id="Progress" transform="translate(0 219)">
              <g id="Arrow-2" transform="translate(562)">
                <path
                  d="M186.7251,49 L186.7251,25.0340422 L186.750597,25 L186.7251,24.9659578 L186.7251,1 L1.99840663,1 L19.9745022,25 L1.99840663,49 L186.7251,49 Z"
                  id="arrow-end-inactive"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVG;
