import React, { useState } from 'react';
import { connect } from 'react-redux';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import isFuture from 'date-fns/isFuture';
import addYears from 'date-fns/addYears';
import isPast from 'date-fns/isPast';
import classNames from 'classnames';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

const today = new Date();
const currentYear = getYear(new Date());
const currentMonth = getMonth(new Date());

const YearMonthDropdown = ({
  date,
  localeUtils,
  onChange,
  yearsAddedToCurrentYear,
  startYear,
  unique
}) => {
  const months = localeUtils.getMonths();
  const endingYear = yearsAddedToCurrentYear
    ? getYear(addYears(new Date(), yearsAddedToCurrentYear))
    : currentYear;
  let mutableYear = startYear;
  const defaultYear = 1900;

  const years = [...new Array(endingYear + 1 - startYear).fill(null).map(_ => mutableYear++)];
  const selectedMonth = date.getMonth();
  const selectedYear = date.getFullYear();

  const handleChange = e => {
    const monthVal = e.target.form[`${unique}-month`].value;
    const yearVal = e.target.form[`${unique}-year`].value;
    onChange(new Date(yearVal, monthVal));
  };

  return (
    <div className="DayPicker-Caption">
      <select name={`${unique}-month`} onChange={handleChange} value={selectedMonth}>
        {months.map((month, i) => (
          <option
            key={month}
            value={i}
            disabled={!yearsAddedToCurrentYear && isFuture(new Date(selectedYear, i))}
          >
            {month}
          </option>
        ))}
      </select>
      <select name={`${unique}-year`} onChange={handleChange} value={selectedYear}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

/***
 * @param {unique} string REQUIRED! prop -- needed to find unique select field in form - an array of unique strings passed into form component
 */

const ChooseMonth = ({
  modelBase,
  model,
  initalDate = new Date(),
  change,
  onChange,
  customClass,
  yearsAddedToCurrentYear = 0,
  startYear = 1900,
  endingMonth = currentMonth,
  unique = '',
  disablePast = false,
  disableFuture = false
}) => {
  const [month, setMonth] = useState(initalDate);
  const [selectedDay, setSelectedDay] = useState(null);

  const fromMonth = new Date(startYear, startYear !== 1900 ? currentMonth : 0);
  const toMonth = new Date(currentYear + yearsAddedToCurrentYear, endingMonth);
  const isThisMonth = date =>
    getMonth(date) === getMonth(today) && getYear(date) === getYear(today);

  const handleDateChange = date => {
    change(`${modelBase}${model}`, date);
    setSelectedDay(date);
    onChange && onChange(date);
  };

  const dayPickerClasses = classNames({
    'day-picker': true,
    [customClass]: customClass
  });

  const disabledDays = {
    before: disablePast ? (isThisMonth(fromMonth) ? today : fromMonth) : undefined,
    after: disableFuture ? (isThisMonth(toMonth) ? today : toMonth) : undefined
  };

  return (
    <div className={dayPickerClasses}>
      <DayPicker
        {...{ month, fromMonth, toMonth, disabledDays }}
        initialMonth={initalDate}
        onDayClick={handleDateChange}
        selectedDays={selectedDay}
        captionElement={({ date, localeUtils }) => (
          <YearMonthDropdown
            {...{ date, localeUtils, yearsAddedToCurrentYear, startYear, unique }}
            onChange={setMonth}
          />
        )}
      />
    </div>
  );
};

export default connect(null, { change: formActions.change })(ChooseMonth);
