"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addEmailAction = exports.addEmailFailure = exports.addEmailSuccess = exports.addEmailRequest = void 0;

var _onboarding = require("../../../constants/dash/onboarding");

var _constants = require("../../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var addEmailRequest = function addEmailRequest() {
  return {
    type: _onboarding.ONBOARD_ADD_EMAIL_REQUEST
  };
};

exports.addEmailRequest = addEmailRequest;

var addEmailSuccess = function addEmailSuccess(_ref) {
  var data = _ref.data;
  return {
    type: _onboarding.ONBOARD_ADD_EMAIL_SUCCESS,
    receivedAt: Date.now(),
    payload: data
  };
};

exports.addEmailSuccess = addEmailSuccess;

var addEmailFailure = function addEmailFailure(error) {
  return {
    type: _onboarding.ONBOARD_ADD_EMAIL_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.addEmailFailure = addEmailFailure;

var addEmailAction = function addEmailAction(email, fbId) {
  return function (dispatch) {
    dispatch(addEmailRequest());
    return _index.default.post("".concat(_constants.API_URL, "/onboard/add-email"), {
      email: email,
      fbId: fbId
    }).then(function (res) {
      return dispatch(addEmailSuccess(res.data));
    }).catch(function (err) {
      return dispatch(addEmailFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.addEmailAction = addEmailAction;