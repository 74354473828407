import React from 'react';
import classNames from 'classnames';

import './DashCardFooter.scss';

import Button from '@evdy/web-core/dist/components/Button';

const DashCardFooter = ({
  footerCopy,
  subtitleText,
  buttonCopy,
  buttonType = 'primary',
  footerBtnAction,
  disabled,
  customClass
}) => {
  const buttonClasses = classNames('invite-btn', {
    delete: buttonType === 'delete'
  });
  return (
    <div className={['card-footer-container', customClass].join(' ')}>
      <div className="details-container">
        <h1 className="card-footer">{footerCopy}</h1>
        <div className="subtitle-txt">{subtitleText}</div>
      </div>
      {buttonCopy && (
        <Button
          {...{ disabled }}
          copy={buttonCopy}
          style={buttonClasses}
          action={footerBtnAction}
        />
      )}
    </div>
  );
};

export default DashCardFooter;
