import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import DropDown from '@evdy/web-core/dist/components/shared/elements/DropDown';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { nameString } from '@evdy/web-core/dist/lib/utils';

import chevron from '@evdy/web-core/dist/images/shared/icons/chevron-icon-black.svg';

import './UserDropdown.scss';

const UserDropdown = ({ user, signOutFn, openSwitchCompanyModal, fetchedCompany }) => {
  const { isCompanyAdmin } = user || {};
  const currentYear = new Date().getFullYear();

  return (
    <DropDown
      direction="right"
      anchorDirection="above"
      addClass="user-profile-dropdown"
      header={
        <div className="user-dropdown-bar">
          <div className="user-section">
            <CharacterProfileImage image={user.image} name={user.name} />
            <div className="user-info">
              <span className="user-name">{nameString(user.name)}</span>
              <span className="user-home">{fetchedCompany?.name || user.company?.name || ''}</span>
            </div>
          </div>
          <img className="user-chevron" src={chevron} />
        </div>
      }
      pane={({ togglePane }) => (
        <div className="user-dropdown-menu">
          <ul>
            <li>
              <Link to="/dash/a/profile" onClick={togglePane}>
                Profile
              </Link>
            </li>
            {!!user?.companies?.length && (
              <li className="non-anchor" onClick={openSwitchCompanyModal}>
                Switch Accounts
              </li>
            )}
            <li>
              <Link to="/clientterms" target="_blank">
                Client Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/clientprivacy" target="_blank">
                Client Privacy Policy
              </Link>
            </li>
            {!!isCompanyAdmin && (
              <li>
                <Link to="/termsandconditions" target="_blank">
                  Terms and Conditions
                </Link>
              </li>
            )}
            {!!isCompanyAdmin && (
              <li>
                <Link to="/clientagreement" target="_blank">
                  Everdays Agreement
                </Link>
              </li>
            )}
            <li className="non-anchor" onClick={signOutFn}>
              Log out
            </li>
            <li className="copyright">
              <span>© {currentYear} Everdays, Inc. All rights reserved</span>
            </li>
          </ul>
        </div>
      )}
    />
  );
};

export default connect(({ dash }) => ({ fetchedCompany: dash.fetchedCompany.company.data }))(
  UserDropdown
);
