import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import get from 'lodash-es/get';

import TruncatedText from '@evdy/web-core/dist/components/shared/elements/TruncatedText';

import { fetchResources } from '@evdy/web-redux/dist/actions/dash/resources';

import './ResourcesSection.scss';

import everdaysGuide from '@evdy/web-core/dist/images/evr-blue-icon_ipad-retina.png';
import pdfIcon from './assets/pdf.svg';
import linkIcon from './assets/link.svg';

const getResourceImage = filetype => {
  switch (filetype) {
    case 'PDF':
      return pdfIcon;
    default:
      return linkIcon;
  }
};

const resourceCard = ({ _id, image, title, subtitle, url, filetype }) => {
  const _image = image || getResourceImage(filetype);
  return (
    <div key={_id} className="resource-card">
      <div
        className="resource-image"
        style={{ backgroundImage: `url('${_image || everdaysGuide}')` }}
      />
      <TruncatedText
        disableReadMore
        disableReadLess
        lines={1}
        message={title}
        truncatedClass="resource-title"
      />
      <TruncatedText
        disableReadMore
        disableReadLess
        lines={3}
        message={subtitle}
        truncatedClass="resource-description"
      />
      <a href={url} target="_blank">
        <button>View</button>
      </a>
    </div>
  );
};

@connect(
  ({ dash }) => ({ resources: dash.resources }),
  { fetchResources }
)
export default class ResourcesSection extends React.Component {
  componentWillMount() {
    this.props.fetchResources({ featured: true });
  }

  render() {
    const { resources } = this.props;
    const resourcesArray = get(resources, 'data', []);

    return (
      <div className="resources-section">
        <h2>Enhance Your Everdays Experience</h2>
        <div className="resource-cards">
          {resourcesArray.map(resource => resourceCard(resource))}
        </div>
        <div className="resource-center-box">
          <div className="resource-center-text">
            <h4>Your Resource Center</h4>
            <p>
              Like what you see? Check out more helpful resources to set yourself up for success
            </p>
          </div>
          <Link to="/dash/a/resource-center">
            <button>Go to Resource Center</button>
          </Link>
        </div>
      </div>
    );
  }
}
