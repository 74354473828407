import React from 'react';

import { Form, InputGroup, isRequired, isEmail } from '@evdy/web-core/dist/components/shared/Input';

const RequestEmail = props => {
  return (
    <div className="request-email-step">
      <Form model="dash.onboarding.forms.requestEmail">
        <div className="onboard-form-row">
          <InputGroup
            labelText="Email Address"
            model=".email"
            validators={{ isRequired, isEmail }}
            messages={{
              isRequired: 'Email address is required',
              isEmail: 'Please enter a valid email address'
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default RequestEmail;
