import React, { useEffect, useState, useRef, useCallback } from 'react';
import { browserHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import isEmpty from 'lodash-es/isEmpty';

import DashCard from '../../../shared/DashCard';
import DashCardHeader from '../../../shared/DashCard/CardBuilder/DashCardHeader';
import DashCardFooter from '../../../shared/DashCard/CardBuilder/DashCardFooter';
import PackagesRow from '../../../shared/PackagesRow';
import AttentionRow from '../../../shared/DashCard/CardBuilder/AttentionRow';
import ActionItemRow from '../../../shared/DashCard/CardBuilder/ActionItemRow';
import PreneedTermsModal from '../../../PreneedTermsModal';
import PackagesModal from '../../components/PackagesModal';
import PricingMatricesModal from '../../components/PricingMatricesModal';
import MissingFieldsModal from '../../../shared/MissingFieldsModal';
import BasicPDFInput from '@evdy/web-core/dist/components/BasicPDFInput';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { renderToast, nameString } from '@evdy/web-core/dist/lib/utils';
import { calculatePackageTotals, cleanPrice } from '../../helperFuncs';

import { funeralHomeOperations } from '@evdy/web-redux/dist/lib/funeralHome';

import pdfIcon from './images/pdf-icon.svg';

import './PackageDetails.scss';

const packagesModel = 'funeralHome.forms.packages';
const stateRequirementsPdfUrl =
  'https://everdays-resources.s3.amazonaws.com/preneed/pdfs/state-requirements-sept-20.pdf';

const {
  uploadGPLPDF,
  resetPreneedTOS,
  resetGPLPDFUpload,
  editPreneedPrices,
  editPreneedPricesAll,
  initPreneed,
  getRawPreneedData,
  resetEditPreneedPrices
} = funeralHomeOperations || {};

const PackageDetails = ({
  coords,
  funeralHomeName,
  funeralHomeId,
  priceListingGPLUrl = '',
  isFuneralHomeFetching,
  funeralHomeLastUpdated,
  pdfUploaded,
  isExternalMode,
  handleTabChange,
  location
}) => {
  const {
    fetchedCompany,
    preneedPackages = {},
    packagesFormData,
    funeralHome,
    requiredFields = {},
    account = {}
  } = useSelector(({ dash, funeralHome, requiredPreneedFields }) => ({
    fetchedCompany: dash.fetchedCompany,
    preneedPackages: funeralHome.home.data?.preneedPackages,
    packagesFormData: funeralHome.forms.packages,
    funeralHome: funeralHome.home,
    requiredFields: requiredPreneedFields?.data,
    account: dash.fetchAccounts.account.data
  }));

  const dispatch = useDispatch();
  const { reset } = formActions;
  const selectedAccount = account?._id ? account : undefined;

  const {
    isFetching: isCompanyFetching,
    company,
    lastUpdated: companyLastUpdated,
    error: companyError,
    tosAcceptedStateChange
  } = fetchedCompany || {};
  const companyData = selectedAccount || company?.data || {};
  const { preneedTOS = {}, funeralHomes = [] } = companyData || {};
  const { agreedDate, userRef } = preneedTOS || {};
  const { name: directorName } = userRef || {};
  const otherFuneralHomeIds = funeralHomes
    .map(({ _id }) => _id)
    .filter(_id => _id?.toString() !== funeralHomeId?.toString());
  const { data: funeralHomeData, pricesUpdated } = funeralHome || {};
  const { packages = [], services = [], dataStructure } = preneedPackages || {};
  const { _id: preNeedDataStructureId } = dataStructure || {};
  const isPreneedInitialized = !!preNeedDataStructureId;

  const tosHasBeenAccepted = !!agreedDate;
  const hasGPLUrl = !!priceListingGPLUrl;

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPackagesModalOpen, setIsPackagesModalOpen] = useState(false);
  const [selectedPackageName, setSelectedPackageName] = useState(null);
  const [isMissingFieldsModalOpen, setIsMissingFieldsModalOpen] = useState(false);
  const [isMatricesModalOpen, setMatricesModalOpen] = useState(false);

  const companyLastUpdatedRef = useRef(companyLastUpdated || 0);
  const funeralHomeLastUpdatedRef = useRef(funeralHomeLastUpdated || 0);
  const pdfInput = useRef(null);

  const { funeralHome: requiredLocationFieldsArr = [], company: requiredCompanyFieldsArr = [] } =
    requiredFields || {};
  const requiredFieldsComplete =
    !requiredLocationFieldsArr.length && !requiredCompanyFieldsArr.length;

  //get raw preneed data on mount for non init homes
  useEffect(() => {
    dispatch(getRawPreneedData());
  }, [dispatch]);

  // clear pdf input on upload success
  useEffect(() => {
    if (!isFuneralHomeFetching && funeralHomeLastUpdated && pdfUploaded) {
      dispatch(resetGPLPDFUpload());
      pdfInput.current.value = '';
    }
  }, [dispatch, isFuneralHomeFetching, funeralHomeLastUpdated, pdfUploaded]);

  // close modal after accepting tos
  useEffect(() => {
    if (
      !isCompanyFetching &&
      companyLastUpdated > companyLastUpdatedRef.current + 1000 &&
      tosAcceptedStateChange
    ) {
      setIsTermsModalOpen(false);
      renderToast({
        id: 'terms-success',
        message: 'Thank you for accepting the Terms and Conditions'
      });
      companyLastUpdatedRef.current = companyLastUpdated;
      dispatch(resetPreneedTOS());
    }
  }, [dispatch, tosAcceptedStateChange, isCompanyFetching, companyLastUpdated]);

  // close modal after updating package pricing
  useEffect(() => {
    if (
      isPackagesModalOpen &&
      funeralHome.lastUpdated > funeralHomeLastUpdatedRef.current &&
      isEmpty(funeralHome.error) &&
      pricesUpdated
    ) {
      setIsPackagesModalOpen(false);
      setSelectedPackageName(null);
      renderToast({ id: 'packages-success', message: 'Package Prices Updated' });
      funeralHomeLastUpdatedRef.current = funeralHome.lastUpdated;
      dispatch(resetEditPreneedPrices());
    }
  }, [funeralHome.error, funeralHome.lastUpdated, isPackagesModalOpen]);

  // display toast on error
  useEffect(() => {
    if (companyError?.message) {
      renderToast({ id: 'terms-error', message: companyError?.original?.message, type: 'error' });
    }
  }, [companyError]);

  const handleView = packageName => {
    setIsPackagesModalOpen(true);
    setSelectedPackageName(packageName);
  };

  const handleInputClick = () => {
    pdfInput.current.click();
  };

  const handlePDFSelect = e => {
    const files = e.target.files;
    const pdfFile = files[0];

    const formData = new FormData();
    formData.append('file', pdfFile);
    dispatch(uploadGPLPDF({ funeralHomeId, pdf: formData }));
  };

  const handleUpdatePackages = ({ updateAll = false }) => {
    const hiddenServicePrices = services
      .filter(({ hidden }) => !!hidden)
      .map(({ serviceIndex, price }) => ({ [`service${serviceIndex}`]: price }));

    const packagePrices = calculatePackageTotals(packages).map(({ packageId, packagePrice }) => ({
      [packageId]: packagePrice
    }));

    const everdaysServicePrices = services
      .filter(({ type }) => type === 'everdays')
      .map(({ serviceIndex, price }) => ({ [`service${serviceIndex}`]: price }));

    const servicePrices = Object.entries(packagesFormData).map(([key, val]) => ({
      [key]: +cleanPrice(val)
    }));

    const updatedPrices = [
      ...packagePrices,
      ...everdaysServicePrices,
      ...hiddenServicePrices,
      ...servicePrices
    ];

    if (updateAll) {
      dispatch(editPreneedPricesAll({ funeralHomeId, updatedPrices, otherFuneralHomeIds }));
    } else {
      dispatch(editPreneedPrices({ funeralHomeId, updatedPrices }));
    }
  };

  const tosRowStatus = () => {
    if (tosHasBeenAccepted && !isExternalMode) return 'complete';
    if (!tosHasBeenAccepted) return 'notStartedCheck';
    return 'hidden';
  };

  const actionRows = [
    {
      rowStatus: hasGPLUrl ? 'complete' : 'notStartedCheck',
      rowCopy: 'Upload General Price List PDF',
      notStartedCheck: {
        buttonCopy: 'Upload',
        buttonAction: handleInputClick
      },
      complete: {
        buttonCopy: 'View',
        buttonAction: () => window.open(priceListingGPLUrl),
        secondaryButtonCopy: 'Upload New',
        secondaryButtonAction: handleInputClick
      }
    },
    {
      rowStatus: tosRowStatus(),
      rowCopy: tosHasBeenAccepted
        ? `Accepted Terms of Service by ${nameString(directorName)} - ${format(
            new Date(agreedDate),
            'h:mm aaaa M/d/yy'
          )}`
        : 'Agree to Terms and Conditions for Account',
      notStartedCheck: isExternalMode
        ? {
            buttonCopy: 'Read and Agree',
            buttonAction: () => setIsTermsModalOpen(true)
          }
        : {},
      complete: {}
    },
    {
      rowStatus: requiredFieldsComplete ? 'complete' : 'notStartedCheck',
      rowCopy: requiredFieldsComplete
        ? 'All Information Provided'
        : 'Complete Required Information',
      notStartedCheck: {
        buttonCopy: 'Complete',
        buttonAction: () => setIsMissingFieldsModalOpen(true)
      },
      complete: {
        buttonCopy: 'Location',
        buttonAction: () => handleTabChange(0),
        secondaryButtonCopy: 'Account',
        secondaryButtonAction: () => browserHistory.push('/dash/a/company')
      }
    },
    {
      rowStatus: 'static',
      rowCopy: 'Review State Specific Documentation',
      static: {
        buttonCopy: 'Review',
        buttonAction: () => window.open(stateRequirementsPdfUrl),
        staticRowIcon: pdfIcon
      }
    }
  ];

  const packagesFooterOptions = {
    buttonCopy: 'Edit Pricing',
    footerBtnAction: () => {
      // hit /initPreneed if there are no preneedPackages, dataStructure doesn't exist, or zipcodesServed is null/empty
      // update this logic to a useEffect if possible - web cleanup 8.31.2020
      if (!preneedPackages || !dataStructure?._id) {
        dispatch(initPreneed({ funeralHomeId }))
          .then(() => setIsPackagesModalOpen(true))
          .catch(e => console.log('error', e));
      } else {
        setIsPackagesModalOpen(true);
      }
    }
  };

  const packageHeaderOptions = {
    //Placeholder until task is made/complete to update functionality of button when in internal/external mode and preneed is not initialized
    buttonCopy: isPreneedInitialized ? 'View All Packages' : '',
    headerBtnAction: isPreneedInitialized
      ? () => {
          setMatricesModalOpen(true);
        }
      : null
  };

  const handleOpenEditPackage = !isExternalMode ? () => setIsPackagesModalOpen(true) : () => {};

  return (
    <div className="package-details">
      <DashCard customClass="packages-card">
        <DashCardHeader
          customClass="padded-button-width"
          headerCopy="Funeral Packages"
          {...packageHeaderOptions}
        />
        <PackagesRow {...{ packages, handleView, isPreneedInitialized, getRawPreneedData }} />
        {!isExternalMode && (
          <DashCardFooter
            footerCopy="Edit Package Price Components"
            subtitleText="8 variables are used to create all 3 packages"
            {...packagesFooterOptions}
          />
        )}
      </DashCard>
      <DashCard>
        <DashCardHeader customClass="remove-bottom-border" headerCopy="Get Started" />
        {actionRows.map(rowProps => {
          const { rowStatus, rowCopy } = rowProps || {};
          return (
            <ActionItemRow
              key={rowCopy}
              {...{ rowStatus, rowCopy }}
              buttonCopy={rowProps[rowStatus]?.buttonCopy}
              buttonAction={rowProps[rowStatus]?.buttonAction}
              secondaryButtonCopy={rowProps[rowStatus]?.secondaryButtonCopy}
              secondaryButtonAction={rowProps[rowStatus]?.secondaryButtonAction}
              staticRowIcon={rowProps[rowStatus]?.staticRowIcon}
            />
          );
        })}
      </DashCard>
      <PreneedTermsModal
        closeModal={() => setIsTermsModalOpen(false)}
        modalVisible={isTermsModalOpen}
      />
      <BasicPDFInput {...{ handlePDFSelect }} forwardedRef={pdfInput} />
      <PackagesModal
        modalVisible={isPackagesModalOpen}
        handleCloseModal={() => {
          setSelectedPackageName(null);
          setIsPackagesModalOpen(false);
          dispatch(reset(packagesModel));
        }}
        handleUpdate={handleUpdatePackages}
        displayPackageName={selectedPackageName}
      />
      <PricingMatricesModal
        {...{ handleOpenEditPackage, isExternalMode }}
        modalVisible={isMatricesModalOpen}
        handleCloseModal={() => setMatricesModalOpen(false)}
      />
      <MissingFieldsModal
        {...{ funeralHomeId, location }}
        closeModal={() => setIsMissingFieldsModalOpen(false)}
        modalVisible={isMissingFieldsModalOpen}
        fromLocationDetails={true}
      />
    </div>
  );
};

export default PackageDetails;
