import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import startCase from 'lodash-es/startCase';

import TaskOption from '../TaskOption';
import LatestActivityModalHeader from '../shared/LatestActivityModalHeader';
import {
  InputGroup,
  DatePicker,
  formActions,
  stdErrMsg,
  Typeahead
} from '@evdy/web-core/dist/components/shared/Input';

import { closeLeadOptions, getMostRecentAttemptCount } from '../../shared/utils';

import './ClosedModalBody.scss';

const ClosedModalBody = props => {
  const { activity, dispatch, latestStageActivity, closedForm, isLimited } = props;
  const { selectedTask } = closedForm;
  const { attempt, currentStage, dueDate } = latestStageActivity;

  const model = 'dash.crm.forms.closed';

  const handleSelect = type => {
    dispatch(formActions.change(`${model}.selectedTask`, type));
  };

  return (
    <div className="closed-modal-body">
      <LatestActivityModalHeader {...{ currentStage, dueDate, attempt }} />
      <h3>What is your next step?</h3>
      <div className="task-options">
        <TaskOption
          title="Close the lead"
          subtitle="Remove from task list"
          selected={selectedTask === 'close'}
          handleSelectClick={() => handleSelect('close')}
        >
          <InputGroup required model={`${model}.close.reason`} type="select" labelText="Reason">
            <option value="">Select</option>
            {closeLeadOptions.map(option => (
              <option value={option}>{startCase(option)}</option>
            ))}
          </InputGroup>
          <InputGroup
            customClass="additional-notes"
            model={`${model}.close.additionalNotes`}
            labelText="Additional Notes"
            type="textarea"
          />
        </TaskOption>
        {!isLimited && (
          <TaskOption
            title="Lead not closed - Schedule next follow-up"
            subtitle={`#${getMostRecentAttemptCount(activity, 'meeting-held') + 1} Attempt`}
            selected={selectedTask === 'retry'}
            handleSelectClick={() => handleSelect('retry')}
          >
            <div className="date-time-row">
              <DatePicker
                isRequired
                model={`${model}.retry`}
                dateModel="date"
                label="Due Date"
                defaultYearRange={3}
                yearBase={moment().get('year') + 2}
              />
              <Typeahead
                time
                medium
                model={`${model}.retry.time`}
                modelBase=""
                labelText="Time"
                messages={stdErrMsg('Time')}
              />
            </div>
            <InputGroup
              customClass="additional-notes"
              model={`${model}.retry.additionalNotes`}
              labelText="Additional Notes"
              type="textarea"
            />
          </TaskOption>
        )}
      </div>
    </div>
  );
};

export default connect(({ dash }) => ({ closedForm: dash.crm.forms.closed }))(ClosedModalBody);
