import React, { useState } from 'react';
import startCase from 'lodash-es/startCase';

import DashCard from '../../../shared/DashCard';
import DashCardHeader from '../../../shared/DashCard/CardBuilder/DashCardHeader';
import CompanyEditModal from '../CompanyEditModal';

import { formatPhoneNumber } from '@evdy/web-core/dist/lib/utils';

import './EditCompanyCard.scss';

const EditCompanyCard = ({
  name,
  type,
  phone,
  website,
  address,
  logo = '',
  taxID,
  email,
  taxClassification
}) => {
  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);

  return (
    <DashCard customClass="company-card">
      <DashCardHeader
        headerCopy="Basics"
        buttonCopy="Edit"
        headerBtnAction={() => setCompanyModalOpen(true)}
      />
      <div className="company-info">
        {logo && <div className="company-image" style={{ backgroundImage: `url('${logo}')` }} />}
        <div className="company-info-row account-name">
          <div className="row-title">Account Name</div>
          <div className="row-info">{name || '-'}</div>
        </div>
        <div className="company-info-row type-of-business">
          <div className="row-title">Type of Business</div>
          <div className="row-info">{startCase(type) || '-'}</div>
        </div>
        <div className="company-info-row tax-id">
          <div className="row-title">Tax ID</div>
          <div className="row-info">{taxID || '-'}</div>
        </div>
        <div className="company-info-row tax-classification">
          <div className="row-title">Tax Classification</div>
          <div className="row-info">{taxClassification || '-'}</div>
        </div>
        <div className="company-info-row phone">
          <div className="row-title">Phone Number</div>
          <div className="row-info">{formatPhoneNumber(phone) || '-'}</div>
        </div>
        <div className="company-info-row email">
          <div className="row-title">Account Email</div>
          <div className="row-info">{email || '-'}</div>
        </div>
        <div className="company-info-row website">
          <div className="row-title">Website</div>
          <div className="row-info">{website || '-'}</div>
        </div>
        <div className="company-info-row address-1">
          <div className="row-title">Address 1</div>
          <div className="row-info">{address?.street1 || '-'}</div>
        </div>
        <div className="company-info-row address-2">
          <div className="row-title">Address 2</div>
          <div className="row-info">{address?.street2 || '-'}</div>
        </div>
        <div className="company-info-row city">
          <div className="row-title">City</div>
          <div className="row-info">{address?.city || '-'}</div>
        </div>
        <div className="company-info-row state">
          <div className="row-title">State</div>
          <div className="row-info">{address?.state || '-'}</div>
        </div>
        <div className="company-info-row zip">
          <div className="row-title">Zip</div>
          <div className="row-info">{address?.zip || '-'}</div>
        </div>
      </div>
      <CompanyEditModal
        modalVisible={isCompanyModalOpen}
        closeModal={() => setCompanyModalOpen(false)}
      />
    </DashCard>
  );
};

export default EditCompanyCard;
