import React from 'react';

import classNames from 'classnames';

import PointedArrow from './PointedArrow';
import EndArrow from './EndArrow';

import './ArrowComponent.scss';

const ArrowComponent = ({ isEnd, text, isActive, closedColor, handleArrowClick }) => {
  const arrowText = classNames('arrow-text', { 'active-text': isActive });

  return (
    <div className="arrow-container" onClick={handleArrowClick && handleArrowClick}>
      {isEnd ? <EndArrow closedColor={closedColor} /> : <PointedArrow isActive={isActive} />}
      <span className={arrowText}>{text}</span>
    </div>
  );
};

export default ArrowComponent;
