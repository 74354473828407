"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var titles = {
  administrativeAssistant: 'Administrative Assistant',
  apprentice: 'Apprentice',
  assistant: 'Assistant/Support',
  caregiver: 'Caregiver',
  celebrant: 'Celebrant',
  CEO: 'CEO',
  chaplain: 'Chaplain',
  clergy: 'Clergy',
  director: 'Director',
  embalmer: 'Embalmer',
  familyServicesCounselor: 'Family Services Counselor',
  funeralArranger: 'Funeral Arranger',
  funeralDirector: 'Funeral Director',
  generalManager: 'General Manager',
  griefCounselor: 'Grief Counselor',
  intern: 'Intern',
  manager: 'Manager',
  mortician: 'Mortician',
  nurse: 'Nurse',
  officeAdministrator: 'Office Administrator',
  other: 'Other',
  owner: 'Owner',
  preneedCounselor: 'Preneed Counselor',
  president: 'President',
  socialWorker: 'Social Worker',
  vicePresident: 'Vice President'
};
var _default = titles;
exports.default = _default;