import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { InputGroup, formActions } from '@evdy/web-core/dist/components/shared/Input';
import { renderToast } from '@evdy/web-core/dist/lib/utils';

import { editUserAction, editUserReset } from '@evdy/web-redux/dist/actions/dash/editUser';

import './DemoTab.scss';

const model = 'dash.profile.forms.demo.isDemoModeOn';

const DemoTab = props => {
  const { change, demo, editUserAction, user, isInternal } = props;
  const { isDemoModeOn: isUserDemoModeOn } = user;

  const handleCheckboxToggle = () => {
    const { isDemoModeOn } = demo;
    editUserAction({ isDemoModeOn: !isDemoModeOn });
  };

  const handleDisabledClick = () => {
    if (isInternal) {
      renderToast({
        id: 'internal-demo-mode',
        type: 'error',
        message: 'Please Switch to Professional Mode first'
      });
    }
  };

  // handle keeping checkbox current with state
  useEffect(() => {
    change(model, isUserDemoModeOn);
  }, [change, isUserDemoModeOn]);

  return (
    <div className="profile-demo-tab">
      {isInternal && <div className="click-container" onClick={() => handleDisabledClick()} />}
      <InputGroup
        model={model}
        type="checkbox"
        customClass="demo-mode-checkbox"
        labelText="Turn on Demo Mode"
        onChange={handleCheckboxToggle}
        disabled={isInternal}
      />
      <p>
        Demo Mode provides a great example of what your account will
        <br /> look like after you utilize the service.
      </p>
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    demo: dash.profile.forms.demo,
    editUser: dash.editUser,
    user: dash.user.data,
    isInternal: !dash?.dashNav?.isExternalMode
  }),
  { editUserAction, editUserReset, change: formActions.change }
)(DemoTab);
