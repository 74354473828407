import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';

import DashCard from '../shared/DashCard';
import DashCardHeader from '../shared/DashCard/CardBuilder/DashCardHeader';
import TeamMemberModal from './components/TeamMemberModal';
import MemberRow from '../shared/DashCard/CardBuilder/MemberRow';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { useScrollToTop, useURLParamSync } from '@evdy/web-core/dist/customHooks';

import './TeamMembers.scss';

const TeamMembers = ({ members = [], reset, isExternalMode, location }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('add-member');
  const [modalData, setModalData] = useState({});

  // on mount, scroll to top of page
  useScrollToTop();

  const memoizedFiltersObj = useMemo(() => ({ isExternalMode }), [isExternalMode]);

  useURLParamSync(memoizedFiltersObj, location);

  const handleAddBtnClick = () => {
    setModalType('add-member');
    setIsModalOpen(true);
    setModalData({});
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalType('add-member');
    setModalData({});
    reset('dash.company.forms.memberModal');
  };

  const handleEdit = member => {
    setModalType('edit-member');
    setModalData({ member });
    window.requestAnimationFrame(() => setIsModalOpen(true)); // use requestAnimationFrame to avoid seeing the empty state before forms are filled
  };

  return (
    <div className="team-members-page">
      <h1>Team Members</h1>
      <DashCard customClass="team-members-card">
        <DashCardHeader
          headerCopy="All Team Members"
          buttonCopy="Add Team Members"
          headerBtnAction={handleAddBtnClick}
        />
        <div className="members-list">
          {members.map(member => (
            <MemberRow
              key={member._id}
              email={member.email}
              image={member.image}
              name={member.name}
              title={member.title}
              handleEdit={() => handleEdit(member)}
            />
          ))}
        </div>
      </DashCard>
      <TeamMemberModal {...{ closeModal, modalData, modalType }} modalVisible={isModalOpen} />
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    members: dash?.fetchedCompany?.company?.data?.users,
    isExternalMode: dash.dashNav.isExternalMode
  }),
  { reset: formActions.reset }
)(TeamMembers);
