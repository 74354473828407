import React from 'react';
import { connect } from 'react-redux';

import { openCreateModal } from '@evdy/web-redux/dist/lib/dash/actions/dashNav';

const NoAnnouncements = ({ searchText, activeFilters, openCreateModal }) => {
  const noResults = searchText?.length > 0 || !!activeFilters.length;

  return (
    <div className="no-annc">
      <div className="no-annc-desc">
        {noResults ? (
          <>
            <h1>No Memorials found</h1>
            <p>The Memorial(s) may not exist or you may need to adjust your filters.</p>
          </>
        ) : (
          <>
            <h1>Create your first Memorial</h1>
            <div>Get started today and your Memorials will display here.</div>
            <p>Check out these helpful tips on introducing Everdays to families.</p>
          </>
        )}
        <button onClick={openCreateModal}>Create Memorial</button>
      </div>
    </div>
  );
};

export default connect(null, { openCreateModal })(NoAnnouncements);
