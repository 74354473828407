import React, { useEffect, useRef, useState } from 'react';

import DashCard from '../../../shared/DashCard';
import DashCardHeader from '../../../shared/DashCard/CardBuilder/DashCardHeader';
import AttentionRow from '../../../shared/DashCard/CardBuilder/AttentionRow';

import ConfirmationModal from '../../../shared/ConfirmationModal';

import { renderToast } from '@evdy/web-core/dist/lib/utils';

import './ArchiveCard.scss';

const ArchiveCard = ({ archiveInvite, inviteMeta, isArchived, memorialId }) => {
  const { isFetching, lastUpdated } = inviteMeta || {};

  const [isArchiveConfirmModalOpen, setArchiveConfirmModalOpen] = useState(false);

  const prevInviteMetaLastUpdated = useRef(null);
  const prevArchiveStatus = useRef();

  // close archive modal after success
  useEffect(() => {
    if (prevInviteMetaLastUpdated.current < lastUpdated - 100 && !isFetching) {
      setArchiveConfirmModalOpen(false);
      if (prevArchiveStatus.current !== undefined && prevArchiveStatus.current !== isArchived) {
        renderToast({
          id: 'archive-invite-success',
          message: `Memorial ${isArchived ? 'Archived' : 'Restored'}`
        });
      }
    }
    prevInviteMetaLastUpdated.current = lastUpdated;
    prevArchiveStatus.current = isArchived;
  }, [isFetching, lastUpdated]);

  const handleArchive = () => {
    archiveInvite({ memorialId });
  };

  return (
    <>
      <DashCard customClass="invite-archive-card">
        <DashCardHeader
          headerCopy={isArchived ? 'Restore Memorial' : 'Archive Memorial'}
          headerBtnAction={() => (isArchived ? handleArchive() : setArchiveConfirmModalOpen(true))}
          buttonCopy={isArchived ? 'Restore' : 'Archive'}
          buttonType="delete"
        />
        <AttentionRow
          cautionFlag
          footerCopy={
            isArchived
              ? 'This Memorial will be accessible again'
              : 'All 3 family members and everyone they’ve notified will lose access'
          }
        />
      </DashCard>
      <ConfirmationModal
        closeModal={() => setArchiveConfirmModalOpen(false)}
        modalVisible={isArchiveConfirmModalOpen}
        headerCopy="Confirm Archive"
        confirmText="Are you sure you want to archive this Memorial?"
        mainButtonText="Archive"
        mainButtonFn={handleArchive}
      />
    </>
  );
};

export default ArchiveCard;
