import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';

import DashCard from '../../shared/DashCard';
import ActionItemRow from '../../shared/DashCard/CardBuilder/ActionItemRow';
import MissingFieldsModal from '../../shared/MissingFieldsModal';
import AccountSetupHeader from '../../shared/DashCard/CardBuilder/AccountSetupHeader';
import ProgressBar from '../../shared/DashCard/CardBuilder/ProgressBar';

import './GenerateSales.scss';

const GenerateSales = ({ handleOpenTermsModal, locations = [], preneedTOS, routeLocation }) => {
  const { missingPreneedCompFieldsArr = [] } = useSelector(({ requiredPreneedFields }) => ({
    missingPreneedCompFieldsArr: requiredPreneedFields.data?.company
  }));

  const { agreedDate = 0 } = preneedTOS || {};
  const { query } = routeLocation || {};

  const [isRequiredModalOpen, setRequiredModalOpen] = useState(false);

  const hasCompleteData = !missingPreneedCompFieldsArr.length;

  const actionRows = [
    {
      rowStatus: !!agreedDate ? 'hidden' : 'notStartedCheck',
      notStartedCheck: {
        rowCopy: 'Agree to Terms and Conditions',
        buttonCopy: 'Read and Agree',
        buttonAction: handleOpenTermsModal
      }
    },
    {
      rowStatus: hasCompleteData ? 'complete' : 'notStartedCheck',
      complete: {
        rowCopy: 'All Information Provided',
        buttonCopy: 'Account',
        buttonAction: () => browserHistory.push('/dash/a/company')
      },
      notStartedCheck: {
        rowCopy: 'Complete Required Account Information',
        buttonCopy: 'Complete',
        buttonAction: () => setRequiredModalOpen(true)
      }
    }
  ];

  // loop through locations and add a row for each location
  for (let i = 0; i < locations.length; i++) {
    const { _id: locationId, name, packageSetupComplete = false } = locations[i] || {};
    const locationDetailsUrl = `/dash/a/locations/${locationId}`;

    const locationObj = {
      rowStatus: packageSetupComplete ? 'complete' : 'notStartedCheck',
      complete: {
        rowCopy: name,
        buttonCopy: 'Edit',
        buttonAction: () =>
          browserHistory.push({ pathname: locationDetailsUrl, query: { ...query, tab: 1 } })
      },
      notStartedCheck: {
        rowCopy: name,
        buttonCopy: 'Setup',
        buttonAction: () =>
          browserHistory.push({ pathname: locationDetailsUrl, query: { ...query, tab: 1 } })
      }
    };

    actionRows.push(locationObj);
  }

  const completeRows = actionRows.filter(row => row.rowStatus === 'complete');

  return (
    <>
      <DashCard>
        <div className="generate-sales-header">
          <AccountSetupHeader
            headerCopy="Start generating preneed sales"
            customClass="remove-btm-border"
          />
          <ProgressBar numOfSteps={actionRows.length} numOfCompleteSteps={completeRows.length} />
        </div>
        {actionRows.map(rowProps => {
          const { rowStatus } = rowProps || {};
          return (
            <ActionItemRow
              {...{ rowStatus }}
              rowCopy={rowProps[rowStatus]?.rowCopy}
              buttonCopy={rowProps[rowStatus]?.buttonCopy}
              buttonAction={rowProps[rowStatus]?.buttonAction}
              secondaryButtonCopy={rowProps[rowStatus]?.secondaryButtonCopy}
              secondaryButtonAction={rowProps[rowStatus]?.secondaryButtonAction}
            />
          );
        })}
      </DashCard>
      <MissingFieldsModal
        modalVisible={isRequiredModalOpen}
        fromAccountSetup={true}
        closeModal={() => setRequiredModalOpen(false)}
      />
    </>
  );
};

export default GenerateSales;
