import React, { useEffect, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import Scroll from 'react-scroll/modules';
import isEmpty from 'lodash-es/isEmpty';

import './Users.scss';
import useUserFilter from './useUserFilter';
import {
  Form,
  InputGroup,
  Typeahead,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';
import searchIcon from '../assets/search-icon-grey-small.svg';
import { ListSkeleton } from '../SkeletonLoader';
import renderPagination from '../renderPagination';
import UserList from './UserList';
import { useURLParamSync, useScrollToTop } from '@evdy/web-core/dist/customHooks/index';

import { getUsers } from '@evdy/web-redux/dist/actions/dash/users';

const Users = props => {
  const { dash, location, isExternalMode, searchText, getUsers, reset, change } = props;
  const [reducerIsLoaded, setReducerIsLoaded] = useState(false);

  const store = {
    filter: useUserFilter(),
    filterArgs: [],
    searchTags: [],
    users: dash.users?.data,
    usersNext: dash.users?.next,
    usersPrev: dash.users?.prev,
    total: dash.users?.total
  };

  const [
    userFilter = {},
    setUserFilter = () => {},
    getFilters = () => {},
    filterType = {},
    dropdownOptions = {}
  ] = store.filter;

  const filters = getFilters(...store.filterArgs);
  const { query } = location;
  const searchInput = useRef(null);
  const prevSearchInput = useRef('');
  let activeFilters = [];

  const memoizedUsersFilter = useMemo(
    () => ({
      ...userFilter,
      isExternalMode
    }),
    [userFilter, isExternalMode]
  );

  useScrollToTop();
  //TODO WebCleanUp2020 - memoizedAccountsFilter doesn't need isExternal, dashnav handling
  useURLParamSync(memoizedUsersFilter, location, ['selected']);

  // restore page filters on refresh
  useEffect(() => {
    const {
      usertypeFilter,
      usertypeFilterName,
      regtypeFilter,
      regtypeFilterName,
      sort,
      searchQuery,
      page
    } = query;

    setUserFilter({ type: filterType.SORT, value: query.sort });
    usertypeFilter &&
      setUserFilter({
        type: filterType.USER_TYPE,
        id: usertypeFilter,
        value: usertypeFilterName
      });
    regtypeFilter &&
      setUserFilter({
        type: filterType.REG_TYPE,
        id: regtypeFilter,
        value: regtypeFilterName
      });
    setUserFilter({
      type: filterType.ONLOAD,
      value: {
        usertypeFilter,
        regtypeFilter,
        sort,
        searchQuery,
        page: page || 0
      }
    });

    setReducerIsLoaded(true);

    return () => {
      reset('forms.dashUsers.searchText');
      reset('forms.dashUsers.sort');
    };
  }, []);

  // reset page query params if the user clicks navigation link
  useEffect(() => {
    if (isEmpty(query)) setUserFilter({ type: filterType.RESET });
  }, [filterType.RESET, query, setUserFilter]);

  // clear search query state when the user clears the search input
  useEffect(() => {
    if (
      searchText === '' && // search input is cleared
      !!prevSearchInput.current // there is previous input
    ) {
      setUserFilter({ type: filterType.SEARCH, value: '' });
    }

    prevSearchInput.current = searchText;
  }, [searchText]);

  // fetch updates when state updates
  useEffect(() => {
    if (reducerIsLoaded) {
      change('forms.dashUsers.searchText', userFilter.searchQuery);
      change('forms.dashUsers.sort', userFilter.sort);

      if (dash.users.lastUpdated < Date.now() - 5e2) getUsers(userFilter);
    }
  }, [change, dash.users.lastUpdated, getUsers, userFilter]);

  const renderButton = ({ type, value, id }) => {
    const key = `${type}-${id}`;
    const selectedFilters = Object.values(userFilter.selected).map(({ value }) => value);
    const active = selectedFilters.includes(key);
    const buttonClasses = classNames('button-text', { active });

    return (
      <button key={key} className="filter" onClick={() => setUserFilter({ type, value, id })}>
        <span className={buttonClasses}>{value}</span>
      </button>
    );
  };

  const renderFilterMenu = filters.map(({ header, options }, i) => (
    <div key={`filter-group-${i}`} className="filter-section">
      {header && <div className="filter-section-header">{header}</div>}
      {options.map(renderButton)}
    </div>
  ));

  const renderFilterStatus = () => {
    const { searchQuery, sortName, selected } = userFilter;
    const { total } = store;

    activeFilters = Object.values(selected)
      .map(({ name }) => name)
      .filter(Boolean);

    const filterText = activeFilters.length && `Filtering by ${activeFilters.join(', ')}`;
    const sortText = sortName && `Sorting by ${sortName}`;
    const searchText = searchQuery && `Searching by "${searchQuery}"`;

    const statusText = [filterText, sortText, searchText].filter(Boolean).join(', ');

    if (!filterText && !sortText && !searchText) return null;

    return (
      <div className="usertab-status" onClick={clearFilters}>
        {statusText} ({total} in List)
      </div>
    );
  };

  const clearFilters = () => {
    setUserFilter({ type: filterType.RESET });
    browserHistory.push({ ...location, query: { ...query, page: store.initialPage } });
  };

  const handleSort = ({ target: { value } }) => {
    setUserFilter({ type: filterType.SORT, value });
  };

  const handleSearchSubmit = ({ searchText, filteredSearchText, inlineSearchTag }) => {
    setUserFilter({ type: filterType.SEARCH, value: searchText });
    searchInput.current.changeFocus();
  };

  const handleUserClick = id => {
    const pathname = `${location.pathname}/${id}`;
    browserHistory.push({ ...location, pathname });
  };

  const suggestionArray = [props.searchText || '.']; // '.' is used to hide the suggestions on empty input

  const handleArrowClick = direction => {
    let newPage = 0;
    const scroll = Scroll.animateScroll;
    return () => {
      const page = parseInt(userFilter.page);
      if (direction === 'prev') {
        newPage = page && page - 1;
      } else if (direction === 'next') {
        newPage = page + 1;
      }

      setUserFilter({ type: 'PAGE', value: newPage });
      scroll.scrollToTop({ duration: 400 });
    };
  };

  const currentPage = parseInt(userFilter.page);

  const { users, usersNext, usersPrev, total } = store;

  return (
    <div className="dash-users-container">
      <div className="title-container">
        <h1>Users</h1>
      </div>
      {isExternalMode ? (
        <div>This tab is not available in Professional Mode</div>
      ) : (
        <div className="dash-users-body">
          <div className="usertab-sidebar">{renderFilterMenu}</div>
          <div className="usertab-main">
            <div className="search-dropdown-container">
              <Form
                className="usertab-dropdown"
                model="forms.dashUsers"
                onSubmit={handleSearchSubmit}
              >
                <Typeahead
                  placeholder="Search Users"
                  customClass="usertab-search-input"
                  modelBase="forms.dashUsers"
                  model=".searchText"
                  data={suggestionArray}
                  pre={searchIcon}
                  type="text"
                  ref={searchInput}
                  onSelect={() => handleSearchSubmit({ searchText })}
                />
                <div className="dropdown-filter-menu">
                  {dropdownOptions.sortFilterOptions && (
                    <div className="dropdown-container">
                      <span>Sort by</span>
                      <InputGroup
                        customClass="usertab-dropdown-input"
                        type="select"
                        model=".sort"
                        onChange={handleSort}
                      >
                        {dropdownOptions.sortFilterOptions.map(({ name, value }) => (
                          <option key={`sort-${value}`} {...{ value }}>
                            {name}
                          </option>
                        ))}
                      </InputGroup>
                    </div>
                  )}
                </div>
              </Form>
              {renderFilterStatus()}
            </div>
            <div className="usertab-list-container">
              <div className="users-list">
                {!users ? <ListSkeleton /> : <UserList {...{ users, handleUserClick }} />}
                {users &&
                  !!users.length &&
                  renderPagination({
                    currentPage,
                    startsAtZero: true,
                    handleArrowClick,
                    content: users,
                    next: usersNext,
                    previous: usersPrev,
                    total,
                    pageStep: 10
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  ({ dash, forms }) => ({
    dash,
    isExternalMode: dash.dashNav.isExternalMode,
    searchText: forms.dashUsers.searchText
  }),
  { getUsers, reset: formActions.reset, change: formActions.change }
)(Users);
