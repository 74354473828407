import React from 'react';
import axios from 'axios/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { asYouType, isValidNumber } from 'libphonenumber-js';

import set from 'lodash-es/set';
import get from 'lodash-es/get';
import isFinite from 'lodash-es/isFinite';
import isArray from 'lodash-es/isArray';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import { Step, Stepper, StepLabel, StepButton } from 'material-ui/Stepper';

import IoChevronRight from 'react-icons/lib/io/chevron-right';
import IoChevronLeft from 'react-icons/lib/io/chevron-left';

import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import PopupDialog from '@evdy/web-core/dist/components/shared/elements/PopupDialog';

import RegistrationStartUser from './RegistrationStartUser';
import SetupPasswordAdmin from '../companyRegistration/SetupPasswordAdmin';
import ReviewSubmitUser from './ReviewSubmitUser';

import transAxiosErr from '@evdy/web-etc/dist/lib/transformAxiosError';
import { googleAnalytics } from '@evdy/web-core/dist/lib/utils';
import { API_URL } from '@evdy/web-redux/dist/constants';
import { submitUserSignup, resetError } from '@evdy/web-redux/dist/actions/dash/submitUserSignup';

require('@evdy/web-core/dist/sass/components/shared/DashStepperStyles.css');
require('@evdy/web-core/dist/sass/components/shared/DashLiftIntercom.css');
require('@evdy/web-core/dist/sass/components/shared/DashFormStyles.css');

const classNames = require('classnames');

const instance = axios.create({
  baseURL: API_URL
});

//noinspection JSPotentiallyInvalidConstructorUsage
const parsePhone = phone => new asYouType('US').input(phone);

const emailInUse = 'This email is already in use.';
const phoneInUse = 'This phone number is already in use by another dash user.';

const errorMessages = {
  userSignup: {
    password: 'Enter a password with at least 6 characters.',
    passwordConfirmation: 'Your passwords do not match.',
    email: 'Please enter your email to continue.',
    phone: 'A valid phone number is required.',
    name: {
      first: 'First name required.',
      last: 'Last name required.'
    }
  }
};

class RegistrationUser extends React.Component {
  constructor(props) {
    super(props);

    const defaultQuery = {
      'name.first': '',
      'name.last': '',
      title: '',
      email: '',
      phone: ''
    };

    const query = Object.assign({}, defaultQuery, props.location.query);

    this.state = {
      validInputs: {
        userSignup: {
          name: {
            first: !!query['name.first'],
            last: !!query['name.last']
          },
          phone: !!query.phone,
          email: !!query.email,
          title: !!query.title
        }
      },
      errorText: {
        userSignup: {
          password: '',
          passwordConfirmation: '',
          email: ''
        }
      },
      userSignup: {
        image: '',
        name: {
          first: query['name.first'],
          last: query['name.last']
        },
        phone: query.phone,
        email: '',
        title: query.title,
        password: '',
        passwordConfirmation: ''
      },
      finished: false,
      stepIndex: 0,
      originalPhone: query.phone,
      originalEmail: query.email
    };

    this.startRegistration = this.startRegistration.bind(this);
    this.resetError = this.resetError.bind(this);
    this.validatePage = this.validatePage.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.jumpToStep = this.jumpToStep.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.getInputClass = this.getInputClass.bind(this);
    this.validate = this.validate.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.getErrorText = this.getErrorText.bind(this);
    this.handleFinish = this.handleFinish.bind(this);

    this.cropProfileImage = this.cropProfileImage.bind(this);
  }

  startRegistration() {
    this.setState({ started: true });
  }

  static makeFakeEvent(value) {
    return { target: { value, validity: { valid: true } } };
  }

  componentDidMount() {
    const { email, phone } = this.props.location.query;
    const emailEvent = RegistrationUser.makeFakeEvent(email);
    const updateEmail = () => this.handleEmailChange('email', 'userSignup')(emailEvent);
    if (phone) {
      const phoneEvent = RegistrationUser.makeFakeEvent(phone.replace('+1', ''));
      this.handlePhoneChange('phone', 'userSignup', null, true)(phoneEvent, updateEmail);
    } else if (email) {
      updateEmail();
    }
    document.body.classList.add('stepper');
  }

  componentWillUnmount() {
    document.body.classList.remove('stepper');
  }

  resetError() {
    this.props.actions.resetError();
  }

  validatePage(arrayOfRequiredKeys) {
    const { validInputs, errorText } = this.state;
    const stateObject = 'userSignup';

    let valid = true;
    arrayOfRequiredKeys.forEach(key => {
      if (get(validInputs[stateObject], key)) {
        return;
      }
      valid = false;
      set(errorText[stateObject], key, get(errorMessages[stateObject], key));
    });

    this.setState({ errorText });
    return valid;
  }

  handleNext() {
    const { stepIndex } = this.state;

    const requiredFields = [
      'password',
      'passwordConfirmation',
      'email',
      'phone',
      'name.first',
      'name.last'
    ];

    if (!this.validatePage(requiredFields)) {
      return false;
    } else {
      this.setState({
        stepIndex: stepIndex + 1,
        finished: stepIndex >= 4
      });
    }
  }

  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex === 0) return; // prevent Back press here, because of MUI weirdness
    this.setState({
      loading: false,
      stepIndex: stepIndex - 1
    });
  }

  jumpToStep = num => () => {
    // TODO: handle verification with jump step
    this.setState({ stepIndex: num });
  };

  getInputClass(key) {
    return classNames({
      'form-control': true,
      valid: get(this.state.validInputs, key),
      invalid: get(this.state.errorText, key)
    });
  }

  validatePassword(validInputs) {
    const { password, passwordConfirmation } = this.state.userSignup;

    const passKey = 'userSignup.password';
    const passConfirmKey = 'userSignup.passwordConfirmation';

    set(validInputs, passKey, !!password && password.length > 5);
    set(validInputs, passConfirmKey, password && password === passwordConfirmation);
  }

  validate(el, stateObject, key) {
    const nextState = { validInputs: { ...this.state.validInputs } };
    const comboKey = `${stateObject}.${key}`;

    if (key === 'password' || key === 'passwordConfirmation') {
      this.validatePassword(nextState.validInputs);
    } else {
      set(nextState.validInputs, comboKey, el.validity.valid);
    }

    if (el.validity.valid && get(this.state.errorText, comboKey)) {
      nextState.errorText = { ...this.state.errorText };
      set(nextState.errorText, comboKey, '');
    }

    this.setState(nextState);
  }

  getValue(_key, stateObject, idx) {
    const key = idx > -1 ? `[${idx}].${_key}` : _key;
    return get(this.state[stateObject], key) || '';
  }

  getErrorText(stateObj, key) {
    return get(this.state.errorText, `${stateObj}.${key}`);
  }

  getStepContent(stepIndex) {
    const isUserSignup = this.props.location.pathname === '/dash/user-registration';

    switch (stepIndex) {
      case 0:
        return (
          <SetupPasswordAdmin
            getInputClass={this.getInputClass}
            validate={this.validate}
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleEmailChange={this.handleEmailChange}
            getValue={this.getValue}
            getErrorText={this.getErrorText}
            isUserSignup={isUserSignup}
            image={this.state.userSignup.image}
            setCroppedImage={this.cropProfileImage}
          />
        );
      case 1:
        return <ReviewSubmitUser userInfo={this.state.userSignup} jumpToStep={this.jumpToStep} />;
      default:
        return '';
    }
  }

  handleInputChange(_key, stateObject, idx, skipValidate) {
    return event => {
      const key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      const { target } = event;
      let { value, checked } = target;

      if (target.type === 'checkbox') {
        value = !!checked;
      }

      const oldStateObject = this.state[stateObject];
      const newStateObject = isArray(oldStateObject) ? [...oldStateObject] : { ...oldStateObject };

      this.setState({ [stateObject]: set(newStateObject, key, value) }, () => {
        if (skipValidate) {
          return;
        }
        this.validate(target, stateObject, key);
      });
    };
  }

  static setDupePhoneMessage(stateObject, key, validInputs, errorText) {
    set(validInputs[stateObject], key, false);
    set(errorText[stateObject], key, phoneInUse);
  }

  handlePhoneChange(_key, stateObject, idx, checkDuplicate) {
    return ({ target: { value } }, cb) => {
      const key = isFinite(idx) ? `[${idx}].${_key}` : _key;

      const oldStateObject = this.state[stateObject];
      const newStateObject = isArray(oldStateObject) ? [...oldStateObject] : { ...oldStateObject };

      const validInputs = { ...this.state.validInputs };
      const errorText = { ...this.state.errorText };

      if (/^\(?\d{0,3}\)?$/.test(value)) {
        set(newStateObject, key, value);
      } else {
        value = parsePhone(value);
        set(newStateObject, key, value);
      }

      const isValid = isValidNumber(value, 'US') || isValidNumber(value, 'CA');
      set(validInputs[stateObject], key, isValid);

      if (isValid) {
        set(errorText[stateObject], key, '');
        if (checkDuplicate) {
          clearTimeout(this.checkPhoneTimeout);
          this.checkPhoneTimeout = setTimeout(
            () =>
              instance
                .get(`/public/check-phone/${value}`, { params: { checkHasPass: true } })
                .then(res => {
                  const {
                    exists,
                    hasPass,
                    isApp,
                    isShell,
                    hasCompany,
                    completedDashSignup
                  } = res.data.data;

                  /**
                   * If user is shell, skip this entirely.
                   */
                  if (isShell) return;

                  /**
                   * if user has password and has completed dash signup or is not app user and
                   * has company.
                   */
                  if (exists && hasPass && (completedDashSignup || (!isApp && hasCompany))) {
                    const { validInputs, errorText } = this.state;
                    RegistrationUser.setDupePhoneMessage(stateObject, key, validInputs, errorText);
                    return this.setState({ validInputs, errorText });
                  }

                  /**
                   * If user is app user but not dash, display warning.
                   */
                  if (exists && isApp) {
                    return this.setState({ dupePhoneError: value });
                  }
                  // } else if (exists) {
                  //   this.setState({ dupePhoneError: value });
                  // }
                })
                .catch(error => console.error(transAxiosErr(error))),
            500
          );
        }
      }

      if (typeof cb !== 'function') {
        cb = () => {};
      }

      this.setState({ [stateObject]: newStateObject, validInputs, errorText }, cb);
    };
  }

  handleEmailChange(_key, stateObject, idx) {
    return event => {
      const key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      const { target } = event;
      let { value } = target;

      const oldStateObject = this.state[stateObject];
      const newStateObject = isArray(oldStateObject) ? [...oldStateObject] : { ...oldStateObject };

      if (target.validity.valid) {
        clearTimeout(this.checkEmailTimeout);
        this.checkEmailTimeout = setTimeout(
          () =>
            instance
              .get(`/public/check-email/${value}`, { params: { checkHasPass: true } })
              .then(res => {
                const { exists, hasPass, completedDashSignup } = res.data.data;
                if (exists && hasPass && completedDashSignup) {
                  const validInputs = { ...this.state.validInputs };
                  const errorText = { ...this.state.errorText };

                  set(validInputs[stateObject], key, false);
                  set(errorText[stateObject], key, emailInUse);

                  this.setState({ validInputs, errorText });
                }
              })
              .catch(error => console.error(transAxiosErr(error))),
          500
        );
      }

      this.setState({ [stateObject]: set(newStateObject, key, value) }, () => {
        this.validate(target, stateObject, key, true);
      });
    };
  }

  handleFinish() {
    const submitObject = { ...this.state.userSignup };
    if (this.state.originalPhone === this.state.userSignup.phone) {
      delete submitObject.phone;
    }
    if (this.state.originalEmail === this.state.userSignup.email) {
      delete submitObject.email;
    }
    this.props.actions.submitUserSignup(submitObject, this.props.location.query.userId);
  }

  errorDialog({ http_code, code, message, original }) {
    return (
      <PopupDialog
        title="Registration Problem"
        actions={[{ label: 'Try Again', onClick: this.resetError, primary: true }]}
        actAsExpander={!!original}
        showExpandableButton={!!original}
        stackTrace={original && original.stack}
      >
        {http_code === 401 && code === 104
          ? 'There doesn’t seem to be an account set up with that email.'
          : message}
      </PopupDialog>
    );
  }

  successDialog() {
    const title = (
      <div>
        <div className="rocket-emoji" />
        Great job - Welcome to Everdays!
      </div>
    );
    return (
      <PopupDialog
        title={title}
        actions={[
          {
            label: 'Lets Go!',
            primary: true,
            onClick: () => browserHistory.push('/dash/a/announcements')
          }
        ]}
      >
        You are now setup and ready to start truly providing Everdays for your client families.
        Please contact us at any time with questions.
      </PopupDialog>
    );
  }

  phoneConfirmationDialog(phone) {
    return (
      <PopupDialog
        title="User Upgrade"
        actions={[
          {
            label: 'OK',
            onClick: () => this.setState({ dupePhoneError: undefined }),
            primary: true
          }
        ]}
      >
        The phone number <strong>{phone}</strong> currently belongs to a user that has the Everdays
        app. If this phone number belongs to you, we will upgrade you to an Everdays Dashboard user.
      </PopupDialog>
    );
  }

  cropProfileImage(img) {
    const croppedProfile = { ...this.state.userSignup, image: img };
    this.setState({ userSignup: croppedProfile });
  }

  renderContent() {
    const { finished, stepIndex } = this.state;

    if (finished) {
      return (
        <div className="stepper-inner-container">
          <p>
            <a
              href="#"
              onClick={event => {
                event.preventDefault();
                this.setState({ stepIndex: 0, finished: false });
              }}
            >
              Click here
            </a>{' '}
            to reset.
          </p>
        </div>
      );
    }

    return <div className="stepper-inner-container">{this.getStepContent(stepIndex)}</div>;
  }

  render() {
    const { isFetching, error, saveSuccess } = this.props.dash.userSignup;
    if (!this.state.started) {
      const query = this.props.location.query;
      return (
        <RegistrationStartUser
          firstName={query['name.first']}
          companyName={query.company}
          inviterName={query['inviter.first']}
          startRegistration={this.startRegistration}
        />
      );
    } else {
      const { stepIndex, dupePhoneError } = this.state;
      const mobileSize = this.props.dash.recordPageResize.size <= 938;

      return (
        <div className="stepper-container">
          <div className="stepper-top-content" open={true}>
            {this.renderContent()}
          </div>
          <div className="stepper-line-and-buttons">
            <FlatButton
              className="stepper-progress-button"
              label={mobileSize ? <IoChevronLeft /> : 'Back'}
              disabled={stepIndex === 0}
              onClick={() => {
                this.handlePrev();
                googleAnalytics('user-registration-stepper-back');
              }}
              style={{ marginRight: 12 }}
            />
            <div className="stepper-step-line shorter-stepper">
              {mobileSize ? (
                <Stepper linear={false} className="mobile-stepper">
                  <Step className="mobile-step">
                    <StepButton
                      onClick={() => (stepIndex === 1 ? this.handleFinish() : this.handleNext())}
                      className="mobile-step-button"
                    >
                      <div className="mobile-stepper-label">
                        <p className="next-up">Next up:</p>
                        <p className="next-stepper-title">Finish</p>
                      </div>
                      <IoChevronRight className="next-chevron" />
                    </StepButton>
                  </Step>
                </Stepper>
              ) : (
                <Stepper activeStep={stepIndex}>
                  <Step>
                    <StepLabel>Create Your Profile</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Finish</StepLabel>
                  </Step>
                </Stepper>
              )}
            </div>
            {!mobileSize && (
              <RaisedButton
                className="stepper-progress-button"
                label={stepIndex === 1 ? 'Finish' : 'Next'}
                labelStyle={{ textTransform: 'none' }}
                primary={true}
                labelPosition="before"
                icon={<IoChevronRight className="next-chevron" />}
                onClick={() => {
                  stepIndex === 1 ? this.handleFinish() : this.handleNext();
                  googleAnalytics('user-registration-stepper-next');
                }}
              />
            )}
          </div>
          {isFetching && <LoaderComponent active={isFetching} />}
          {error && error.message && this.errorDialog(error)}
          {dupePhoneError && this.phoneConfirmationDialog(dupePhoneError)}
          {saveSuccess ? this.successDialog() : ''}
        </div>
      );
    }
  }
}

const mapStateToProps = ({ dash }) => ({ dash });

const mapDispatchToProps = dispatch => {
  const actions = { submitUserSignup, resetError };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationUser);
