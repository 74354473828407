import React from 'react';
import IoLocation from 'react-icons/lib/io/location';
import IoAndroidPerson from 'react-icons/lib/io/android-person';

import DashPage from '@evdy/web-core/dist/components/shared/elements/DashPage';
import ReviewSection from '@evdy/web-core/dist/components/shared/elements/dashCompanyRegistration/ReviewSection';

export default class ReviewSubmit extends React.Component {
  render() {
    const { userInfo, companyInfo, users, funeralHomes, jumpToStep } = this.props;
    const compAddress = companyInfo.address;

    const locationArray = funeralHomes
      .filter(home => !!home.name)
      .map((location, idx) => (
        <div key={idx}>
          <p className="review-body-emphasis">{location.name}</p>
          <p>
            {location.address.city && location.address.city}
            {location.address.state && `, ${location.address.state}`}
          </p>
        </div>
      ));

    const userArray = users
      .filter(user => !!user.name.first)
      .map((user, idx) => (
        <div key={idx}>
          <p className="review-body-emphasis">
            {user.name.first} {user.name.last}
          </p>
          <p>{user.isCompanyAdmin ? 'Admin' : 'User'}</p>
        </div>
      ));

    return (
      <DashPage
        customClass="stepper-page"
        headerText="Review and Submit"
        subHeaderText={
          <p>
            If you need to make changes, you can come back and edit any section from this screen.
          </p>
        }
      >
        <div className="review-submit-page">
          <ReviewSection
            title="Your Profile"
            type="standard"
            bodyEmphasis={`${userInfo.name.first} ${userInfo.name.last}`}
            body={
              <div>
                <p>Admin</p>
                {userInfo.title && <p>{userInfo.title}</p>}
                {userInfo.email && <p>{userInfo.email}</p>}
                {userInfo.phone && <p>{userInfo.phone}</p>}
              </div>
            }
            image={userInfo.image}
            editFunct={jumpToStep(0)}
          />
          <ReviewSection
            title="Account Profile"
            type="standard"
            bodyEmphasis={companyInfo.name}
            body={
              <div>
                {compAddress.street1 && <p>{compAddress.street1}</p>}
                {compAddress.street2 && <p>{compAddress.street2}</p>}
                {(compAddress.city || compAddress.state || compAddress.zip) && (
                  <p>{`${compAddress.city}, ${compAddress.state} ${compAddress.zip}`}</p>
                )}
                {companyInfo.phone && <p>{companyInfo.phone}</p>}
              </div>
            }
            image={companyInfo.logo}
            editFunct={jumpToStep(1)}
          />
          <ReviewSection
            title="Account Locations"
            type="repeater"
            repeaterArray={locationArray}
            repeaterIcon={<IoLocation />}
            editFunct={jumpToStep(2)}
          />
          <ReviewSection
            title="Account Authorized Users"
            type="repeater"
            repeaterArray={userArray}
            repeaterIcon={<IoAndroidPerson />}
            editFunct={jumpToStep(3)}
          />
        </div>
      </DashPage>
    );
  }
}
