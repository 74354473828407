import React from 'react';
import startCase from 'lodash-es/startCase';

import { nameString } from '@evdy/web-core/dist/lib/utils';

import adminIcon from './images/admin-avatar-@2x.png';

import './AdminRow.scss';

const AdminRow = ({ image, name, title, isCompanyAdmin, handleEdit }) => {
  const adminName = nameString(name);

  return (
    <div className="admin-row">
      <div className="admin-info-container">
        <div className="admin-img" style={{ backgroundImage: `url('${image || adminIcon}')` }} />
        <div className="admin-details">
          <span className="admin-name">{adminName}</span>
          <span className="admin-title">
            {startCase(title) || '---'} - <span className="admin-tag">Admin</span>
          </span>
        </div>
      </div>
      <button className="admin-edit-button evdy-button" onClick={handleEdit}>
        Edit
      </button>
    </div>
  );
};

export default AdminRow;
