"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editResource = exports.editResourceFailure = exports.editResourceSuccess = exports.editResourceRequest = exports.addResource = exports.addResourceFailure = exports.addResourceSuccess = exports.addResourceRequest = exports.fetchResources = exports.fetchResourcesFailure = exports.fetchResourcesSuccess = exports.fetchResourcesRequest = void 0;

var types = _interopRequireWildcard(require("../../constants/dash/resources"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var fetchResourcesRequest = function fetchResourcesRequest() {
  return {
    type: types.FETCH_RESOURCES_REQUEST
  };
};

exports.fetchResourcesRequest = fetchResourcesRequest;

var fetchResourcesSuccess = function fetchResourcesSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_RESOURCES_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchResourcesSuccess = fetchResourcesSuccess;

var fetchResourcesFailure = function fetchResourcesFailure(error) {
  return {
    type: types.FETCH_RESOURCES_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fetchResourcesFailure = fetchResourcesFailure;

var fetchResources = function fetchResources() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch) {
    dispatch(fetchResourcesRequest());
    return instance.get('/getResources', {
      params: params
    }).then(function (res) {
      return dispatch(fetchResourcesSuccess(res.data));
    }, function (error) {
      return dispatch(fetchResourcesFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.fetchResources = fetchResources;

var addResourceRequest = function addResourceRequest() {
  return {
    type: types.ADD_RESOURCE_REQUEST
  };
};

exports.addResourceRequest = addResourceRequest;

var addResourceSuccess = function addResourceSuccess(_ref2) {
  var data = _ref2.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.ADD_RESOURCE_SUCCESS
  };
};

exports.addResourceSuccess = addResourceSuccess;

var addResourceFailure = function addResourceFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.ADD_RESOURCE_FAILURE
  };
};

exports.addResourceFailure = addResourceFailure;

var addResource = function addResource(data) {
  return function (dispatch) {
    dispatch(addResourceRequest());
    return instance.post('/getResources', data).then(function (res) {
      return dispatch(addResourceSuccess(res.data));
    }, function (error) {
      return dispatch(addResourceFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.addResource = addResource;

var editResourceRequest = function editResourceRequest() {
  return {
    type: types.EDIT_RESOURCE_REQUEST
  };
};

exports.editResourceRequest = editResourceRequest;

var editResourceSuccess = function editResourceSuccess(_ref3) {
  var data = _ref3.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.EDIT_RESOURCE_SUCCESS
  };
};

exports.editResourceSuccess = editResourceSuccess;

var editResourceFailure = function editResourceFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.EDIT_RESOURCE_FAILURE
  };
};

exports.editResourceFailure = editResourceFailure;

var editResource = function editResource(data) {
  return function (dispatch) {
    dispatch(editResourceRequest());
    return instance.put("/getResources/".concat(data._id), data).then(function (res) {
      return dispatch(editResourceSuccess(res.data));
    }, function (error) {
      return dispatch(editResourceFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.editResource = editResource;