import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import {
  Form,
  InputGroup,
  isEmail,
  isRequired,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';
import PasswordInput from '@evdy/web-core/dist/components/shared/PasswordInput';

import { resetCheckPhone } from '@evdy/web-redux/dist/actions/dash/onboarding/checkPhone';

import './AccountExists.scss';

const AccountExists = props => {
  const { dispatch, userError } = props;

  useEffect(() => {
    // if a user reaches this step, reset the checkPhone flag so that they may
    // go through the check phone process again if needed after logging into their user
    dispatch(resetCheckPhone());
    dispatch(formActions.change('dash.onboarding.forms.verifyPhone.code', ''));
  }, [dispatch]);

  return (
    <div className="onboard-account-exists">
      <Form model="dash.onboarding.forms.accountExists" className="account-exists-form">
        <div className="onboard-form-row">
          <InputGroup
            required
            type="email"
            labelText="Email Address"
            model=".email"
            validators={{ isRequired, isEmail }}
            messages={{
              isRequired: 'Email is required',
              isEmail: 'Please enter a valid email address'
            }}
          />
          <PasswordInput model=".password" />
        </div>
        {userError?.message && (
          <p className="login-error-message">Password does not match an Everdays account.</p>
        )}
        <button
          type="button"
          className="forgot-password-button"
          onClick={() => browserHistory.push('/dash/request-password')}
        >
          Forgot Password?
        </button>
      </Form>
    </div>
  );
};

export default connect(({ dash }) => ({
  accountExists: dash.onboarding.forms.accountExists,
  userError: dash.user.error
}))(AccountExists);
