import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import InviteExampleCard from '../shared/InviteExampleCard';
import DecedentOverviewModal from '../InviteBuilder/components/modals/DecedentOverviewModal';
import ObitPreviewCard from '../ObitPreviewCard';
import DonationPreviewCards from '../DonationPreviewCards';

import './InvitePreviewGutter.scss';

const InvitePreviewGutter = ({
  //memorialId from params
  memorialId,
  //comes from dash component
  currentPage,
  decedentEditMode = false,
  showQuickActions = false,
  donationsData
}) => {
  const {
    services,
    decedentDetails,
    inviteMeta,
    serviceStatus,
    decedentFormData,
    decedentFormMetaData,
    obitFormMessage,
    detailsObituary,
    creationObituary
  } = useSelector(({ inviteDetails, inviteCreation, client }) => ({
    services: inviteDetails?.services,
    decedentDetails: inviteDetails?.decedentDetails,
    inviteMeta: inviteDetails?.inviteMeta,
    serviceStatus: inviteDetails.decedentDetails?.data?.serviceStatus,
    decedentFormData: inviteDetails.forms.decedentInfo,
    decedentFormMetaData: inviteDetails.forms.formsMeta.decedentInfo,
    obitFormMessage:
      inviteDetails.forms.obituary.message?.message || inviteCreation?.forms?.obituary?.message,
    detailsObituary: inviteDetails.obituary,
    creationObituary: inviteCreation?.obituary
  }));
  const prevLastUpdatedDecedent = useRef(null);

  const { data: decedentInfo, isFetching: isFetchingDecedent, lastUpdated: lastUpdatedDecedent } =
    decedentDetails || {};
  const { data: inviteMetaData } = inviteMeta || {};
  const { shareUrl, isArchived } = inviteMetaData || {};

  const { displayName, image, dateOfBirth, dateOfDeath } = decedentInfo || {};

  const [isDecedentModalOpen, setDecedentModalOpen] = useState(false);

  // close modal once decedent data has been updated
  useEffect(() => {
    if (prevLastUpdatedDecedent.current < lastUpdatedDecedent - 100 && !isFetchingDecedent) {
      setDecedentModalOpen(false);
    }
    prevLastUpdatedDecedent.current = lastUpdatedDecedent;
  }, [isFetchingDecedent, lastUpdatedDecedent]);

  return (
    <div className="invite-preview-gutter">
      <InviteExampleCard
        editMode={decedentEditMode}
        {...{ displayName, image, serviceStatus, services, dateOfBirth, dateOfDeath }}
        handleEditDecedent={() => setDecedentModalOpen(true)}
      />
      <div className="obituary-donation-gutter">
        <ObitPreviewCard
          {...{ memorialId, obitFormMessage, detailsObituary, creationObituary, currentPage }}
        />
        <DonationPreviewCards {...{ memorialId, donationsData, currentPage }} />
        {!!showQuickActions && (
          <div className="quick-actions">
            <h4>Quick Actions</h4>
            {!isArchived && (
              <a target="_blank" href={shareUrl} rel="noopener noreferrer">
                Visit Memorial
              </a>
            )}
            <span onClick={() => setDecedentModalOpen(true)}>Edit Basic Details</span>
            <span onClick={() => setDecedentModalOpen(true)}>Change Profile Picture</span>
          </div>
        )}
      </div>
      <DecedentOverviewModal
        {...{ decedentInfo, decedentFormData, decedentFormMetaData }}
        modalVisible={isDecedentModalOpen}
        handleCloseModal={() => setDecedentModalOpen(false)}
        model="inviteDetails.forms.decedentInfo"
      />
    </div>
  );
};

export default InvitePreviewGutter;
