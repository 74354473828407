import React from 'react';

import './AccLocPhotos.scss';
import LocationPhotosBody from '../../../../../shared/LocationModalBody/components/LocationPhotosBody';

const AccLocPhotos = ({ form, handleLocationUpdate, model }) => {
  const { location: locationForm, accountDetailsMeta } = form;
  const isValid = accountDetailsMeta?.location?.$form?.valid || false;

  return (
    <div className="dash-acc-loc-photos-container">
      <LocationPhotosBody form={locationForm} model={`${model}.location`} />
      <button className="save-btn" onClick={handleLocationUpdate} disabled={!isValid}>
        Save
      </button>
    </div>
  );
};

export default AccLocPhotos;
