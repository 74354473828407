import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchActivities, activitiesViewed } from '@evdy/web-redux/dist/lib/activity';
import moment from 'moment';
import { Link } from 'react-router';
import classNames from 'classnames';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { fetchDemoActivities } from '@evdy/web-redux/dist/lib/demoMode';

import { renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import logo from './logo.svg';
import evdySquareLogo from './everdays-square-logo.png';

import './notifications.scss';

const Notifications = props => {
  const { activities, fetchActivities, fetchDemoActivities, user, activitiesViewed } = props;
  const [activitiesLimit, setActivitiesLimit] = useState(10);
  const { isDemoModeOn = false } = user || {};
  const activityIncrement = 5;
  const lastUpdated = activities?.lastUpdated;

  //once mounted, set activites as viewed
  useEffect(() => {
    if (user.hasNewActivity) {
      activitiesViewed();
    }
  }, [activitiesViewed, user]);

  const Notification = ({ image, fullName, date, message, link }) => (
    <div className="notification">
      <div className="image-container">
        <CharacterProfileImage {...{ image }} customClasses={['square']} name={fullName} />
      </div>
      <div className="notification-body">
        <p className="name">{fullName} </p>
        <p className="date">{date}</p>
        <p className="message">{message} </p>
      </div>
      {link &&
        (isDemoModeOn ? (
          <button onClick={() => renderDemoToast({ id: 'view-activities-demo' })}>View</button>
        ) : (
          <button>
            <Link to={link}>View</Link>
          </button>
        ))}
    </div>
  );

  //update the activities on mount
  useEffect(() => {
    if (lastUpdated < Date.now() - 1e3) {
      isDemoModeOn
        ? fetchDemoActivities()
        : fetchActivities({ user, query: { limit: activitiesLimit, source: 'dash' } });
    }
  }, [isDemoModeOn, fetchDemoActivities, lastUpdated, fetchActivities, user, activitiesLimit]);

  const currentActivities = activities.data.activities || [];
  const totalActivities = activities.data.totalActivities || 0;

  const viewMoreClasses = classNames('view-more-button', {
    disabled: activitiesLimit >= totalActivities
  });

  const notificationsClasses = classNames('notifications-section');

  return (
    <div className={notificationsClasses}>
      <div className="notifications-wrapper">
        <h2 className="section-title">Director Care</h2>
        {currentActivities.map((act, index) => {
          const date = moment(act.createdAt).format('MMMM Do[,] YYYY');
          const { image, name: fullName } = act.sender;
          const memorialId = act.memorialRef._id;
          const message = act.message;
          const link = `/dash/a/announcements/${memorialId}`;
          return (
            <div index={index} key={index + fullName}>
              <Notification {...{ date, image, fullName, message, link }} />
            </div>
          );
        })}
        {currentActivities?.length < 6 && (
          <Notification
            image={evdySquareLogo}
            fullName={'Everdays'}
            message={
              "Here you'll find reminders to connect with your client families on important dates including the decedent's birthday and the anniversary of the passing. You will also see updates when your home receives a new review, Invites build large communities and team members reach new milestones."
            }
          />
        )}
        {!!totalActivities && (
          <button
            className={viewMoreClasses}
            onClick={() => setActivitiesLimit(activitiesLimit + activityIncrement)}
          >
            View More
          </button>
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ dash, internalDash }) => ({
    activities: internalDash.fetchedActivities,
    user: dash.user.data
  }),
  { fetchActivities, activitiesViewed, fetchDemoActivities }
)(Notifications);
