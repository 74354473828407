import {
  resetInviteCreationAction,
  startInviteCreationRequest,
  startInviteCreationSuccess,
  startInviteCreationFailure,
  fetchUnfinishedInviteRequest,
  fetchUnfinishedInviteSuccess,
  fetchUnfinishedInviteFailure,
  editDecedentDetailsRequest,
  editDecedentDetailsSuccess,
  editDecedentDetailsFailure,
  addAccessorRequest,
  addAccessorSuccess,
  addAccessorFailure,
  editAccessorRequest,
  editAccessorSuccess,
  editAccessorFailure,
  removeAccessorRequest,
  removeAccessorSuccess,
  removeAccessorFailure,
  resetDupeAccessorError,
  addServiceRequest,
  addServiceSuccess,
  addServiceFailure,
  editServiceRequest,
  editServiceSuccess,
  editServiceFailure,
  deleteServiceRequest,
  deleteServiceSuccess,
  deleteServiceFailure,
  getLocationsRequest,
  getLocationsFailure,
  getLocationsSuccess,
  addNoServiceOptionsRequest,
  addNoServiceOptionsFailure,
  addNoServiceOptionsSuccess,
  addObituaryRequest,
  addObituarySuccess,
  addObituaryFailure,
  editObituaryRequest,
  editObituarySuccess,
  editObituaryFailure,
  editFuneralInfoRequest,
  editFuneralInfoSuccess,
  editFuneralInfoFailure,
  editFuneralInfoReset,
  editThemeRequest,
  editThemeSuccess,
  editThemeFailure,
  finishInviteCreationRequest,
  finishInviteCreationSuccess,
  finishInviteCreationFailure,
  inviteReset,
  sendMemorialLinkRequest,
  sendMemorialLinkSuccess,
  sendMemorialLinkFailure,
  sendMemorialLinkReset,
  deleteInviteRequest,
  deleteInviteSuccess,
  deleteInviteFailure,
  selectDirectorVideoRequest,
  selectDirectorVideoSuccess,
  selectDirectorVideoFailure,
  setUploadInProgress,
  setUploadProgress,
  editDecedentPhoneRequest,
  editDecedentPhoneFailure,
  editDecedentPhoneSuccess,
  setUploadThumbnail,
  resetDirectorVideoUpload as _resetDirectorVideoUpload,
  invalidUploadDuration,
  addDonationFailure,
  addDonationSuccess,
  addDonationRequest,
  editDonationFailure,
  editDonationSuccess,
  editDonationRequest,
  deleteDonationFailure,
  deleteDonationSuccess,
  deleteDonationRequest
} from './actions';
import { API_URL } from '../../constants';

import axios from 'axios/index';
import transAxiosErr from '@evdy/web-etc/dist/lib/transformAxiosError';

import { nameString } from '@evdy/web-core/dist/lib/utils';

const instance = axios.create({
  baseURL: `${API_URL}`
});

// Reset Invite Creation

const resetInviteCreation = () => dispatch => {
  dispatch(resetInviteCreationAction);
};

// Start Invite Creation
const startInviteCreationAction = memorial => dispatch => {
  dispatch(startInviteCreationRequest());

  return instance
    .post('/v2/private/memorial', memorial)
    .then(res => dispatch(startInviteCreationSuccess(res)))
    .catch(err => dispatch(startInviteCreationFailure(transAxiosErr(err))));
};

// Fetch Unfinished Memorial
const fetchUnfinishedMemorialAction = ({ memorialId, showAllEvents }) => dispatch => {
  dispatch(fetchUnfinishedInviteRequest());

  return instance
    .get(`/v2/private/memorial/${memorialId}`, { params: { showAllEvents } })
    .then(res => dispatch(fetchUnfinishedInviteSuccess(res)))
    .catch(err => dispatch(fetchUnfinishedInviteFailure(transAxiosErr(err))));
};

// Edit Decedent Details
const editDecedentDetailsAction = ({ memorialId, decedent }) => dispatch => {
  dispatch(editDecedentDetailsRequest());

  const { name, dateOfBirth, dateOfDeath, displayName: requestedDisplayName } = decedent || {};
  const searchName = nameString(name || null);
  const isValidDisplayName = requestedDisplayName && requestedDisplayName.trim() !== '';
  const displayName = isValidDisplayName ? requestedDisplayName : nameString(name);

  const body = {
    ...decedent,
    searchName,
    displayName,
    dateOfBirth: dateOfBirth && new Date(dateOfBirth).getTime(),
    dateOfDeath: dateOfDeath && new Date(dateOfDeath).getTime()
  };

  return instance
    .put(`/v2/private/memorial/${memorialId}/hero`, body)
    .then(res => dispatch(editDecedentDetailsSuccess(res)))
    .catch(err => dispatch(editDecedentDetailsFailure(transAxiosErr(err))));
};
const editDecedentPhoneAction = ({ memorialId, decedentPhone }) => dispatch => {
  dispatch(editDecedentPhoneRequest());

  return instance
    .put(`/v2/private/memorial/${memorialId}/decedentphone`, { decedentPhone })
    .then(res => dispatch(editDecedentPhoneSuccess(res)))
    .catch(err => dispatch(editDecedentPhoneFailure(err)));
};

// Accessors
const addAccessorAction = ({ memorialId, newAccessors }) => dispatch => {
  dispatch(addAccessorRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/accessors`, { accessors: newAccessors, from: 'dash' })
    .then(res => dispatch(addAccessorSuccess(res)))
    .catch(err => dispatch(addAccessorFailure(transAxiosErr(err))));
};
const editAccessorAction = ({ memorialId, accessor }) => dispatch => {
  const { _id: accessorId } = accessor;

  dispatch(editAccessorRequest());

  return instance
    .put(`/v2/private/memorial/${memorialId}/accessors/${accessorId}`, accessor)
    .then(res => dispatch(editAccessorSuccess(res)))
    .catch(err => dispatch(editAccessorFailure(transAxiosErr(err))));
};
const removeAccessorAction = ({ memorialId, accessorId }) => dispatch => {
  dispatch(removeAccessorRequest);

  return instance.delete(`/v2/private/memorial/${memorialId}/accessors/${accessorId}`).then(
    res => dispatch(removeAccessorSuccess(res)),
    err => dispatch(removeAccessorFailure(err))
  );
};
const resetAccessorErrorAction = () => dispatch => {
  dispatch(resetDupeAccessorError);
};

// Services
const addServiceAction = ({ memorialId, service }) => dispatch => {
  dispatch(addServiceRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/service`, service)
    .then(res => dispatch(addServiceSuccess(res)))
    .catch(err => dispatch(addServiceFailure(transAxiosErr(err))));
};
const editServiceAction = ({ memorialId, service }) => dispatch => {
  const { _id: serviceId, notify } = service;
  dispatch(editServiceRequest());

  const notifyCommunity = () => (!!notify ? `/?notify=true` : '');

  return instance
    .put(`/v2/private/memorial/${memorialId}/service/${serviceId}${notifyCommunity()}`, service)
    .then(res => dispatch(editServiceSuccess(res)))
    .catch(err => dispatch(editServiceFailure(transAxiosErr(err))));
};
const getLocationsList = ({ funeralHomeId, input, coords }) => dispatch => {
  dispatch(getLocationsRequest());
  const params = { input, funeralHomeId, coords };

  return instance.get(`/v2/search/places/`, { params }).then(
    res => dispatch(getLocationsSuccess(res)),
    err => dispatch(getLocationsFailure(err))
  );
};
const deleteServiceAction = ({ memorialId, serviceId }) => dispatch => {
  dispatch(deleteServiceRequest);

  return instance.delete(`/v2/private/memorial/${memorialId}/service/${serviceId}`).then(
    res => dispatch(deleteServiceSuccess(res)),
    err => dispatch(deleteServiceFailure(err))
  );
};
const addNoServiceOptionsAction = ({ memorialId, noServices }) => dispatch => {
  dispatch(addNoServiceOptionsRequest);

  const body = { serviceStatus: noServices };
  return instance.put(`/v2/private/memorial/${memorialId}/servicestatus`, body).then(
    res => dispatch(addNoServiceOptionsSuccess(res)),
    err => dispatch(addNoServiceOptionsFailure(err))
  );
};

// Obituary
const addObituaryAction = ({ memorialId, message }) => dispatch => {
  dispatch(addObituaryRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/obituary`, { obituary: message })
    .then(res => dispatch(addObituarySuccess(res)))
    .catch(err => dispatch(addObituaryFailure(transAxiosErr(err))));
};
const editObituaryAction = ({ memorialId, message }) => dispatch => {
  dispatch(editObituaryRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/obituary`, { obituary: message })
    .then(res => dispatch(editObituarySuccess(res)))
    .catch(err => dispatch(editObituaryFailure(transAxiosErr(err))));
};

// Donation
const addDonationAction = ({ memorialId, title, description, url }) => dispatch => {
  dispatch(addDonationRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/donation`, { title, description, url })
    .then(res => dispatch(addDonationSuccess(res)))
    .catch(err => dispatch(addDonationFailure(transAxiosErr(err))));
};
const editDonationAction = ({ memorialId, donationId, title, description, url }) => dispatch => {
  dispatch(editDonationRequest());

  return instance
    .put(`/v2/private/memorial/${memorialId}/donation/${donationId}`, {
      title,
      description,
      url
    })
    .then(res => dispatch(editDonationSuccess(res)))
    .catch(err => editDonationFailure(transAxiosErr(err)));
};
const deleteDonationAction = ({ memorialId, donationId }) => dispatch => {
  dispatch(deleteDonationRequest());

  return instance
    .delete(`/v2/private/memorial/${memorialId}/donation/${donationId}`)
    .then(res => dispatch(deleteDonationSuccess(res)))
    .catch(err => dispatch(deleteDonationFailure(transAxiosErr(err))));
};

// Funeral Info
const editFuneralInfoAction = ({
  memorialId,
  funeralHomeId,
  funeralDirectorId,
  funeralDirectorCreator
}) => dispatch => {
  dispatch(editFuneralInfoRequest());

  const data = {
    funeralHome: funeralHomeId,
    funeralDirectorId,
    ...(funeralDirectorCreator && { funeralDirectorCreator })
  };

  return instance
    .put(`/v2/private/memorial/${memorialId}/funeralinfo`, data)
    .then(res => dispatch(editFuneralInfoSuccess(res.data)))
    .catch(err => dispatch(editFuneralInfoFailure(transAxiosErr(err))));
};

const editFuneralInfoResetAction = () => dispatch => dispatch(editFuneralInfoReset());

// Theme
const editThemeAction = ({ memorialId, themeIdx }) => dispatch => {
  dispatch(editThemeRequest());

  return instance
    .put(`/v2/private/memorial/${memorialId}/theme`, { themeIdx })
    .then(res => dispatch(editThemeSuccess(res)))
    .catch(err => dispatch(editThemeFailure(transAxiosErr(err))));
};

// Finish/Finalize Creation
const finishCreationAction = ({ memorialId, customSMS }) => dispatch => {
  dispatch(finishInviteCreationRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/finalize`)
    .then(res => dispatch(finishInviteCreationSuccess(res)))
    .catch(err => dispatch(finishInviteCreationFailure(transAxiosErr(err))));
};

const inviteResetAction = () => dispatch => dispatch(inviteReset()); // TODO - doesn't appear to be used - Marc 4.21.20

// Send Memorial Link SMS
const sendMemorialLinkAction = ({ memorialId }) => dispatch => {
  dispatch(sendMemorialLinkRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/sendmemorialsmslink`)
    .then(res => dispatch(sendMemorialLinkSuccess(res)))
    .catch(err => dispatch(sendMemorialLinkFailure(transAxiosErr(err))));
};

const sendMemorialLinkResetAction = () => dispatch => dispatch(sendMemorialLinkReset());

// Delete Invite
const deleteInviteAction = ({ memorialId }) => dispatch => {
  dispatch(deleteInviteRequest());

  return instance
    .delete(`/v2/private/memorial/${memorialId}`)
    .then(res => dispatch(deleteInviteSuccess(res)))
    .catch(err => dispatch(deleteInviteFailure(transAxiosErr(err))));
};

//selectDirectorVideo
const selectDirectorVideo = ({ memorialId, directorVideoUrl, idx }) => dispatch => {
  dispatch(selectDirectorVideoRequest());

  return instance
    .post(`/v2/private/memorial/${memorialId}/directorvideo`, { directorVideoUrl, idx })
    .then(res => dispatch(selectDirectorVideoSuccess(res)))
    .catch(err => dispatch(selectDirectorVideoFailure(err)));
};

let streamProps = { handler: undefined, dispatch: undefined, file: undefined };

const handleUploadNextSlice = ({ percent, place, sliceAmt }) => {
  const reader = new FileReader();
  streamProps.dispatch(setUploadProgress(percent));
  const uploadPosition = place * sliceAmt;
  const newSlice = streamProps.file.slice(
    uploadPosition,
    uploadPosition + Math.min(sliceAmt, streamProps.file.size - uploadPosition)
  );
  reader.readAsBinaryString(newSlice);
  reader.onload = ({ target }) => {
    streamProps.handler.emit('event://upload-new-video-slice', {
      name: streamProps.file.name,
      data: target.result
    });
  };
};

const handleFinishUpload = ({ memorial, thumbnailUrl }) => {
  streamProps.handler.emit('uploadDisconnect');

  streamProps.dispatch(setUploadInProgress(false));
  streamProps.dispatch(setUploadProgress(null));
  streamProps.dispatch(setUploadThumbnail(thumbnailUrl));
  streamProps.dispatch(selectDirectorVideoSuccess({ data: { data: memorial } }));

  streamProps.handler.removeEventListener(
    'event://ready-for-next-video-slice',
    handleUploadNextSlice
  );
  streamProps.handler.removeEventListener('event://finished-video-upload', handleFinishUpload);
  streamProps = { handler: undefined, dispatch: undefined, file: undefined };
};

const getVideoDuration = file => {
  const fileURL = URL.createObjectURL(file);
  const video = document.createElement('video');
  video.setAttribute('src', fileURL);

  return new Promise(res => {
    video.onloadedmetadata = () => res(video.duration);
  });
};

const startUploadDirectorVideo = ({ handler, file, memorialId }) => async dispatch => {
  const duration = await getVideoDuration(file);

  if (duration > 10 * 60) {
    const durationInMin = (duration / 60).toFixed(1);
    dispatch(invalidUploadDuration(`Selected video is too long: ${durationInMin}min > 10min`));
  } else if (duration < 1) {
    dispatch(invalidUploadDuration('Selected video is less an one sec long'));
  } else {
    if (handler) {
      dispatch(setUploadInProgress(true));
      dispatch(selectDirectorVideoRequest());
      handler.emit('event://start-video-upload', {
        name: file.name,
        size: file.size,
        memorialId
      });
      handler.on('event://ready-for-next-video-slice', handleUploadNextSlice);
      handler.on('event://finished-video-upload', handleFinishUpload);
      streamProps = { dispatch, handler, file };
    }
  }
};

const resetDirectorVideoUpload = () => dispatch => {
  dispatch(_resetDirectorVideoUpload());
};

export {
  resetInviteCreation,
  startInviteCreationAction,
  fetchUnfinishedMemorialAction,
  editDecedentDetailsAction,
  addAccessorAction,
  editAccessorAction,
  removeAccessorAction,
  resetAccessorErrorAction,
  addServiceAction,
  editServiceAction,
  deleteServiceAction,
  getLocationsList,
  addNoServiceOptionsAction,
  addObituaryAction,
  editObituaryAction,
  editFuneralInfoAction,
  editFuneralInfoResetAction,
  editThemeAction,
  finishCreationAction,
  inviteResetAction,
  sendMemorialLinkAction,
  sendMemorialLinkResetAction,
  deleteInviteAction,
  selectDirectorVideo,
  startUploadDirectorVideo,
  editDecedentPhoneAction,
  resetDirectorVideoUpload,
  addDonationAction,
  editDonationAction,
  deleteDonationAction
};
