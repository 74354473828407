import React from 'react';

import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import IoEye from 'react-icons/lib/io/eye';
import IoEyeDisabled from 'react-icons/lib/io/eye-disabled';

import DashPage from '@evdy/web-core/dist/components/shared/elements/DashPage';
import MuiUploader from '@evdy/web-core/dist/components/shared/elements/imageUpload/MuiUploader';

import { normalizeSelectEvent } from '@evdy/web-etc/dist/lib/eventNormalization';

const hidePasswordStyle = { width: 96 };
const showPasswordStyle = { width: 104 };

export default class SetupPasswordAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: {}, passVisible: false };
    this.togglePassVisible = this.togglePassVisible.bind(this);
    this.getErrorText = key => props.getErrorText('userSignup', key);
  }

  togglePassVisible() {
    this.setState({ passVisible: !this.state.passVisible });
  }

  render() {
    const {
      getInputClass,
      handleInputChange,
      handleEmailChange,
      handlePhoneChange,
      getValue,
      isUserSignup
    } = this.props;
    const { passVisible } = this.state;

    return (
      <DashPage
        customClass="stepper-page"
        headerText={`Complete Your ${isUserSignup ? 'User Profile' : 'Admin Account'}`}
        subHeaderText={
          isUserSignup ? (
            <p>
              Look over your user profile and make changes you’d like. This information is always
              editable.
            </p>
          ) : (
            <p>
              Look over the profile you've already started and create a password. As the admin for
              your account, you can set up additional admins, add users, and edit account and
              location information. This information is always editable.
            </p>
          )
        }
      >
        <form className="admin-profile-form standard-dash-form">
          <p className="section-title">Set up your Password</p>
          <div className="form-two-fields">
            <div className={getInputClass('userSignup.password')}>
              <TextField
                floatingLabelText="Password*"
                id="password"
                required
                type={passVisible ? 'text' : 'password'}
                hintText=""
                className="form-input"
                errorText={this.getErrorText('password')}
                onChange={handleInputChange('password', 'userSignup')}
                value={getValue('password', 'userSignup')}
              />
              {passVisible && (
                <IconButton
                  onClick={this.togglePassVisible}
                  tooltip="Hide Password"
                  tooltipPosition="bottom-center"
                  tooltipStyles={hidePasswordStyle}
                  className="view-password-toggle"
                  tabIndex={-1}
                >
                  <IoEyeDisabled size={19} />
                </IconButton>
              )}
              {!passVisible && (
                <IconButton
                  onClick={this.togglePassVisible}
                  tooltip="Show Password"
                  tooltipPosition="bottom-center"
                  tooltipStyles={showPasswordStyle}
                  className="view-password-toggle"
                  tabIndex={-1}
                >
                  <IoEye size={19} />
                </IconButton>
              )}
            </div>
            <div className={getInputClass('userSignup.passwordConfirmation')}>
              <TextField
                floatingLabelText="Password Confirmation*"
                id="passwordConfirmation"
                required
                type={passVisible ? 'text' : 'password'}
                hintText=""
                className="form-input"
                errorText={this.getErrorText('passwordConfirmation')}
                onChange={handleInputChange('passwordConfirmation', 'userSignup')}
                value={getValue('passwordConfirmation', 'userSignup')}
              />
              {passVisible && (
                <IconButton
                  onClick={this.togglePassVisible}
                  tooltip="Hide Password"
                  tooltipPosition="bottom-center"
                  tooltipStyles={hidePasswordStyle}
                  className="view-password-toggle"
                  tabIndex={-1}
                >
                  <IoEyeDisabled size={19} />
                </IconButton>
              )}
              {!passVisible && (
                <IconButton
                  onClick={this.togglePassVisible}
                  tooltip="Show Password"
                  tooltipPosition="bottom-center"
                  tooltipStyles={showPasswordStyle}
                  className="view-password-toggle"
                  tabIndex={-1}
                >
                  <IoEye size={19} />
                </IconButton>
              )}
            </div>
          </div>
          <p className="section-title">Your Profile</p>
          <MuiUploader
            label="Profile Photo"
            image={getValue('image', 'userSignup')}
            handleUpload={handleInputChange('image', 'userSignup')}
            gaKey="company-registration-configureadmin-addprofile"
            setCroppedImage={this.props.setCroppedImage}
          />
          <div className="form-two-fields">
            <div className={getInputClass('userSignup.name.first')}>
              <TextField
                floatingLabelText="First Name*"
                id="name.first"
                required
                type="text"
                hintText="John"
                className="form-input"
                errorText={this.getErrorText('name[first]')}
                onChange={handleInputChange('name.first', 'userSignup')}
                value={getValue('name.first', 'userSignup')}
              />
            </div>
            <div className={getInputClass('userSignup.name.last')}>
              <TextField
                floatingLabelText="Last Name*"
                id="name.last"
                required
                type="text"
                hintText="Smith"
                className="form-input"
                errorText={this.getErrorText('name.last')}
                onChange={handleInputChange('name.last', 'userSignup')}
                value={getValue('name.last', 'userSignup')}
              />
            </div>
          </div>
          <div className="form-single-field">
            <div className={getInputClass('userSignup.title')}>
              <SelectField
                id="title"
                className="form-input"
                maxHeight={300}
                hintText="e.g. Funeral Director, Owner"
                floatingLabelFixed
                floatingLabelText="Your Role"
                labelStyle={{ paddingLeft: 'none' }}
                selectedMenuItemStyle={{ color: '#3084FF' }}
                onChange={normalizeSelectEvent('title', handleInputChange('title', 'userSignup'))}
                value={getValue('title', 'userSignup')}
              >
                <MenuItem value="owner" primaryText="Owner" />
                <MenuItem value="president" primaryText="President" />
                <MenuItem value="CEO" primaryText="CEO" />
                <MenuItem value="funeralDirector" primaryText="Funeral Director" />
                <MenuItem value="funeralArranger" primaryText="Funeral Arranger" />
                <MenuItem value="manager" primaryText="Manager" />
                <MenuItem value="embalmer" primaryText="Embalmer" />
                <MenuItem value="officeAdministrator" primaryText="Office Administrator" />
                <MenuItem value="assistant" primaryText="Assistant/Support" />
                <MenuItem value="celebrant" primaryText="Celebrant" />
                <MenuItem value="familyServicesCounselor" primaryText="Family Services Counselor" />
                <MenuItem value="preneedCounselor" primaryText="Preneed Counselor" />
                <MenuItem value="apprentice" primaryText="Apprentice" />
                <MenuItem value="other" primaryText="Other" />
              </SelectField>
            </div>
          </div>
          <div className="form-single-field">
            <div className={getInputClass('userSignup.email')}>
              <TextField
                floatingLabelText="Email Address*"
                id="email"
                required
                type="email"
                hintText=""
                className="form-input"
                errorText={this.getErrorText('email')}
                onChange={handleEmailChange('email', 'userSignup')}
                value={getValue('email', 'userSignup')}
              />
            </div>
          </div>
          <div className="form-single-field">
            <div className={getInputClass('userSignup.phone')}>
              <TextField
                floatingLabelText="Cell Phone Number*"
                id="phone"
                required
                type="text"
                hintText=""
                className="form-input"
                errorText={this.getErrorText('phone')}
                onChange={handlePhoneChange('phone', 'userSignup', null, true)}
                value={getValue('phone', 'userSignup')}
              />
            </div>
          </div>
        </form>
      </DashPage>
    );
  }
}
