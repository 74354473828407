import DecedentInfo from './components/DecedentInfo';
import Accessors from './components/Accessors';
import InvitePreview from './components/InvitePreview';
import EventsPage from './components/Events';

export const inviteBuilderSteps = [
  {
    name: 'decedent_info',
    Component: DecedentInfo,
    nextBtnText: 'Next, add events'
  },
  {
    name: 'events',
    Component: EventsPage,
    nextBtnText: 'Next, add family'
  },
  {
    name: 'accessors',
    Component: Accessors,
    nextBtnText: 'Next, finalize'
  },
  {
    name: 'invite_preview',
    Component: InvitePreview
  }
];

export const inviteBuilderStepOrder = inviteBuilderSteps.map(step => step.name);
