import React from 'react';

const SVG = ({ isActive }) => {
  return (
    <svg width="188" height="50" viewBox="0 0 188 50" xmlns="http://www.w3.org/2000/svg">
      <g id="CRM" fill="none" fillRule="evenodd">
        <g
          id="CRM/Details/Activity2"
          transform="translate(-741 -405)"
          fill={isActive ? '#0072cd' : '#F7F7F7'}
          fillRule="nonzero"
          stroke={isActive ? '#0072cd' : '#B3B3B3'}
          strokeWidth="2"
        >
          <g id="Right-Side" transform="translate(554 186)">
            <g id="Progress" transform="translate(0 219)">
              <g id="Arrow-2" transform="translate(187)">
                <path
                  d="M168.774502,49 L186.750597,25 L168.774502,1 L1.99840663,1 L19.9745022,25 L1.99840663,49 L168.774502,49 Z"
                  id="arrow-inactive"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVG;
