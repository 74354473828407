import { useReducer } from 'react';

import locationShowAllActive from '../Announcements/assets/location-showall_active.svg';
import locationShowAllInactive from '../Announcements/assets/location-showall_inactive.svg';
import clearStageActive from '../Announcements/assets/clear-time-period_active.svg';
import clearStageInactive from '../Announcements/assets/clear-time-period_inactive.svg';
// import stageNewActive from './images/head-circle-active.svg';
import stageNewActive from './images/person-star.svg';
import stageNewInactive from './images/person-star-inactive.svg';
import stageSetMeetingActive from './images/calendar-clock.svg';
import stageSetMeetingInactive from './images/calendar-clock-inactive.svg';
import stageHeldMeetingActive from './images/calendar-check.svg';
import stageHeldMeetingInactive from './images/calendar-check-inactive.svg';
import prequalActive from './images/prequal.svg';
import prequalInactive from './images/prequal-inactive.svg';
import stageClosedActive from '../Announcements/assets/completed_active.svg';
import stageClosedInactive from '../Announcements/assets/completed_inactive.svg';

const filterImages = {
  CLEAR_LOCATION: {
    active: locationShowAllActive,
    inactive: locationShowAllInactive
  },
  CLEAR_STAGE: {
    active: clearStageActive,
    inactive: clearStageInactive
  },
  STAGE_NEW: {
    active: stageNewActive,
    inactive: stageNewInactive
  },
  STAGE_SET_MEETING: {
    active: stageSetMeetingActive,
    inactive: stageSetMeetingInactive
  },
  STAGE_HELD_MEETING: {
    active: stageHeldMeetingActive,
    inactive: stageHeldMeetingInactive
  },
  STAGE_CLOSED: {
    active: stageClosedActive,
    inactive: stageClosedInactive
  },
  PREQUALIFIED: {
    active: prequalActive,
    inactive: prequalInactive
  }
};

const stageNameMap = {
  CLEAR_STAGE: '',
  STAGE_NEW: 'new',
  STAGE_SET_MEETING: 'meeting-set',
  STAGE_HELD_MEETING: 'meeting-held',
  STAGE_CLOSED: 'closed'
};

const filterType = {
  CLEAR_STAGE: 'CLEAR_STAGE',
  STAGE_NEW: 'STAGE_NEW',
  STAGE_SET_MEETING: 'STAGE_SET_MEETING',
  STAGE_HELD_MEETING: 'STAGE_HELD_MEETING',
  STAGE_CLOSED: 'STAGE_CLOSED',
  DIRECTOR: 'DIRECTOR',
  LOCATION: 'LOCATION',
  LOCATION_SHOWALL: 'LOCATION_SHOWALL',
  SEARCH: 'SEARCH',
  SORT: 'SORT',
  PAGE: 'PAGE',
  CLEAR_LOCATION: 'CLEAR_LOCATION',
  RESET: 'RESET',
  PREQUALIFIED: 'PREQUALIFIED',
  AFP: 'AFP',
  CLEAR_AFP: 'CLEAR_AFP'
};

const initFilters = {
  searchQuery: '',
  locationFilter: '',
  locationFilterName: '',
  locationFilterShowAll: false,
  selectedFilterLocations: `${filterType.CLEAR_LOCATION}-`,
  selectedFilterLocationsName: '',
  selectedFilterStage: `${filterType.CLEAR_STAGE}-`,
  selectedFilterStageName: '',
  selectedStage: '',
  selectedStageName: 'All',
  sort: 'dueDate',
  page: 0,
  sortName: '',
  qualified: true,
  afp: '',
  afpName: 'All Agents',
  selectedAfp: `${filterType.CLEAR_AFP}-`,
  selectedAfpName: ''
};

const resetOverlapingLocations = {
  locationFilter: '',
  locationFilterName: '',
  selectedFilterLocations: `${filterType.CLEAR_LOCATION}-`,
  selectedFilterLocationsName: ''
};

const filterReducer = (state, { type, value, id, name }) => {
  switch (type) {
    case filterType.PREQUALIFIED:
      return {
        ...state,
        qualified: !state.qualified
      };
    case filterType.RESET:
      return {
        ...initFilters
      };

    case filterType.PAGE:
      return {
        ...state,
        page: value
      };

    case filterType.SEARCH:
      return {
        ...state,
        searchQuery: value
      };

    case filterType.CLEAR_LOCATION:
      return {
        ...state,
        ...resetOverlapingLocations
      };

    case filterType.LOCATION:
      return {
        ...state,
        ...resetOverlapingLocations,
        locationFilter: id,
        locationFilterName: value,
        selectedFilterLocations: `${type}-${id}`,
        selectedFilterLocationsName: value,
        page: 0
      };

    case filterType.LOCATION_SHOWALL:
      return {
        ...state,
        locationFilterShowAll: !state.locationFilterShowAll
      };

    case filterType.CLEAR_STAGE:
      return {
        ...state,
        selectedFilterStage: `${type}-`,
        selectedFilterStageName: '',
        selectedStage: '',
        selectedStageName: 'All',
        page: 0
      };

    case filterType.STAGE_NEW:
    case filterType.STAGE_SET_MEETING:
    case filterType.STAGE_HELD_MEETING:
    case filterType.STAGE_CLOSED:
      return {
        ...state,
        selectedFilterStage: `${type}-`,
        selectedFilterStageName: value,
        selectedStage: stageNameMap[type],
        selectedStageName: value,
        page: 0
      };

    case filterType.SORT:
      let sortName;
      switch (value) {
        case 'createdAt':
          sortName = 'Due Date';
          break;
        case 'searchName':
          sortName = 'A-Z';
          break;
        case '-searchName':
          sortName = 'Z-A';
          break;
        default:
          sortName = '';
      }
      return {
        ...state,
        sort: value,
        sortName
      };
    case filterType.AFP:
      return {
        ...state,
        afp: id,
        afpName: value,
        selectedAfp: `${type}-${id}`,
        selectedAfpName: value
      };

    case filterType.CLEAR_AFP:
      return {
        ...state,
        afp: '',
        afpName: '',
        selectedAfp: `${type}-`,
        selectedAfpName: ''
      };

    default:
  }
};

const useLeadFilter = () => {
  const [leadFilter, setLeadFilter] = useReducer(filterReducer, initFilters);
  return [leadFilter, setLeadFilter, filterType, filterImages];
};

export default useLeadFilter;
