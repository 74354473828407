import React from 'react';
import classNames from 'classnames';

import chev from '../assets/chevron-right-black.svg';

import './renderPagination.scss';

const renderPagination = props => {
  const {
    currentPage,
    startsAtZero,
    handleArrowClick,
    content,
    next,
    previous,
    total,
    pageStep
  } = props;
  const paginationClasses = type =>
    classNames({
      'arrow-container': true,
      disable: (type === 'prev' && !previous) || (type === 'next' && !next)
    });
  return (
    <div className="pagination">
      <span className="numbers-container">
        {renderPaginationNumbers({ content, startsAtZero, total, currentPage, pageStep })}
      </span>
      <span className={paginationClasses('prev')} onClick={handleArrowClick('prev')}>
        <img className="arrow-left" alt="previous page" src={chev} />
      </span>
      <span className={paginationClasses('next')} onClick={handleArrowClick('next')}>
        <img className="arrow-right" alt="next page" src={chev} />
      </span>
    </div>
  );
};

const renderPaginationNumbers = ({ content, startsAtZero, total, currentPage, pageStep }) => {
  const adjustedPageNumber = startsAtZero ? currentPage : currentPage - 1;
  const contentCount = content.length;
  const lowerBound = adjustedPageNumber * pageStep + 1;
  const upperBound = adjustedPageNumber * pageStep + contentCount;

  return (
    <div className="pagination-text">
      <span>
        {lowerBound}-{upperBound}
      </span>
      <span>{` of ${total}`}</span>
    </div>
  );
};

export default renderPagination;
