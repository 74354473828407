import React from 'react';
import classNames from 'classnames';
import { string, func, oneOf, oneOfType, element } from 'prop-types';

import notStartedOutlineIcon from '../../../../../images/not-started-outline-activity-icon.svg';
import notStartedCheckIcon from '../../../../../images/not-started-check-activity-icon.svg';
import inProgressIcon from '../../../../../images/not-finished-activity-icon.svg';
import editPhaseIcon from '../../../../../images/activity-row-finished-icon.svg';

import './ActionItemRow.scss';

const ActionItemRow = ({
  rowStatus,
  rowCopy = '',
  buttonCopy = '',
  buttonAction = () => {},
  secondaryButtonCopy = '',
  secondaryButtonAction = () => {},
  staticRowIcon = ''
}) => {
  const typeProps = {
    notStartedOutline: {
      icon: notStartedOutlineIcon
    },
    notStartedCheck: {
      icon: notStartedCheckIcon
    },
    inProgress: {
      icon: inProgressIcon
    },
    complete: {
      icon: editPhaseIcon
    }
  };

  const actionItemRowClasses = classNames('action-item-row', { hide: rowStatus === 'hidden' });
  const imgSrc = rowStatus === 'static' ? staticRowIcon : typeProps[rowStatus]?.icon;

  return (
    <div className={actionItemRowClasses}>
      <div className="row-info-container">
        {rowStatus && <img src={imgSrc} alt="row-icon-img" />}
        <p>{rowCopy}</p>
      </div>
      <div className="buttons">
        {secondaryButtonCopy && (
          <button className="secondary" onClick={secondaryButtonAction}>
            {typeof secondaryButtonCopy === 'string' ? (
              <span>{secondaryButtonCopy}</span>
            ) : (
              secondaryButtonCopy
            )}
          </button>
        )}
        {buttonCopy && (
          <button className="primary" onClick={buttonAction}>
            {typeof buttonCopy === 'string' ? <span>{buttonCopy}</span> : buttonCopy}
          </button>
        )}
      </div>
    </div>
  );
};

ActionItemRow.propTypes = {
  rowStatus: oneOf([
    'complete',
    'inProgress',
    'notStartedCheck',
    'notStartedOutline',
    'hidden',
    'static'
  ]),
  rowCopy: string,
  buttonCopy: oneOfType([string, element]),
  buttonAction: func,
  secondaryButtonCopy: oneOfType([string, element]),
  secondaryButtonAction: func,
  staticRowIcon: string
};

export default ActionItemRow;
