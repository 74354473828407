import React from 'react';
import classNames from 'classnames';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { formatAddress } from '@evdy/web-core/dist/lib/utils';

import './LocationRow.scss';

const LocationRow = ({ address, name, image, handleEdit, isSetupComplete }) => {
  const editButtonClasses = classNames('location-edit-button', {
    'setup-incomplete': !isSetupComplete
  });

  return (
    <div className="location-row">
      <div className="location-info-container">
        {!!image ? (
          <div className="location-img" style={{ backgroundImage: `url('${image}')` }} />
        ) : (
          <CharacterProfileImage {...{ image, name }} />
        )}
        <div className="location-details">
          <span className="location-name">{name}</span>
          <span className="location-address">{formatAddress(address)}</span>
        </div>
      </div>
      <button className={editButtonClasses} onClick={handleEdit}>
        Edit
      </button>
    </div>
  );
};

export default LocationRow;
