import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash-es/get';

import ActivityCard from './ActivityCard';

import { fetchActivity, loadMoreActivities } from '@evdy/web-redux/dist/lib/dash/actions/activity';

import './ActivitySection.scss';

@connect(
  ({ dash }) => ({ activity: dash.activity }),
  { fetchActivity, loadMoreActivities }
)
export default class ActivitySection extends React.Component {
  constructor(props) {
    super(props);

    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentWillMount() {
    this.props.fetchActivity();
  }

  handleLoadMore() {
    const nextUrl = get(this.props.activity, 'data.next', '');
    this.props.loadMoreActivities(nextUrl);
  }

  render() {
    const { activity } = this.props;
    const activityArray = get(activity, 'data.data', []);
    const nextUrl = get(this.props.activity, 'data.next', '');

    return (
      <div className="activity-section">
        <h2>Activity</h2>
        <div className="activity-cards">
          {activityArray.map((activity, idx) => (
            <ActivityCard {...activity} key={idx} />
          ))}
        </div>
        {nextUrl && <button onClick={this.handleLoadMore}>View More Activity</button>}
      </div>
    );
  }
}
