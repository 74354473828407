module.exports = {
  CA: {
    requiredFields: ['specialStateLicenseInfo'],
    specialStateLicenseInfo: 'California Funeral Home Number'
  },
  CT: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  FL: {
    requiredFields: ['specialStateLicenseInfo'],
    specialStateLicenseInfo: 'Florida License ID Number'
  },
  ID: {
    requiredFields: ['funeralDirectorNumber']
  },
  IN: {
    requiredFields: ['funeralDirectorNumber']
  },
  KY: {
    requiredFields: ['funeralDirectorNumber']
  },
  LA: {
    requiredFields: ['funeralDirectorNumber']
  },
  MA: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  MD: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  ME: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  MO: {
    requiredFields: ['specialStateLicenseInfo'],
    specialStateLicenseInfo: 'Missouri License Number'
  },
  MT: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  NC: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber', 'specialStateLicenseInfo'],
    specialStateLicenseInfo: 'North Carolina Preneed Sales License'
  },
  NJ: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber', 'specialStateLicenseInfo'],
    specialStateLicenseInfo: 'Funeral Home License Number'
  },
  OH: {
    requiredFields: ['funeralDirectorNumber']
  },
  PA: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  RI: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  SC: {
    requiredFields: [
      'funeralDirectorNumber',
      'insuranceLicenseNumber',
      'specialStateLicenseInfo',
      'specialStateLicenseInfo2'
    ],
    specialStateLicenseInfo: 'Funeral License Number',
    specialStateLicenseInfo2: 'Preneed License Number'
  },
  TN: {
    requiredFields: ['specialStateLicenseInfo'],
    specialStateLicenseInfo: 'Preneed Registration Number'
  },
  UT: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  VT: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  VA: {
    requiredFields: ['funeralDirectorNumber', 'insuranceLicenseNumber']
  },
  WI: {
    requiredFields: ['funeralDirectorNumber']
  }
};
