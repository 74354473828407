import React from 'react';
import classNames from 'classnames';

import './TaskOption.scss';

const TaskOption = props => {
  const { children, title, subtitle, selected, handleSelectClick } = props;

  const taskOptionClasses = classNames('task-option', {
    selected
  });

  return (
    <div className={taskOptionClasses}>
      <p className="task-option-title">{title}</p>
      <span className="task-option-subtitle">{subtitle}</span>
      <button onClick={handleSelectClick}>Select</button>
      {selected && <div>{children}</div>}
    </div>
  );
};

export default TaskOption;
