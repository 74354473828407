"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyPhoneAction = exports.verifyPhoneFailure = exports.verifyPhoneSuccess = exports.verifyPhoneRequest = void 0;

var _onboarding = require("../../../constants/dash/onboarding");

var _constants = require("../../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var verifyPhoneRequest = function verifyPhoneRequest() {
  return {
    type: _onboarding.ONBOARD_VERIFY_PHONE_REQUEST
  };
};

exports.verifyPhoneRequest = verifyPhoneRequest;

var verifyPhoneSuccess = function verifyPhoneSuccess(_ref) {
  var data = _ref.data;
  return {
    type: _onboarding.ONBOARD_VERIFY_PHONE_SUCCESS,
    receivedAt: Date.now(),
    payload: data
  };
};

exports.verifyPhoneSuccess = verifyPhoneSuccess;

var verifyPhoneFailure = function verifyPhoneFailure(error) {
  return {
    type: _onboarding.ONBOARD_VERIFY_PHONE_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.verifyPhoneFailure = verifyPhoneFailure;

var verifyPhoneAction = function verifyPhoneAction(code, phone) {
  return function (dispatch) {
    dispatch(verifyPhoneRequest());
    return _index.default.post("".concat(_constants.API_URL, "/onboard/verify-code"), {
      code: code,
      phone: phone
    }).then(function (res) {
      return dispatch(verifyPhoneSuccess(res.data));
    }).catch(function (err) {
      return dispatch(verifyPhoneFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.verifyPhoneAction = verifyPhoneAction;