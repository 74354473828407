import React from 'react';
import classNames from 'classnames';

import { Form, InputGroup, isRequired } from '@evdy/web-core/dist/components/shared/Input';

import { mapRequiredCompanyFields, mapRequiredAccountFields } from './helperFncs';

import './MissingRequiredFieldsForm.scss';

const taxClasses = ['Sole Proprietor', 'Partnership', 'Corporation'];

const MissingRequiredFieldsForm = ({
  missingFields,
  companyFormModel,
  locationFormModel,
  fromLocationDetails,
  homeHasOwnTaxInfo,
  address
}) => {
  const { company = [], funeralHome = [] } = missingFields || {};

  const compInputRowClass = classNames({
    'input-row': true,
    'from-locations-page': fromLocationDetails
  });

  const compFormClassName = classNames({
    'missing-company-required-form': true,
    'from-locations-page': fromLocationDetails
  });

  return (
    <>
      {!!company.length && (
        <Form model={companyFormModel} className={compFormClassName}>
          {fromLocationDetails && <p className="accnt-inputs-copy">On the Account</p>}
          <div className={compInputRowClass}>{mapRequiredCompanyFields(company)}</div>
          {fromLocationDetails && !!company.length && <div className="separator" />}
        </Form>
      )}
      {fromLocationDetails && !!funeralHome.length && (
        <Form model={locationFormModel} className="location-form">
          {fromLocationDetails && <p className="location-inputs-copy">On the Location</p>}
          <div className="location-form-wrapper">
            {mapRequiredAccountFields(funeralHome, address)}
          </div>
        </Form>
      )}
      {fromLocationDetails && (
        <Form model={locationFormModel} className="location-form">
          <InputGroup
            type="checkbox"
            labelText="This Location uses a different Tax ID than my Account"
            model=".homeHasOwnTaxInfo"
          />
          {homeHasOwnTaxInfo && (
            <div className="input-row">
              <InputGroup
                model=".taxID"
                labelText="Tax ID Number *"
                validators={{ isRequired }}
                messages={{ isRequired: 'Fields is required' }}
              />
              <InputGroup
                validators={{ isRequired }}
                messages={{ isRequired: 'Field is required' }}
                model=".taxClassification"
                type="select"
                labelText="Tax Classificiation *"
              >
                <option />
                {taxClasses.map(c => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </InputGroup>
            </div>
          )}
        </Form>
      )}
    </>
  );
};

export default MissingRequiredFieldsForm;
