import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import clamp from 'lodash/clamp';

import DashCard from '../../../shared/DashCard';
import Button from '@evdy/web-core/dist/components/Button';
import EditAccessorInfoModal from '../modals/EditAccessorInfoModal';

import profileFilledIcon from './greyscale-avatar-icon.svg';
import profileIcon from './avatar-greyscale-icon-60@2x.png';
import { useScrollToTopOfStepperPage } from '../CustomHook';

import { nameString, capitalize } from '@evdy/web-core/dist/lib/utils';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import './Accessors.scss';

const model = 'inviteCreation.forms.accessor';

const placeholderAccessor = [
  {
    relation: 'daughter',
    buttonCopy: 'Add children'
  },
  {
    relation: 'friend',
    buttonCopy: 'Add friend'
  },
  {
    relation: 'wife',
    buttonCopy: 'Add spouse'
  },
  {
    relation: 'brother',
    buttonCopy: 'Add sibling'
  },
  {
    relation: 'someone else',
    buttonCopy: 'Add'
  }
];

const Accessors = props => {
  const {
    merge,
    reset,
    accessors,
    accessorFormData,
    accessorFormMetaData,
    memorialId,
    premiumProgram,
    decedentFirstName
  } = props;
  const { array: family = [], lastUpdated, error = {} } = accessors;
  const [modalVisible, setModalVisible] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const prevLastUpdated = useRef(0);

  // on mount, scroll to top
  useScrollToTopOfStepperPage('edit-decedent-details');

  const headerCopy = `Sharing ${decedentFirstName}’s Digital Memorial `;
  const titleCopy = `Bring family and friends together to honor ${decedentFirstName}. The people you add here can easily invite their phone contacts via text message to participate in ${decedentFirstName}’s Digital Memorial so everyone can share condolences, memories, and support.`;

  const handleOpenModal = ({ memberIndex } = {}) => {
    const editMode = memberIndex !== null;
    setModalVisible(true);
    if (editMode) {
      const { name, phone, email, relation, primarySurvivor } = family[memberIndex];
      setMemberId(family[memberIndex]._id);
      merge(model, { name, phone, email, relation, primarySurvivor });
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setMemberId(null);
    reset(model);
  };

  // close accessor modal if data was successfully updated
  useEffect(() => {
    const didUpdate = prevLastUpdated.current < lastUpdated - 50;
    if (didUpdate && modalVisible) {
      closeModal();
    }
    prevLastUpdated.current = lastUpdated;
  }, [lastUpdated, modalVisible]);

  const MemberCard = ({ relation, name, email, memberIndex, buttonCopy = '' }) =>
    !isEmpty(name) ? (
      <DashCard raised>
        <div className="member-info">
          <img className="placeholder-profile" src={profileFilledIcon} alt="filled profile icon" />
          <div className="member-details">
            <div className="name">{nameString(name)}</div>
            <div className="title">{capitalize(relation || '')}</div>
          </div>
        </div>
        <div className="member-controls">
          {/*to put back when accessors is fully figured - maybe only show for shell users*/}
          {/* <ul className="warn-list">{!email && <li>Missing Email Address</li>}</ul> */}
          <button onClick={() => handleOpenModal({ memberIndex })}>{buttonCopy}</button>
        </div>
      </DashCard>
    ) : (
      <DashCard
        raised
        clickHandler={() => {
          handleOpenModal({ memberIndex: null });
        }}
      >
        <div className="member-info placeholder-card">
          <img className="placeholder-profile" src={profileIcon} alt="profile icon" />
          <div className="member-details">
            <div className="title">{capitalize(relation || '')}</div>
            <div className="placeholder-bar" />
          </div>
        </div>
        <div className="member-controls">
          <button onClick={() => handleOpenModal({ memberIndex: null })}>{buttonCopy}</button>
        </div>
      </DashCard>
    );

  return (
    <div className="invite-builder-family-members-wrapper">
      <div className="invite-builder-family-members">
        <h1>{headerCopy}</h1>
        <h2>{titleCopy}</h2>
        <div className="member-addition">
          <Button
            copy="Add a family member"
            style="standard-blue-solid-btn"
            action={() => handleOpenModal({ memberIndex: null })}
          />
        </div>
        {family.map(({ relation, name, email, _id }, memberIndex) => (
          <MemberCard {...{ relation, name, email, memberIndex }} key={_id} buttonCopy="Edit" />
        ))}
        {placeholderAccessor
          .slice(clamp(family.length, 0, 3))
          .map(({ relation, buttonCopy }, idx) => (
            <MemberCard {...{ relation, buttonCopy }} key={`${idx}${relation}`} />
          ))}
      </div>
      <EditAccessorInfoModal
        {...{
          accessorFormData,
          accessorFormMetaData,
          memorialId,
          model,
          modalVisible,
          closeModal,
          memberId,
          error,
          premiumProgram
        }}
      />
    </div>
  );
};

export default connect(
  ({ inviteCreation }) => ({
    noServices: inviteCreation.decedentDetails.data?.noServices,
    accessors: inviteCreation.accessors,
    accessorFormData: inviteCreation?.forms?.accessor,
    accessorFormMetaData: inviteCreation.forms.formsMeta.accessor,
    memorialId: inviteCreation?.decedentDetails?.data?._id,
    decedentFirstName: inviteCreation?.decedentDetails?.data?.name?.first,
    premiumProgram: inviteCreation?.funeralInfo?.funeralHome?.premiumProgram
  }),
  { merge: formActions.merge, reset: formActions.reset }
)(Accessors);
