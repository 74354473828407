import React from 'react';
import { Link } from 'react-router';
import get from 'lodash-es/get';
import moment from 'moment/moment';
import classNames from 'classnames';

const ActivityCard = activityData => {
  const memorialId = get(activityData, 'memorialRef._id', '');
  const cardClasses = classNames('activity-card', {
    highlighted: activityData.isHighlighted
  });

  return (
    <div key={activityData._id} className={cardClasses}>
      <div
        className="activity-image"
        style={{
          backgroundImage: `url('${activityData.createdBy && activityData.createdBy.image}')`
        }}
      />
      <div className="activity-text">
        <h4>{activityData.message}</h4>
        <p>{moment(activityData.createdAt).format('ddd, MMM D YYYY')}</p>
      </div>
      <Link to={`/dash/a/announcements/${memorialId}`}>
        <button>View</button>
      </Link>
    </div>
  );
};

export default ActivityCard;
