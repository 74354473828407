import React from 'react';

import DashCard from '../../../../shared/DashCard';
import DashCardHeader from '../../../../shared/DashCard/CardBuilder/DashCardHeader';
import AttentionRow from '../../../../shared/DashCard/CardBuilder/AttentionRow';

import './InvitePreviewCard.scss';

const InvitePreviewCard = ({
  cautionFlag,
  children,
  footerCopy,
  footerAction,
  headerBtnAction,
  headerBtnCopy,
  headerCopy,
  inset,
  raised,
  customClass
}) => {
  const shouldRenderHeader = !!headerBtnCopy || !!headerBtnAction || !!headerCopy;
  const shouldRenderFooter = !!footerCopy || !!footerAction || !!cautionFlag;

  return (
    <DashCard {...{ inset, raised, customClass }}>
      {shouldRenderHeader && (
        <DashCardHeader {...{ buttonCopy: headerBtnCopy, headerBtnAction, headerCopy }} />
      )}
      {children}
      {shouldRenderFooter && <AttentionRow {...{ footerCopy, footerAction, cautionFlag }} />}
    </DashCard>
  );
};

export default InvitePreviewCard;
