import React from 'react';
import DashPage from '@evdy/web-core/dist/components/shared/elements/DashPage';

export default class Tutorials extends React.Component {
  render() {
    return (
      <DashPage
        customClass="tutorials-page"
        headerText="Tutorials &amp; Help"
        subHeaderText={
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet,
            consect.
          </p>
        }
      >
        <p>Get some help with these here tutorials.</p>
      </DashPage>
    );
  }
}
