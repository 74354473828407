import React from 'react';

import './AccLocProfile.scss';
import LocationAboutBody from '../../../../../shared/LocationModalBody/components/LocationAboutBody';

const AccLocProfile = ({ form, handleLocationUpdate, model }) => {
  const { location: locationForm, accountDetailsMeta } = form;
  const isValid = accountDetailsMeta?.location?.$form?.valid;

  return (
    <div className="dash-acc-loc-profile-container">
      <LocationAboutBody form={locationForm} isInternal model={`${model}.location`} />
      <button className="save-btn" onClick={handleLocationUpdate} disabled={!isValid}>
        Save
      </button>
    </div>
  );
};

export default AccLocProfile;
