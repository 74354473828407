import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  ModalOverlay,
  SolidColorModalHeader,
  ResponsiveContent,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';

import { calculatePackageTotals, separateBasicServices } from '../../helperFuncs';

import './PricingMatricesModal.scss';

import greenCheck from '../../../../images/green-check-contact.svg';

const PricingMatricesModal = ({
  modalVisible,
  handleCloseModal,
  handleOpenEditPackage,
  isExternalMode
}) => {
  const { preneedPackages } = useSelector(({ funeralHome }) => ({
    preneedPackages: funeralHome.home.data?.preneedPackages
  }));

  const { packages = [], services = [] } = preneedPackages ?? {};
  // urn serviceIndices = 6000 || 6001 || 6002
  // flowers serviceIndices = 9001 || 9002
  // these services belong in multiple packages and need to be filtered out of the packages service array
  const duplicateServiceIndices = [6001, 6002, 9002];

  const funeralHomeServices = useMemo(() => {
    // hidden services should be included in this list so that add ons will be shown for pricing clarification to the directors
    // we need the services row to show all the packages the services belongs to but not show the same service on multiple rows
    const isNotParentService = ({ isParent }) => !isParent;
    const isFuneralHomeService = ({ type }) => type === 'funeralhome';
    // filter out addOns in multiple packages to show in one row
    const isDuplicate = ({ serviceIndex }) =>
      !duplicateServiceIndices.includes(serviceIndex) ? true : false;

    return services.filter(isNotParentService).filter(isFuneralHomeService).filter(isDuplicate);
  }, [duplicateServiceIndices, services]);
  //separate service arrays to give basic services prioroity over remaining
  //ig: [pkg1.basicServce, pkg2.basicService, pkg3.basicService, ...rest]
  const [basicServices, remainingServices] = separateBasicServices(funeralHomeServices);

  const packageTotals = useMemo(() => {
    return calculatePackageTotals(packages);
  }, [packages]);

  const mapRemainingServiceRows = service => {
    const {
      serviceName: { director: directorServiceName },
      parentPackage = [],
      price = 0,
      addOn,
      serviceIndex
    } = service || {};
    const servicesPerPackage = {};
    parentPackage.forEach(pkg => {
      const { packageIdx } = pkg || {};
      if (packageIdx || packageIdx === 0) {
        servicesPerPackage[packageIdx] = true;
      }
      // serviceIndex for urn = 6000 (ie: map to all three pkgs)
      if (addOn && serviceIndex === 6000) {
        servicesPerPackage['0'] = true;
        servicesPerPackage['1'] = true;
        servicesPerPackage['2'] = true;
      }
      // serviceIndex for flowers = 9001 (ie: map to heartfelt && complete funeral)
      if (addOn && serviceIndex === 9001) {
        servicesPerPackage['1'] = true;
        servicesPerPackage['2'] = true;
      }
    });

    const showAdditionalPkgsRow = directorServiceName === 'Visitation';

    return (
      <>
        {showAdditionalPkgsRow && (
          <div className="header-row additional-pkgs">
            <span>Additional Package Products</span>
          </div>
        )}
        <div className="service-row">
          <div className="service-name">
            <span>{directorServiceName}</span>
          </div>
          {servicesPerPackage['0'] ? (
            <div className="check-container">
              <img className="package-check" src={greenCheck} alt="green-check" />
            </div>
          ) : (
            <span className="not-in-pkg" />
          )}
          {servicesPerPackage['1'] ? (
            <div className="check-container">
              <img className="package-check" src={greenCheck} alt="green-check" />
            </div>
          ) : (
            <span className="not-in-pkg" />
          )}
          {servicesPerPackage['2'] ? (
            <div className="check-container">
              <img className="package-check" src={greenCheck} alt="green-check" />
            </div>
          ) : (
            <span className="not-in-pkg" />
          )}
          <div className="row-price">
            <span>${price}</span>
          </div>
        </div>
      </>
    );
  };

  const renderBasicServices = () => (
    <div className="service-row">
      <div className="service-name">
        <span>Full Service Cremation</span>
      </div>
      <div className="price-container">{`$${basicServices[0]?.price}`}</div>
      <div className="price-container">{`$${basicServices[1]?.price}`}</div>
      <div className="price-container">{`$${basicServices[2]?.price}`}</div>
      <div className="row-price" />
    </div>
  );

  const packageTotalsRowData = packageTotals.map(pkg => (
    <div className="price-column">
      <div className="price">${pkg.packagePrice}</div>
      <div className="title">{pkg.packageName.director}</div>
    </div>
  ));

  const openPackageModal = () => {
    handleCloseModal();
    handleOpenEditPackage();
  };

  return (
    <ModalOverlay
      {...{ modalVisible }}
      closeModal={handleCloseModal}
      customClass="matrices-modal-container"
    >
      <ResponsiveContent>
        <SolidColorModalHeader
          headerCopy="All Advance Funeral Packages"
          closeModal={handleCloseModal}
        />
        <div className="matrices-modal">
          <div className="package-total-row">
            <div className="row-header">
              <span>Product</span>
            </div>
            <div className="virtual-celebration-col">{packageTotalsRowData}</div>
            <div className="your-price">
              <span>Your Price</span>
            </div>
          </div>
          <div className="header-row">
            <span>Basic Services</span>
          </div>
          {renderBasicServices()}
          {remainingServices.map(mapRemainingServiceRows)}
        </div>
        <ModalFooter
          mainButtonText="Done"
          mainButtonFn={handleCloseModal}
          secondaryButtonText={!isExternalMode ? 'Update Pricing' : ''}
          secondaryButtonFn={!isExternalMode ? openPackageModal : () => {}}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

const { bool, func } = PropTypes;

PricingMatricesModal.propTypes = {
  modalVisible: bool.isRequired,
  handleCloseModal: func.isRequired,
  handleOpenEditPackage: func.isRequired,
  isExternalMode: bool
};

export default PricingMatricesModal;
