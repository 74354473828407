import React from 'react';
import { useSelector } from 'react-redux';

import familyTogetherImg from './family-together.png';

import {
  ModalOverlay,
  ResponsiveContent,
  SolidColorModalHeader,
  ModalFooter
  // ListContent
} from '@evdy/web-core/dist/components/shared/Modal2020';

import './FamilyValueModal.scss';

const FamilyValueModal = ({ accessorCount, modalVisible, closeModal, nextStep }) => {
  const { decedentName } = useSelector(({ inviteCreation }) => ({
    decedentName: inviteCreation?.decedentDetails?.data?.name
  }));
  const { last: lastName = '' } = decedentName || {};

  const headerCopy = 'We recommend adding more family';
  const buttonCopy = 'Add family members';

  return (
    <ModalOverlay {...{ modalVisible, closeModal }} customClass="family-value-modal">
      <ResponsiveContent>
        <SolidColorModalHeader {...{ closeModal, headerCopy }} />
        <div className="family-value-content">
          <div className="family-value-text">
            <h1>{`Surround the ${lastName} family with support`}</h1>
            <p>
              Each person you include will be able to quickly and easily bring relatives and friends
              together in honor of their loved one.
            </p>
          </div>
          <img className="family-value-graphics" src={familyTogetherImg} alt="group of profiles" />
        </div>
        <ModalFooter
          secondaryButtonHide={accessorCount < 1}
          secondaryButtonText="Skip for now"
          secondaryButtonFn={() => {
            nextStep();
            closeModal();
          }}
          mainButtonText={buttonCopy}
          mainButtonFn={closeModal}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default FamilyValueModal;
