import React from 'react';
import classNames from 'classnames';

import {
  Form,
  InputGroup,
  isEmailNonRequired,
  isRequired,
  isValidPhone,
  parsePhone
} from '@evdy/web-core/dist/components/shared/Input';
import { useFocusInput } from '@evdy/web-core/dist/customHooks/index';

import InfoWindow from '@evdy/web-core/dist/components/shared/elements/InfoWindow';
import relationshipOptions from '@evdy/web-etc/dist/lib/relationships';

import './EditAccessorInfoForm.scss';

const EditAccessorInfoForm = ({ model, editMode, modalVisible }) => {
  const informantRowClasses = classNames('input-row');

  //focus first name input when mounted and modal open
  useFocusInput({ model, inputModel: 'name.first', modalVisible });

  return (
    <Form model={model} className="accessor-info-form">
      <div className="input-row">
        <InputGroup
          required
          labelText="First name *"
          model=".name.first"
          validators={{ isRequired }}
          messages={{
            isRequired: 'This field is required'
          }}
        />
        <InputGroup
          required
          labelText="Last name *"
          model=".name.last"
          validators={{ isRequired }}
          messages={{
            isRequired: 'This field is required'
          }}
        />
      </div>
      <div className="input-row">
        <InputGroup
          inline
          model=".email"
          type="email"
          labelText="Email (recommended)"
          validators={{ isEmailNonRequired }}
          messages={{ isEmail: 'Please enter a valid email' }}
        />
        <div className="input-with-tooltip">
          <InputGroup
            type="tel"
            labelText="Mobile number *"
            model=".phone"
            parser={parsePhone}
            validators={{
              isValidPhone,
              isRequired
            }}
            messages={{
              isValidPhone: 'Please enter a valid phone number'
            }}
            disabled={!!editMode}
          />
          <InfoWindow noClose hoverEvents outsideText="?">
            The Memorial will be sent via text message to this phone number
          </InfoWindow>
        </div>
      </div>
      <div className={informantRowClasses}>
        <InputGroup inline model=".relation" type="select" labelText="Relation to decedent">
          <option value="" />
          {relationshipOptions.map((relation, idx) => (
            <option value={relation.toLowerCase()} key={idx}>
              {relation}
            </option>
          ))}
        </InputGroup>
      </div>
    </Form>
  );
};

export default EditAccessorInfoForm;
