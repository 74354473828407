import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

import { userContainsOne, renderDemoToast } from '@evdy/web-core/dist/lib/utils';
import { signOut } from '@evdy/web-redux/dist/actions/dash/user';
import {
  closeCreateModal,
  setIsEmployee,
  setDashMode
} from '@evdy/web-redux/dist/lib/dash/actions/dashNav';
import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import UniversalDashModal from '@evdy/web-core/dist/components/shared/elements/UniversalDashModal';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import SwitchCompanyModalBody from './SwitchCompanyModalBody';
import DemoBar from './DemoBar';
import UserDropdown from './UserDropdown';
import DecedentNavPreview from './DecedentNavPreview';
import CreationExitModal from './CreationExitModal';

import TextlessEverdaysLogo from '../../images/shared/logos/everdays-textless-logo.svg';
import EverdaysLogo from '@evdy/web-core/dist/images/everdays-logo@2x.png';

import './DashNav.scss';

const { deleteInviteAction } = creationOperations;

const { reset } = formActions;

const locationFormModel = 'funeralHome.forms.homeDetails';
const companyFormModel = 'dash.company.forms.overview';

const DashNav = ({
  annc,
  closeCreateModal,
  currentPage,
  fetchedCompany,
  inviteCreation,
  isEmployee,
  isExternalMode,
  locationState,
  pageTitle,
  setDashMode,
  setIsEmployee,
  signOut,
  switchCompany,
  user,
  deleteInviteAction,
  reset
}) => {
  const { isDemoModeOn = false } = user || {};

  const [isDraftConfirmationModalOpen, setIsDraftConfirmationModalOpen] = useState(false);
  const [isSwitchCompanyModalOpen, setIsSwitchCompanyModalOpen] = useState(false);

  const { _id: memorialId } = inviteCreation?.decedentDetails?.data;
  const { receivedAt, isFetching } = inviteCreation?.inviteDelete;

  //set employee if admin
  useEffect(() => {
    const isEmployee = userContainsOne(user, ['everdaysAdmin']);
    setIsEmployee(isEmployee);
  }, [annc.forms.homeInfo.funeralDirectorId, annc.forms.homeInfo.funeralHome, setIsEmployee, user]);

  //close exit modal and redirect to invite list on successful delete
  useEffect(() => {
    if (!isFetching && receivedAt >= Date.now() - 5e3) {
      setIsDraftConfirmationModalOpen(false);
      browserHistory.push('/dash/a/announcements');
    }
  }, [isFetching, receivedAt]);

  const openSwitchCompanyModal = () => {
    setIsSwitchCompanyModalOpen(true);
  };

  //fn to reset required missing fields on company switch
  const resetRequiredFieldsOnSwitch = () => {
    reset(companyFormModel);
    reset(locationFormModel);
  };

  const renderSwitchCompanyModal = () => (
    <UniversalDashModal
      v3Styling
      closeModal={() => setIsSwitchCompanyModalOpen(false)}
      headerTitle="Switch Account"
      noFooter={true}
    >
      <SwitchCompanyModalBody {...{ switchCompany, resetRequiredFieldsOnSwitch }} />
    </UniversalDashModal>
  );

  const handleSignOut = () => {
    signOut(window.location.href);
  };

  const creationLinks = () => {
    return (
      <div className="creation-links">
        <a href="/dash/a/resource-center" target="_blank" rel="noopener noreferrer">
          Help
        </a>
        <button
          className="finish-later"
          onClick={() =>
            isDemoModeOn
              ? renderDemoToast({ id: 'annc-builder-save-draft-demo' })
              : setIsDraftConfirmationModalOpen(true)
          }
        >
          Save & Exit
        </button>
      </div>
    );
  };

  const renderCenterNav = () => {
    const { displayName, dateOfBirth, dateOfDeath, image } = inviteCreation?.forms?.decedentInfo;
    return (
      <div className="center-nav">
        {currentPage === 'builder' && (
          <DecedentNavPreview {...{ displayName, dateOfBirth, dateOfDeath, image }} />
        )}
      </div>
    );
  };

  const renderRightNav = () => {
    if (currentPage === 'announcement-builder' || currentPage === 'builder') return creationLinks();

    if (currentPage === 'onboarding')
      return (
        <div className="nav-phone-number">
          Need Help? Call <strong>(877) 400-3297</strong>
        </div>
      );

    return user ? (
      <UserDropdown
        user={user}
        signOutFn={handleSignOut}
        openSwitchCompanyModal={openSwitchCompanyModal}
      />
    ) : (
      <div className="non-auth-links">
        <a
          href="https://help.everdays.com/"
          className="help"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help
        </a>
        <Link to="/dash/signup" className="sign-up">
          Sign up
        </Link>
      </div>
    );
  };

  const renderDraftModal = () => {
    const closeModal = () => {
      setIsDraftConfirmationModalOpen(false);
    };
    const handleExit = () => {
      setIsDraftConfirmationModalOpen(false);
      browserHistory.push('/dash/a/announcements');
    };
    const secondaryButtonAction = () => {
      deleteInviteAction({ memorialId });
    };

    return (
      <CreationExitModal
        {...{ closeModal, handleExit, secondaryButtonAction }}
        isModalVisible={isDraftConfirmationModalOpen}
        headerCopy="Do you want to finish this Memorial later?"
        mainBtnCopy="Save draft & exit"
        secondaryButtonCopy="Delete & exit"
      >
        You can save a draft and come back when you’re ready to send it out.
      </CreationExitModal>
    );
  };

  const dashNavClasses = classNames('dash-nav', {
    'demo-mode-on': user?.isDemoModeOn,
    'is-employee': isEmployee
  });

  const navLogo = pageTitle ? TextlessEverdaysLogo : EverdaysLogo;
  const shouldShowLoader = !!isFetching;

  return (
    <div className={dashNavClasses}>
      <DemoBar />
      <div className="desktop-nav-body">
        <div className="left-nav">
          <Link className="nav-logo-link" to="/dash/a/dashboard">
            <img className="nav-logo" src={navLogo} alt="Everdays Logo" />
          </Link>
          {pageTitle && <p className="nav-page-title">{pageTitle}</p>}
        </div>
        {renderCenterNav()}
        <div className="right-nav">{renderRightNav()}</div>
      </div>
      {isDraftConfirmationModalOpen && renderDraftModal()}
      {isSwitchCompanyModalOpen && renderSwitchCompanyModal()}
      {shouldShowLoader && <LoaderComponent />}
    </div>
  );
};

export default connect(
  ({ dash, inviteCreation }) => ({
    user: dash.user.data,
    annc: dash.annc,
    isExternalMode: dash.dashNav.isExternalMode,
    isEmployee: dash.dashNav.isEmployee,
    fetchedCompany: dash.fetchedCompany,
    inviteCreation
  }),
  {
    setDashMode,
    setIsEmployee,
    closeCreateModal,
    signOut,
    deleteInviteAction,
    reset
  }
)(DashNav);
