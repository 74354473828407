import React from 'react';

const NoLeads = ({
  searchQuery,
  selectedStage,
  selectedStageName,
  handleSetPrequalifiedFilter
}) => {
  const combinedQueryMessage = [
    `${searchQuery ? `“${searchQuery}”` : ''}`,
    `${selectedStage ? `leads at stage “${selectedStageName}”` : ''}`
  ]
    .filter(Boolean)
    .join(' and for ');

  return (
    <div className="no-leads">
      <div className="no-leads-desc">
        <h1>Your search of {combinedQueryMessage} returned 0 results.</h1>
        <p>Suggestions:</p>
        <ul>
          <li>Confirm the name you're searching is spelled correctly</li>
          <li>
            Check the list of{' '}
            <button onClick={handleSetPrequalifiedFilter}>Leads Pending Qualification</button>, as
            those do not appear in the search results
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NoLeads;
