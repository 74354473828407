"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchCompanyTypeahead = exports.fetchCompanyTypeaheadFailure = exports.fetchCompanyTypeaheadSuccess = exports.fetchCompanyTypeaheadRequest = void 0;

var types = _interopRequireWildcard(require("../../constants/onboarding/fetchCompanyTypeahead"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var instance = _index.default.create({
  baseURL: "".concat(_constants.API_URL)
});

var fetchCompanyTypeaheadRequest = function fetchCompanyTypeaheadRequest() {
  return {
    type: types.FETCH_COMPANY_TYPEAHEAD_REQUEST
  };
};

exports.fetchCompanyTypeaheadRequest = fetchCompanyTypeaheadRequest;

var fetchCompanyTypeaheadSuccess = function fetchCompanyTypeaheadSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_COMPANY_TYPEAHEAD_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchCompanyTypeaheadSuccess = fetchCompanyTypeaheadSuccess;

var fetchCompanyTypeaheadFailure = function fetchCompanyTypeaheadFailure(error) {
  return {
    type: types.FETCH_COMPANY_TYPEAHEAD_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fetchCompanyTypeaheadFailure = fetchCompanyTypeaheadFailure;

var fetchCompanyTypeahead = function fetchCompanyTypeahead(companyName) {
  return function (dispatch) {
    dispatch(fetchCompanyTypeaheadRequest());
    return instance.get("/search/funeral-homes/".concat(companyName)).then(function (res) {
      return dispatch(fetchCompanyTypeaheadSuccess(res));
    }, function (err) {
      return dispatch(fetchCompanyTypeaheadFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.fetchCompanyTypeahead = fetchCompanyTypeahead;