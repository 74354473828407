import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DonationModal from '../InviteBuilder/components/modals/DonationModal';

import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';
import { renderToast } from '@evdy/web-core/dist/lib/utils';

import styles from './DonationPreviewCards.module.scss';

const { addDonationAction, editDonationAction, deleteDonationAction } = creationOperations;

const NoDonationsCard = ({ openDonateModal }) => (
  <div className={styles.donationCard}>
    <h3 className={styles.title}>Donations</h3>
    <p>Donation information has not been added yet.</p>
    <div className={styles.ctaCopy} onClick={openDonateModal}>
      Add organization
    </div>
  </div>
);

const DonationPreviewCards = ({
  whiteBackground,
  memorialId,
  detailsDonations,
  creationDonations,
  currentPage,
  donationsData
}) => {
  const dispatch = useDispatch();

  const {
    array: donationsArray,
    isFetching: isFetchingDonations,
    lastUpdated: lastUpdatedDonations,
    donationAdded,
    donationUpdated,
    donationRemoved
  } = donationsData || {};

  const [isDonationsModalOpen, setDonationsModalOpen] = useState(false);
  const [currentDonation, setCurrentDonation] = useState({});

  const handleDonationSubmit = ({
    organizationName,
    donationUrl,
    description,
    hasDonation,
    donationId
  }) =>
    hasDonation
      ? dispatch(
          editDonationAction({
            title: organizationName,
            url: donationUrl,
            memorialId,
            donationId,
            description
          })
        )
      : dispatch(
          addDonationAction({
            title: organizationName,
            url: donationUrl,
            memorialId,
            description
          })
        );

  const handleDonationDelete = ({ e, memorialId, donationId }) => {
    e.preventDefault();
    dispatch(deleteDonationAction({ memorialId, donationId }));
  };

  const openDonateModal = () => setDonationsModalOpen(true);
  const handleDonateModalClose = () => {
    setDonationsModalOpen(false);
    setCurrentDonation({});
  };

  // open donation modal when donationToEdit is set
  useEffect(() => {
    const { _id } = currentDonation || {};
    if (!!_id) setDonationsModalOpen(true);
  }, [currentDonation]);

  //show success toast for donation on add/edit/delete && close
  useEffect(() => {
    const hasDonationBeenUpdated = lastUpdatedDonations >= Date.now() - 5e2;
    const showAddDonationSuccess = !isFetchingDonations && donationAdded && hasDonationBeenUpdated;
    const showEditDonationSuccess =
      !isFetchingDonations && donationUpdated && hasDonationBeenUpdated;
    const showRemoveDonationSuccess =
      !isFetchingDonations && donationRemoved && hasDonationBeenUpdated;

    if (showAddDonationSuccess) {
      renderToast({ id: 'Add Donation Success', message: 'Donation Added', type: 'success' });
      setDonationsModalOpen(false);
    }
    if (showEditDonationSuccess) {
      renderToast({ id: 'Edit Donation Success', message: 'Donation Updated', type: 'success' });
      handleDonateModalClose();
    }
    if (showRemoveDonationSuccess) {
      renderToast({ id: 'Remove Donation Success', message: 'Donation Removed', type: 'success' });
      handleDonateModalClose();
    }
  }, [donationAdded, donationRemoved, donationUpdated, isFetchingDonations, lastUpdatedDonations]);

  return (
    <>
      {!!donationsArray?.length ? (
        donationsArray.map(donation => {
          const handleEditDonation = () => setCurrentDonation(donation);

          return (
            <div className={styles.donationCard} key={donation._id}>
              <h3 className={styles.title}>Donations</h3>
              <div className={styles.body}>{donation?.title}</div>
              <div onClick={handleEditDonation} className={styles.ctaCopy}>
                Edit Donation
              </div>
              <div onClick={openDonateModal} className={styles.ctaCopy}>
                Add another organization
              </div>
            </div>
          );
        })
      ) : (
        <NoDonationsCard {...{ openDonateModal }} />
      )}
      <DonationModal
        modalVisible={isDonationsModalOpen}
        handleSubmit={handleDonationSubmit}
        handleDelete={handleDonationDelete}
        handleCloseModal={handleDonateModalClose}
        {...{ currentDonation, memorialId }}
      />
    </>
  );
};

export default DonationPreviewCards;
