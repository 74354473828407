import React, { useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { FacebookShareButton } from 'react-share';

import {
  ModalOverlay,
  SolidColorModalHeader,
  ModalFooter,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';

import { handleCopy, formatPhoneNumber } from '@evdy/web-core/dist/lib/utils';

import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';

import fbCircleIcon from '@evdy/web-share/src/images/facebook-circle-white.png';
import fbIcon from './images/facebook-icon-black.png';
import copyIcon from './images/copy-icon-black.png';
import speechBubbleIcon from './images/speech-bubble-icon-black.png';

import './BuilderOutroModal.scss';

const { sendMemorialLinkAction } = creationOperations;

const BuilderOutroModal = ({
  accessorsArray,
  sendMemorialLinkAction,
  memorialId,
  modalVisible,
  previewSent,
  shareUrl,
  userData
}) => {
  const [wasLinkCopied, setLinkCopied] = useState(false);

  const anncDetailsUrl = `/dash/a/announcements/${memorialId}`;

  const closeModal = () => {
    browserHistory.push(anncDetailsUrl);
  };

  const handleSendPreview = () => {
    if (previewSent) return;

    sendMemorialLinkAction({ memorialId });
  };

  const numOfFamilyMembers = accessorsArray?.length || 0;
  const userPhone = userData?.phone;

  return (
    <ModalOverlay {...{ modalVisible }} customClass="builder-outro-modal">
      <ResponsiveContent>
        <SolidColorModalHeader {...{ closeModal }} headerCopy="Congrats!" />
        <div className="builder-outro-modal-body">
          <div className="outro-text">
            <h3>
              The Digital Memorial has been sent to {numOfFamilyMembers}{' '}
              {numOfFamilyMembers === 1 ? 'person' : 'people'}
            </h3>
          </div>
          <div className="outro-action facebook-share">
            <div className="outro-action-content">
              <img className="outro-action-icon" src={fbIcon} />
              <div className="outro-action-copy">
                <span className="outro-action-title">Share to Facebook</span>
                <span className="outro-action-description">
                  Confirm you’re logged into your funeral home’s account first
                </span>
              </div>
            </div>
            <FacebookShareButton url={shareUrl}>
              <button className="outro-action-button">
                <img className="fb-icon" src={fbCircleIcon} alt="share on facebook" /> Share
              </button>
            </FacebookShareButton>
          </div>
          <div className="outro-action copy-link">
            <div className="outro-action-content">
              <img className="outro-action-icon" src={copyIcon} />
              <div className="outro-action-copy">
                <span className="outro-action-title">Web Memorial link</span>
                <input className="outro-action-description link-input" disabled value={shareUrl} />
              </div>
            </div>
            <div
              className="outro-action-button"
              onClick={() => {
                handleCopy(shareUrl);
                setLinkCopied(true);
              }}
            >
              {wasLinkCopied ? 'Copied!' : 'Copy Link'}
            </div>
          </div>
          <div className="outro-action send-text">
            <div className="outro-action-content">
              <img className="outro-action-icon" src={speechBubbleIcon} />
              <div className="outro-action-copy">
                <span className="outro-action-title">Send this Memorial to your phone</span>
                <span className="outro-action-description">{`Your cell: ${formatPhoneNumber(
                  userPhone
                )}`}</span>
              </div>
            </div>
            <button className="outro-action-button" onClick={handleSendPreview}>
              {previewSent ? 'Sent!' : 'Send text'}
            </button>
          </div>
        </div>
        <ModalFooter
          mainButtonText="Done"
          mainButtonFn={() => {
            browserHistory.push(anncDetailsUrl);
          }}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(
  ({ authUser, inviteCreation }) => ({
    shareUrl: inviteCreation?.decedentDetails?.data?.shareUrl,
    memorialId: inviteCreation?.decedentDetails?.data?._id,
    accessorsArray: inviteCreation?.accessors?.array,
    userData: authUser?.data,
    previewSent: inviteCreation?.invitePreview?.previewSent
  }),
  {
    sendMemorialLinkAction
  }
)(BuilderOutroModal);
