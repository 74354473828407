import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ModalOverlay,
  ModalFooter,
  GradientModalHeader,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import CompanyEditModalBody from '../CompanyEditModalBody';

import { editCompanyAction, editCompanyReset } from '@evdy/web-redux/dist/actions/dash/editCompany';

import { renderToast, renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import './CompanyEditModal.scss';

const CompanyEditModal = ({
  // component props
  closeModal,
  modalVisible,
  // redux state
  companyFormValid,
  editCompany,
  fetchedCompanyData,
  overview,
  userData,
  // redux actions
  editCompanyAction,
  editCompanyReset
}) => {
  const { isDemoModeOn } = userData || {};
  const { _id, funeralHomes, users } = fetchedCompanyData || {};

  // show Toast on either success or error
  useEffect(() => {
    if (modalVisible && editCompany.lastUpdated && editCompany?.data?._id) {
      renderToast({ id: 'edit-overview-success', message: 'Account successfully updated!' });
      closeModal();
      editCompanyReset();
    } else if (modalVisible && editCompany.lastUpdated && editCompany?.error?.message) {
      renderToast({ id: 'edit-overview-error', message: editCompany.error.message, type: 'error' });
      editCompanyReset();
    }
  }, [modalVisible, editCompany, editCompanyReset]);

  const handleSaveCompany = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-overview-demo' });
    const submitObj = {
      company: { ...overview },
      funeralHomes,
      users,
      delete: {}
    };

    editCompanyAction(submitObj, _id);
  };

  const handleUploadFinish = url => {
    const submitObj = {
      company: { ...overview, logo: url },
      funeralHomes,
      users,
      delete: {}
    };

    editCompanyAction(submitObj, _id);
  };

  const handleRemoveImage = () => {
    const submitObj = {
      company: { ...overview, logo: '' },
      funeralHomes,
      users,
      delete: {}
    };

    editCompanyAction(submitObj, _id);
  };

  return (
    <ModalOverlay {...{ closeModal, modalVisible }} customClass="company-edit-modal">
      <ResponsiveContent>
        <GradientModalHeader {...{ closeModal }} headerCopy="Edit Account" />
        {modalVisible && <CompanyEditModalBody {...{ handleUploadFinish, handleRemoveImage }} />}
        <ModalFooter
          mainButtonText="Save"
          mainButtonFn={handleSaveCompany}
          disabled={!companyFormValid}
          secondaryButtonText="Cancel"
          secondaryButtonFn={closeModal}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(
  ({ authUser, dash }) => ({
    editCompany: dash.editCompany,
    fetchedCompanyData: dash?.fetchedCompany?.company?.data,
    overview: dash.company?.forms?.overview,
    companyFormValid: dash.company.forms.companyMeta.overview.$form.valid,
    userData: authUser?.data
  }),
  {
    editCompanyAction,
    editCompanyReset
  }
)(CompanyEditModal);
