import React, { useState } from 'react';
import { connect } from 'react-redux';
import Link from 'react-router/es/Link';
import qs from 'querystring';

import UniversalDashModal from '@evdy/web-core/dist/components/shared/elements/UniversalDashModal';
import ReplaceAgentModal from './ReplaceAgentModal';

import { nameString } from '@evdy/web-core/dist/lib/utils';

import { fetchAccounts } from '@evdy/web-redux/dist/actions/dash/fetchAccounts';
import { addAfp, replaceAfp } from '@evdy/web-redux/dist/actions/dash/editAfp';

import blankImg from '../../../../assets/no-photo-placeholder.svg';

import './AccLocAgents.scss';

const AccLocAgents = props => {
  const {
    accountId,
    locationId,
    members,
    afps = [],
    model,
    form,
    addAfp,
    replaceAfp,
    fetchAccounts
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState({ current: '', replaceWith: '' });
  const { agents: agentsForm, accountDetailsMeta } = form;
  const isValid = accountDetailsMeta?.agents?.$form?.valid;
  const agentIds = afps
    .filter(({ _id }) =>
      members.find(({ _id: memberId }) => memberId?.toString() === _id?.toString())
    )
    .map(({ _id }) => _id);
  const agents = members.filter(({ _id }) => agentIds.includes(_id?.toString()));
  const nonAgents = members.filter(({ _id }) => !agentIds.includes(_id?.toString()));
  const agentsMeta = {
    currentIsValid: accountDetailsMeta?.agents?.current?.valid
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalSelection = (type, id) => {
    setSelectedAgents(prevSelection => ({ ...prevSelection, [type]: id }));
  };

  const handleAddAgent = async userId => {
    await addAfp(locationId, userId);
    await fetchAccounts(accountId);
  };

  const handleReplaceAgent = async () => {
    const { current, replaceWith } = selectedAgents;
    await replaceAfp(locationId, current, replaceWith);
    await fetchAccounts(accountId);
    handleCloseModal();
  };

  const agentEditLinkParams = {
    isExternalMode: false,
    originDisplayName: 'Agents List',
    originPathName: `/dash/a/accounts/${accountId}/${locationId}`
  };

  return (
    <div className="dash-acc-loc-agents-container">
      <div className="agent-controls">
        <button onClick={() => setIsModalOpen(true)}>Replace Agent</button>
      </div>
      <div className="agent-list">
        {agents.map(({ _id, image, name, email, title, isCompanyAdmin }) => (
          <div key={_id} className="agent-item">
            <div className="item-section">
              <img src={image || blankImg} alt="agent profile" />
              <div className="info">
                <div className="name">{nameString(name)}</div>
                <div className="email-title">
                  {email} - {title}
                </div>
                {isCompanyAdmin && <div className="admin">Admin</div>}
              </div>
            </div>
            <div className="item-section">
              <Link
                to={{ pathname: `/dash/a/users/${_id}`, search: qs.stringify(agentEditLinkParams) }}
                className="edit"
              >
                Edit
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="agent-list non-agents">
        {nonAgents.map(({ _id, image, name, email, title, isCompanyAdmin }) => (
          <div key={_id} className="agent-item">
            <div className="item-section">
              <img src={image || blankImg} alt="agent profile" />
              <div className="info">
                <div className="name">{nameString(name)}</div>
                <div className="email-title">
                  {email} - {title}
                </div>
                {isCompanyAdmin && <div className="admin">Admin</div>}
              </div>
            </div>
            <div className="item-section">
              <button onClick={() => handleAddAgent(_id)} className="add-as-agent">
                Add as Agent
              </button>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <UniversalDashModal
          v3Styling
          closeModal={handleCloseModal}
          mainButtonText="Save"
          mainButtonFn={handleReplaceAgent}
          disabled={!isValid}
          headerTitle="Find & Replace Agents"
        >
          <ReplaceAgentModal
            {...{ agents, agentsForm, agentsMeta, handleModalSelection }}
            model={`${model}.agents`}
          />
        </UniversalDashModal>
      )}
    </div>
  );
};

export default connect(
  null,
  { fetchAccounts, addAfp, replaceAfp }
)(AccLocAgents);
