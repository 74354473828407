import React from 'react';
import { browserHistory } from 'react-router';

import DashCard from '../../shared/DashCard';
import AccountSetupHeader from '../../shared/DashCard/CardBuilder/AccountSetupHeader';
import ActionItemRow from '../../shared/DashCard/CardBuilder/ActionItemRow';

import './GetStartedCard.scss';

const GetStartedCard = () => {
  return (
    <DashCard customClass="get-started-card">
      <div className="activity-card-header">
        <AccountSetupHeader
          headerCopy="Get your team started"
          subtitleCopy="Share resources with your team"
        />
      </div>
      <ActionItemRow
        rowCopy="Learn how to introduce Everdays to your family"
        buttonCopy="Learn More"
        buttonAction={() => browserHistory.push('/dash/a/resource-center')}
      />
      <ActionItemRow
        rowCopy="Common questions asked and answered"
        buttonCopy="Download PDF"
        buttonAction={() => {
          const pdfLink =
            'https://requiem-dashboard-media.s3.amazonaws.com/2020/05/65e3854b-9e4f-44d2-9c42-1fdb5ec99e10_IntroducingEverdaysToYourFamilies-May2020.pdf';
          window.open(pdfLink, '_blank', 'noopener');
        }}
      />
    </DashCard>
  );
};

export default GetStartedCard;
