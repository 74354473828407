"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCompanyInfoAction = exports.addCompanyInfoFailure = exports.addCompanyInfoSuccess = exports.addCompanyInfoRequest = void 0;

require("core-js/modules/es6.function.name.js");

var _onboarding = require("../../../constants/dash/onboarding");

var _constants = require("../../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var addCompanyInfoRequest = function addCompanyInfoRequest() {
  return {
    type: _onboarding.ONBOARD_ADD_COMPANY_INFO_REQUEST
  };
};

exports.addCompanyInfoRequest = addCompanyInfoRequest;

var addCompanyInfoSuccess = function addCompanyInfoSuccess(_ref) {
  var data = _ref.data;
  return {
    type: _onboarding.ONBOARD_ADD_COMPANY_INFO_SUCCESS,
    receivedAt: Date.now(),
    payload: data
  };
};

exports.addCompanyInfoSuccess = addCompanyInfoSuccess;

var addCompanyInfoFailure = function addCompanyInfoFailure(error) {
  return {
    type: _onboarding.ONBOARD_ADD_COMPANY_INFO_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.addCompanyInfoFailure = addCompanyInfoFailure;

var addCompanyInfoAction = function addCompanyInfoAction(_ref2) {
  var name = _ref2.name,
      address = _ref2.address,
      phone = _ref2.phone,
      type = _ref2.type,
      title = _ref2.title,
      website = _ref2.website,
      image = _ref2.image,
      email = _ref2.email;
  return function (dispatch) {
    dispatch(addCompanyInfoRequest());
    return _index.default.post("".concat(_constants.API_URL, "/onboard/add-company"), {
      name: name,
      address: address,
      phone: phone,
      type: type,
      title: title,
      website: website,
      image: image,
      email: email
    }).then(function (res) {
      return dispatch(addCompanyInfoSuccess(res.data));
    }).catch(function (err) {
      return dispatch(addCompanyInfoFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.addCompanyInfoAction = addCompanyInfoAction;