import React from 'react';

import Repeater from '@evdy/web-core/dist/components/shared/repeater/Repeater';
import RepeaterField from '@evdy/web-core/dist/components/shared/repeater/RepeaterField';
import MuiUploader from '@evdy/web-core/dist/components/shared/elements/imageUpload/MuiUploader';

const getCityState = loc => {
  const { city, state } = loc.address;
  if (!city && !state) return 'Address, City, State';
  if (city && state) {
    return `${city}, ${state}`;
  } else {
    return city || state;
  }
};

const getTitle = ({ name }, idx) => (
  <p className="expander-emphasis"> {name || `Location ${idx + 1}`} </p>
);

const dropPin = require('../../images/icons/drop-pin-location.svg');
const svgIcon = <img src={dropPin} />;

export default class AddFuneralHomes extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddRowAndIncrement = this.handleAddRowAndIncrement.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.deleteRowAndDecrement = this.deleteRowAndDecrement.bind(this);

    this.inputFns = {
      makeKey: AddFuneralHomes.makeKey.bind(this),
      handleChange: this.handleChange.bind(this),
      handlePhoneChange: this.handlePhoneChange.bind(this),
      getValue: this.getValue.bind(this),
      getClass: this.getClass.bind(this),
      clearField: this.clearField.bind(this),
      getErrorText: this.getErrorText.bind(this)
    };
  }

  handleAddRowAndIncrement(e) {
    e.preventDefault();
    this.props.handleAddRow('funeralHomes');
  }

  handleDeleteConfirm() {
    this.props.handleDelete('funeralHomes');
  }

  deleteRowAndDecrement(destination, _idx) {
    const { delRow, handleSetRow, funeralHomeIdx } = this.props;
    const idx = isFinite(_idx) ? _idx : funeralHomeIdx;
    const newIdx = idx === 0 ? 0 : idx - 1;
    handleSetRow(newIdx, true, 'funeralHomes')();
    delRow(idx, destination);
  }

  static makeKey(name, idx) {
    return `funeralHomes[${idx}].${name}`;
  }

  handleChange(name, idx) {
    return this.props.handleInputChange(name, 'funeralHomes', idx);
  }

  handlePhoneChange(name, idx) {
    return this.props.handlePhoneChange(name, 'funeralHomes', idx, true);
  }

  getValue(name, idx) {
    return this.props.getValue(name, 'funeralHomes', idx);
  }

  getClass(name, idx) {
    return this.props.getInputClass(AddFuneralHomes.makeKey(name, idx), idx);
  }

  getErrorText(name, idx) {
    return this.props.getErrorText('funeralHomes', `[${idx}].${name}`);
  }

  clearField(name, idx) {
    return this.props.clearField(name, 'funeralHomes', idx);
  }

  render() {
    const {
      rows,
      getValue,
      funeralHomeIdx,
      handleSetRow,
      handleInputChange,
      handleSaveCompany,
      prependProtocol,
      validatePage,
      isPageValid,
      analyticsPrefix,
      modifiedHomes: modifiedArray
    } = this.props;

    const funeralHomeArray = rows || [{}];
    const shouldStartShut = !!(funeralHomeArray.length && funeralHomeArray[0].phone);

    return (
      <div className="dash-page-card-content">
        <Repeater
          title="Location"
          titleFn={getTitle}
          subTitleFn={getCityState}
          groupClass="admin-funeral-home-form"
          cornerImageKey="profileImage"
          fns={this.inputFns}
          activeIdx={funeralHomeIdx}
          setRowIdx={idx => handleSetRow(idx, null, 'funeralHomes')()}
          saveFn={handleSaveCompany}
          addRowAndIncrement={this.handleAddRowAndIncrement}
          deleteRowAndDecrement={this.handleDeleteConfirm}
          analyticsObject={{
            addnew: `${analyticsPrefix}-addlocation`,
            close: `${analyticsPrefix}-close`,
            view: `${analyticsPrefix}-view`,
            save: `${analyticsPrefix}-save`,
            delete: `${analyticsPrefix}-delete`
          }}
          startShut={shouldStartShut}
          {...{ rows, validatePage, isPageValid, svgIcon, modifiedArray }}
        >
          <MuiUploader
            label="Funeral Logo"
            image={getValue('profileImage', 'funeralHomes', funeralHomeIdx)}
            handleUpload={handleInputChange('profileImage', 'funeralHomes', funeralHomeIdx)}
            gaKey="company-registration-addlocations-addfhimage"
            setCroppedImage={this.props.setCroppedImage}
            analyticsObject={{
              browse: `${analyticsPrefix}-browsephoto`,
              crop: `${analyticsPrefix}-cropphoto`,
              remove: `${analyticsPrefix}-removephoto`
            }}
          />
          <div className="form-two-fields">
            <RepeaterField required name="name" label="Location’s Public Name *" />
            <RepeaterField
              required
              name="websiteUrl"
              type="url"
              label="Location’s Website *"
              onBlur={idx => prependProtocol('funeralHomes', `[${idx}].websiteUrl`)}
            />
          </div>
          <div className="form-two-fields">
            <RepeaterField required name="address.street" label="Address *" />
            <RepeaterField required name="address.city" label="City *" />
          </div>
          <div className="form-two-fields">
            <RepeaterField required name="address.state" label="State *" />
            <RepeaterField required name="address.zip" label="Postal Code *" />
          </div>
          <div className="form-two-fields">
            <RepeaterField required name="phone" type="tel" label="Location’s Phone Number *" />
            <RepeaterField required name="email" type="email" label="Location’s Email" />
          </div>
          <RepeaterField
            required
            name="casesPerYear"
            type="number"
            label="Total Cases per Year at This Location"
          />
        </Repeater>
      </div>
    );
  }
}
