import React, { useState, useEffect, useRef, useMemo } from 'react';
import Link from 'react-router/es/Link';
import { useSelector, useDispatch } from 'react-redux';
import S3Upload from 'react-s3-uploader/s3upload';
import { browserHistory } from 'react-router';
import clamp from 'lodash/clamp';
import zipObject from 'lodash-es/zipObject';

import TabbedNav from '../../shared/TabbedNav';
import BasicDetails from './BasicDetails';
import PackageDetails from './PackageDetails';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { postToImgCompressLambda, overFieldNames } from '@evdy/web-core/dist/lib/utils';
import { funeralHomeOperations } from '@evdy/web-redux/dist/lib/funeralHome';
import { useScrollToTop, useURLParamSync } from '@evdy/web-core/dist/customHooks';

import './LocationDetails.scss';

const { fetchFuneralHome, editFuneralHome, resetFuneralHome } = funeralHomeOperations;

const LocationDetails = ({ params, location: routeLocation }) => {
  const { id = '' } = params;
  const { query } = routeLocation;
  const { tab: selectedTabIdx = '0' } = query;

  const navRef = useRef(null);
  const dispatch = useDispatch();
  const {
    homeLocation = {},
    homeDetailsForm,
    user = {},
    funeralHomeLicenseInfo,
    isFuneralHomeFetching,
    funeralHomeLastUpdated,
    pdfUploaded,
    isExternalMode
  } = useSelector(({ dash, funeralHome, authUser, client }) => ({
    homeLocation: funeralHome.home.data,
    homeDetailsForm: funeralHome.forms.homeDetails,
    user: authUser.data,
    funeralHomeLicenseInfo: client.config.data.funeralHomeLicenseInfo,
    isFuneralHomeFetching: funeralHome.home.isFetching,
    funeralHomeLastUpdated: funeralHome.home.lastUpdated,
    pdfUploaded: funeralHome.home.pdfUploaded,
    isExternalMode: dash.dashNav.isExternalMode
  }));
  const {
    coords = [],
    name: funeralHomeName,
    _id: funeralHomeId,
    priceListingGPLUrl,
    packageSetupComplete = false
  } = homeLocation;

  const [amtToUpload, setAmtToUpload] = useState(null);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  const { isCompanyAdmin } = user;
  const uploadingPhotos = !!amtToUpload;
  const tabsArray = [
    {
      tabName: 'Basics'
    },
    {
      tabName: 'Advance Funeral Packages',
      showIndicator: !packageSetupComplete
    }
  ];

  // scroll to top on mount
  useScrollToTop();

  // set current tab in params
  useEffect(() => {
    const selectedTab = clamp(selectedTabIdx, 0, tabsArray.length - 1);
    navRef.current.handleTabSelect(+selectedTab);
  }, [selectedTabIdx, tabsArray.length]);

  const memoizedFiltersObj = useMemo(
    () => ({
      tab: selectedTabIdx,
      isExternalMode
    }),
    [isExternalMode, selectedTabIdx]
  );

  // persist current tab && persist internal/external mode on refresh
  useURLParamSync(memoizedFiltersObj, routeLocation);

  const handleTabChange = ({ selectedTabIndex }) => {
    browserHistory.push({ ...routeLocation, query: { ...query, tab: selectedTabIndex } });
  };

  // fetch funeralhome data on load
  useEffect(() => {
    dispatch(fetchFuneralHome(id));
    return () => {
      dispatch(resetFuneralHome());
    };
  }, [dispatch, id]);

  const handleUploadLogo = url => {
    dispatch(editFuneralHome(id, { profileImage: url }));
  };

  const handleRemoveLogo = () => {
    dispatch(editFuneralHome(id, { profileImage: '' }));
  };

  const handleUpdateLocation = () => {
    const updatedFormData = {
      ...homeDetailsForm,
      funeralDirectorNumber: null,
      insuranceLicenseNumber: null,
      specialStateLicenseInfo: null,
      specialStateLicenseInfo2: null
    };

    // if state has special keys, use form values otherwise set to empty string
    const { requiredFields = [] } = funeralHomeLicenseInfo[homeDetailsForm.address.state] ?? {};
    const fieldKeys = funeralHomeLicenseInfo[homeDetailsForm.address.state];
    const fieldValues = homeDetailsForm;
    const fieldIsObject = [].includes.bind(['specialStateLicenseInfo', 'specialStateLicenseInfo2']);

    const mappedFieldValues = requiredFields.map(
      overFieldNames(fieldKeys, fieldValues, fieldIsObject)
    );

    dispatch(
      editFuneralHome(id, { ...updatedFormData, ...zipObject(requiredFields, mappedFieldValues) })
    );
  };

  const onFinishS3Put = res => {
    const match = res.signedUrl.match(/(.*)\?/);
    const url = match && match[1];
    postToImgCompressLambda(url).then(res => {
      setUploadedPhotos(uploadedPhotos => [...uploadedPhotos, res.data.path]);
    });
  };

  const handlePhotoUpload = e => {
    const files = e.target.files;
    setAmtToUpload(files.length);
    new S3Upload({ signingUrl: '/s3/dash/media/sign', onFinishS3Put, files });
  };

  // save new photos to backend after s3 upload and compress
  useEffect(() => {
    if (amtToUpload !== null && amtToUpload === uploadedPhotos.length) {
      dispatch(editFuneralHome(id, { photos: [...homeLocation.photos, ...uploadedPhotos] }));
      setAmtToUpload(null);
      setUploadedPhotos([]);
    }
  }, [amtToUpload, dispatch, id, homeLocation, uploadedPhotos]);

  const handleRemovePhoto = photo => {
    const updatedPhotos = homeLocation.photos.filter(p => p !== photo);
    dispatch(editFuneralHome(id, { photos: updatedPhotos }));
  };

  const filterTabs = ({ tabName }) =>
    ['Basics'].includes(tabName) ||
    (['Advance Funeral Packages'].includes(tabName) && isCompanyAdmin);

  return (
    <div className="location-details">
      <TabbedNav
        ref={navRef}
        renderTitle={() => (
          <div className="breadcrumb">
            <Link to={'/dash/a/locations'}>Locations</Link>
            {' / '}
            <span>{homeLocation?.name ?? '-'}</span>
          </div>
        )}
        tabs={tabsArray.filter(filterTabs)}
        onChange={handleTabChange}
      >
        <BasicDetails
          {...{
            homeLocation,
            uploadingPhotos,
            handleUploadLogo,
            handleRemoveLogo,
            handleUpdateLocation,
            handlePhotoUpload,
            handleRemovePhoto
          }}
        />
        {isCompanyAdmin && (
          <PackageDetails
            {...{
              coords,
              funeralHomeName,
              funeralHomeId,
              priceListingGPLUrl,
              isFuneralHomeFetching,
              funeralHomeLastUpdated,
              pdfUploaded,
              isExternalMode,
              handleTabChange
            }}
          />
        )}
      </TabbedNav>
      {isFuneralHomeFetching && <LoaderComponent />}
    </div>
  );
};

export default LocationDetails;
