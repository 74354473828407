import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Form,
  InputGroup,
  formActions,
  parsePhone,
  isValidPhone,
  isEmail,
  isRequired
} from '@evdy/web-core/dist/components/shared/Input';
import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import InfoWindow from '@evdy/web-core/dist/components/shared/elements/InfoWindow';
import './EditUserProfile.scss';
import { editSpecificUser } from '@evdy/web-redux/dist/actions/dash/editUser';
import { renderToast } from '@evdy/web-core/dist/lib/utils';

const model = 'dash.userDetails.forms.profile';
const successToastId = 'crisp-toast';
const errorToastId = 'burnt-toast';

const tshirtSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'];
const jobTitles = {
  administrativeAssistant: 'Administrative Assistant',
  apprentice: 'Apprentice',
  assistant: 'Assistant/Support',
  caregiver: 'Caregiver',
  celebrant: 'Celebrant',
  CEO: 'CEO',
  chaplain: 'Chaplain',
  clergy: 'Clergy',
  director: 'Director',
  embalmer: 'Embalmer',
  familyServicesCounselor: 'Family Services Counselor',
  funeralArranger: 'Funeral Arranger',
  funeralDirector: 'Funeral Director',
  generalManager: 'General Manager',
  griefCounselor: 'Grief Counselor',
  intern: 'Intern',
  manager: 'Manager',
  mortician: 'Mortician',
  nurse: 'Nurse',
  officeAdministrator: 'Office Administrator',
  other: 'Other',
  owner: 'Owner',
  preneedCounselor: 'Preneed Counselor',
  president: 'President',
  socialWorker: 'Social Worker',
  vicePresident: 'Vice President'
};

const EditUserProfile = props => {
  const {
    fetchedUser,
    editErrorMessage,
    lastUpdated,
    submitted,
    profileData,
    isValid,
    merge,
    reset,
    editSpecificUser
  } = props;

  const {
    _id: userId,
    name: { first = '', last = '' } = {},
    email = '',
    shellEmail = '',
    fbEmail = '',
    phone = '',
    title = '',
    bio = '',
    shirtSize = '',
    image = '',
    crmRef,
    hasPassword,
    fbId,
    meta: { isApp } = {},
    sfRef,
    updatedAt,
    company: { name: companyName } = {}
  } = fetchedUser || {};

  const stats = {
    'Everdays ID': userId || '--',
    'CRM ID': crmRef || '--',
    'Password Set': `${hasPassword || '--'}`,
    'Facebook Connect': fbId || '--',
    'Downloaded App': `${isApp || '--'}`,
    'Last Modified': updatedAt ? moment(updatedAt).format('lll') : '--',
    Company: companyName || '--'
  };

  const userPersonalInfo = useMemo(
    () => ({
      name: { first, last },
      email,
      shellEmail,
      fbEmail,
      phone,
      title,
      bio,
      shirtSize,
      image,
      sfRef
    }),
    [bio, email, fbEmail, first, image, last, phone, sfRef, shellEmail, shirtSize, title]
  );

  // merge loaded user data into form
  useEffect(() => {
    merge(model, userPersonalInfo);

    return () => {
      reset(model);
    };
  }, [merge, reset, userPersonalInfo]);

  // display toast on success/save confirmation
  useEffect(() => {
    if (submitted && lastUpdated >= Date.now() - 500 && !editErrorMessage) {
      renderToast({ id: successToastId, message: 'Successfully saved changes' });
    }
  }, [editErrorMessage, fetchedUser, lastUpdated, submitted]);

  // show toast if its burnt
  useEffect(() => {
    if (editErrorMessage) {
      renderToast({ id: errorToastId, message: editErrorMessage, type: 'error' });
    }
  }, [editErrorMessage]);

  const handleSubmit = () => {
    editSpecificUser(profileData, userId);
  };

  const handleRemoveImage = () => {
    editSpecificUser({ image: '' }, userId);
  };

  const handleUploadFinish = url => {
    editSpecificUser({ image: url }, userId);
  };

  return (
    <div className="edit-profile-tab">
      <div className="user-profile-stats">
        {Object.entries(stats).map(([name, value], i) => (
          <div key={i} className="user-profile-stat">
            <div className="profile-stat-name">{name}</div>
            <div className="profile-stat-value">{value}</div>
          </div>
        ))}
      </div>
      <div className="user-profile-data">
        <ImageHandler
          {...{ handleUploadFinish, handleRemoveImage }}
          currentImg={profileData.image}
          modalTitle="Profile Image"
        />
        <Form model={model} onSubmit={handleSubmit} className="personal-info">
          <div className="input-row">
            <InputGroup inline full model=".name.first" labelText="First Name" />
            <InputGroup inline full model=".name.last" labelText="Last Name" />
          </div>
          <div className="input-row">
            <InputGroup
              inline
              full
              model=".email"
              labelText="Email Address"
              validators={{ isEmail }}
              messages={{
                isEmail: 'Please enter a valid email address'
              }}
            />
            <InputGroup
              required
              inline
              full
              type="tel"
              model=".phone"
              labelText="Mobile Phone Number"
              parser={parsePhone}
              validators={{ isValidPhone, isRequired }}
              messages={{
                isValidPhone: 'Please enter a valid phone number',
                isRequired: 'Please enter a valid phone number'
              }}
            />
          </div>
          <div className="input-row">
            <InputGroup
              inline
              full
              model=".shellEmail"
              labelText="Shell Email"
              validators={{ isEmail }}
              messages={{
                isEmail: 'Please enter a valid email address'
              }}
            />
            <InputGroup
              inline
              full
              model=".fbEmail"
              labelText="Facebook Email"
              validators={{ isEmail }}
              messages={{
                isEmail: 'Please enter a valid email address'
              }}
            />
          </div>
          <div className="input-row">
            <InputGroup inline full type="select" labelText="Title" model=".title">
              <option />
              {Object.entries(jobTitles).map(([value, titleName], i) => (
                <option key={i} value={value}>
                  {titleName}
                </option>
              ))}
            </InputGroup>
            <div className="input-with-question-mark">
              <InputGroup inline full model=".shirtSize" labelText="T-Shirt Size" type="select">
                <option />
                {tshirtSizes.map((size, i) => (
                  <option key={i}>{size}</option>
                ))}
              </InputGroup>
              <InfoWindow noClose outsideText="?" windowClass="question-box">
                We’d love to send you an Everdays T-Shirt as a way to thank you for being a valued
                partner!
              </InfoWindow>
            </div>
          </div>
          <div className="input-row">
            <InputGroup type="textarea" inline full model=".bio" labelText="About" />
            <InputGroup inline full model=".sfRef" labelText="Salesforce Ref#" />
          </div>
          <div className="controls">
            <button disabled={!isValid} type="submit">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    profileData: dash.userDetails.forms.profile,
    isValid: dash.userDetails.forms.userDetailsMeta.profile.$form.valid
  }),
  { editSpecificUser, merge: formActions.merge, reset: formActions.reset }
)(EditUserProfile);
