import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { nameString } from '@evdy/web-core/dist/lib/utils';

import ArrowComponent from '../../components/ArrowComponent';
import LeadActivityCard from '../../components/LeadActivityCard';

import { lifecycleStages } from '../../shared/utils';

import './Timeline.scss';

const dateForDesktop = date => (date ? moment.utc(date).format('MMMM D, YYYY') : 'Unknown');

const Timeline = props => {
  const {
    handleOpenModal,
    currentLead,
    currentLead: { memorials = [], activity = [], createdAt, lifecycleStage, name, userRef },
    latestStageActivity = { currentStage: 'new', attempt: 0 },
    isInformant
  } = props;

  const leadMemorial = memorials?.[0];

  const leadRelationship =
    leadMemorial?.accessors.find(acc => acc?.userRef === userRef?._id).relation || undefined;

  const isFamily =
    ['owner', 'manager'].includes(
      leadMemorial?.accessors.find(acc => acc?.userRef === userRef?._id)
    ) || undefined;

  const isCommunity = leadMemorial?.accessors.find(acc => acc?.userRef === userRef?._id);

  const { funeralHome: { name: funeralHomeName = '' } = {}, name: decedentName } =
    memorials[memorials.length - 1] || {};

  const nextModalStage =
    lifecycleStages[lifecycleStages.indexOf(latestStageActivity.currentStage) + 1];

  const closeSuccessColor = latestStageActivity.closeReason === 'purchased' ? '#00ad3e' : '#b31414';
  const closedColor = lifecycleStage === 'closed' ? closeSuccessColor : undefined;

  const stepHistoryClasses = classNames('step-history', {
    closed: latestStageActivity?.currentStage === 'closed'
  });

  return (
    <div className="lead-timeline">
      <div className="step-display">
        <div className="steps">
          <div className="arrow-wrapper">
            <ArrowComponent isActive text={'New'} />
          </div>
          <div className="arrow-wrapper">
            <ArrowComponent
              isActive={lifecycleStages.indexOf(lifecycleStage) > 0}
              text={'Meeting Set'}
              handleArrowClick={() => handleOpenModal('meeting-set', true)}
            />
          </div>
          <div className="arrow-wrapper">
            <ArrowComponent
              isActive={lifecycleStages.indexOf(lifecycleStage) > 1}
              text={'Meeting Held'}
              handleArrowClick={() => handleOpenModal('meeting-held', true)}
            />
          </div>
          <div className="arrow-wrapper">
            <ArrowComponent
              closedColor={closedColor}
              isActive={lifecycleStages.indexOf(lifecycleStage) > 2}
              isEnd
              text={'Closed'}
              handleArrowClick={() => handleOpenModal('closed', true)}
            />
          </div>
        </div>
      </div>
      {latestStageActivity?.currentStage !== 'closed' && (
        <LeadActivityCard
          {...latestStageActivity}
          handleCheckboxClick={() => handleOpenModal(nextModalStage)}
          type="checkbox-stage"
        />
      )}
      <div className="lead-memorial">
        <div className="image-body">
          {leadMemorial?.image && (
            <div className="memorial-image">
              <img src={leadMemorial?.image} alt="" />
            </div>
          )}
          <div className="lead-memorial-content">
            <div className="name-dates">
              <a
                className="memorial-name"
                target="_blank"
                rel="noopener noreferrer"
                href={leadMemorial?.shareUrl}
              >{`${nameString(leadMemorial?.name)}`}</a>
              <div className="memorial-dates">
                <span className="highlighted">{`${dateForDesktop(
                  leadMemorial?.dateOfBirth
                )} - `}</span>
                <span className="highlighted">{dateForDesktop(leadMemorial?.dateOfDeath)}</span>
              </div>
            </div>
            <div className="intro-line">Memorial Associated to {nameString(name)}</div>
          </div>
        </div>
        <a
          className="view-button"
          target="_blank"
          rel="noopener noreferrer"
          href={leadMemorial?.shareUrl}
        >
          View
        </a>
      </div>
      <div className={stepHistoryClasses}>
        {!!activity &&
          activity
            .filter(
              activity =>
                latestStageActivity?.currentStage === 'closed' ||
                activity._id !== latestStageActivity._id
            )
            .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())
            .map(({ _id, type, createdAt, createdBy, message, currentStage, closeReason }) => (
              <LeadActivityCard
                key={_id}
                {...{ type, createdAt, createdBy, message, currentStage, closeReason }}
              />
            ))}
        <LeadActivityCard
          {...latestStageActivity}
          type="created"
          {...{
            createdAt,
            funeralHomeName,
            decedentName,
            memorials,
            isInformant,
            name,
            leadRelationship,
            isFamily,
            isCommunity
          }}
        />
      </div>
    </div>
  );
};

export default connect(({ dash }) => ({
  currentLead: dash.currentLead.data
}))(Timeline);
