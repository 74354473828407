import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import './LocationPhotosBody.scss';

const LocationPhotosBody = props => {
  const { change, form, model } = props;
  const { profileImage: formProfileImg, photos } = form;

  const handleImageClick = image => {
    change(`${model}.profileImage`, image);
  };

  return (
    <div className="location-photos-body">
      <h4>Add More Photos</h4>
      <div className="location-photos-image-upload-section">
        <ImageHandler
          currentImg={''}
          handleRemoveImage={() => {}}
          handleUploadFinish={url => {
            const homePhotos = [url, ...photos];

            change(`${model}.photos`, homePhotos);
          }}
          dropzoneSubtitle="Photo of home must be at least 300px by 200px"
          dropzoneMultiple={false}
        />
      </div>
      {!!photos.length && <h4>Select Default Photo</h4>}
      <div className="location-photos-image-select-section">
        {photos.map(photo => {
          const imageClasses = classNames({
            'background-photo': true,
            selected: photo === formProfileImg
          });

          return (
            <div
              key={photo}
              className={imageClasses}
              style={{
                backgroundImage: `url(${photo})`
              }}
              onClick={() => handleImageClick(photo)}
            >
              <div className="check-mark" />
              <div className="border-div" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default connect(null, { change: formActions.change })(LocationPhotosBody);
