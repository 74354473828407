import React from 'react';
import { nameString, createDateTimeString, formatPhoneNumber } from '@evdy/web-core/dist/lib/utils';
import moment from 'moment';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';

import LeadActivityCard from '../components/LeadActivityCard';

import './LeadListItem.scss';

const LeadListItem = props => {
  const { lead, onCheckboxClick, isQualified, renderToast, isSkeleton, allLocations } = props;

  const user = lead?.userRef;
  const userId = user?._id;

  const leadMemorial = lead?.memorials?.reverse().find(m => {
    const locationIds = allLocations.map(location => location._id) || [];
    return (m.funeralHome && locationIds.includes(m.funeralHome)) || lead?.memorials?.reverse()[0];
  });

  const tagClasses = x => classNames('tag', x);

  const Tag = ({ type = 'creator', children }) => (
    <div className={tagClasses(type)}>{children}</div>
  );

  const getTimeDifference = date => {
    const timeDif = Math.round(moment.duration(Date.now() - new Date(date).getTime()).asDays());
    if (timeDif === 1) {
      return `${timeDif} day`;
    } else {
      return `${timeDif} days`;
    }
  };

  const getInformantStatus = (memorial, status) => {
    return memorial?.accessors?.find(acc => {
      return acc[status] && acc?.userRef?.toString() === userId?.toString();
    });
  };

  const latestStageActivity = lead?.activity
    .filter(activity => activity.type === 'stage')
    .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0];

  const SkeletonLoader = () => (
    <ContentLoader
      height={100}
      width={460}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="8" y="6" rx="4" ry="4" width="117" height="6" />
      <rect x="8" y="20" rx="3" ry="4" width="80" height="6" />
      <rect x="300" y="6" rx="4" ry="4" width="50" height="6" />
      <rect x="300" y="20" rx="4" ry="4" width="50" height="6" />
      <rect x="72" y="50" rx="3" ry="4" width="300" height="6" />
      <rect x="72" y="64" rx="4" ry="4" width="80" height="6" />
      <rect x="15" y="45" rx="4" ry="4" width="40" height="40" />
    </ContentLoader>
  );

  return (
    <>
      {isSkeleton ? (
        <>
          <div className="lead-user-container">
            <SkeletonLoader />
          </div>
          <div className="lead-user-container">
            <SkeletonLoader />
          </div>
        </>
      ) : (
        <div className="lead-user-container">
          <div
            className="lead-information-container"
            onClick={
              isQualified ? () => browserHistory.push(`/dash/a/sales/${lead._id}`) : renderToast
            }
          >
            <div className="lead-user-information">
              <div className="user-name">{nameString(lead?.name)}</div>
              {isQualified && (
                <>
                  <div className="user-email">{lead?.email || ''}</div>
                  <div className="user-phone">{formatPhoneNumber(lead?.phone) || ''}</div>
                </>
              )}
            </div>
            {isQualified && (
              <div className="lead-stats">
                <div className="stat-wrapper">
                  <div className="stat-title">Last Activity</div>
                  <div className="stat-items-container">
                    <div className="stat-item">{createDateTimeString(lead.updatedAt)}</div>
                    <div className="stat-item">
                      @
                      {createDateTimeString(
                        lead.updatedAt,
                        { hour: 'numeric', minute: '2-digit' },
                        false
                      )}
                    </div>
                  </div>
                </div>
                <div className="stat-wrapper">
                  <div className="stat-title">Lead Added</div>
                  <div className="stat-items-container">
                    <div className="stat-item">
                      {getTimeDifference(lead.dateQualified || lead.createdAt)} ago
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="lead-tags">
              {/* relation (for relationship),  */}
              {getInformantStatus(leadMemorial, 'primarySurvivor') && (
                <Tag type="informant">Informant</Tag>
              )}
              {getInformantStatus(leadMemorial, 'relation') && (
                <Tag type="relation">{getInformantStatus(leadMemorial, 'relation').relation}</Tag>
              )}
              {getInformantStatus(leadMemorial, 'type') &&
                (['owner', 'manager'].includes(getInformantStatus(leadMemorial, 'type').type) ? (
                  <Tag type="family">Family</Tag>
                ) : (
                  <Tag type="community">Community</Tag>
                ))}
              {latestStageActivity?.currentStage === 'closed' && (
                <Tag type={latestStageActivity?.closeReason}>Closed</Tag>
              )}
            </div>
          </div>
          <div className="lead-agent-memorial">
            {lead?.afp && <div className="lead-afp">Agent: {nameString(lead?.afp?.name)}</div>}
            <div className="lead-memorial">
              Memorial:{' '}
              <a href={leadMemorial?.shareUrl} target="_blank">
                {nameString(leadMemorial?.name)}
              </a>
            </div>
          </div>
          {latestStageActivity && latestStageActivity?.currentStage !== 'closed' && isQualified && (
            <LeadActivityCard
              {...latestStageActivity}
              handleCheckboxClick={e => {
                e.stopPropagation();
                onCheckboxClick(lead, latestStageActivity);
              }}
              type="checkbox-stage"
            />
          )}
        </div>
      )}
    </>
  );
};

export default LeadListItem;
