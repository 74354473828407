import React from 'react';
import { Link } from 'react-router';

import DashCard from '../shared/DashCard';

import videoIcon from '@evdy/web-core/dist/images/shared/icons/video-icon.svg';
import glassesIcon from '@evdy/web-core/dist/images/shared/icons/glasses-icon.svg';

import './ResourcesColumn.scss';

const ResourcesColumn = ({ handleOpenVideoModal }) => {
  return (
    <div className="resources-column">
      <div className="resources-column-header">
        <h2>Helpful Resources</h2>
        <Link onlyActiveOnIndex={false} to="/dash/a/resource-center">
          View All
        </Link>
      </div>
      <DashCard customClass="resource-card" raised>
        <img src={glassesIcon} alt="Everdays Family Handouts" />
        <h3>Request New Everdays Materials</h3>
        <p>Everdays has changed and so has our family materials. Request your copies today.</p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeruHkJ2V-AjDmLHEv1NVIv9w-x29xwVqHW4vMrGUuN3KsgSw/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get PDFs
        </a>
      </DashCard>
      <DashCard customClass="resource-card" raised>
        <h3>Get in touch anytime</h3>
        <div className="resource-contact-links">
          <span>Call Us: (877) 400-3297</span>
          <button className="intercom-chat">Chat with us</button>
        </div>
      </DashCard>
    </div>
  );
};

export default ResourcesColumn;
