import React from 'react';

import { Form, InputGroup } from '@evdy/web-core/dist/components/shared/Input';

const Finish = props => {
  return (
    <div className="onboard-finish-step">
      <Form model="dash.onboarding.forms.finish">
        <div className="onboard-form-row">
          {/*<InputGroup*/}
          {/*  labelText="Subscribe to Community Builder Email"*/}
          {/*  model=".subscribe"*/}
          {/*  type="checkbox"*/}
          {/*/>*/}
        </div>
      </Form>
    </div>
  );
};

export default Finish;
