import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import EditCompanyCard from './components/EditCompanyCard';
import AdministratorsCard from './components/AdministratorsCard';

import { useScrollToTop, useURLParamSync } from '@evdy/web-core/dist/customHooks';

import './Company.scss';

const Company = ({ editCompany, fetchedCompany, members = [], isExternalMode, location }) => {
  const { isFetching } = editCompany;
  const { company } = fetchedCompany || {};
  const { data: fetchedCompanyData } = company || {};
  const { name, type, phone, website, address, logo, email, taxID, taxClassification } =
    fetchedCompanyData || {};

  // on mount, scroll to top of page
  useScrollToTop();

  const memoizedFiltersObj = useMemo(() => ({ isExternalMode }), [isExternalMode]);

  useURLParamSync(memoizedFiltersObj, location);

  return (
    <div className="company-page">
      <h1>
        Account: <strong>{name || '-'}</strong>
      </h1>
      <EditCompanyCard
        {...{ address, logo, name, phone, type, website, email, taxID, taxClassification }}
      />
      <AdministratorsCard />
      {isFetching && <LoaderComponent />}
    </div>
  );
};

export default connect(({ dash }) => ({
  editCompany: dash.editCompany,
  fetchedCompany: dash?.fetchedCompany,
  isExternalMode: dash.dashNav.isExternalMode
}))(Company);
