import React from 'react';
import moment from 'moment';

import { customCalendarFormatting, getStageTitle } from '../../../shared/utils';

import whiteCheckmark from '../../../images/icon-check-white.png';

import './LatestActivityModalHeader.scss';

const LatestActivityModalHeader = props => {
  const { currentStage, dueDate, attempt } = props;

  return (
    <div className="last-completed-activity">
      <div className="checkbox">
        <img src={whiteCheckmark} alt="white checkmark" />
      </div>
      <div className="activity-text">
        <p className="activity-title">
          {getStageTitle(currentStage)}
          {currentStage !== 'closed' && (
            <span>
              - Due: {moment.utc(dueDate).calendar(null, customCalendarFormatting) || 'Today'}
            </span>
          )}
        </p>
        <span>#{attempt} Attempt</span>
      </div>
    </div>
  );
};

export default LatestActivityModalHeader;
