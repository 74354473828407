import React from 'react';
import moment from 'moment';

import TruncatedText from '@evdy/web-core/dist/components/shared/elements/TruncatedText';

import DashCard from '../../../../shared/DashCard';

import { timeWithZoneOffset, formatAddress } from '@evdy/web-core/dist/lib/utils';
import Button from '@evdy/web-core/dist/components/Button';

import './ServiceCard.scss';

import emptyCal from '../EmptyEventRow/empty-cal.svg';

const ServiceCard = ({
  title = '',
  location = {},
  startDateISO = '',
  endDateISO = '',
  startTimeTBD = false,
  description = '',
  _id = '',
  videoStreamUrl = '',
  handleOpenEdit = () => {}
}) => {
  const isAllDayEvent = !!startTimeTBD;
  const dateWithYear = isAllDayEvent ? 'MMMM D, Y' : 'MMM D, YYYY - @h:mma';
  const startTimeTzOffset = timeWithZoneOffset(new Date(startDateISO));
  const endTimeTzOffset = timeWithZoneOffset(new Date(endDateISO));

  return (
    <DashCard raised customClass="event-page-event-card" clickHandler={() => handleOpenEdit(_id)}>
      <div className="event-details">
        <img className="empty-cal" src={emptyCal} alt="emtpy-calendar-icon" />
        <div className="title-and-edit-btn">
          <div className="event-info">
            <div className="event-title">{title}</div>
            <div className="date-and-time-wrapper">
              <div className="event-date-start-time">
                {moment(startTimeTzOffset).format(dateWithYear)}
              </div>
              {!!endDateISO && (
                <div className="end-time">{`- ${moment(endTimeTzOffset).format('h:mma')}`}</div>
              )}
            </div>
            {!!videoStreamUrl && <div className="live-stream-label">Live Stream Added</div>}
            {location?.name && <div className="event-name">{location.name}</div>}
            {location?.address && <div className="event-address">{formatAddress(location)}</div>}
          </div>
          <Button
            copy="Edit"
            action={() => handleOpenEdit(_id)}
            style="white-background-black-outline"
          />
        </div>
      </div>
      {!!description && (
        <TruncatedText
          truncatedClass="event-description"
          message={description}
          lines={2}
          disableReadMore
        />
      )}
    </DashCard>
  );
};

export default ServiceCard;
