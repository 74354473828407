import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import SubNav from '@evdy/web-core/dist/components/shared/elements/SubNav';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import PersonalInfoTab from './PersonalInfoTab';
import PasswordTab from './PasswordTab';
import DemoTab from './DemoTab';

import { useScrollToTop } from '@evdy/web-core/dist/customHooks';

import './Profile.scss';

const Profile = props => {
  const { editUser } = props;
  const subNav = useRef();

  useScrollToTop();

  const handleTabSwitch = () => {
    subNav.current.handleTabSelect(0);
  };

  const profileClasses = classNames('dash-content-container', 'profile-page');

  return (
    <div className={profileClasses}>
      <div className="title-container">
        <h1>Profile</h1>
      </div>
      <SubNav ref={subNav} tabs={['Profile', 'Change Password', 'Demo']}>
        <PersonalInfoTab />
        <PasswordTab {...{ handleTabSwitch }} />
        <DemoTab />
      </SubNav>
      {editUser?.isFetching && <LoaderComponent />}
    </div>
  );
};

export default connect(({ dash }) => ({
  editUser: dash.editUser
}))(Profile);
