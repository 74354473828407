import React from 'react';
import startCase from 'lodash-es/startCase';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { nameString } from '@evdy/web-core/dist/lib/utils';

import './MemberRow.scss';

const MemberRow = ({ email, image, name, title, handleEdit }) => {
  const userName = nameString(name);
  return (
    <div className="member-row">
      <div className="member-info-container">
        {!!image ? (
          <div className="member-img" style={{ backgroundImage: `url('${image}')` }} />
        ) : (
          <CharacterProfileImage {...{ image, name }} customClasses={['member-img']} />
        )}
        <div className="member-details">
          <span className="member-name">{userName}</span>
          <span className="member-email">{email || '---'}</span>
          <span className="member-title">{startCase(title) || '---'}</span>
        </div>
      </div>
      <button className="member-edit-button" onClick={handleEdit}>
        Edit
      </button>
    </div>
  );
};

export default MemberRow;
