export const ONBOARD_INIT_USER_REQUEST = 'ONBOARD_INIT_USER_REQUEST';
export const ONBOARD_INIT_USER_SUCCESS = 'ONBOARD_INIT_USER_SUCCESS';
export const ONBOARD_INIT_USER_FAILURE = 'ONBOARD_INIT_USER_FAILURE';

export const ONBOARD_CHECK_PHONE_REQUEST = 'ONBOARD_CHECK_PHONE_REQUEST';
export const ONBOARD_CHECK_PHONE_SUCCESS = 'ONBOARD_CHECK_PHONE_SUCCESS';
export const ONBOARD_CHECK_PHONE_FAILURE = 'ONBOARD_CHECK_PHONE_FAILURE';

export const ONBOARD_VERIFY_PHONE_REQUEST = 'ONBOARD_VERIFY_PHONE_REQUEST';
export const ONBOARD_VERIFY_PHONE_SUCCESS = 'ONBOARD_VERIFY_PHONE_SUCCESS';
export const ONBOARD_VERIFY_PHONE_FAILURE = 'ONBOARD_VERIFY_PHONE_FAILURE';

export const ONBOARD_ADD_EMAIL_REQUEST = 'ONBOARD_ADD_EMAIL_REQUEST';
export const ONBOARD_ADD_EMAIL_SUCCESS = 'ONBOARD_ADD_EMAIL_SUCCESS';
export const ONBOARD_ADD_EMAIL_FAILURE = 'ONBOARD_ADD_EMAIL_FAILURE';

export const ONBOARD_UPDATE_EMAIL_REQUEST = 'ONBOARD_UPDATE_EMAIL_REQUEST';
export const ONBOARD_UPDATE_EMAIL_SUCCESS = 'ONBOARD_UPDATE_EMAIL_SUCCESS';
export const ONBOARD_UPDATE_EMAIL_FAILURE = 'ONBOARD_UPDATE_EMAIL_FAILURE';

export const ONBOARD_CHECK_COMPANY_REQUEST = 'ONBOARD_CHECK_COMPANY_REQUEST';
export const ONBOARD_CHECK_COMPANY_SUCCESS = 'ONBOARD_CHECK_COMPANY_SUCCESS';
export const ONBOARD_CHECK_COMPANY_FAILURE = 'ONBOARD_CHECK_COMPANY_FAILURE';

export const ONBOARD_ADD_COMPANY_INFO_REQUEST = 'ONBOARD_ADD_COMPANY_INFO_REQUEST';
export const ONBOARD_ADD_COMPANY_INFO_SUCCESS = 'ONBOARD_ADD_COMPANY_INFO_SUCCESS';
export const ONBOARD_ADD_COMPANY_INFO_FAILURE = 'ONBOARD_ADD_COMPANY_INFO_FAILURE';

export const ONBOARD_FINISH_REQUEST = 'ONBOARD_FINISH_REQUEST';
export const ONBOARD_FINISH_SUCCESS = 'ONBOARD_FINISH_SUCCESS';
export const ONBOARD_FINISH_FAILURE = 'ONBOARD_FINISH_FAILURE';

export const ONBOARD_RESET_CHECK_PHONE = 'ONBOARD_RESET_CHECK_PHONE';
