import React from 'react';

const NoAccounts = ({ searchText, activeFilters }) => {
  const noResults = searchText?.length > 0 || !!activeFilters.length;

  return (
    <div className="no-accounts">
      <div className="no-accounts-desc">
        <h1>No Accounts or Locations found</h1>
        <p>What you're looking for may not exist or you may need to adjust your filters.</p>
      </div>
    </div>
  );
};

export default NoAccounts;
