import React from 'react';
import axios from 'axios/index';
import * as flat from 'flat';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { asYouType, isValidNumber } from 'libphonenumber-js';

import set from 'lodash-es/set';
import get from 'lodash-es/get';
import isArray from 'lodash-es/isArray';
import isFinite from 'lodash-es/isFinite';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import { Step, Stepper, StepButton } from 'material-ui/Stepper';

import IoChevronRight from 'react-icons/lib/io/chevron-right';
import IoChevronLeft from 'react-icons/lib/io/chevron-left';

import DashPage from '@evdy/web-core/dist/components/shared/elements/DashPage';
import PopupDialog from '@evdy/web-core/dist/components/shared/elements/PopupDialog';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import AddCompanyInfo from '@evdy/web-core/dist/components/companyRegistration/AddCompanyInfo';

import AddUsers from './AddUsers';
import ReviewSubmit from './ReviewSubmit';
import AddFuneralHomes from './AddFuneralHomes';
import RegistrationStart from './RegistrationStart';
import SetupPasswordAdmin from './SetupPasswordAdmin';

import transAxiosErr from '@evdy/web-etc/dist/lib/transformAxiosError';
import { API_URL } from '@evdy/web-redux/dist/constants';
import { googleAnalytics } from '@evdy/web-core/dist/lib/utils';
import {
  submitAddCompanyWithMisc,
  resetError
} from '@evdy/web-redux/dist/actions/dash/addCompanyWithMisc';
import { setStepperPage } from '@evdy/web-redux/dist/actions/dash/SetStepperPage';

const instance = axios.create({
  baseURL: API_URL
});

const emailInUse = 'This email is already in use.';
const phoneInUse = 'This phone number is already in use.';

require('@evdy/web-core/dist/sass/components/shared/DashStepperStyles.css');
require('@evdy/web-core/dist/sass/components/shared/DashLiftIntercom.css');
require('@evdy/web-core/dist/sass/components/shared/DashFormStyles.css');

const classNames = require('classnames');

const getTruthies = obj => Object.values(flat(obj)).filter(Boolean);

const stepperSteps = [
  'Your Profile',
  'Account Profile',
  'Add Locations',
  'Add Users',
  'Preview',
  'Finish'
];

const getInitObj = {
  funeralHomes(query) {
    return {
      profileImage: query ? query.FHImage : '',
      name: query ? query.FHName : '',
      address: {
        street: query ? query.FHStreet : '',
        city: query ? query.FHCity : '',
        state: query ? query.FHState : '',
        zip: query ? query.FHZip : ''
      },
      email: query ? query.email : '',
      casesPerYear: query ? query.FHCasesPerYear : '',
      phone: query ? query.phone : '',
      websiteUrl: query ? query.FHWeb : ''
    };
  },
  users() {
    return {
      image: '',
      name: {
        first: '',
        last: ''
      },
      email: '',
      title: '',
      phone: '',
      isCompanyAdmin: false
    };
  }
};

const errorMessages = {
  userSignup: {
    password: 'Enter a password with at least 6 characters.',
    passwordConfirmation: 'Your passwords do not match.',
    email: 'Please enter a valid email to continue.',
    phone: 'A valid phone number is required.',
    name: {
      first: 'First name required.',
      last: 'Last name required.'
    }
  },
  companySignup: {
    name: 'An account name is required.',
    address: {
      street1: 'Please enter a street address.',
      city: 'Please enter a city.',
      state: 'Please enter a state.',
      zip: 'Zip code required.'
    },
    phone: 'An account phone number is required.',
    website: 'Please enter account website.'
  },
  funeralHomes: {
    name: 'A funeral home name is required.',
    address: {
      street: 'Please enter a street address.',
      city: 'A city is required.',
      state: 'A state is required.',
      zip: 'ZIP code is required.'
    },
    phone: 'Funeral home phone number is required.',
    websiteUrl: 'Please enter funeral home’s website.'
  },
  users: {
    name: {
      first: 'User’s first name is required.',
      last: 'User’s last name is required.'
    },
    email: 'Please enter a valid email to continue.',
    phone: 'Please enter a phone number for this user.'
  }
};

class Registration extends React.Component {
  constructor(props) {
    super(props);

    const defaultQuery = {
      nameFirst: '',
      nameLast: '',
      company: '',
      title: '',
      email: '',
      phone: '',
      websiteUrl: '',
      casesPerYear: '',
      software: '',
      referral: '',
      crmRef: ''
    };

    const query = Object.assign({}, defaultQuery, props.location.query);

    this.state = {
      validInputs: {
        userSignup: {
          name: {
            first: !!query.nameFirst,
            last: !!query.nameLast
          },
          phone: !!query.phone,
          email: !!query.email,
          title: !!query.title
        },
        companySignup: {
          name: !!query.company,
          phone: !!query.phone,
          website: !!query.websiteUrl,
          numberOfLocations: !!query.numberOfLocations
        },
        funeralHomes: [{}],
        users: [{}]
      },
      errorText: {
        userSignup: {},
        companySignup: {},
        funeralHomes: [{}],
        users: [{}]
      },
      userSignup: {
        name: {
          first: query.nameFirst,
          last: query.nameLast
        },
        phone: query.phone,
        email: '',
        title: query.title,
        crmRef: query.crmRef,
        image: '',
        password: '',
        passwordConfirmation: '',
        isCompanyAdmin: true
      },
      companySignup: {
        name: query.company,
        phone: query.phone,
        website: query.websiteUrl,
        numberOfLocations: query.numberOfLocations,
        address: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: ''
        },
        logo: ''
      },
      funeralHomes: [getInitObj.funeralHomes(query)],
      users: [getInitObj.users()],
      stepIndex: 0,
      validPages: [],
      usersIndex: 0,
      funeralHomeIndex: 0,
      finished: false,
      showDeleteConfirmation: false,
      typeToDelete: ''
    };

    this.startRegistration = this.startRegistration.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.passRequiredFields = this.passRequiredFields.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.jumpToStep = this.jumpToStep.bind(this);
    this.markPageComplete = this.markPageComplete.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.getInputClass = this.getInputClass.bind(this);
    this.validate = this.validate.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validatePage = this.validatePage.bind(this);
    this.isPageValid = this.isPageValid.bind(this);
    this.prependProtocol = this.prependProtocol.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.resetStepper = this.resetStepper.bind(this);
    this.addRepeaterRow = this.addRepeaterRow.bind(this);
    this.delRepeaterRow = this.delRepeaterRow.bind(this);
    this.checkRepeaterStatus = this.checkRepeaterStatus.bind(this);
    this.getErrorText = this.getErrorText.bind(this);
    this.errorDialog = this.errorDialog.bind(this);
    this.resetError = this.resetError.bind(this);
    this.successDialog = this.successDialog.bind(this);
    this.phoneConfirmationDialog = this.phoneConfirmationDialog.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteDialog = this.deleteDialog.bind(this);
    this.clearDeleteDialog = this.clearDeleteDialog.bind(this);
    this.handleDeleteRowAndDecrement = this.handleDeleteRowAndDecrement.bind(this);
    this.handleSetRowIdx = this.handleSetRowIdx.bind(this);

    this.cropProfileImage = this.cropProfileImage.bind(this);
    this.cropCompanyImage = this.cropCompanyImage.bind(this);
    this.cropLocationImage = this.cropLocationImage.bind(this);
    this.cropUserImage = this.cropUserImage.bind(this);
  }

  static makeFakeEvent(value) {
    return { target: { value, validity: { valid: true } } };
  }

  componentDidMount() {
    const { email, phone } = this.props.location.query;
    const emailEvent = Registration.makeFakeEvent(email);
    const updateEmail = () => this.handleEmailChange('email', 'userSignup')(emailEvent);
    if (phone) {
      const phoneEvent = Registration.makeFakeEvent(phone.replace('+1', ''));
      this.handlePhoneChange('phone', 'userSignup', null, true)(phoneEvent, updateEmail);
    } else if (email) {
      updateEmail();
    }
    document.body.classList.add('stepper');
  }

  componentWillUnmount() {
    document.body.classList.remove('stepper');
  }

  resetError() {
    this.props.actions.resetError();
  }

  errorDialog({ http_code, code, message, original }) {
    let body;
    if (original && original.length && http_code === 400 && (code === 108 || code === 109)) {
      body = (
        <div>
          <p>{message}</p>
          <ul className="error-list">
            {original.map((reason, idx) => (
              <li key={idx}>
                <span>{reason}</span>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      body = message;
    }
    return (
      <PopupDialog
        title="Registration Problem"
        actions={[{ label: 'Try Again', onClick: this.resetError, primary: true }]}
        actAsExpander={!!original}
        showExpandableButton={!!original}
        stackTrace={original && original.stack}
      >
        {body}
      </PopupDialog>
    );
  }

  phoneConfirmationDialog(phone) {
    return (
      <PopupDialog
        title="User Upgrade"
        actions={[
          {
            label: 'OK',
            onClick: () => this.setState({ dupePhoneError: undefined }),
            primary: true
          }
        ]}
      >
        The phone number <strong>{phone}</strong> currently belongs to a user that has the Everdays
        app. If this phone number belongs to the user you are trying to add, we will upgrade them to
        an Everdays Dashboard user.
      </PopupDialog>
    );
  }

  successDialog() {
    const title = (
      <div>
        <div className="rocket-emoji" />
        Great job - Welcome to Everdays!
      </div>
    );
    return (
      <PopupDialog
        title={title}
        actions={[
          {
            label: 'Lets Go!',
            primary: true,
            onClick: () => browserHistory.push('/dash/a/announcements')
          }
        ]}
      >
        You are now setup and ready to start truly providing Everdays for your client families.
        Please contact us at anytime with questions.
      </PopupDialog>
    );
  }

  deleteDialog() {
    const typeToDeleteText = this.state.typeToDelete === 'funeralHomes' ? 'Funeral Home' : 'User';
    const typeToDeletePronoun = this.state.typeToDelete === 'funeralHomes' ? 'it' : 'them';
    const title = (
      <div>
        <div className="no-entry-sign" />
        DELETING IS PERMANENT
      </div>
    );
    return (
      <PopupDialog
        title={title}
        actions={[
          {
            label: 'Cancel',
            onClick: this.clearDeleteDialog
          },
          {
            label: 'Yes, Delete',
            primary: true,
            onClick: this.handleDeleteRowAndDecrement
          }
        ]}
      >
        Deleting a {typeToDeleteText} element will remove {typeToDeletePronoun} permanently. You
        will only be able to add {typeToDeletePronoun} back to your account by contacting us. Are
        you sure you'd like to delete {typeToDeletePronoun}?
      </PopupDialog>
    );
  }

  clearDeleteDialog() {
    this.setState({ showDeleteConfirmation: false, typeToDelete: '' });
  }

  setTypeAndDelete(type) {
    return this.setState({ typeToDelete: type }, this.handleDeleteRowAndDecrement);
  }

  handleDelete(type) {
    const { stepIndex, funeralHomes, funeralHomeIndex, users, usersIndex } = this.state;

    switch (stepIndex) {
      case 2: // locations
        const home = { ...funeralHomes[funeralHomeIndex] };
        delete home.email;
        if (funeralHomeIndex > 0 && getTruthies(home).length === 0) {
          return this.setTypeAndDelete(type);
        }
        break;

      case 3: // users
        if (usersIndex > 0 && getTruthies(users[usersIndex]).length === 0) {
          return this.setTypeAndDelete(type);
        }
        break;
    }

    this.setState({ showDeleteConfirmation: true, typeToDelete: type });
  }

  handleDeleteRowAndDecrement(nextprev) {
    const { typeToDelete } = this.state;
    const idx =
      this.state.typeToDelete === 'funeralHomes'
        ? this.state.funeralHomeIndex
        : this.state.usersIndex;
    const newIdx = idx === 0 ? 0 : idx - 1;

    this.handleSetRowIdx(
      newIdx,
      'deleteIndicator',
      typeToDelete
    )(() => this.delRepeaterRow(typeToDelete)(idx, nextprev));
    this.setState({ showDeleteConfirmation: false, typeToDelete: '' });
    this.scrollToTop();
  }

  handleAddRow(type) {
    if (this.validatePage()) {
      this.addRepeaterRow(type)(() =>
        this.handleSetRowIdx(this.state[type].length - 1, '', type)()
      );
      this.scrollToTop();
    }
  }

  handleSetRowIdx(idx, deleteIndicator, type) {
    let nextState = {};
    if (type === 'funeralHomes') {
      nextState = { funeralHomeIndex: idx };
    } else {
      nextState = { usersIndex: idx };
    }

    return cb => {
      if (typeof cb !== 'function') cb = undefined;
      if (deleteIndicator || this.validatePage()) {
        this.setState(nextState, cb);
      }
    };
  }

  startRegistration() {
    this.props.actions.setStepperPage(stepperSteps[this.state.stepIndex], this.state.stepIndex + 1);
    this.setState({ started: true });
  }

  onFirstPage() {
    return this.state.stepIndex === 0;
  }

  validatePage(_idx) {
    const currentTab = this.state.stepIndex;
    const { validInputs } = this.state;
    let stateObject;
    const errorText = { ...this.state.errorText };
    switch (currentTab) {
      case 0:
        stateObject = 'userSignup';
        break;
      case 1:
        stateObject = 'companySignup';
        break;
      case 2:
        stateObject = 'funeralHomes';
        break;
      case 3:
        stateObject = 'users';
        break;
      default:
        stateObject = '';
    }

    let valid = true;
    const arrayOfRequiredKeys = this.passRequiredFields();

    arrayOfRequiredKeys.forEach(_key => {
      let key;
      if (stateObject === 'funeralHomes') {
        const idx = isFinite(_idx) ? _idx : this.state.funeralHomeIndex;
        key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      } else if (stateObject === 'users') {
        const idx = isFinite(_idx) ? _idx : this.state.usersIndex;
        key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      } else {
        key = _key;
      }

      if (get(validInputs[stateObject], key)) {
        return;
      }
      valid = false;
      set(errorText[stateObject], key, get(errorMessages[stateObject], _key));
    });

    this.setState({ errorText });
    return valid;
  }

  isPageValid(_idx) {
    const currentTab = this.state.stepIndex;
    const { validInputs } = this.state;
    let stateObject;
    const errorText = { ...this.state.errorText };
    switch (currentTab) {
      case 0:
        stateObject = 'userSignup';
        break;
      case 1:
        stateObject = 'companySignup';
        break;
      case 2:
        stateObject = 'funeralHomes';
        break;
      case 3:
        stateObject = 'users';
        break;
      default:
        stateObject = '';
    }

    let valid = true;
    const arrayOfRequiredKeys = this.passRequiredFields();

    arrayOfRequiredKeys.forEach(_key => {
      let key;
      if (stateObject === 'funeralHomes') {
        const idx = isFinite(_idx) ? _idx : this.state.funeralHomeIndex;
        key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      } else if (stateObject === 'users') {
        const idx = isFinite(_idx) ? _idx : this.state.usersIndex;
        key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      } else {
        key = _key;
      }

      if (get(validInputs[stateObject], key)) {
        return;
      }
      valid = false;
    });
    return valid;
  }

  handleFinish() {
    const data = {
      users: [this.state.userSignup, ...this.state.users],
      company: this.state.companySignup,
      funeralHomes: this.state.funeralHomes
    };
    data.company.contactName = this.state.userSignup.name;
    this.props.actions.submitAddCompanyWithMisc(data);
  }

  markPageComplete() {
    const { stepIndex } = this.state;
    if (this.state.validPages.indexOf(stepIndex) === -1 && stepIndex !== 4) {
      this.state.validPages.push(stepIndex);
    }
  }

  passRequiredFields() {
    const { stepIndex } = this.state;
    let requiredFields;
    switch (stepIndex) {
      case 0:
        requiredFields = [
          'password',
          'passwordConfirmation',
          'email',
          'phone',
          'name.first',
          'name.last'
        ];
        break;
      case 1:
        requiredFields = [
          'name',
          'address.street1',
          'address.city',
          'address.state',
          'address.zip',
          'phone',
          'website'
        ];
        break;
      case 2:
        requiredFields = [
          'name',
          'address.street',
          'address.city',
          'address.state',
          'address.zip',
          'phone',
          'websiteUrl'
        ];
        break;
      case 3:
        requiredFields = ['name.first', 'name.last', 'email', 'phone'];
        break;
      default:
        requiredFields = [];
    }
    return requiredFields;
  }

  scrollToTop() {
    if (this.dashContainer) {
      this.dashContainer.scrollToTop();
    }
  }

  handleNext = skipValidation => {
    const { stepIndex } = this.state;
    const { setStepperPage } = this.props.actions;
    const nextIdx = stepIndex + 1;

    if (skipValidation === 'pass') {
      setStepperPage(stepperSteps[nextIdx], nextIdx + 1);
      return;
    }

    if (skipValidation) {
      this.markPageComplete();
      this.setState({
        stepIndex: nextIdx,
        finished: stepIndex >= 4
      });
      setStepperPage(stepperSteps[nextIdx], nextIdx + 1);
      this.scrollToTop();
      return;
    }

    if (!this.validatePage()) {
      return false;
    } else {
      this.markPageComplete();
      setStepperPage(stepperSteps[nextIdx], nextIdx + 1);
      this.setState({
        stepIndex: nextIdx,
        finished: stepIndex >= 4
      });
      this.scrollToTop();
    }
  };

  handlePrev = skipValidation => {
    const { stepIndex, validPages } = this.state;
    const indexOfPage = validPages.indexOf(stepIndex);
    const { setStepperPage } = this.props.actions;
    const nextIdx = stepIndex - 1;
    setStepperPage(stepperSteps[nextIdx], nextIdx + 1);

    if (skipValidation === 'pass') {
      return;
    }

    if (!skipValidation) {
      if (this.validatePage()) {
        this.markPageComplete();
      } else if (indexOfPage !== -1) {
        validPages.splice(indexOfPage, 1);
      }
    }

    this.setState({ loading: false, stepIndex: nextIdx });
    this.scrollToTop();
  };

  jumpToStep = (num, skipValidation) => () => {
    const { stepIndex, validPages } = this.state;
    const indexOfPage = validPages.indexOf(stepIndex);

    if (skipValidation === 'pass') {
      return;
    }

    if (skipValidation) {
      this.setState({ stepIndex: num });
      this.scrollToTop();
      return;
    }

    if (num < this.state.stepIndex) {
      if (this.validatePage()) {
        this.markPageComplete();
      } else if (indexOfPage !== -1) {
        validPages.splice(indexOfPage, 1);
      }
      this.setState({ stepIndex: num });
      return;
    }

    if (this.validatePage()) {
      this.setState({ stepIndex: num });
      this.scrollToTop();
    }
  };

  validatePassword(validInputs) {
    const { password, passwordConfirmation } = this.state.userSignup;

    const passKey = 'userSignup.password';
    const passConfirmKey = 'userSignup.passwordConfirmation';

    set(validInputs, passKey, !!password && password.length > 5);
    set(validInputs, passConfirmKey, password && password === passwordConfirmation);
  }

  validate(el, stateObject, key, resetError) {
    const nextState = { validInputs: { ...this.state.validInputs } };
    const comboKey = `${stateObject}.${key}`;

    if (key === 'password' || key === 'passwordConfirmation') {
      this.validatePassword(nextState.validInputs);
    } else {
      set(nextState.validInputs, comboKey, el.validity.valid);
    }

    if (resetError || (el.validity.valid && get(this.state.errorText, comboKey))) {
      nextState.errorText = { ...this.state.errorText };
      set(nextState.errorText, comboKey, '');
    }

    this.setState(nextState);
  }

  getInputClass(key) {
    return classNames({
      'form-control': true,
      valid: get(this.state.validInputs, key),
      invalid: get(this.state.errorText, key)
    });
  }

  getErrorText(stateObj, key) {
    return get(this.state.errorText, `${stateObj}.${key}`);
  }

  prependProtocol(stateObjectRef, key) {
    return ({ target: el }) => {
      if (el.value && !/^http/.test(el.value)) {
        el.value = 'http://' + el.value;
        const stateObj = this.state[stateObjectRef];
        const copy = isArray(stateObj) ? [...stateObj] : { ...stateObj };
        const nextState = { [stateObjectRef]: set(copy, key, el.value) };
        this.setState(nextState);
        this.validate(el, stateObjectRef, key);
      }
    };
  }

  handleInputChange(_key, stateObject, idx, skipValidate) {
    return event => {
      const key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      const { target } = event;
      let { value, checked } = target;

      if (target.type === 'checkbox') {
        value = !!checked;
      }

      const oldStateObject = this.state[stateObject];
      const newStateObject = isArray(oldStateObject) ? [...oldStateObject] : { ...oldStateObject };

      this.setState({ [stateObject]: set(newStateObject, key, value) }, () => {
        if (skipValidate) {
          return;
        }
        this.validate(target, stateObject, key);
      });
    };
  }

  setDupeEmailMessage(stateObject, key) {
    const validInputs = { ...this.state.validInputs };
    const errorText = { ...this.state.errorText };

    set(validInputs[stateObject], key, false);
    set(errorText[stateObject], key, emailInUse);

    this.setState({ validInputs, errorText });
  }

  handleEmailChange(_key, stateObject, idx) {
    return event => {
      const key = isFinite(idx) ? `[${idx}].${_key}` : _key;
      const { target } = event;
      let { value } = target;

      const oldStateObject = this.state[stateObject];
      const newStateObject = isArray(oldStateObject) ? [...oldStateObject] : { ...oldStateObject };

      let skipValidation;

      if (target.validity.valid) {
        const { userSignup, users } = this.state;
        const allEmails = [userSignup.email, ...users.map(u => u.email)];

        if (allEmails.find(e => e === value)) {
          skipValidation = true;
          this.setDupeEmailMessage(stateObject, key);
        } else {
          clearTimeout(this.checkEmailTimeout);
          this.checkEmailTimeout = setTimeout(
            () =>
              instance
                .get(`/public/check-email/${value}`, { params: { checkHasPass: true } })
                .then(res => {
                  const { exists, isDash } = res.data.data;
                  if (exists && isDash) {
                    skipValidation = true;
                    this.setDupeEmailMessage(stateObject, key);
                  }
                })
                .catch(error => console.error(transAxiosErr(error))),
            500
          );
        }
      }

      this.setState({ [stateObject]: set(newStateObject, key, value) }, () => {
        if (!skipValidation) {
          this.validate(target, stateObject, key, true);
        }
      });
    };
  }

  static setDupePhoneMessage(stateObject, key, validInputs, errorText) {
    set(validInputs[stateObject], key, false);
    set(errorText[stateObject], key, phoneInUse);
  }

  handlePhoneChange(_key, stateObject, idx, checkDuplicate) {
    return ({ target: { value } }, cb) => {
      const key = isFinite(idx) ? `[${idx}].${_key}` : _key;

      const { userSignup, users, funeralHomes } = this.state;
      const allPhones =
        stateObject === 'funeralHomes'
          ? funeralHomes.map(fh => fh.phone)
          : [userSignup.phone, ...users.map(u => u.phone)];

      const oldStateObject = this.state[stateObject];
      const newStateObject = isArray(oldStateObject) ? [...oldStateObject] : { ...oldStateObject };

      const validInputs = { ...this.state.validInputs };
      const errorText = { ...this.state.errorText };

      if (/^\(?\d{0,3}\)?$/.test(value)) {
        set(newStateObject, key, value);
      } else {
        // noinspection JSPotentiallyInvalidConstructorUsage
        value = new asYouType('US').input(value);
        set(newStateObject, key, value);
      }

      const isValid = isValidNumber(value, 'US') || isValidNumber(value, 'CA');
      set(validInputs[stateObject], key, isValid);

      if (isValid) {
        set(errorText[stateObject], key, '');
        if (checkDuplicate && allPhones.find(e => e === value)) {
          Registration.setDupePhoneMessage(stateObject, key, validInputs, errorText);
        } else if (checkDuplicate) {
          clearTimeout(this.checkPhoneTimeout);
          this.checkPhoneTimeout = setTimeout(
            () =>
              instance
                .get(`/public/check-phone/${value}`, { params: { checkHasPass: true } })
                .then(res => {
                  const { exists, isDash } = res.data.data;
                  if (exists && isDash) {
                    const { validInputs, errorText } = this.state;
                    Registration.setDupePhoneMessage(stateObject, key, validInputs, errorText);
                    this.setState({ validInputs, errorText });
                  } else if (exists) {
                    this.setState({ dupePhoneError: value });
                  }
                })
                .catch(error => console.error(transAxiosErr(error))),
            500
          );
        }
      }

      if (typeof cb !== 'function') {
        cb = () => {};
      }

      this.setState({ [stateObject]: newStateObject, validInputs, errorText }, cb);
    };
  }

  getValue(_key, stateObject, idx) {
    const key = isFinite(idx) ? `[${idx}].${_key}` : _key;

    if (/\[\d+]\.isCompanyAdmin/.test(key)) {
      return get(this.state[stateObject], key) || false;
    } else {
      return get(this.state[stateObject], key) || '';
    }
  }

  addRepeaterRow(key) {
    return cb => {
      const nextState = {
        [key]: [...this.state[key], getInitObj[key](this.state.userSignup)],
        validInputs: { ...this.state.validInputs },
        errorText: { ...this.state.errorText }
      };

      nextState.validInputs[key].push({});
      nextState.errorText[key].push({});

      this.setState(nextState, cb);
    };
  }

  delRepeaterRow(key) {
    const { stepIndex } = this.state;

    return (idx, destination, cb) => {
      const rows = [...this.state[key]];
      const validInputs = { ...this.state.validInputs };
      const errorText = { ...this.state.errorText };

      rows.splice(idx, 1);
      validInputs[key].splice(idx, 1);
      errorText[key].splice(idx, 1);

      if (typeof cb !== 'function') {
        cb = undefined;
      }

      if (typeof destination !== 'string' && !isFinite(destination)) {
        destination = undefined;
      }

      this.setState({ [key]: rows, validInputs, errorText }, () => {
        // Ia a page jump
        if (isFinite(destination)) {
          this.jumpToStep(destination)();
        } else if (destination === 'next') {
          this.markPageComplete();
          this.setState({
            stepIndex: stepIndex + 1,
            finished: stepIndex >= 4
          });
          this.scrollToTop();
        } else if (destination === 'prev') {
          this.markPageComplete();
          this.setState({
            loading: false,
            stepIndex: stepIndex - 1
          });
          this.scrollToTop();
        }
        if (cb) cb();
      });
    };
  }

  deleteAndGo(destination, jumpTo) {
    return () => {
      if (destination === 'jump') {
        this.pageContainer.deleteRowAndDecrement(jumpTo);
      } else {
        this.pageContainer.deleteRowAndDecrement(destination);
      }
      return 'pass';
    };
  }

  checkRepeaterStatus(destination, jumpTo) {
    const deleteAndGo = this.deleteAndGo(destination, jumpTo);
    const { stepIndex, users, usersIndex, funeralHomes, funeralHomeIndex } = this.state;

    if (stepIndex === 3) {
      // users repeater
      const truthyValues = getTruthies(users[usersIndex]);
      if (usersIndex > 0 && truthyValues.length <= 0) {
        return deleteAndGo();
      } else {
        return destination === 'prev' || jumpTo < 1 || !(truthyValues.length > 0);
      }
    } else if (stepIndex === 2) {
      // funeral homes repeater
      const truthyValues = getTruthies(funeralHomes[funeralHomeIndex]);
      if (funeralHomeIndex > 0 && truthyValues.length <= 1) {
        // will often be 1 because of autofilling email
        return deleteAndGo();
      } else if (destination === 'prev' || jumpTo < 2) {
        return true;
      }
    } else {
      return false;
    }
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <SetupPasswordAdmin
            getInputClass={this.getInputClass}
            validate={this.validate}
            handleInputChange={this.handleInputChange}
            handleEmailChange={this.handleEmailChange}
            handlePhoneChange={this.handlePhoneChange}
            getValue={this.getValue}
            getErrorText={this.getErrorText}
            image={this.state.userSignup.image}
            setCroppedImage={this.cropProfileImage}
          />
        );
      case 1:
        return (
          <DashPage
            customClass="stepper-page"
            headerText="Add Account/Corporate Information"
            ref={el => {
              this.dashContainer = el;
            }}
            subHeaderText={
              <p>
                Enter your account/corporate information; you will be able to enter information for
                individual homes on the following pages.
              </p>
            }
          >
            <AddCompanyInfo
              getInputClass={this.getInputClass}
              handleInputChange={this.handleInputChange}
              handlePhoneChange={this.handlePhoneChange}
              getValue={this.getValue}
              prependProtocol={this.prependProtocol}
              getErrorText={this.getErrorText}
              image={this.state.companySignup.logo}
              setCroppedImage={this.cropCompanyImage}
              analyticsPrefix="company-registration-companyinfo"
            />
          </DashPage>
        );
      case 2:
        return (
          <DashPage
            customClass="stepper-page repeater"
            headerText="Add Each Of Your Funeral Home Locations"
            ref={el => {
              this.dashContainer = el;
            }}
            subHeaderText={
              <p>
                Adding all of your locations lets your team easily create Memorials for any
                location. It also allows families to search for all of your locations. This
                information can be updated or edited at any time. &nbsp; (If you have more than five
                homes, please feel free to contact us and we will add all of them for you.)
              </p>
            }
          >
            <AddFuneralHomes
              getInputClass={this.getInputClass}
              handleInputChange={this.handleInputChange}
              handlePhoneChange={this.handlePhoneChange}
              getValue={this.getValue}
              prependProtocol={this.prependProtocol}
              getErrorText={this.getErrorText}
              rows={this.state.funeralHomes}
              delRow={this.delRepeaterRow('funeralHomes')}
              handleNext={this.handleNext}
              checkRepeaterStatus={this.checkRepeaterStatus}
              scrollToTop={this.scrollToTop}
              ref={el => {
                this.pageContainer = el;
              }}
              funeralHomeIdx={this.state.funeralHomeIndex}
              handleAddRow={this.handleAddRow}
              handleSetRow={this.handleSetRowIdx}
              handleDelete={this.handleDelete}
              validatePage={this.validatePage}
              isPageValid={this.isPageValid}
              setCroppedImage={this.cropLocationImage}
              analyticsPrefix="company-registration-addlocations"
            />
          </DashPage>
        );
      case 3:
        return (
          <DashPage
            customClass="stepper-page repeater"
            headerText="Add Authorized Users and Additional Admins"
            ref={el => {
              this.dashContainer = el;
            }}
            subHeaderText={
              <p>
                Add directors and other staff members you'd like to authorize to create memorial
                Invites for your client families. We'll send them an email with instructions to set
                up their account. The more users and admins your home has, the easier it will be for
                you to manage Invites. You can add or edit this list at any time. &nbsp; (If you'd
                like to add more than five users or admins, please feel free to contact us and we'll
                take care of them for you.)
              </p>
            }
          >
            <AddUsers
              getInputClass={this.getInputClass}
              handleInputChange={this.handleInputChange}
              handleEmailChange={this.handleEmailChange}
              handlePhoneChange={this.handlePhoneChange}
              getValue={this.getValue}
              getErrorText={this.getErrorText}
              rows={this.state.users}
              delRow={this.delRepeaterRow('users')}
              handleNext={this.handleNext}
              checkRepeaterStatus={this.checkRepeaterStatus}
              scrollToTop={this.scrollToTop}
              ref={el => {
                this.pageContainer = el;
              }}
              userIdx={this.state.usersIndex}
              handleAddRow={this.handleAddRow}
              handleSetRow={this.handleSetRowIdx}
              handleDelete={this.handleDelete}
              validatePage={this.validatePage}
              isPageValid={this.isPageValid}
              setCroppedImage={this.cropUserImage}
              analyticsPrefix="company-registration-addusers"
            />
          </DashPage>
        );
      case 4:
        return (
          <ReviewSubmit
            userInfo={this.state.userSignup}
            companyInfo={this.state.companySignup}
            users={this.state.users}
            funeralHomes={this.state.funeralHomes}
            jumpToStep={this.jumpToStep}
          />
        );
      default:
        return '';
    }
  }

  resetStepper(event) {
    event.preventDefault();
    this.setState({ stepIndex: 0, finished: false });
  }

  cropProfileImage(img) {
    const croppedProfile = { ...this.state.userSignup, image: img };
    this.setState({ userSignup: croppedProfile });
  }

  cropCompanyImage(img) {
    const croppedCompany = { ...this.state.companySignup, logo: img };
    this.setState({ companySignup: croppedCompany });
  }

  cropLocationImage(img) {
    const croppedLocations = [...this.state.funeralHomes];
    croppedLocations[this.state.funeralHomeIndex].profileImage = img;
    this.setState({ funeralHomes: croppedLocations });
  }

  cropUserImage(img) {
    const croppedUsers = [...this.state.users];
    croppedUsers[this.state.usersIndex].image = img;
    this.setState({ users: croppedUsers });
  }

  renderContent() {
    const { finished, stepIndex } = this.state;

    if (finished) {
      return (
        <div className="stepper-inner-container">
          <p>
            <a href="#" onClick={this.resetStepper}>
              {' '}
              Click here{' '}
            </a>{' '}
            to reset.{' '}
          </p>
        </div>
      );
    }

    return <div className="stepper-inner-container">{this.getStepContent(stepIndex)}</div>;
  }

  render() {
    const { isFetching, error, saveSuccess } = this.props.dash.addCompanyWithMisc;
    if (!this.state.started) {
      const userFirstName = this.props.location.query.nameFirst;
      return (
        <RegistrationStart firstName={userFirstName} startRegistration={this.startRegistration} />
      );
    } else {
      const { stepIndex, validPages, dupePhoneError } = this.state;

      const { size } = this.props.dash.recordPageResize;
      const mobileSize = size && size <= 938;

      const getStepClasses = step => {
        return classNames({
          'step-button': true,
          active: stepIndex === step,
          completed: stepIndex !== step && validPages.indexOf(step) >= 0
        });
      };

      return (
        <div className="stepper-container" id="stepper-container">
          <div className="stepper-top-content" open={true}>
            {this.renderContent()}
          </div>
          {mobileSize && (
            <div className="stepper-progress-bar-container">
              <div
                className="stepper-progress-bar"
                style={{ width: `${(validPages.length / (stepperSteps.length - 1)) * 100}%` }}
              />
            </div>
          )}
          <div className="stepper-line-and-buttons">
            <FlatButton
              label={mobileSize ? <IoChevronLeft /> : 'Back'}
              className="stepper-progress-button"
              disabled={this.onFirstPage()}
              onClick={e => {
                this.handlePrev(this.checkRepeaterStatus('prev'));
                googleAnalytics('company-registration-stepper-back')(e);
              }}
              style={{ marginRight: 12 }}
              labelStyle={{ textTransform: 'none' }}
            />
            <div className="stepper-step-line">
              {mobileSize ? (
                <Stepper linear={false} className="mobile-stepper">
                  <Step className="mobile-step">
                    <StepButton
                      onClick={() =>
                        stepIndex === 4
                          ? this.handleFinish()
                          : this.handleNext(this.checkRepeaterStatus('next'))
                      }
                      className="mobile-step-button"
                    >
                      <div className="mobile-stepper-label">
                        <p className="next-up">Next up:</p>
                        <p className="next-stepper-title">{stepperSteps[stepIndex + 1]}</p>
                      </div>
                      <IoChevronRight className="next-chevron" />
                    </StepButton>
                  </Step>
                </Stepper>
              ) : (
                <Stepper linear={false} activeStep={stepIndex}>
                  <Step>
                    <StepButton
                      className={getStepClasses(0)}
                      onClick={e => {
                        this.jumpToStep(0, this.checkRepeaterStatus('jump', 0))(e);
                        googleAnalytics('company-registration-stepper-yourprofile')(e);
                      }}
                      completed={stepIndex !== 0 && validPages.indexOf(0) !== -1}
                    >
                      Your Profile
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      className={getStepClasses(1)}
                      onClick={e => {
                        this.jumpToStep(1, this.checkRepeaterStatus('jump', 1))(e);
                        googleAnalytics('company-registration-stepper-companyprofile')(e);
                      }}
                      disabled={
                        stepIndex !== 1 &&
                        validPages.indexOf(1) === -1 &&
                        validPages.indexOf(0) === -1
                      }
                      completed={stepIndex !== 1 && validPages.indexOf(1) !== -1}
                    >
                      Account Profile
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      className={getStepClasses(2)}
                      onClick={e => {
                        this.jumpToStep(2, this.checkRepeaterStatus('jump', 2))(e);
                        googleAnalytics('company-registration-stepper-addlocations')(e);
                      }}
                      disabled={
                        stepIndex !== 2 &&
                        validPages.indexOf(2) === -1 &&
                        validPages.indexOf(1) === -1
                      }
                      completed={stepIndex !== 2 && validPages.indexOf(2) !== -1}
                    >
                      Add Locations
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      className={getStepClasses(3)}
                      onClick={e => {
                        this.jumpToStep(3, this.checkRepeaterStatus('jump', 3))(e);
                        googleAnalytics('company-registration-stepper-addusers')(e);
                      }}
                      disabled={
                        stepIndex !== 3 &&
                        validPages.indexOf(3) === -1 &&
                        validPages.indexOf(2) === -1
                      }
                      completed={stepIndex !== 3 && validPages.indexOf(3) !== -1}
                    >
                      Add Users
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      className={getStepClasses(4)}
                      onClick={e => {
                        this.jumpToStep(4, this.checkRepeaterStatus('jump', 4))(e);
                        googleAnalytics('company-registration-stepper-review')(e);
                      }}
                      disabled={this.state.validPages.length < 4}
                    >
                      Review
                    </StepButton>
                  </Step>
                </Stepper>
              )}
            </div>
            {!mobileSize && (
              <RaisedButton
                primary
                style={{ maxHeight: 36 }}
                labelStyle={{ textTransform: 'none' }}
                label={stepIndex === 4 ? 'Finish' : 'Next'}
                labelPosition="before"
                icon={<IoChevronRight className="next-chevron" />}
                onClick={e => {
                  stepIndex === 4
                    ? this.handleFinish()
                    : this.handleNext(this.checkRepeaterStatus('next'));
                  googleAnalytics('company-registration-stepper-next')(e);
                }}
              />
            )}
          </div>
          {isFetching && <LoaderComponent active={isFetching} />}
          {error && error.message && this.errorDialog(error)}
          {dupePhoneError && this.phoneConfirmationDialog(dupePhoneError)}
          {saveSuccess ? this.successDialog() : ''}
          {this.state.showDeleteConfirmation ? this.deleteDialog() : ''}
        </div>
      );
    }
  }
}

const mapStateToProps = ({ dash }) => ({ dash });

const mapDispatchToProps = dispatch => {
  const actions = { submitAddCompanyWithMisc, resetError, setStepperPage };
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
