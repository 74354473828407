import React from 'react';
import { nameString } from '@evdy/web-core/dist/lib/utils';
import isEmpty from 'lodash-es/isEmpty';

import blankImg from '../../assets/no-photo-placeholder.svg';

import './AccountListItem.scss';

const AccountListItem = ({
  _id,
  name,
  phone,
  website,
  logo,
  funeralHomes,
  address,
  contactName,
  handleAccountClick
}) => {
  const locationsArray = funeralHomes =>
    funeralHomes.map(({ name }, i) => name + (i === funeralHomes.length - 1 ? '' : ', '));
  const bgImage = logo || blankImg;

  return (
    <div className="dash-accounts-list-item" onClick={() => handleAccountClick(_id)}>
      <img className="accounts-item-img" src={bgImage} alt="account-list-item" />
      <div className="accounts-item-body">
        <div className="accounts-item-info">
          <h1 className="account-item-name">{nameString(name) || 'No Name Provided'}</h1>
          {!isEmpty(address) && (
            <p className="account-item-address">{address?.city + ', ' + address?.state}</p>
          )}
          {!isEmpty(contactName) && (
            <p className="account-item-contact">
              <span>Rep</span> {nameString(contactName)}
            </p>
          )}
          <p className="account-item-locations">Locations ({locationsArray(funeralHomes)})</p>
        </div>
      </div>
    </div>
  );
};

export default AccountListItem;
