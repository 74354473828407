import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  SolidColorModalHeader,
  ModalOverlay,
  ResponsiveContent,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020/index';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';

import NoServicesOptionsForm from '../../Forms/NoServicesForm/index';

import './ChooseNoServicesModal.scss';

const ChooseNoServicesModal = ({
  closeModal,
  modalVisible,
  addNoServiceOptionsAction,
  memorialId,
  serviceStatus,
  formData,
  change,
  reset,
  model
}) => {
  const {
    eventInfo: { noServices }
  } = formData;

  const handleCloseModal = () => {
    reset(`${model}.noServices`);
    closeModal();
  };

  // map form data on mount and when changing between options/cancelling/closing
  useEffect(() => {
    if (serviceStatus && noServices === null) {
      change(`${model}.noServices`, serviceStatus);
    }
  }, [noServices, serviceStatus]);

  //map form data on mount to upcoming if in default status && there is no form data
  useEffect(() => {
    if (serviceStatus === 'defaultStatus' && noServices === null) {
      change(`${model}.noServices`, 'servicesUpcoming');
    }
  }, [noServices]);

  return (
    <ModalOverlay
      customClass="no-events-options-modal"
      closeModal={handleCloseModal}
      {...{ modalVisible }}
    >
      <ResponsiveContent>
        <SolidColorModalHeader closeModal={handleCloseModal} headerCopy="Confirm Event Status" />
        <NoServicesOptionsForm {...{ model }} />
        <ModalFooter
          mainButtonText="Save"
          mainButtonFn={() => {
            addNoServiceOptionsAction({ memorialId, noServices });
            closeModal();
          }}
          secondaryButtonText="Cancel"
          secondaryButtonFn={handleCloseModal}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(null, {
  addNoServiceOptionsAction: creationOperations.addNoServiceOptionsAction,
  reset: formActions.reset,
  change: formActions.change
})(ChooseNoServicesModal);
