import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import './PackagesRow.scss';

const PackagesRow = ({
  handleView,
  detached,
  packages = [],
  packageNameType = 'director',
  isPreneedInitialized = false
}) => {
  const { rawPreneedData = {} } = useSelector(({ funeralHome }) => ({
    rawPreneedData: funeralHome.home?.data?.rawPreneedData
  }));

  const packageTitlesArray = Object.values(rawPreneedData)
    .filter(pkg => {
      const { packageName } = pkg || {};
      const { director } = packageName || {};
      if (!packageName || !director) return false;
      return true;
    })
    .map(({ packageName: { director } }) => director);

  const packageClasses = classNames('packages-row', { detached });

  const determinePackagesToMap = isPreneedInitialized ? packages : packageTitlesArray;

  return (
    <div className={packageClasses}>
      {determinePackagesToMap.map(pkg => {
        const { packageName, packagePrice } = pkg;
        const hasPrice = !!packagePrice?.toLocaleString();
        const determinePackageItem = isPreneedInitialized ? packageName[packageNameType] : pkg;
        const determinePackageTitle = isPreneedInitialized ? packageName[packageNameType] : pkg;

        return (
          <div key={`packages-${determinePackageItem}`} className="package-item">
            <div className="price">{hasPrice ? `$${packagePrice?.toLocaleString()}` : '-'}</div>
            <div className="title">{determinePackageTitle}</div>
            {handleView && hasPrice && (
              <button
                className="view-more"
                onClick={() => handleView(packageName[packageNameType])}
              >
                View Details
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PackagesRow;
