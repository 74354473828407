import React from 'react';

import infoIcon from '../../../../../images/information-icon-24@2x.png';
import warningIcon from '../../../../../images/warning-icon-24@2x.png';

import './AttentionRow.scss';

const AttentionRow = ({ footerCopy, footerAction, cautionFlag, customClass }) => {
  return !!footerCopy ? (
    <div className={['attention-row', customClass].join(' ')}>
      <img src={cautionFlag ? warningIcon : infoIcon} alt="info-icon" />
      <div className="footer-copy">{footerCopy}</div>
      {!!footerAction && (
        <div className="foot-action" onClick={footerAction}>
          See Example
        </div>
      )}
    </div>
  ) : null;
};

export default AttentionRow;
