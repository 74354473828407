"use strict";

function normalizeDateEvent(id, fn) {
  return function (__, value) {
    fn({
      target: {
        id: id,
        value: value,
        type: 'date',
        validity: {
          valid: !!value
        }
      }
    });
  };
}

function normalizeTimeEvent(id, fn) {
  return function (__, value) {
    fn({
      target: {
        id: id,
        value: value,
        type: 'time',
        validity: {
          valid: !!value
        }
      }
    });
  };
}

function normalizeSelectEvent(id, fn) {
  return function (__, idx, value) {
    fn({
      target: {
        id: id,
        value: value,
        type: 'select',
        validity: {
          valid: !!value
        }
      }
    });
  };
}

module.exports = {
  normalizeDateEvent: normalizeDateEvent,
  normalizeTimeEvent: normalizeTimeEvent,
  normalizeSelectEvent: normalizeSelectEvent
};