import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import uniqBy from 'lodash-es/uniqBy';
import truncate from 'lodash-es/truncate';

import AddEventsForm from '../../forms/AddEventsForm';
import NewCustomAddressModalBody from '@evdy/web-core/dist/components/announcementDetail/modalBodies/NewCustomAddressModalBody';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import {
  SolidColorModalHeader,
  ModalOverlay,
  ResponsiveContent,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';
import { timeWithZoneOffset } from '@evdy/web-core/dist/lib/utils';

import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';
import { addRecentLocation } from '@evdy/web-redux/dist/actions/dash/editUser';
import { useFocusInput } from '@evdy/web-core/dist/customHooks/index';

import { formatHome } from '../../../../etc/dashUtils';

import './AddEventsModal.scss';

const AddEventsModal = props => {
  const {
    modalVisible,
    closeModal,
    model,
    getLocationsList,
    addServiceAction,
    deleteServiceAction,
    editServiceAction,
    funeralHomeId,
    isFetchingGoogleHomes,
    googleLocations,
    companyHomes,
    memorialId,
    formData,
    reset,
    change,
    merge,
    validate,
    editEventId,
    userData,
    addRecentLocation,
    eventAddress,
    servicesArray = [],
    isMemorialFinished,
    eventFormMeta
  } = props;
  const { eventName, eventDate, startTime, endTime, description, videoStreamUrl } = formData || {};
  const [locationsModalOpen, setLocationsModalOpen] = useState(false);
  const [locationFormOpen, setLocationFormOpen] = useState(false);
  const [locationSearchText, setLocationSearchText] = useState('');
  const [funeralHomeArray, setFuneralHomeArray] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  const [currentEvent, setCurrentEvent] = useState(null);

  //handle close to reset event forms/customlocation forms
  const handleCloseModal = () => {
    reset(model);
    reset('forms.eventAddress');
    setLocationsModalOpen(false);
    setLocationFormOpen(false);
    closeModal();
  };

  const handleGoBack = () => {
    return isLocationsModalOpen ? setLocationFormOpen(false) : closeModal();
  };

  //grab event out of services array on mount
  useEffect(() => {
    editEventId
      ? setCurrentEvent(
          servicesArray.find(event => event._id.toString() === editEventId.toString())
        )
      : setCurrentEvent(null);
  }, [editEventId, servicesArray]);

  //prefill location info on mount
  useEffect(() => {
    if ('geolocation' in navigator && !currentLocation) {
      navigator.geolocation.getCurrentPosition(position =>
        setCurrentLocation(`${position.coords.latitude},${position.coords.longitude}`)
      );
    }
    return () => {
      if (!!currentLocation) {
        setCurrentLocation('');
      }
    };
  }, [currentLocation]);

  //set googleLocations array to results of typeahead search/e.p
  useEffect(() => {
    if (!!googleLocations?.length) {
      const { locationSearchText } = eventAddress;

      // Array of Google search results for typeahead
      // Filter companyHomes by current search term before concat-ing with fetched Google homes
      // noinspection JSCheckFunctionSignatures
      const googleHomeArray = uniqBy(
        companyHomes
          .filter(home => home.name.toLowerCase().includes(locationSearchText.toLowerCase()))
          .concat(googleLocations)
          .map(formatHome),
        'uniqueKey'
      );

      setFuneralHomeArray(googleHomeArray);
    }
  }, [googleLocations]);

  //map/reset event values to modal for edit on mount/unmount
  useEffect(() => {
    if (currentEvent) {
      const isAllDayEvent = !!currentEvent?.startTimeTBD;
      const noEndTime = !currentEvent?.endDateISO;
      change(`${model}.eventName`, currentEvent?.title);
      change(
        `${model}.location`,
        currentEvent?.location?.name
          ? currentEvent?.location
          : { name: '', city: '', street: '', zip: '' }
      );
      //if no times included all day event was formatting to day prior
      change(
        `${model}.eventDate`,
        isAllDayEvent
          ? timeWithZoneOffset(Date.parse(currentEvent?.startDateISO))
          : moment.utc(currentEvent?.startDateISO)._d
      );
      change(
        `${model}.startTime`,
        isAllDayEvent ? '' : moment.utc(currentEvent?.startDateISO).format('h:mma')
      );
      change(
        `${model}.endTime`,
        isAllDayEvent || noEndTime ? '' : moment.utc(currentEvent?.endDateISO).format('h:mma')
      );
      change(`${model}.videoStreamUrl`, currentEvent?.videoStreamUrl);
      change(`${model}.description`, currentEvent?.description);
    }
    return () => {
      reset(model);
    };
  }, [change, currentEvent, modalVisible, model, reset]);

  // this needs to be in modal below the useEffect that resets the form data or else the focus gets wiped
  // focus on event name on mount when modal open
  useFocusInput({ model, inputModel: 'eventName', modalVisible });

  const isEndTimeAfterStartTime = ({ momentStartTime, momentEndTime }) => {
    if (momentStartTime && momentEndTime) {
      if (momentEndTime > momentStartTime) return true;
    }
  };
  const eventTimeValidators = ({ startTime, endTime, momentStartTime, momentEndTime }) => {
    if (!startTime && !endTime) return true;
    if (startTime && !endTime) return true;
    const endTimeAfterStart = !!isEndTimeAfterStartTime({
      momentStartTime,
      momentEndTime
    });
    validate(`${model}.endTime`, {
      validEventTimes: () => endTimeAfterStart
    });
    if (!!endTimeAfterStart) return true;
  };

  const mapStartDateISO = ({ eventDate, momentStartTime, startTime }) => {
    return startTime
      ? moment(
          moment
            .utc(eventDate)
            .hour(momentStartTime.hour())
            .minute(momentStartTime.minute())
            .valueOf()
        ).toISOString()
      : new Date(eventDate.setUTCHours(0, 0, 0, 0)).toISOString();
  };

  const mapEndDateISO = ({ eventDate, momentEndTime, endTime }) => {
    return endTime
      ? moment(
          moment
            .utc(eventDate)
            .hour(momentEndTime.hour())
            .minute(momentEndTime.minute())
            .valueOf()
        ).toISOString()
      : new Date(eventDate.setUTCHours(0, 0, 0, 0)).toISOString();
  };

  const handleSubmit = () => {
    const service = {
      title: eventName,
      location: formData?.location,
      coords: formData?.coords,
      description,
      videoStreamUrl
    };

    const momentStartTime = moment.utc(startTime, 'h:mma');
    const momentEndTime = moment.utc(endTime, 'h:mma');
    if (
      !eventTimeValidators({
        startTime,
        endTime,
        momentStartTime,
        momentEndTime
      })
    ) {
      return;
    }

    if (!startTime) service.startTimeTBD = true;

    service.startDateISO = mapStartDateISO({
      eventDate,
      momentStartTime,
      startTime
    });
    service.endDateISO = endTime
      ? mapEndDateISO({
          eventDate,
          momentEndTime,
          endTime
        })
      : null;

    //backend is expecting null for services to set coords for custom
    if (!service?.coords?.length) service.coords = null;

    addServiceAction({ memorialId, service });
    handleCloseModal();
  };

  const handleEdit = () => {
    const service = {
      title: eventName,
      location: formData?.location,
      coords: formData?.coords,
      description,
      _id: currentEvent?._id,
      notify: formData?.notifyCommunity,
      videoStreamUrl
    };
    const momentStartTime = moment.utc(startTime, 'h:mma');
    const momentEndTime = moment.utc(endTime, 'h:mma');
    if (
      !eventTimeValidators({
        startTime,
        endTime,
        momentStartTime,
        momentEndTime
      })
    ) {
      return;
    }

    if (!startTime) service.startTimeTBD = true;
    if (startTime && service.startTimeTBD === true) service.startTimeTBD = false;
    service.startDateISO = mapStartDateISO({
      eventDate,
      momentStartTime,
      startTime
    });
    service.endDateISO = endTime
      ? mapEndDateISO({
          eventDate,
          momentEndTime,
          endTime
        })
      : null;

    //backend is expecting null for services to set coords for custom
    if (!service?.coords?.length) service.coords = null;

    editServiceAction({ memorialId, service });
    handleCloseModal();
  };

  const handleEventDelete = (memorialId, serviceId) => {
    deleteServiceAction({ memorialId, serviceId });
    closeModal();
  };

  const handleLocationTypeaheadChange = searchText => {
    const coords = currentLocation;
    setLocationSearchText(searchText);
    getLocationsList({ funeralHomeId, input: searchText, coords });
  };

  const handleLocationTypeaheadSelection = () => {
    return chosenRequest => {
      let display;

      const location = chosenRequest.address;
      location.name = chosenRequest.display.split(' —')[0];
      location.address = chosenRequest.address.street;
      if (location.name.length >= 40) {
        display = truncate(location.name, { length: 39 });
      }

      const funeralHome =
        chosenRequest.value === 'place' || typeof chosenRequest.value === 'object' // handle custom address value (<MenuItem>)
          ? undefined
          : chosenRequest.value;

      const newAddress = { ...eventAddress, ...chosenRequest, location, display, funeralHome };

      merge(model, { ...newAddress });

      closeLocationModal();
    };
  };

  const handleAddRecentLocation = location => {
    const userId = userData?._id;
    const { name, city, street, state, zip } = location.address;

    const { coords, profileImage } = location;
    const userObj = {
      newLocation: {
        name,
        coords,
        profileImage,
        address: street,
        city,
        state: state || '',
        zip: zip || ''
      }
    };

    addRecentLocation(userId, userObj);
  };

  const clearLocationSearch = () => {
    setLocationSearchText('');
  };

  const closeLocationModal = () => {
    setLocationFormOpen(false);
    setLocationSearchText('');
    setFuneralHomeArray([]);
    setLocationsModalOpen(false);
  };

  const handleLocationSubmit = () => {
    const { customAddress } = eventAddress;

    const newAddress = {
      ...eventAddress,
      location: {
        ...eventAddress.location,
        ...customAddress
      }
    };

    merge(model, { ...newAddress });

    closeLocationModal();
  };

  const saveCustomAddress = () => {
    const customAddress = { ...eventAddress?.customAddress };

    let displayText = `${customAddress.name || 'Custom Address'}`;
    if (displayText.length >= 40) {
      displayText = truncate(displayText, { length: 39 });
      customAddress.display = displayText;
      customAddress.coords = [];
    }

    const userId = userData?._id;
    const userObj = {
      newLocation: {
        name: displayText,
        coords: [],
        address: customAddress.address,
        city: customAddress.city,
        state: customAddress.state,
        zip: customAddress.zip
      }
    };
    addRecentLocation(userId, userObj);
    handleLocationSubmit();
  };

  const renderLocationModal = () => (
    <NewCustomAddressModalBody
      user={userData}
      handleTypeaheadChange={handleLocationTypeaheadChange}
      handleTypeaheadSelection={handleLocationTypeaheadSelection}
      handleAddRecentLocation={handleAddRecentLocation}
      locationSearchText={locationSearchText}
      clearLocationSearch={clearLocationSearch}
      saveCustomAddress={saveCustomAddress}
      funeralHomeArray={funeralHomeArray}
      closeCustomAddressModal={closeLocationModal}
      {...{ isFetchingGoogleHomes }}
    />
  );

  const isLocationsModalOpen = locationFormOpen && locationsModalOpen;
  //disable submit btn if form is missing required fields
  const isButtonDisabled =
    !eventName ||
    !eventDate ||
    (!!videoStreamUrl && !eventFormMeta?.eventInfo?.videoStreamUrl?.valid);
  const validationFns = {
    eventTimeValidators
  };

  return (
    <ModalOverlay
      customClass="add-events-modal-form"
      closeModal={handleCloseModal}
      {...{ modalVisible }}
    >
      <ResponsiveContent>
        <SolidColorModalHeader
          {...{ handleGoBack }}
          headerCopy={`${editEventId ? 'Edit' : 'Add'} event`}
          closeModal={handleCloseModal}
        />
        {!isLocationsModalOpen ? (
          <>
            <AddEventsForm
              {...{
                currentEvent,
                formData,
                model,
                setLocationFormOpen,
                setLocationsModalOpen,
                validationFns,
                isMemorialFinished
              }}
            />
            <ModalFooter
              mainButtonText="Save"
              mainButtonFn={currentEvent ? () => handleEdit() : () => handleSubmit()}
              secondaryButtonText="Cancel"
              secondaryButtonFn={handleCloseModal}
              tertiaryButtonText={currentEvent ? 'Delete Event' : null}
              tertiaryButtonFn={() => handleEventDelete(memorialId, currentEvent?._id)}
              disabled={isButtonDisabled}
            />
          </>
        ) : (
          renderLocationModal()
        )}
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(
  ({ inviteCreation, authUser, forms, dash, inviteDetails }) => ({
    isFetchingGoogleHomes: inviteCreation.googleLocations.isFetching,
    googleLocations: inviteCreation.googleLocations.locations,
    isMemorialFinished: inviteDetails?.inviteMeta?.data?.isFinished,
    userData: authUser.data,
    eventAddress: forms.eventAddress,
    companyHomes: dash.fetchedCompany.company.data.funeralHomes
  }),
  {
    getLocationsList: creationOperations.getLocationsList,
    addServiceAction: creationOperations.addServiceAction,
    deleteServiceAction: creationOperations.deleteServiceAction,
    editServiceAction: creationOperations.editServiceAction,
    reset: formActions.reset,
    change: formActions.change,
    merge: formActions.merge,
    validate: formActions.validate,
    addRecentLocation
  }
)(AddEventsModal);
