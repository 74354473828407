import React from 'react';
import { connect } from 'react-redux';

import DecedentInfoCard from './DecedentInfoCard';
import ServicesPreview from './ServicesPreview';
import FamilyPreview from './FamilyPreview';
import ProviderPreview from './ProviderPreview';
import InvitePreviewGutter from '../../../InvitePreviewGutter';

import { useScrollToTopOfStepperPage } from '../CustomHook';

import './InvitePreview.scss';

const InvitePreview = ({
  // Accessor
  accessors,
  accessorFormData,
  accessorFormMetaData,
  // Decedent Details
  decedentDetails,
  decedentFormData,
  decedentFormMetaData,
  // Funeral Info
  funeralInfo,
  funeralHomeId,
  premiumProgram,
  funeralInfoFormData,
  // Misc
  isDemoModeOn,
  // Services
  servicesArray,
  eventFormData,
  eventFormMeta,
  chooseServiceFormData,
  serviceStatus,
  // Donations
  donations: donationsData
}) => {
  const { array: accessorsArr = [] } = accessors || {};
  const {
    data: { _id: memorialId }
  } = decedentDetails || {};
  const { _id: funeralDirectorId } = funeralInfo?.data?.director || {};

  // on mount, scroll to top
  useScrollToTopOfStepperPage('edit-decedent-details');

  return (
    <div className="invite-preview-step">
      <div className="invite-preview-sections">
        <section className="main-content">
          {/*<IntroVideoPreview />*/}
          <h1 className="invite-preview-header">Review & Send</h1>
          <FamilyPreview
            {...{
              accessors,
              accessorFormData,
              accessorFormMetaData,
              funeralDirectorId,
              premiumProgram,
              memorialId
            }}
            accessorFormModel="inviteCreation.forms.accessor"
          />
          <ServicesPreview
            {...{
              chooseServiceFormData,
              eventFormData,
              eventFormMeta,
              funeralHomeId,
              memorialId,
              servicesArray,
              serviceStatus
            }}
            eventFormModel="inviteCreation.forms.eventInfo"
          />
          <ProviderPreview
            {...{ funeralInfo, accessorsArr, memorialId, isDemoModeOn }}
            formData={funeralInfoFormData}
            providerFormModel="inviteCreation.forms.inviteCreationDetails"
          />
        </section>
        <InvitePreviewGutter decedentEditMode {...{ memorialId, donationsData }} />
      </div>
    </div>
  );
};

export default connect(({ authUser, client, inviteCreation }) => ({
  // Accessor
  accessors: inviteCreation?.accessors,
  accessorFormData: inviteCreation.forms.accessor,
  accessorFormMetaData: inviteCreation.forms.formsMeta.accessor,
  // Decedent Details
  decedentDetails: inviteCreation.decedentDetails,
  decedentFormData: inviteCreation.forms.decedentInfo,
  decedentFormMetaData: inviteCreation.forms.formsMeta.decedentInfo,
  // Funeral Info
  funeralInfo: inviteCreation?.funeralInfo,
  funeralHomeId: inviteCreation.funeralInfo.funeralHome?._id,
  premiumProgram: inviteCreation?.funeralInfo?.funeralHome?.premiumProgram,
  funeralInfoFormData: inviteCreation?.forms?.inviteCreationDetails,
  // Misc
  isDemoModeOn: authUser?.isDemoModeOn,
  // Services
  servicesArray: inviteCreation.services.array,
  eventFormData: inviteCreation.forms.eventInfo,
  eventFormMeta: inviteCreation.forms.formsMeta,
  chooseServiceFormData: inviteCreation.forms,
  serviceStatus: inviteCreation.decedentDetails?.data?.serviceStatus,
  // Donations
  donations: inviteCreation?.donations
}))(InvitePreview);
