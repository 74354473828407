import React from 'react';
import { Router, Route, browserHistory, IndexRedirect, IndexRoute } from 'react-router';
import { routerActions } from 'react-router-redux';
import { UserAuthWrapper } from 'redux-auth-wrapper';

import redirectRoute from '@evdy/web-core/dist/components/redirectRoute';

import { userHasAccess } from '@evdy/web-core/dist/lib/utils';

import ResourceCenter from '@evdy/web-core/dist/components/ResourceCenter';
import ResetPassword from '@evdy/web-core/dist/components/ResetPassword';
import RequestPassword from '@evdy/web-core/dist/components/RequestPassword';
import InviteDetails from './components/InviteDetails';
import Accessors from './components/InviteBuilder/components/Accessors';

import App from './components/App';

import Dash from './components/Dash';
import Login from './components/Login';
import Tutorials from './components/Tutorials';
import Announcements from './components/Announcements';
import Accounts from './components/Accounts';
import AccountDetails from './components/Accounts/AccountDetails';
import AccountLocationDetails from './components/Accounts/AccountDetails/AccLocations/AccountLocationDetails';

import Registration from './components/companyRegistration/Registration';
import RegistrationUser from './components/userRegistration/RegistrationUser';

import InviteBuilder from './components/InviteBuilder';

import SignupSuccess from './components/SignupSuccess';
import Dashboard from './components/Dashboard';
import Notifications from './components/Notifications';
import Onboarding from './components/Onboarding';
import CRM from './components/CRM';
import NotPermitted from './components/shared/NotPermitted';
import Profile from './components/Profile';
import Users from './components/Users';
import UserDetails from './components/Users/UserDetails';
import Company from './components/Company';
import Locations from './components/Locations';
import LocationDetails from './components/Locations/LocationDetails';
import TeamMembers from './components/TeamMembers';
import AccountSetup from './components/AccountSetup';

import LeadDetails from './components/CRM/LeadDetails';

const UserIsAuthenticated = UserAuthWrapper({
  authSelector: state => state.dash.user.data,
  predicate: userData => userData && userData.company,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
  failureRedirectPath: state => {
    const { data } = state.dash.user;
    return data ? '/logout?redirect=/dash/login' : '/dash/login';
  }
});

const UserIsNotAuthenticated = UserAuthWrapper({
  authSelector: state => state.dash.user,
  predicate: user => !user.data || !user.data.company,
  failureRedirectPath: (state, ownProps) => ownProps.location.query.redirect || '/dash/a',
  wrapperDisplayName: 'UserIsNotAuthenticated',
  redirectAction: routerActions.replace,
  allowRedirectBack: false
});

const UserHasCrmAccess = UserAuthWrapper({
  authSelector: state => ({
    userData: state.dash.user?.data,
    companyHomes: state?.dash?.fetchedCompany?.company?.data?.funeralHomes
  }),
  predicate: ({ userData, companyHomes = [] }) => {
    const hasAccess = userHasAccess({
      funeralHomes: companyHomes,
      user: userData,
      accessString: 'crmAccess'
    });

    return (
      userData?.isCompanyAdmin || userData?.meta?.everdaysAdmin || userData?.isAFP || hasAccess
    );
  },
  wrapperDisplayName: 'UserHasCrmAccess',
  FailureComponent: NotPermitted
});

const UserIsEverdaysAdmin = UserAuthWrapper({
  authSelector: ({ dash }) => ({ isEmployee: dash.dashNav.isEmployee }),
  predicate: ({ isEmployee }) => isEmployee,
  failureRedirectPath: '/dash',
  wrapperDisplayName: 'UserIsEverdaysAdmin',
  redirectAction: routerActions.replace,
  allowRedirectBack: false
});

const Routes = (store, history) => {
  const connect = fn => (nextState, replaceState) => fn(store, nextState, replaceState);

  return (
    <Router history={history || browserHistory}>
      <Route path="/" component={App}>
        <Route path="dash" component={Dash}>
          <IndexRedirect to="a/announcements" />
          <Route path="signup">
            <IndexRedirect to="/dash/login" />
          </Route>
          <Route path="signup-success" component={SignupSuccess} />
          {/* the following will have to change as auth dependent on invite email */}
          <Route path="company-registration" component={Registration} />
          <Route path="user-registration" component={RegistrationUser} />
          {/* <Route path="request-password" component={RequestPassword} /> */}
          <Route path="reset-password" component={ResetPassword} />
          <Route
            path="login"
            component={UserIsNotAuthenticated(Login)}
            onEnter={connect(UserIsNotAuthenticated.onEnter)}
          />
          <Route
            path="a"
            component={UserIsAuthenticated(props => (
              <div className="auth-wrapper">{props.children}</div>
            ))}
            onEnter={connect(UserIsAuthenticated.onEnter)}
          >
            <IndexRedirect to="announcements" />
            <Route path="family-members" component={Accessors} />
            <Route path="announcements" component={Announcements} />
            <Route path="resource-center" component={ResourceCenter} />
            <Route path="announcements/:id" component={InviteDetails} />
            <Route path="builder/:memorialId" component={InviteBuilder} />
            <Route path="company" component={Company} />
            <Route path="locations">
              <IndexRoute component={Locations} />
              <Route path=":id" component={LocationDetails} />
            </Route>
            <Route path="team-members" component={TeamMembers} />
            <Route path="tutorials" component={Tutorials} />
            <Route path="profile" component={Profile} />
            <Route path="dashboard">
              <IndexRedirect to="../announcements" />
            </Route>
            <Route path="account-setup" component={AccountSetup} />
            <Route path="notifications" component={Notifications} />
            <Route path="sales" component={UserHasCrmAccess(props => props.children)}>
              <IndexRoute component={CRM} />
              <Route path=":id" component={LeadDetails} />
            </Route>
            <Route path="accounts" component={UserIsEverdaysAdmin(props => props.children)}>
              <IndexRoute component={Accounts} />
              <Route path=":accountId" component={AccountDetails} />
              <Route path=":accountId/:locationId" component={AccountLocationDetails} />
            </Route>
            <Route path="users" component={UserIsEverdaysAdmin(props => props.children)}>
              <IndexRoute component={Users} />
              <Route path=":id" component={UserDetails} />
            </Route>
          </Route>
        </Route>
        {redirectRoute()}
      </Route>
    </Router>
  );
};

export default Routes;
