import React, { useState } from 'react';
import { connect } from 'react-redux';

import DashCard from '../../../shared/DashCard';
import DashCardHeader from '../../../shared/DashCard/CardBuilder/DashCardHeader';
import AttentionRow from '../../../shared/DashCard/CardBuilder/AttentionRow';
import AdminRow from '../../../shared/DashCard/CardBuilder/AdminRow';
import TeamMemberModal from '../../../TeamMembers/components/TeamMemberModal';

import './AdministratorsCard.scss';

const AdministratorsCard = ({ members = [] }) => {
  const [isAdminModalOpen, setAdminModalOpen] = useState(false);
  const [adminModalType, setAdminModalType] = useState('add-member');
  const [modalData, setModalData] = useState({});

  const handleAddBtnClick = () => {
    setAdminModalOpen(true);
    setAdminModalType('add-member');
    setModalData({});
  };

  const closeModal = () => {
    setAdminModalOpen(false);
    setAdminModalType('add-member');
    setModalData({});
  };

  const handleEdit = member => {
    setAdminModalType('edit-member');
    setModalData({ member });
    window.requestAnimationFrame(() => setAdminModalOpen(true)); // use requestAnimationFrame to avoid seeing the empty state before forms are filled
  };

  return (
    <DashCard customClass="administrators-card">
      <DashCardHeader
        headerCopy="Administrators"
        buttonCopy="Add Admin"
        headerBtnAction={handleAddBtnClick}
      />
      <div className="admin-list">
        {members
          .filter(member => member.isCompanyAdmin)
          .map(member => (
            <AdminRow
              name={member.name}
              title={member.title}
              image={member.image}
              handleEdit={() => handleEdit(member)}
            />
          ))}
      </div>
      <AttentionRow
        cautionFlag={false}
        footerCopy="Every Admin will receive a bi-weekly success report via email"
      />
      <TeamMemberModal
        {...{ closeModal, modalData }}
        forceAdmin
        modalType={adminModalType}
        modalVisible={isAdminModalOpen}
      />
    </DashCard>
  );
};

export default connect(({ dash }) => ({
  members: dash?.fetchedCompany?.company?.data?.users
}))(AdministratorsCard);
