import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import startCase from 'lodash-es/startCase';

import TaskOption from '../TaskOption';
import LatestActivityModalHeader from '../shared/LatestActivityModalHeader';
import {
  InputGroup,
  DatePicker,
  formActions,
  stdErrMsg,
  Typeahead
} from '@evdy/web-core/dist/components/shared/Input';

import { closeLeadOptions, getMostRecentAttemptCount } from '../../shared/utils';

import './MeetingSetModalBody.scss';

const MeetingSetModalBody = props => {
  const { activity, dispatch, latestStageActivity, meetingSetForm, isLimited } = props;
  const { selectedTask } = meetingSetForm;
  const { attempt = 1, currentStage, dueDate } = latestStageActivity || {};

  const model = 'dash.crm.forms.meetingSet';

  const handleSelect = type => {
    dispatch(formActions.change(`${model}.selectedTask`, type));
  };

  return (
    <div className="meeting-set-modal-body">
      <LatestActivityModalHeader {...{ currentStage, dueDate, attempt }} />
      <h3>What is your next step?</h3>
      <div className="task-options">
        <TaskOption
          title="Successfully Connected - Schedule Meeting"
          subtitle={`#${getMostRecentAttemptCount(activity, 'meeting-set') + 1} Attempt`}
          selected={selectedTask === 'success'}
          handleSelectClick={() => handleSelect('success')}
        >
          <div className="date-time-row">
            <DatePicker
              isRequired
              model={`${model}.success`}
              dateModel="date"
              label="Due Date"
              defaultYearRange={3}
              yearBase={moment().get('year') + 2}
            />
            <Typeahead
              time
              medium
              model={`${model}.success.time`}
              modelBase=""
              labelText="Time"
              messages={stdErrMsg('Time')}
            />
          </div>
          <InputGroup
            customClass="additional-notes"
            model={`${model}.success.additionalNotes`}
            labelText="Additional Notes"
            type="textarea"
          />
        </TaskOption>
        {!isLimited && (
          <TaskOption
            title="Did not connect - Schedule next call attempt"
            subtitle={`#${getMostRecentAttemptCount(activity, 'new') + 1} Attempt`}
            selected={selectedTask === 'retry'}
            handleSelectClick={() => handleSelect('retry')}
          >
            <div className="date-time-row">
              <DatePicker
                isRequired
                model={`${model}.retry`}
                dateModel="date"
                label="Due Date"
                defaultYearRange={3}
                yearBase={moment().get('year') + 2}
              />
              <Typeahead
                time
                medium
                model={`${model}.retry.time`}
                modelBase=""
                labelText="Time"
                messages={stdErrMsg('Time')}
              />
            </div>
            <InputGroup
              customClass="additional-notes"
              model={`${model}.retry.additionalNotes`}
              labelText="Additional Notes"
              type="textarea"
            />
          </TaskOption>
        )}
        {!isLimited && (
          <TaskOption
            title="Currently uninterested in preplanning"
            subtitle="Remove from task list"
            selected={selectedTask === 'close'}
            handleSelectClick={() => handleSelect('close')}
          >
            <InputGroup required model={`${model}.close.reason`} type="select" labelText="Reason">
              <option value="">Select</option>
              {closeLeadOptions.map(option => (
                <option value={option}>{startCase(option)}</option>
              ))}
            </InputGroup>
            <InputGroup
              customClass="additional-notes"
              model={`${model}.close.additionalNotes`}
              labelText="Additional Notes"
            />
          </TaskOption>
        )}
      </div>
    </div>
  );
};

export default connect(({ dash }) => ({ meetingSetForm: dash.crm.forms.meetingSet }))(
  MeetingSetModalBody
);
