import React from 'react';

import { Form, InputGroup, isRequired } from '@evdy/web-core/dist/components/shared/Input';
import { useFocusInput } from '@evdy/web-core/dist/customHooks/index';

import './ObituaryForm.scss';

const ObituaryForm = ({ model, placeholder = '', modalVisible }) => {
  //focus obit message field
  useFocusInput({ model, inputModel: 'message', modalVisible });

  return (
    <Form model={model} className="invite-overview-modal-form">
      <InputGroup
        {...{ placeholder }}
        full
        type="textarea"
        model=".message"
        validators={{ isRequired }}
      />
    </Form>
  );
};

export default ObituaryForm;
