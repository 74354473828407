// remove price formatting "$" ","
export const cleanPrice = price => {
  if (price === undefined) return '0';
  return `${price}`.replace(/[$,a-zA-Z]/g, '');
};

export const formatPrice = (value = '') => {
  const price = +`${value}`.replace(/[$,a-zA-Z]/g, '');
  if (!value && value !== 0) return '';
  return `$${price.toLocaleString()}`;
};

export const calculatePackageTotals = (packages, allPackageServices) => {
  // create pkg.filteredPackageServices on the pkg object - new array of package services with add ons filtered out
  // package totals should be sent to the back end separate from the add on price directors are allowed to set
  // packagePrice is calculated using the base package price + non add on services
  packages.forEach(pkg => {
    const { packageServices } = pkg;
    pkg.filteredPackageServices = packageServices.filter(({ addOn }) => !addOn);
    return pkg;
  });
  const reducer = (total, { serviceIndex, type, price }) => {
    if (type === 'funeralhome') {
      // if no form data is passed (formdData: allPackageServices) to calculatePackageTotals function the package/service prices are coming from the back end
      // and the prices can be calculated using the raw package price + the non addOn services ie:  pkg.filteredPackageServices from line 19
      // if form data is passed use allPackageServices to update package prices so front can update prices shown in modal live
      return !!allPackageServices
        ? +cleanPrice(allPackageServices?.[`service${serviceIndex}`]) + total
        : +cleanPrice(price) + total;
    }
    // package prices are calculated off of type = funeralhome. type = everdays are free services at the moment
    // leaving the conditional in case type = everdays prices are added
    return (price ?? 0) + total;
  };

  return packages.map(({ packageName, packageId, filteredPackageServices }) => {
    const packagePrice = filteredPackageServices.reduce(reducer, 0);
    return {
      packageName,
      packageId,
      packagePrice
    };
  });
};

export const separateBasicServices = funeralHomeServices => {
  const remainingServices = [];
  const basicServices = funeralHomeServices.filter(service => {
    const { serviceName } = service;
    if (serviceName.director !== 'Full Service Cremation') {
      remainingServices.push(service);
    }
    return serviceName.director === 'Full Service Cremation';
  });
  return [basicServices, remainingServices];
};
