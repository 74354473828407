import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  InputGroup,
  Typeahead,
  formActions,
  isRequired
} from '@evdy/web-core/dist/components/shared/Input';
import { fetchCompanyTypeahead } from '@evdy/web-redux/dist/actions/onboarding/fetchCompanyTypeahead';

import jobTitles from '@evdy/web-etc/dist/lib/jobTitles';

const CompanyName = props => {
  const {
    dispatch,
    companyName: { name = '', save: homeId, companyExists } = {},
    typeaheadSuggestions
  } = props;

  // update typeahead results
  useEffect(() => {
    if (name) {
      dispatch(fetchCompanyTypeahead(name));
    }
  }, [dispatch, name]);

  // check if company exists
  useEffect(() => {
    if (homeId) {
      const fh = typeaheadSuggestions.find(
        ({ _id }) => (_id.toString ? _id.toString() : _id) === homeId
      );

      dispatch(
        formActions.change('dash.onboarding.forms.companyName.companyExists', !!fh?.company)
      );
    } else if (companyExists) {
      // if there is not homeId, then clear flag
      dispatch(formActions.change('dash.onboarding.forms.companyName.companyExists', false));
    }
  }, [dispatch, homeId, companyExists, typeaheadSuggestions]);

  const handleTypeaheadChange = () => {
    homeId && dispatch(formActions.change('dash.onboarding.forms.companyName.save', false));
  };

  const suggestionArray =
    !!name && !typeaheadSuggestions.some(({ name: homeName }) => homeName === name) // dedupe after selection
      ? [{ name }, ...typeaheadSuggestions]
      : typeaheadSuggestions;

  return (
    <div className="company-name-step">
      <Form model="dash.onboarding.forms.companyName">
        <div className="onboard-form-row">
          <Typeahead
            required
            modelBase="dash.onboarding.forms.companyName"
            model=".name"
            labelText="Business Name"
            customClass="business-name-typeahead"
            onChange={handleTypeaheadChange}
            data={suggestionArray}
            displayKey="name"
            saveKey="_id"
          />
          <InputGroup
            required
            type="select"
            model=".type"
            labelText="Type of Business"
            validators={{ isRequired }}
            messages={{ isRequired: 'Business type is required' }}
          >
            <option />
            <option>Crematory</option>
            <option>Cemetery</option>
            <option>Funeral Home</option>
            <option>Home Health</option>
            <option>Hospice</option>
            <option>Other</option>
          </InputGroup>
        </div>
        <div className="onboard-form-row">
          <InputGroup
            required
            model=".title"
            labelText="Your Title"
            type="select"
            validators={{ isRequired }}
            messages={{ isRequired: 'Title is required' }}
          >
            <option />
            {Object.keys(jobTitles).map(title => (
              <option value={title}>{jobTitles[title]}</option>
            ))}
          </InputGroup>
        </div>
      </Form>
    </div>
  );
};

export default connect(({ dash }) => ({
  companyName: dash.onboarding.forms.companyName,
  typeaheadSuggestions: dash.onboarding.fetchCompanyTypeahead.data
}))(CompanyName);
