import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Link from 'react-router/es/Link';

import UniversalDashModal from '@evdy/web-core/dist/components/shared/elements/UniversalDashModal';
import LocationModalBody from '../../../shared/LocationModalBody';

import { formatAddress, renderToast } from '@evdy/web-core/dist/lib/utils';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { editAccountAction } from '@evdy/web-redux/dist/actions/dash/editAccount';
import { fetchAccounts } from '@evdy/web-redux/dist/actions/dash/fetchAccounts';

import blankImg from '../../assets/no-photo-placeholder.svg';

import './AccLocations.scss';

const addHomeSuccess = 'add-home-success-toast';
const addHomeFailure = 'add-home-failure-toast';

const model = 'dash.accountDetails.forms.location';

const AccLocations = props => {
  const {
    account = {},
    locations = [],
    editAccountAction,
    locationForm,
    locationFormValid,
    editAccount,
    fetchAccounts,
    reset
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { _id: accountId } = account;

  const handleModalSave = () => {
    editAccountAction({ company: account, funeralHomes: [...locations, locationForm] }, accountId);
  };

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    reset(model);
  }, [reset]);

  // reset the location form when navigated away
  useEffect(() => {
    return () => {
      reset(model);
    };
  }, [reset]);

  // render success toast if home was added successfully
  useEffect(() => {
    if (editAccount.isFetching !== true && editAccount.lastUpdated && !editAccount.error.message) {
      renderToast({ id: addHomeSuccess, message: 'Location successfully added' });
      handleModalClose();
      fetchAccounts(account._id);
    }
  }, [editAccount, handleModalClose]);

  // render error toast if there was an error adding new home
  useEffect(() => {
    if (editAccount.error.message && editAccount.isFetching === false) {
      renderToast({ id: addHomeFailure, message: editAccount.error.message, type: 'error' });
      handleModalClose();
    }
  }, [editAccount, handleModalClose]);

  return (
    <div className="dash-acc-locations-container">
      <button className="location-add" onClick={() => setIsModalOpen(true)}>
        Add a Location
      </button>
      {locations.filter(Boolean).map(({ _id: locationId, profileImage, name, address }) => (
        <Link
          key={locationId}
          to={{
            pathname: `/dash/a/accounts/${accountId}/${locationId}`
          }}
          className="location-item"
        >
          <div className="location-item-section">
            <img src={profileImage || blankImg} alt="location logo" />
            <div className="location-info">
              <div className="location-name">{name}</div>
              <div className="location-address">{formatAddress(address)}</div>
            </div>
          </div>
          <div className="location-item-section">
            <button className="location-edit">Edit</button>
          </div>
        </Link>
      ))}
      {isModalOpen && (
        <UniversalDashModal
          v3Styling
          closeModal={handleModalClose}
          mainButtonText="Save"
          mainButtonFn={() => {
            handleModalSave();
          }}
          disabled={!locationFormValid}
          secondaryButtonText="Cancel"
          secondaryButtonFn={handleModalClose}
          headerTitle="Add a Location"
        >
          <LocationModalBody isAddHome isInternal {...{ model }} form={locationForm} />
        </UniversalDashModal>
      )}
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    locationForm: dash?.accountDetails?.forms?.location,
    locationFormValid: dash.accountDetails.forms.accountDetailsMeta.$form.valid,
    editAccount: dash.editAccount
  }),
  { editAccountAction, fetchAccounts, reset: formActions.reset }
)(AccLocations);
