import { useState, useEffect } from 'react';
import moment from 'moment/moment';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import { select } from 'd3-selection';
import { scaleBand, scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import 'd3-transition';

const transitionDuration = 300;
const margin = { top: 20, right: 20, bottom: 55, left: 50 };
const minTickLabelWidth = { month: 40, quarter: 70, year: 30 };

const format = {
  quarter: {
    1: '1st Q',
    2: '2nd Q',
    3: '3rd Q',
    4: '4th Q'
  }
};

export const formatSum = {
  threeMnAgo: 3,
  sixMnAgo: 6,
  oneYrAgo: 12,
  twoYrAgo: 24,
  allTime: undefined
};

export const chooseChartSize = windowWidth => {
  const large = 600;
  const medium = 425;
  const small = 325;
  const micro = 275;
  const nano = 225;

  if (windowWidth >= 1024) {
    return large;
  } else if (windowWidth >= 768) {
    return medium;
  } else if (windowWidth >= 425) {
    return small;
  } else if (windowWidth >= 375) {
    return micro;
  } else {
    return nano;
  }
};

export const useBarchartResize = () => {
  const [chartWidth, setChartWidth] = useState(0);

  const resizeChart = () => {
    const newWidth = chooseChartSize(window.innerWidth);
    chartWidth !== newWidth && setChartWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeChart);
    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  });

  return [chartWidth, setChartWidth];
};

export const groupData = (data, today) => {
  const groupedData = {
    threeMnAgo: {},
    sixMnAgo: {},
    oneYrAgo: {},
    twoYrAgo: {},
    allTime: {},
    amtPerMonth: []
  };

  const eventDates = {
    threeMnAgo: moment(today).subtract(3, 'months'),
    sixMnAgo: moment(today).subtract(6, 'months'),
    oneYrAgo: moment(today).subtract(1, 'years'),
    twoYrAgo: moment(today).subtract(2, 'years')
  };

  data.forEach(announcement => {
    const eventMoment = moment.utc(announcement.date);

    const month = eventMoment.format('MMM');
    const quarter = format.quarter[eventMoment.quarter()];
    const year = eventMoment.format('YY');

    const yearString = `${year}’`;
    const quarterString = `${quarter} ${yearString}`;
    const monthString = `${month} ${yearString}`;

    for (let [key, val] of Object.entries(eventDates)) {
      if (eventMoment.isAfter(val)) {
        const acc = groupedData[key];
        const groupedDataKey = key === 'twoYrAgo' ? quarterString : monthString;

        acc[groupedDataKey] ||= 0;
        acc[groupedDataKey]++;
      }
    }

    const { allTime, amtPerMonth } = groupedData;
    const prevValueAtYear = allTime[yearString];
    prevValueAtYear ? (allTime[yearString] += 1) : (allTime[yearString] = 1);

    const monthsAgo = moment(today).diff(eventMoment, 'month');
    const prevValueAtMonth = amtPerMonth[monthsAgo];
    prevValueAtMonth ? (amtPerMonth[monthsAgo] += 1) : (amtPerMonth[monthsAgo] = 1);
  });

  // convert data from object ({ key: value }) to array of objects ({date: <key>, amt: <value> })
  const formatData = (key, data, type, description) => ({
    key,
    dataset: map(toPairs(data), ([date, amt]) => ({ date, amt, key: `${date}-${key}` })).reverse(),
    tickType: type,
    description
  });
  groupedData.threeMnAgo = formatData('threeMnAgo', groupedData.threeMnAgo, 'month', '3 Months');
  groupedData.sixMnAgo = formatData('sixMnAgo', groupedData.sixMnAgo, 'month', '6 Months');
  groupedData.oneYrAgo = formatData('oneYrAgo', groupedData.oneYrAgo, 'month', '1 Year');
  groupedData.twoYrAgo = formatData('twoYrAgo', groupedData.twoYrAgo, 'quarter', '2 Years');
  groupedData.allTime = formatData('allTime', groupedData.allTime, 'year', 'All Time');

  return groupedData;
};

export const chartSetup = ({ height, chartAnchor }) => {
  const svg = select(chartAnchor.current);
  const chartHeight = height - margin.top - margin.bottom;
  const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

  g.append('g')
    .attr('transform', `translate(0,${chartHeight})`)
    .attr('class', 'xAxis');
  g.append('g').attr('class', 'yAxis');
  g.append('g').attr('class', 'bars');
};

export const chartReset = ({ chartAnchor }) => {
  const svg = select(chartAnchor.current);
  svg.selectAll('g').remove();
};

export const updateChart = ({ width, height, chartAnchor, data }) => {
  const { dataset, tickType } = data;

  const svg = select(chartAnchor.current);
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const xAxis = svg.selectAll('.xAxis');
  const yAxis = svg.selectAll('.yAxis');

  const xScale = scaleBand()
    .rangeRound([0, chartWidth])
    .padding(0.5);
  const yScale = scaleLinear().rangeRound([chartHeight, 0]);

  xScale.domain(dataset.map(({ date }) => date));
  yScale.domain([0, max(dataset, ({ amt }) => amt)]);

  const bars = svg
    .selectAll('.bars')
    .selectAll('.bar')
    .data(dataset, ({ key }) => key);

  // Enter
  let barWidth = 0;
  const barsEnter = bars
    .enter()
    .append('rect')
    .attr('class', 'bar')
    .attr('x', ({ date }) => xScale(date))
    .attr('y', chartHeight)
    .attr('width', d => {
      barWidth = xScale.bandwidth(d);
      return barWidth;
    });

  if (bars.exit().empty()) {
    barsEnter
      .transition()
      .duration(transitionDuration)
      .on('end', () => xAxis.classed('transitioning', false))
      .attr('y', ({ amt }) => yScale(amt))
      .attr('height', ({ amt }) => chartHeight - yScale(amt));
  } else {
    barsEnter
      .transition()
      .delay(transitionDuration)
      .duration(transitionDuration)
      .on('end', () => xAxis.classed('transitioning', false))
      .attr('y', ({ amt }) => yScale(amt))
      .attr('height', ({ amt }) => chartHeight - yScale(amt));
  }

  // Update
  bars
    .attr('x', ({ date }) => xScale(date))
    .attr('y', ({ amt }) => yScale(amt))
    .attr('height', ({ amt }) => chartHeight - yScale(amt))
    .attr('width', d => {
      barWidth = xScale.bandwidth(d);
      return barWidth;
    });

  // Exit
  bars
    .exit()
    .transition()
    .duration(transitionDuration)
    .on('start', () => xAxis.classed('transitioning', true))
    .attr('height', 0)
    .attr('y', chartHeight)
    .remove();

  xAxis
    .call(axisBottom(xScale).tickSize(0))
    .call(g => g.select('.domain').remove())
    .selectAll('text')
    .attr('transform', 'rotate(0)')
    .attr('x', 0)
    .attr('y', 10);

  if (barWidth <= minTickLabelWidth[tickType])
    xAxis
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .attr('x', -30)
      .attr('y', 1);

  yAxis
    .transition()
    .duration(transitionDuration)
    .call(
      axisLeft(yScale)
        .ticks(7)
        .tickSize(-chartWidth)
    );
};
