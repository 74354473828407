import React, { useRef, useEffect } from 'react';

import DonationForm from '../../forms/AddDonationForm';

import {
  ModalOverlay,
  ResponsiveContent,
  SolidColorModalHeader
} from '@evdy/web-core/dist/components/shared/Modal2020';

import styles from './DonationModal.module.scss';

const DonationModal = ({
  modalVisible,
  handleCloseModal,
  handleSubmit,
  isValid,
  currentDonation,
  handleDelete,
  memorialId
}) => {
  const { _id: editDonationId } = currentDonation || {};
  const hasDonation = !!editDonationId;
  const formRef = useRef();

  //reset form values when modal closed
  useEffect(() => {
    if (!modalVisible) {
      formRef.current.resetForm({ organizationName: '', donationUrl: '', description: '' });
    }
  }, [modalVisible]);

  return (
    <ModalOverlay
      closeModal={handleCloseModal}
      customClass={styles.donationModal}
      {...{ modalVisible }}
    >
      <ResponsiveContent>
        <SolidColorModalHeader
          headerCopy={`${hasDonation ? 'Edit Donation' : 'Add Donation'}`}
          closeModal={handleCloseModal}
        />
        <DonationForm
          ref={formRef}
          {...{
            handleSubmit,
            handleCloseModal,
            hasDonation,
            currentDonation,
            handleDelete,
            memorialId
          }}
        />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default DonationModal;
