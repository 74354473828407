import React from 'react';
import { connect } from 'react-redux';

import { Form, RadioGroup } from '@evdy/web-core/dist/components/shared/Input';

import upcomingEventsCal from '../../Events/ServiceOptionsRow/upcoming-cal.svg';
import privateEventsCal from '../../Events/ServiceOptionsRow/private-cal.svg';
import noEventsCal from '../../Events/ServiceOptionsRow/no-events-cal.svg';

import './NoServicesForm.scss';

const NoServicesOptionsForm = ({ model, serviceStatusCopy }) => {
  const noServicesOptions = [
    {
      value: 'servicesUpcoming',
      labelText: serviceStatusCopy['servicesUpcoming'],
      image: upcomingEventsCal
    },
    {
      value: 'servicesNotPublic',
      labelText: serviceStatusCopy['servicesNotPublic'],
      image: privateEventsCal
    },
    { value: 'noServices', labelText: serviceStatusCopy['noServices'], image: noEventsCal }
  ];

  return (
    <Form model={model} className="select-options-form">
      <RadioGroup
        model=".noServices"
        values={noServicesOptions}
        customClass="no-services-options-buttons"
        id="radio-group"
      />
    </Form>
  );
};

export default connect(({ client }) => ({
  serviceStatusCopy: client?.config?.data?.copy?.memorialServiceStatus
}))(NoServicesOptionsForm);
