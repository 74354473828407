import React from 'react';
import { number } from 'prop-types';
import classNames from 'classnames';

import './ProgressBar.scss';

const ProgressBar = ({ numOfSteps = 4, numOfCompleteSteps = 0 }) => {
  const progressPercent = Math.floor((numOfCompleteSteps / numOfSteps) * 100);

  const color = (() => {
    if (progressPercent === 0) return 'red';
    if (progressPercent > 0 && progressPercent < 100) return 'yellow';
    return 'green';
  })();

  const fillerClasses = classNames('filler', { [color]: color });

  const Filler = ({ percent }) => (
    <div
      className={fillerClasses}
      style={{ width: `${progressPercent === 0 ? 5 : progressPercent}%` }}
    />
  );

  return (
    <div className="progress-percentage-bar">
      <span>{progressPercent}%</span>
      <div className="progress-bar">
        <Filler percent={progressPercent} />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  numOfSteps: number,
  numOfCompleteSteps: number
};

export default ProgressBar;
