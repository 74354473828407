import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router';

import NotificationDot from '../shared/NotificationDot';

import { setDashMode } from '@evdy/web-redux/dist/lib/dash/actions/dashNav';
import { renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import DropDown from '@evdy/web-core/dist/components/shared/elements/DropDown';

import './DashSideNav.scss';

const sideNavLinks = {
  owner: [
    {
      title: 'Account Setup',
      href: '/dash/a/account-setup',
      img: '',
      pageName: 'account-setup',
      linkClass: 'account-setup'
    }
  ],
  initial: [
    {
      title: 'Home',
      href: '/dash/a/announcements',
      img: '',
      pageName: 'invitations',
      linkClass: 'invitations'
    }
  ],
  internal: [
    {
      title: 'Users',
      href: '/dash/a/users',
      img: '',
      pageName: 'users',
      linkClass: 'users'
    },
    {
      title: 'Accounts',
      href: '/dash/a/accounts',
      img: '',
      pageName: 'accounts',
      linkClass: 'accounts'
    },
    {
      title: 'Send Demo',
      href: '/send-demo/v2',
      type: 'a',
      linkClass: 'send-demo'
    }
  ],
  // preneed: [
  //   {
  //     title: 'Preneed Contacts',
  //     href: '/dash/a/sales',
  //     img: '',
  //     pageName: 'sales',
  //     linkClass: 'preneed-contacts'
  //   }
  // {
  //   title: 'Write Policy',
  //   href: '/dash/a/dashboard',
  //   img: '',
  //   pageName: 'write',
  //   linkClass: 'write-policy'
  // }
  // ],
  company: [
    {
      title: 'Locations',
      href: '/dash/a/locations',
      img: '',
      pageName: 'locations',
      linkClass: 'locations',
      showIndicator: true
    },
    {
      title: 'Team Members',
      href: '/dash/a/team-members',
      img: '',
      pageName: 'team-members',
      linkClass: 'team-members'
    },
    {
      title: 'Account',
      href: '/dash/a/company',
      img: '',
      pageName: 'company',
      linkClass: 'company'
    }
  ],
  etc: [
    {
      title: 'Resources',
      href: '/dash/a/resource-center',
      img: '',
      pageName: 'resource-center',
      linkClass: 'resources'
    },
    {
      title: 'Order Materials',
      href:
        'https://docs.google.com/forms/d/e/1FAIpQLSeruHkJ2V-AjDmLHEv1NVIv9w-x29xwVqHW4vMrGUuN3KsgSw/viewform',
      img: '',
      type: 'a',
      linkClass: 'order-materials'
    },
    {
      title: 'FAQs',
      href: 'https://help.everdays.com/en/collections/2673002-funeral-provider-faqs',
      img: '',
      type: 'a',
      pageName: 'faqs',
      linkClass: 'faqs'
    },
    {
      title: 'Live Chat',
      href: 'mailto:wdj13bk6@intercom-mail.com',
      img: '',
      linkClass: 'live-chat intercom-chat'
    }
  ]
};

const SideNavLink = ({
  title,
  href,
  currentPage,
  pageName,
  type = 'Link',
  linkClass,
  showIndicator = false,
  indicatorCount
}) => {
  const sideNavLinkClasses = classNames('side-nav-link', linkClass, {
    active: currentPage === pageName
  });

  const LinkElement = props => {
    return type === 'Link' ? (
      <Link {...props} />
    ) : (
      <a target="_blank" rel="noopener noreferrer" {...props} />
    );
  };

  return (
    <LinkElement key={href} to={href} className={sideNavLinkClasses} {...{ title, href }}>
      <div className={`side-nav-icon ${linkClass}`} />
      <span>{title}</span>
      {showIndicator && <NotificationDot displayAmt={indicatorCount} />}
    </LinkElement>
  );
};

const DashSideNav = ({
  currentPage,
  user,
  isEmployee,
  isDemoModeOn,
  isInternal,
  setDashMode,
  homesNeedPackageSetup = 0
}) => {
  const filterLinkCategories = entry => {
    const key = entry[0];
    // don't show company links if user is not company admin
    const userIsNotCompanyAdmin =
      ['company', 'owner', 'preneed'].includes(key) && !user?.isCompanyAdmin;
    const userCannotSeeInternal = ['internal'].includes(key) && (!isInternal || !isEmployee);

    return !((userIsNotCompanyAdmin || userCannotSeeInternal) /* other condidtionals here */);
  };

  const modeSelectClasses = classNames('mode-select-dropdown', {
    internal: isInternal,
    professional: !isInternal
  });
  const dropdownButtonText = isInternal ? 'Internal' : 'Professional';
  const paneItemClasses = type =>
    classNames({
      selected: type === dropdownButtonText.toLocaleLowerCase()
    });

  const sideNavClasses = classNames('dash-side-nav', {
    'demo-mode-on': isDemoModeOn
  });

  return (
    <div className={sideNavClasses}>
      <div className="side-nav-links">
        {isEmployee && (
          <DropDown
            direction="left"
            header={
              <div className={modeSelectClasses}>
                <div>{dropdownButtonText}</div>
                <div className="chev" />
              </div>
            }
            pane={({ togglePane }) => (
              <ul>
                <li
                  className={paneItemClasses('internal')}
                  onClick={() => {
                    if (isDemoModeOn) {
                      renderDemoToast({ id: 'internal-mode-demo' });
                    } else {
                      setDashMode('internal');
                    }
                    togglePane();
                  }}
                >
                  Internal
                </li>
                <li
                  className={paneItemClasses('professional')}
                  onClick={() => {
                    setDashMode('external');
                    togglePane();
                  }}
                >
                  Professional
                </li>
              </ul>
            )}
          />
        )}
        {Object.entries(sideNavLinks)
          .filter(filterLinkCategories)
          .map((entry, idx) => (
            <div key={idx} className="side-nav-group">
              {entry[1].map(link => (
                <SideNavLink
                  key={link.title + link.linkClass}
                  title={link.title}
                  href={link.href}
                  pageName={link.pageName}
                  currentPage={currentPage}
                  type={link.type}
                  linkClass={link.linkClass}
                  showIndicator={
                    user?.isCompanyAdmin && link.showIndicator && !!homesNeedPackageSetup
                  }
                  indicatorCount={link.pageName === 'locations' ? homesNeedPackageSetup : undefined}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default connect(
  ({ dash, authUser }) => ({
    isInternal: !dash?.dashNav?.isExternalMode,
    homesNeedPackageSetup: dash.fetchedCompany?.company?.data?.homesNeedPackageSetup
  }),
  {
    setDashMode
  }
)(DashSideNav);
