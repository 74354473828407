"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.object.keys.js");

require("core-js/modules/es6.symbol.js");

require("core-js/modules/es6.array.filter.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es7.object.get-own-property-descriptors.js");

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLeadActivity = exports.leadActivityReset = exports.putLeadActivity = exports.postLeadActivity = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var types = _interopRequireWildcard(require("./constants"));

var _constants2 = require("../../constants");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var instance = _axios.default.create({
  baseURL: _constants2.API_URL
});
/**
 * @param {object} activityData
 */
//POST


var postLeadActivityRequest = function postLeadActivityRequest() {
  return {
    type: types.POST_LEAD_ACTIVITY_REQUEST
  };
};

var postLeadActivitySuccess = function postLeadActivitySuccess(data) {
  return {
    type: types.POST_LEAD_ACTIVITY_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

var postLeadActivityFailure = function postLeadActivityFailure(error) {
  return {
    type: types.POST_LEAD_ACTIVITY_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

var postLeadActivity = function postLeadActivity(_ref) {
  var leadId = _ref.leadId,
      activityData = _ref.activityData;
  return function (dispatch) {
    dispatch(postLeadActivityRequest());
    return instance.post("/lead/".concat(leadId, "/activity"), _objectSpread({}, activityData)).then(function (res) {
      return dispatch(postLeadActivitySuccess(res.data));
    }, function (error) {
      return dispatch(postLeadActivityFailure((0, _transformAxiosError.default)(error)));
    });
  };
}; //PUT


exports.postLeadActivity = postLeadActivity;

var putLeadActivityRequest = function putLeadActivityRequest() {
  return {
    type: types.PUT_LEAD_ACTIVITY_REQUEST
  };
};

var putLeadActivitySuccess = function putLeadActivitySuccess(data) {
  return {
    type: types.PUT_LEAD_ACTIVITY_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

var putLeadActivityFailure = function putLeadActivityFailure(error) {
  return {
    type: types.PUT_LEAD_ACTIVITY_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

var putLeadActivity = function putLeadActivity(_ref2) {
  var leadId = _ref2.leadId,
      activityId = _ref2.activityId,
      activityData = _ref2.activityData;
  return function (dispatch) {
    dispatch(putLeadActivityRequest());
    return instance.put("/lead/".concat(leadId, "/activity/").concat(activityId), _objectSpread({}, activityData)).then(function (res) {
      return dispatch(putLeadActivitySuccess(res.data));
    }, function (error) {
      return dispatch(putLeadActivityFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.putLeadActivity = putLeadActivity;

var leadActivityReset = function leadActivityReset() {
  return {
    type: types.LEAD_ACTIVITY_RESET
  };
}; //GET


exports.leadActivityReset = leadActivityReset;

var getLeadActivityRequest = function getLeadActivityRequest() {
  return {
    type: types.GET_LEAD_ACTIVITY_REQUEST
  };
};

var getLeadActivitySuccess = function getLeadActivitySuccess(data) {
  return {
    type: types.GET_LEAD_ACTIVITY_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

var getLeadActivityFailure = function getLeadActivityFailure(error) {
  return {
    type: types.GET_LEAD_ACTIVITY_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

var getLeadActivity = function getLeadActivity(_ref3) {
  var leadId = _ref3.leadId,
      activityId = _ref3.activityId;
  return function (dispatch) {
    dispatch(getLeadActivityRequest());
    return instance.get("/lead/".concat(leadId, "/activity/").concat(activityId)).then(function (res) {
      return dispatch(getLeadActivitySuccess(res.data));
    }, function (error) {
      return dispatch(getLeadActivityFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.getLeadActivity = getLeadActivity;