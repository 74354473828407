import moment from 'moment/moment';
import { parse } from 'libphonenumber-js';

// Dash Helper Functions

export const formatHome = foundHome => {
  let { _id, address, coords, name: text, profileImage } = foundHome;

  if (address && address.city) {
    const streetAddress =
      address.street.length > 15 ? `${address.street.slice(0, 15)}...` : address.street;
    text += ` — ${streetAddress}, ${address.city}`;
  }

  return {
    text,
    coords,
    address,
    display: text,
    profileImage: profileImage || '',
    value: _id || 'place',
    uniqueKey: [text, _id, coords].join(':')
  };
};

export const setStartEndDate = _e => {
  const e = { ..._e };

  const tzOffset = date => new Date(date).getTimezoneOffset() * 60 * 1000;

  const startTime = moment(e.startTime, 'h:ma');
  const endTime = e.endTime && moment(e.endTime, 'h:ma');

  if (e.startTimeTBD && e.startTime) {
    e.startTimeTBD = false;
  } else if (!e.startTime) {
    e.startTimeTBD = true;
    e.startTime = '12:00am';
  }

  e.startDate =
    moment(e.date)
      .hour(startTime.hour())
      .minute(startTime.minute())
      .valueOf() - tzOffset(moment(e.date));

  e.endDate =
    endTime &&
    moment(e.date)
      .hour(endTime.hour())
      .minute(endTime.minute())
      .valueOf() - tzOffset(moment(e.date));

  delete e.startTime;
  delete e.endTime;

  return e;
};

export const getDateWithOffset = time => {
  const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(Number(time) + tzOffset);
};

export const formatTimeFromDate = date => {
  const timeWithOffset = getDateWithOffset(date);

  return moment(timeWithOffset).format('h:mma');
};

export const extractDraftDateDataFromStartDate = startDate => {
  const startTime = getDateWithOffset(startDate);

  return {
    day: startTime.getDate(),
    month: startTime.getMonth(),
    year: startTime.getFullYear()
  };
};

export const getPrefillDate = () => {
  const currentDate = new Date();
  const nextMonth = currentDate.getDate() >= 28;
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth();
  if (currentMonth === 11) {
    currentMonth = nextMonth && 0;
    currentYear++;
  } else {
    nextMonth && currentMonth++;
  }

  return {
    currentMonth,
    currentYear
  };
};

export const parsePhone = phone => {
  return parse(phone, 'US').phone;
};
