import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';

import LoginContainer from '@evdy/web-core/dist/components/LoginContainer';
import Trademark from '@evdy/web-core/dist/components/shared/elements/Trademark';
import PopupDialog from '@evdy/web-core/dist/components/shared/elements/PopupDialog';
import { InputGroup, isRequired } from '@evdy/web-core/dist/components/shared/Input';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { googleAnalytics } from '@evdy/web-core/dist/lib/utils';
import { authUserOperations } from '@evdy/web-redux/dist/lib/authUser';

import logo from '@evdy/web-core/dist/images/Everdays-Logo-(colored)@2x.png';

import '@evdy/web-core/dist/sass/Login.css';

const { dashLogin, resetError } = authUserOperations;

@connect(({ dash, forms }) => ({
  login: dash.user,
  loginDetails: forms.loginDetails,
  isValid: forms.formsMeta.loginDetails.$form.valid
}))
export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.errorDialog = this.errorDialog.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  handleSubmit(data) {
    const { email, password: dashPassword } = data;

    this.props.dispatch(dashLogin({ email, dashPassword }));
  }

  resetForm() {
    return e => {
      const { dispatch } = this.props;
      dispatch(resetError());
    };
  }

  errorDialog() {
    const { login } = this.props;
    const { message, original } = login.error;
    return (
      <PopupDialog
        title="Login Problem"
        actions={[{ label: 'Try Again', onClick: this.resetForm(), primary: true }]}
        actAsExpander={!!original}
        showExpandableButton={!!original}
        stackTrace={original && original.stack}
      >
        {message}
      </PopupDialog>
    );
  }

  render() {
    const { error, isFetching } = this.props.login;

    return (
      <LoginContainer>
        <div className="content">
          <img className="login-logo" alt="Everdays Logo" src={logo} />
          <p className="login-title">Log in as an Everdays Funeral Home</p>
          <p>
            Don't have an Everdays account? <a href="https://partners.everdays.com/">Sign up.</a>
          </p>
          <Form onSubmit={this.handleSubmit} model="forms.loginDetails">
            <InputGroup
              model=".email"
              labelText="Email Address"
              type="email"
              validators={{ isRequired }}
              messages={{ isRequired: 'Email is required' }}
              customClass="input"
            />

            <InputGroup
              model=".password"
              labelText="Password"
              type="password"
              validators={{ isRequired }}
              messages={{ isRequired: 'Password is required' }}
            />

            <Link
              className="forgot-password"
              to="/dash/request-password/"
              onClick={googleAnalytics('login-forgotpassword')}
            >
              Forgot password?
            </Link>

            <button
              disabled={!this.props.isValid}
              className={!this.props.isValid ? 'disabled' : ''}
              type="submit"
            >
              Log in
            </button>
          </Form>
          <Trademark />
          {error && error.message && this.errorDialog()}
          {isFetching && <LoaderComponent />}
        </div>
      </LoginContainer>
    );
  }
}
