import axios from 'axios/index';
import transAxiosErr from '@evdy/web-etc/dist/lib/transformAxiosError';

import { API_URL } from '../../constants';
import * as types from '../../constants/dash/editAccount';

const instance = axios.create({
  baseURL: API_URL
});

export const editAccountReset = () => ({
  type: types.EDIT_ACCOUNT_RESET
});

export const editAccountRequest = () => ({
  type: types.EDIT_ACCOUNT_REQUEST
});

export const editAccountSuccess = ({ data }) => ({
  data,
  receivedAt: Date.now(),
  type: types.EDIT_ACCOUNT_SUCCESS
});

export const editAccountFailure = error => ({
  error,
  receivedAt: Date.now(),
  type: types.EDIT_ACCOUNT_FAILURE
});

export const editAccountAction = (data, id) => dispatch => {
  dispatch(editAccountRequest());
  return instance
    .put(`/company/${id}`, data)
    .then(res => dispatch(editAccountSuccess(res.data)))
    .catch(error => dispatch(editAccountFailure(transAxiosErr(error))));
};
