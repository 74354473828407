import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  ModalOverlay,
  GradientModalHeader,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import PricesForm from './PricesForm';
import DisplayPackage from './DisplayPackage';

import './PackagesModal.scss';

import { calculatePackageTotals, formatPrice } from '../../helperFuncs';

const model = 'funeralHome.forms.packages';

const PackagesModal = ({ modalVisible, handleCloseModal, handleUpdate, displayPackageName }) => {
  const dispatch = useDispatch();
  const { formData, isValid, preneedPackages } = useSelector(({ funeralHome }) => ({
    formData: funeralHome.forms.packages,
    isValid: funeralHome.forms.formsMeta.packages.$form.valid,
    preneedPackages: funeralHome.home.data?.preneedPackages
  }));

  const { packages = [], services = [], dataStructure } = preneedPackages ?? {};
  const { _id: preNeedDataStructureId } = dataStructure || {};
  const isPreneedInitialized = !!preNeedDataStructureId;

  const funeralHomeServices = useMemo(() => {
    const isNotParentService = ({ isParent }) => !isParent;
    const isFuneralHomeService = ({ type }) => type === 'funeralhome';
    const isNotHidden = ({ hidden }) => !hidden;
    return services.filter(isNotParentService).filter(isFuneralHomeService).filter(isNotHidden);
  }, [services]);

  const packageTotals = useMemo(() => {
    const hiddenServicePrices = services
      .filter(({ hidden }) => !!hidden)
      .map(({ serviceIndex, price }) => ({ [`service${serviceIndex}`]: price }));

    const hiddenServicesPricesObj = {};

    hiddenServicePrices.forEach(serv => {
      const [key, value] = Object.entries(serv)[0] ?? [];
      if (key && value) {
        hiddenServicesPricesObj[key] = value;
      }
    });

    const allPackageServices = {
      ...formData,
      ...hiddenServicesPricesObj
    };

    return calculatePackageTotals(packages, allPackageServices);
  }, [formData, packages, services]);

  // merge package prices with redux
  useEffect(() => {
    funeralHomeServices.forEach(({ serviceIndex, price }) =>
      dispatch(formActions.change(`${model}.service${serviceIndex}`, formatPrice(price)))
    );
  }, [dispatch, funeralHomeServices, modalVisible]);

  return (
    <ModalOverlay
      {...{ modalVisible }}
      closeModal={handleCloseModal}
      customClass="packages-modal-container"
    >
      <ResponsiveContent>
        <div className="packages-modal">
          <GradientModalHeader
            closeModal={handleCloseModal}
            headerCopy={displayPackageName || 'All Advance Funeral Packages'}
          />
          {displayPackageName ? (
            <DisplayPackage {...{ packages, displayPackageName, handleCloseModal }} />
          ) : (
            <PricesForm
              {...{
                model,
                handleCloseModal,
                handleUpdate,
                isValid,
                isPreneedInitialized
              }}
              packages={packageTotals}
              services={funeralHomeServices}
              fullServiceCremation={services[0]}
            />
          )}
        </div>
      </ResponsiveContent>
    </ModalOverlay>
  );
};

const { bool, func } = PropTypes;

PackagesModal.propTypes = {
  modalVisible: bool.isRequired,
  handleCloseModal: func.isRequired,
  handleUpdate: func
};

export default PackagesModal;
