"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailSupport = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _constants = require("../../constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var emailSupport = function emailSupport(homeId) {
  return _index.default.post("".concat(_constants.API_URL, "/auth/funeral-home/").concat(homeId, "/user-request")).catch(function (error) {
    return (0, _transformAxiosError.default)(error);
  });
};

exports.emailSupport = emailSupport;