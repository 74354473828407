import { useReducer } from 'react';

export const useStepper = (steps, initialStepIdx = 0) => {
  const stepReducer = ({ stepIdx }, { direction }) => {
    switch (direction) {
      case 'forward':
        return { stepIdx: stepIdx + 1 };
      case 'back':
        return { stepIdx: stepIdx - 1 };
      default:
        const foundStep = steps.findIndex(stepName => stepName === direction);
        return foundStep ? { stepIdx: foundStep } : stepIdx;
    }
  };
  const [{ stepIdx }, dispatch] = useReducer(stepReducer, { stepIdx: initialStepIdx }); // TODO: see why setting a step on refresh causes render issues for the back button

  return [stepIdx, stepName => dispatch({ direction: stepName })];
};
