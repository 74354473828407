import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import zipObject from 'lodash-es/zipObject';

import {
  ModalOverlay,
  ResponsiveContent,
  GradientModalHeader,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';
import LocationModalBody from '../../../shared/LocationModalBody';
import ConfirmationModal from '@evdy/web-core/dist/components/shared/elements/ConfirmationModal';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { renderDemoToast, renderToast, overFieldNames } from '@evdy/web-core/dist/lib/utils';

import { editCompanyAction, editCompanyReset } from '@evdy/web-redux/dist/actions/dash/editCompany';
import { editHomeAction, editHomeReset } from '@evdy/web-redux/dist/actions/dash/editHome';
import { fetchCompany } from '@evdy/web-redux/dist/actions/dash/fetchCompany';

import './LocationModal.scss';

const LocationModal = ({
  // component props
  closeModal,
  currentHome,
  modalVisible,
  modalType = 'add-location',

  // redux props
  editCompany,
  editHomeData,
  fetchedCompany,
  locationForm,
  locationFormValid,
  userData,
  isInternal,
  funeralHomeLicenseInfo,

  // redux actions
  editCompanyAction,
  editCompanyReset,
  editHomeAction,
  editHomeReset,
  fetchCompany,

  //rrf fncs
  reset
}) => {
  const { data: fetchedCompanyData = {} } = fetchedCompany?.company || {};
  const { funeralHomes = [], _id: companyId } = fetchedCompanyData || {};
  const { isDemoModeOn } = userData || {};

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Show confirmation toast and close modal after updating funeral home
  useEffect(() => {
    if (
      editHomeData.isFetching !== true &&
      editHomeData.lastUpdated &&
      !editHomeData.error.message &&
      editHomeData.submitted === true
    ) {
      renderToast({ id: 'home-edit-success-toast', message: 'Successfully updated funeral home' });
      closeModal();
      editHomeReset();
    }
  }, [editHomeData, editHomeReset, closeModal]);

  // Show error toast when trying to edit home
  useEffect(() => {
    if (
      editHomeData.error.message &&
      editHomeData.submitted === false &&
      editHomeData.isFetching === false
    ) {
      renderToast({
        id: 'home-edit-failure-toast',
        message: editHomeData.error.message,
        type: 'error'
      });
      editHomeReset();
    }
  }, [editHomeData, editHomeReset]);

  // Show confirmation toats and close modal after adding a new funeral home
  useEffect(() => {
    if (editCompany.isFetching !== true && editCompany.lastUpdated && !editCompany.error.message) {
      renderToast({ id: 'add-home-success-toast', message: 'Your account has been updated' });
      closeModal();
      editCompanyReset();
    }
  }, [editCompany, editCompanyReset, closeModal]);

  // Show error toast when trying to add a home
  useEffect(() => {
    if (editCompany.error.message && editCompany.isFetching === false) {
      renderToast({
        id: 'add-home-failure-toast',
        message: editCompany.error.message,
        type: 'error'
      });
      editCompanyReset();
    }
  }, [editCompany, editCompanyReset]);

  // update company funeral homes on changes
  useEffect(() => {
    if (
      !editHomeData?.isFetching &&
      !fetchedCompany?.isFetching &&
      editHomeData?.lastUpdated > fetchedCompany?.lastUpdated
    ) {
      fetchCompany(fetchedCompanyData._id);
    }
  }, [fetchedCompanyData._id, editHomeData, fetchCompany, fetchedCompany]);

  const handleModalSave = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-location-modal-save-demo' });
    if (modalType === 'add-location') {
      let updatedFormData = {
        ...locationForm,
        funeralDirectorNumber: null,
        insuranceLicenseNumber: null,
        specialStateLicenseInfo: null,
        specialStateLicenseInfo2: null
      };

      // if state has special keys, use form values otherwise set to empty string
      const { requiredFields = [] } = funeralHomeLicenseInfo[locationForm.address.state] ?? {};
      const fieldKeys = funeralHomeLicenseInfo[locationForm.address.state];
      const fieldValues = locationForm;
      const fieldIsObject = [].includes.bind([
        'specialStateLicenseInfo',
        'specialStateLicenseInfo2'
      ]);

      const mappedFieldValues = requiredFields.map(
        overFieldNames(fieldKeys, fieldValues, fieldIsObject)
      );

      const newLocation = {
        ...updatedFormData,
        ...zipObject(requiredFields, mappedFieldValues)
      };
      editCompanyAction(
        {
          company: fetchedCompanyData,
          funeralHomes: [...funeralHomes, newLocation]
        },
        companyId
      );
    } else {
      editHomeAction(locationForm, currentHome._id);
    }
  };

  const handleConfirmDeletion = () => {
    setIsConfirmModalOpen(true);
  };

  const handleModalDelete = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-location-modal-delete-demo' });
    editCompanyAction(
      {
        company: fetchedCompanyData,
        delete: {
          funeralHomes: [currentHome._id]
        }
      },
      companyId
    );
  };

  const modalHeader = modalType === 'add-location' ? 'Add a Location' : 'Edit Location';

  const confirmSubject = currentHome?.name || 'this Home';
  const confirmCopy = `Are you sure you want to delete ${confirmSubject}?`;

  const showLoader =
    fetchedCompany?.isFetching || editHomeData?.isFetching || editCompany?.isFetching;

  const handleCloseModal = () => {
    reset('dash.company.forms.locationModal');
    closeModal();
  };

  return (
    <ModalOverlay {...{ modalVisible }} closeModal={handleCloseModal} customClass="location-modal">
      <ResponsiveContent>
        <GradientModalHeader closeModal={handleCloseModal} headerCopy={modalHeader} />
        <LocationModalBody
          isAddHome={modalType === 'add-location'}
          model="dash.company.forms.locationModal"
          form={locationForm}
          fhLocation={currentHome}
          {...{ isInternal }}
        />
        <ModalFooter
          mainButtonText="Save"
          mainButtonFn={handleModalSave}
          disabled={!locationFormValid}
          secondaryButtonText="Cancel"
          secondaryButtonFn={handleCloseModal}
          tertiaryButtonText="Delete"
          tertiaryButtonFn={handleConfirmDeletion}
          tertiaryButtonHide={modalType === 'add-location'}
        />
        <ConfirmationModal
          confirmCopy={confirmCopy}
          display={isConfirmModalOpen}
          onConfirm={handleModalDelete}
          onClose={() => setIsConfirmModalOpen(false)}
        />
        {showLoader && <LoaderComponent />}
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(
  ({ authUser, dash, client }) => ({
    editCompany: dash.editCompany,
    editHomeData: dash.editHome,
    fetchedCompany: dash.fetchedCompany,
    locationForm: dash.company.forms.locationModal,
    locationFormValid: dash.company.forms.companyMeta.locationModal.$form.valid,
    userData: authUser?.data,
    isInternal: !dash?.dashNav?.isExternalMode,
    funeralHomeLicenseInfo: client.config.data.funeralHomeLicenseInfo
  }),
  {
    editCompanyAction,
    editCompanyReset,
    editHomeAction,
    editHomeReset,
    fetchCompany,
    reset: formActions.reset
  }
)(LocationModal);
