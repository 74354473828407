import React from 'react';

import Button from '@evdy/web-core/dist/components/Button';

import './EmptyEventRow.scss';

import emptyCal from './empty-cal.svg';

const EmptyEventRow = ({ btnAction = () => {}, customClass = '', rowCopy, btnCopy }) => {
  return (
    <div className={`empty-event-row ${customClass}`}>
      <div className="cal-and-row-copy">
        <img className="empty-cal" src={emptyCal} alt="blank-calendar-icon" />
        <div className="copy-wrapper">
          <div className="main-copy">{rowCopy}</div>
          <div className="empty-row" />
        </div>
      </div>
      <Button copy={btnCopy} style="white-background-black-outline" action={btnAction} />
    </div>
  );
};

export default EmptyEventRow;
