"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateEmailAction = exports.updateEmailFailure = exports.updateEmailSuccess = exports.updateEmailRequest = void 0;

var _onboarding = require("../../../constants/dash/onboarding");

var _constants = require("../../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var updateEmailRequest = function updateEmailRequest() {
  return {
    type: _onboarding.ONBOARD_UPDATE_EMAIL_REQUEST
  };
};

exports.updateEmailRequest = updateEmailRequest;

var updateEmailSuccess = function updateEmailSuccess(_ref) {
  var data = _ref.data;
  return {
    type: _onboarding.ONBOARD_UPDATE_EMAIL_SUCCESS,
    receivedAt: Date.now(),
    payload: data
  };
};

exports.updateEmailSuccess = updateEmailSuccess;

var updateEmailFailure = function updateEmailFailure(error) {
  return {
    type: _onboarding.ONBOARD_UPDATE_EMAIL_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.updateEmailFailure = updateEmailFailure;

var updateEmailAction = function updateEmailAction(email, phone) {
  return function (dispatch) {
    dispatch(updateEmailRequest());
    return _index.default.post("".concat(_constants.API_URL, "/onboard/update-email"), {
      email: email,
      phone: phone
    }).then(function (res) {
      return dispatch(updateEmailSuccess(res.data));
    }).catch(function (err) {
      return dispatch(updateEmailFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.updateEmailAction = updateEmailAction;