import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import InvitePreviewCard from '../InvitePreviewCard';
import EditProviderModal from '../../modals/EditProviderModal';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { formatAddress, nameString, renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import {
  editFuneralInfoAction,
  editFuneralInfoResetAction
} from '@evdy/web-redux/dist/lib/inviteCreation/operations';

import jobTitles from '@evdy/web-etc/dist/lib/jobTitles';

import './ProviderPreview.scss';

const ProviderPreviewCard = ({
  accessorsArr,
  editFuneralInfoAction,
  editFuneralInfoResetAction,
  formData,
  funeralInfo,
  isDemoModeOn = false,
  memorialId,
  providerFormModel = 'inviteCreation.forms.inviteCreationDetails'
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { director, funeralHome } = funeralInfo || {};
  const inviteCreator = accessorsArr.find(acc => acc.type === 'owner')?.userRef;

  const model = providerFormModel;

  // close modal after success
  useEffect(() => {
    if (funeralInfo?.lastUpdated) {
      setModalVisible(false);
      editFuneralInfoResetAction();
    }
  }, [funeralInfo, editFuneralInfoResetAction]);

  const handleEditProvider = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onSubmitAction = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'annc-list-filter-button-demo' });

    const { funeralHome: formFuneralHome, director: formDirector } = formData;
    editFuneralInfoAction({
      memorialId,
      funeralHomeId: formFuneralHome,
      funeralDirectorId: formDirector
    });
  };

  return (
    <InvitePreviewCard headerCopy="Funeral home" customClass="provider-card-container">
      <div className="funeral-home-container provider-row">
        <div className="provider-info">
          <CharacterProfileImage image={funeralHome?.image} name={funeralHome?.name} />
          <div className="home-info-container">
            <div className="home-name">{funeralHome?.name}</div>
            <div className="home-address">{formatAddress(funeralHome?.address)}</div>
          </div>
        </div>
        <button onClick={handleEditProvider} className="edit-provider-button">
          Edit
        </button>
      </div>
      <div className="director-info-container provider-row">
        <div className="provider-info">
          <CharacterProfileImage image={director?.image} name={director?.name} />
          <div className="director-info">
            <div className="director-name">{nameString(director?.name || '')}</div>
            <div className="director-title">{jobTitles[director?.title]}</div>
          </div>
        </div>
        <button onClick={handleEditProvider} className="edit-provider-button">
          Edit
        </button>
      </div>
      {inviteCreator?._id && (
        <div className="creator-details-container provider-row">
          <div className="provider-info">
            <CharacterProfileImage image={inviteCreator?.image} name={inviteCreator?.name} />
            <div className="invite-creator-info">
              <div className="creator-name">{nameString(inviteCreator?.name || '')}</div>
              <div className="creator-title">
                {jobTitles[inviteCreator?.title]} -<div>Memorial Creator</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <EditProviderModal
        {...{ formData, modalVisible, model, onSubmitAction }}
        closeModal={handleCloseModal}
      />
    </InvitePreviewCard>
  );
};

export default connect(null, {
  editFuneralInfoAction,
  editFuneralInfoResetAction
})(ProviderPreviewCard);
