import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import InvitePreviewCard from '../InvitePreviewCard';
import FamilyMemberRow from '../../../../shared/DashCard/CardBuilder/FamilyMemberRow';
import EditAccessorInfoModal from '../../modals/EditAccessorInfoModal';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import './FamilyPreview.scss';

const FamilyPreview = ({
  accessors,
  accessorFormData,
  accessorFormMetaData,
  funeralDirectorId,
  premiumProgram,
  memorialId,
  merge,
  accessorFormModel = 'inviteCreation.forms.accessor',
  reset,
  isInternal
}) => {
  const { array: family, lastUpdated, error = {} } = accessors || {};
  const [modalVisible, setModalVisible] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const prevLastUpdated = useRef(0);

  const model = accessorFormModel;

  const filteredFamily = family.filter(fam => (!isInternal ? fam?.type !== 'viewer' : true));

  const mapFamilyMembers = familyArray => {
    return familyArray.map((fam, i) => {
      const { name = {}, relation, email, _id } = fam;
      const handleEdit = () => {
        handleOpenModal({ memberIndex: i, familyArray });
      };
      return (
        <FamilyMemberRow
          key={_id}
          customClass="fam-mem-row-container"
          {...{ title: name.first, subtitle: name.last, relation, email, handleEdit }}
        />
      );
    });
  };

  const handleOpenModal = ({ memberIndex, familyArray }) => {
    const editMode = memberIndex !== null;
    setModalVisible(true);
    if (editMode) {
      const { name, phone, email, relation, primarySurvivor } = familyArray[memberIndex];
      setMemberId(familyArray[memberIndex]._id);
      merge(model, { name, phone, email, relation, primarySurvivor });
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setMemberId(null);
    reset(model);
  };

  // close accessor modal if data was successfully updated
  useEffect(() => {
    const didUpdate = prevLastUpdated.current < lastUpdated - 50;
    if (didUpdate && modalVisible) {
      closeModal();
    }
    prevLastUpdated.current = lastUpdated;
  }, [lastUpdated, modalVisible]);

  return (
    <InvitePreviewCard
      cautionFlag={true}
      customClass="fam-prev-container"
      headerBtnAction={() => handleOpenModal({ memberIndex: null })}
      headerBtnCopy="Add family"
      headerCopy="Family and close friends"
      inset={!family.length}
    >
      <div className="fam-member-rows">{mapFamilyMembers(filteredFamily)}</div>
      {!family.length && <div className="empty-row-state">Add family and friends...</div>}
      <EditAccessorInfoModal
        {...{
          accessorFormData,
          accessorFormMetaData,
          memorialId,
          model,
          modalVisible,
          closeModal,
          memberId,
          error,
          premiumProgram
        }}
      />
    </InvitePreviewCard>
  );
};

export default connect(null, { merge: formActions.merge, reset: formActions.reset })(FamilyPreview);
