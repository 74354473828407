import axios from 'axios/index';
import transAxiosErr from '@evdy/web-etc/dist/lib/transformAxiosError';

import * as types from './constants';

import { API_URL } from '../../constants';

const instance = axios.create({
  baseURL: API_URL
});

const editManyHomesRequest = () => ({
  type: types.EDIT_MANY_HOMES_REQUEST
});

const editManyHomesSuccess = data => ({
  type: types.EDIT_MANY_HOMES_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

const editManyHomesFailure = error => ({
  type: types.EDIT_MANY_HOMES_FAILURE,
  error,
  receivedAt: Date.now()
});

export const editManyHomes = data => dispatch => {
  dispatch(editManyHomesRequest());

  return instance.put(`/funeral-home/all`, data).then(
    res => {
      dispatch(editManyHomesSuccess(res.data));
    },
    error => dispatch(editManyHomesFailure(transAxiosErr(error)))
  );
};
