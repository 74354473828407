import React, { forwardRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import TextInput from '../TextInput';

import styles from './AddDonationForm.module.scss';

const DonationForm = forwardRef(
  (
    { handleSubmit, handleCloseModal, hasDonation, currentDonation, handleDelete, memorialId },
    ref
  ) => {
    const DonationFormSchema = Yup.object().shape({
      organizationName: Yup.string().required('This is required!'),
      donationUrl: Yup.string().required('This is required!'),
      description: Yup.string()
    });

    const {
      _id: donationId,
      title: currentDonationTitle,
      url: currentDonationUrl,
      description: currentDonationDescription
    } = currentDonation || {};

    const initialFormValues = {
      organizationName: hasDonation ? currentDonationTitle : '',
      donationUrl: hasDonation ? currentDonationUrl : '',
      description: hasDonation ? currentDonationDescription : ''
    };

    return (
      <Formik
        initialValues={initialFormValues}
        validationSchema={DonationFormSchema}
        onSubmit={e => handleSubmit({ ...e, hasDonation, donationId })}
        enableReinitialize
        innerRef={ref}
      >
        {({ isValid }) => {
          return (
            <Form className={styles.donationForm}>
              <div className={styles.inputContainer}>
                <TextInput type="text" label="Organization name" name="organizationName" />
                <TextInput
                  type="text"
                  label="URL to donate"
                  name="donationUrl"
                  placeholder="https://"
                />
                <TextInput
                  textarea
                  type="text"
                  label="Description"
                  name="description"
                  placeholder="Add helpful details here. This may include a link to view a live stream of the services, important instructions for guests, or anything else you'd like to share"
                />
              </div>
              <div className={styles.footerSection}>
                {hasDonation && (
                  <button
                    type="button"
                    onClick={e => handleDelete({ e, donationId, memorialId })}
                    className={styles.deleteBtn}
                  >
                    Delete
                  </button>
                )}
                <button
                  onClick={() => handleCloseModal()}
                  type="button"
                  className={styles.cancelBtn}
                >
                  Cancel
                </button>
                <button className={styles.submitBtn} valid={isValid.toString()} type="submit">
                  Save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
);

export default DonationForm;
