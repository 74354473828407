import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PINInput from '@evdy/web-core/dist/components/shared/elements/PINInput';

import {
  Form,
  InputGroup,
  isRequired,
  isFourDigitPIN,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';

import { checkPhoneAction } from '@evdy/web-redux/dist/actions/dash/onboarding/checkPhone';

import './VerifyPhone.scss';

const VerifyPhone = props => {
  const {
    code,
    dispatch,
    handleNext,
    onboardUser: { error },
    phone
  } = props;

  const [codeResent, setCodeResent] = useState(false);

  const model = 'dash.onboarding.forms.verifyPhone';

  // automatically submit code when 4 digits are filled
  useEffect(() => {
    if (code.length === 4) {
      handleNext();
    }
  }, [code]); // putting handleNext as a dep will cause an infinite loop

  const onPINChange = input => {
    props.dispatch(formActions.change(`${model}.code`, input));
  };

  const resendCode = () => {
    dispatch(checkPhoneAction(phone)).then(() => setCodeResent(true));
  };

  return (
    <div className="verify-phone-step">
      <Form model={model}>
        <div className="onboard-form-row">
          <PINInput onComplete={handleNext} onPINChange={onPINChange} />
          <InputGroup
            required
            customClass="pin-input-group"
            model=".code"
            validators={{ isRequired, isFourDigitPIN }}
            messages={{
              isRequired: 'Verification code is required',
              isFourDigitPIN: 'Please enter a valid PIN'
            }}
          />
        </div>
        {error?.message && <p className="pin-error-message">{error.message}</p>}
      </Form>
      {codeResent ? (
        <span>Code resent!</span>
      ) : (
        <button className="resend-code-button" onClick={resendCode}>
          Didn't get a text message? Resend code
        </button>
      )}
    </div>
  );
};

export default connect(({ dash }) => ({
  code: dash.onboarding.forms.verifyPhone.code,
  onboardUser: dash.onboarding.onboardUser,
  phone: dash.onboarding.forms.checkPhone.phone
}))(VerifyPhone);
