import React, { useEffect } from 'react';
import curry from 'lodash/curry';
import { connect } from 'react-redux';
import { actions as formActions } from 'react-redux-form';

import { fetchGoogleAddress } from '@evdy/web-redux/dist/lib/googleAddress';

import { GMAPS_PLACES_URL } from '@evdy/web-redux/dist/constants';

import Script from 'react-load-script';

import {
  Form,
  InputGroup,
  isRequired,
  isValidPhone,
  isValidZip,
  parsePhone,
  formatHTTPS
} from '@evdy/web-core/dist/components/shared/Input';
import { googleAnalytics } from '@evdy/web-core/dist/lib/utils';

const CompanyInfo = props => {
  const { dispatch, companyName, fetchedGoogleAddress } = props;
  const model = 'dash.onboarding.forms.companyInfo';

  const updateFormData = addressObject => {
    const searchWithinArray = curry((array, searchTerms) =>
      array.find(obj => obj.types.includes(searchTerms))
    );

    const updateForm = key => value => {
      dispatch(formActions.merge(model, { [key]: value }));
    };

    const searchAddress = searchWithinArray(addressObject.address_components);

    updateForm('name')(addressObject?.name || '');
    updateForm('zip')(searchAddress('postal_code')?.long_name || '');
    updateForm('city')(searchAddress('locality', 'fancy')?.long_name || '');
    updateForm('country')(searchAddress('country', 'political')?.long_name || '');
    updateForm('state')(searchAddress('administrative_area_level_1')?.long_name || '');
    updateForm('phone')(addressObject?.formatted_phone_number || '');
    updateForm('website')(addressObject?.website || '');
    const streetNumber = searchAddress('street_number')?.long_name || '';
    const streetName = searchAddress('route')?.long_name || '';
    updateForm('address')(streetNumber + ' ' + streetName);
  };

  useEffect(() => {
    if (fetchedGoogleAddress?.data?.address_components) {
      updateFormData(fetchedGoogleAddress?.data);
    }
  }, [fetchedGoogleAddress?.data?.address_components]);

  let autocomplete = undefined;
  let placeDetails = undefined;

  const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace();
    let addressComponents = addressObject?.address_components;

    if (addressComponents) {
      updateFormData(addressObject);
    }
  };

  const handleScriptLoad = () => {
    //your linter might suggest "google" is an error. It is not.
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autoCompleteInput')
    );

    autocomplete.addListener('place_changed', handlePlaceSelect);

    if (companyName?.name) {
      const request = {
        query: companyName.name
      };

      // for some reason it needs a target. Normally it would be a map.
      placeDetails = new google.maps.places.PlacesService(document.getElementById('google-div'));

      placeDetails.textSearch(request, async (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          getAddressComponents(results[0].place_id);
        }
      });
    }
  };

  const getAddressComponents = place_id => {
    dispatch(
      fetchGoogleAddress({
        place_id
      })
    );
  };

  return (
    <div className="company-info-step">
      <Script url={GMAPS_PLACES_URL} onLoad={handleScriptLoad} />
      <div id="google-div" />
      {/*<div className="onboard-form-row full-single-input">*/}
      {/*  <div className="ev-input-group">*/}
      {/*    <label className="ev-label">Search for your Business</label>*/}
      {/*    <input*/}
      {/*      className="ev-control"*/}
      {/*      id="autoCompleteInput"*/}
      {/*      type="text"*/}
      {/*      placeholder="Search for your Business here"*/}
      {/*      autoFocus*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Form model={model}>
        <div className="onboard-form-row">
          <InputGroup
            required
            defaultValue={companyName.name}
            labelText="Name"
            model=".name"
            validators={{ isRequired }}
            messages={{ isRequired: 'Name is required' }}
          />
          <InputGroup
            required
            labelText="Address"
            model=".address"
            validators={{ isRequired }}
            messages={{ isRequired: 'Address is required' }}
          />
        </div>
        <div className="onboard-form-row">
          <InputGroup
            required
            labelText="City"
            model=".city"
            validators={{ isRequired }}
            messages={{ isRequired: 'City is required' }}
          />
          <InputGroup
            required
            labelText="State/Province"
            model=".state"
            validators={{ isRequired }}
            messages={{ isRequired: 'State is required' }}
          />
        </div>
        <div className="onboard-form-row">
          <InputGroup
            required
            labelText="Postal Code"
            model=".zip"
            validators={{ isRequired, isValidZip }}
            messages={{
              isRequired: 'Postal Code is required',
              isValidZip: 'Please enter a valid zip code'
            }}
          />
          <InputGroup
            required
            labelText="Country"
            model=".country"
            type="select"
            defaultValue="United States"
            validators={{ isRequired }}
            messages={{ isRequired: 'Country is required' }}
          >
            <option value="United States">United States</option>
            <option value="Canada">Canada</option>
          </InputGroup>
        </div>
        <div className="onboard-form-row">
          <InputGroup
            required
            labelText="Business Phone"
            model=".phone"
            type="tel"
            parser={parsePhone}
            validators={{ isRequired, isValidPhone }}
            messages={{
              isRequired: 'Phone is required',
              isValidPhone: 'Please enter a valid phone number'
            }}
          />
          <InputGroup labelText="Website" model=".website" parser={formatHTTPS} />
        </div>
      </Form>
    </div>
  );
};

export default connect(({ dash, fetchedGoogleAddress }) => ({
  companyName: dash.onboarding.forms.companyName,
  fetchedGoogleAddress
}))(CompanyInfo);
