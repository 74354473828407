import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import zipObject from 'lodash-es/zipObject';

import {
  ModalOverlay,
  ModalFooter,
  GradientModalHeader,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import MissingRequiredFieldsForm from './MissingRequiredFieldsForm';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { editCompanyAction, editCompanyReset } from '@evdy/web-redux/dist/actions/dash/editCompany';
import { funeralHomeOperations } from '@evdy/web-redux/dist/lib/funeralHome';

import { overFieldNames } from '@evdy/web-core/dist/lib/utils';
import { requiredFieldsOperations } from '@evdy/web-redux/dist/lib/requiredFields';

import './MissingFieldsModal.scss';

const { editFuneralHome } = funeralHomeOperations;

const companyFormModel = 'dash.company.forms.overview';
const locationFormModel = 'funeralHome.forms.homeDetails';

const MissingFieldsModal = ({
  modalVisible,
  closeModal,
  fromAccountSetup,
  fromLocationDetails,
  funeralHomeId
}) => {
  const { getRequiredFields } = requiredFieldsOperations;
  const { reset, change, setFieldsValidity } = formActions;
  const dispatch = useDispatch();

  //redux props
  const {
    missingFields = {},
    companyFormData = {},
    companyFormValid = false,
    fetchedCompanyData = {},
    locationFormData = {},
    locationFormValid = false,
    locationData = {},
    funeralHomeLicenseInfo,
    missingFieldsSubmitted,
    missingFieldsLastUpdated,
    account = {}
  } = useSelector(({ dash, requiredPreneedFields, funeralHome, client }) => ({
    fetchedCompanyData: dash.fetchedCompany?.company?.data,
    missingFields: requiredPreneedFields?.data,
    companyFormData: dash.company.forms?.overview,
    companyFormValid: dash.company.forms?.companyMeta?.overview?.$form?.valid,
    locationFormData: funeralHome.forms?.homeDetails,
    locationFormValid: funeralHome.forms?.formsMeta?.$form?.valid,
    locationData: funeralHome.home?.data,
    missingFieldsSubmitted: requiredPreneedFields?.missingFieldsSubmitted,
    missingFieldsLastUpdated: requiredPreneedFields?.missingFieldsLastUpdated,
    funeralHomeLicenseInfo: client.config.data.funeralHomeLicenseInfo,
    account: dash.fetchAccounts.account.data
  }));

  const selectedAccount = account?._id ? account : fetchedCompanyData;
  const {
    _id = '',
    users = [],
    funeralHomes = [],
    name,
    email,
    type,
    website,
    phone,
    logo,
    address,
    taxID: currentCompTaxID,
    taxClassification: currentCompTaxClassification
  } = selectedAccount || {};
  const { taxID = '', taxClassification = '' } = companyFormData || {};
  const {
    specialStateLicenseInfo = '',
    specialStateLicenseInfo2 = '',
    funeralDirectorNumber = '',
    insuranceLicenseNumber = ' ',
    homeHasOwnTaxInfo = false,
    taxID: homeTaxIDFormData = '',
    taxClassification: homeTaxClassificationFormData = ''
  } = locationFormData || {};
  const { funeralHome = [], company = [] } = missingFields || {};

  const defaultFuneralHomeId = Array.isArray(funeralHomes) && funeralHomes[0]?._id;
  const currentFuneralHome = locationData || {};
  const {
    specialStateLicenseInfo: currentLocationSpecialLicenseInfo = {},
    specialStateLicenseInfo2: currentLocationSpecialLicenseInfo2 = {},
    funeralDirectorNumber: currentLocationFuneralDirectorNumber = '',
    insuranceLicenseNumber: currentLocationInsuranceLicenseNumber = '',
    homeHasOwnTaxInfo: currentHomeHasOwnInfo = false,
    taxID: currentHomeTaxID = '',
    taxClassification: currentHomeTaxClass = ''
  } = currentFuneralHome || {};
  const { value: stateLicenseInfoValue = '' } = currentLocationSpecialLicenseInfo || {};
  const { value: stateLicenseInfoValue2 = '' } = currentLocationSpecialLicenseInfo2 || {};
  const funeralHomeState = currentFuneralHome?.address?.state;

  const mainCopy = `Your ${fromAccountSetup ? 'account' : 'home'} is almost ready to begin selling`;
  const subTextCopy = 'We just need these additional fields to be filled to complete this location';

  const isFormValid = fromAccountSetup ? companyFormValid : companyFormValid && locationFormValid;

  const handleSave = () => {
    if (companyFormValid && company.length) {
      const companyObj = {
        name,
        email,
        type,
        website,
        phone,
        logo,
        address,
        taxID,
        taxClassification
      };
      const submitObj = {
        company: { ...companyObj },
        funeralHomes,
        users,
        delete: {}
      };

      dispatch(editCompanyAction(submitObj, _id));
    }

    if (!fromAccountSetup && locationFormValid) {
      const formData = {
        specialStateLicenseInfo,
        specialStateLicenseInfo2,
        funeralDirectorNumber,
        insuranceLicenseNumber,
        homeHasOwnTaxInfo,
        taxID: homeTaxIDFormData,
        taxClassification: homeTaxClassificationFormData
      };

      const updatedFormData = {
        ...locationData,
        ...formData,
        funeralDirectorNumber: null,
        insuranceLicenseNumber: null,
        specialStateLicenseInfo: null,
        specialStateLicenseInfo2: null
      };

      // if state has special keys, use form values otherwise keep empty string
      const { requiredFields = [] } = funeralHomeLicenseInfo[funeralHomeState] ?? {};
      const fieldKeys = funeralHomeLicenseInfo[funeralHomeState];
      const fieldValues = formData;
      const fieldIsObject = [].includes.bind([
        'specialStateLicenseInfo',
        'specialStateLicenseInfo2'
      ]);

      const mappedFieldValues = requiredFields.map(
        overFieldNames(fieldKeys, fieldValues, fieldIsObject)
      );

      dispatch(
        editFuneralHome(funeralHomeId, {
          ...updatedFormData,
          ...zipObject(requiredFields, mappedFieldValues)
        })
      );
    }
  };

  const handleCloseModal = useCallback(() => {
    dispatch(reset(companyFormModel));
    dispatch(reset(locationFormModel));
    closeModal();
  }, [closeModal, dispatch, reset]);

  // merge form data if it exists from b.e.
  useEffect(() => {
    dispatch(reset(companyFormModel));
    dispatch(reset(locationFormModel));
    // company form info merge
    currentCompTaxID && dispatch(change(`${companyFormModel}.taxID`, currentCompTaxID));
    currentCompTaxClassification &&
      dispatch(change(`${companyFormModel}.taxClassification`, currentCompTaxClassification));
    // location form info merge
    stateLicenseInfoValue &&
      dispatch(change(`${locationFormModel}.specialStateLicenseInfo`, stateLicenseInfoValue));
    stateLicenseInfoValue2 &&
      dispatch(change(`${locationFormModel}.specialStateLicenseInfo2`, stateLicenseInfoValue2));
    currentLocationFuneralDirectorNumber &&
      dispatch(
        change(`${locationFormModel}.funeralDirectorNumber`, currentLocationFuneralDirectorNumber)
      );
    currentLocationInsuranceLicenseNumber &&
      dispatch(
        change(`${locationFormModel}.insuranceLicenseNumber`, currentLocationInsuranceLicenseNumber)
      );
    currentHomeHasOwnInfo &&
      dispatch(change(`${locationFormModel}.homeHasOwnTaxInfo`, currentHomeHasOwnInfo));
    currentHomeTaxID && dispatch(change(`${locationFormModel}.taxID`, currentHomeTaxID));
    currentHomeTaxClass &&
      dispatch(change(`${locationFormModel}.taxClassification`, currentHomeTaxClass));
  }, [
    change,
    currentCompTaxClassification,
    currentCompTaxID,
    currentHomeHasOwnInfo,
    currentHomeTaxClass,
    currentHomeTaxID,
    currentLocationFuneralDirectorNumber,
    currentLocationInsuranceLicenseNumber,
    dispatch,
    reset,
    stateLicenseInfoValue,
    stateLicenseInfoValue2
  ]);

  // request missing required fields on mount
  useEffect(() => {
    if (defaultFuneralHomeId && fromAccountSetup) {
      dispatch(getRequiredFields({ funeralHomeId: defaultFuneralHomeId }));
    } else if (funeralHomeId && fromLocationDetails) {
      dispatch(getRequiredFields({ funeralHomeId }));
    }
  }, [
    defaultFuneralHomeId,
    dispatch,
    fromAccountSetup,
    fromLocationDetails,
    funeralHomeId,
    getRequiredFields
  ]);

  // revalidate conditional fields after unchecking homeHasOwnTaxInfo checkbox
  useEffect(() => {
    if (!homeHasOwnTaxInfo) {
      dispatch(
        setFieldsValidity(locationFormModel, { '.taxID': true, '.taxClassification': true })
      );
      //reset form values in case submit happens after fields were filled && checkbox unchecked
      dispatch(reset(`${locationFormModel}.taxID`));
      dispatch(reset(`${locationFormModel}.taxClassification`));
    }
  }, [homeHasOwnTaxInfo]);

  // close modal on success
  useEffect(() => {
    if (!missingFieldsSubmitted && missingFieldsLastUpdated >= Date.now() - 3e2) {
      //refetch missing fields on success to update action row
      dispatch(getRequiredFields({ funeralHomeId: funeralHomeId || defaultFuneralHomeId }));

      handleCloseModal();
      dispatch(editCompanyReset()); // reset edit company on success to prevent any toasts from appearing on other pages (ie. Account/Company)
    }
  }, [
    defaultFuneralHomeId,
    dispatch,
    funeralHomeId,
    getRequiredFields,
    missingFieldsLastUpdated,
    missingFieldsSubmitted
  ]);

  return (
    <ModalOverlay
      {...{ modalVisible }}
      closeModal={handleCloseModal}
      customClass="required-fields-modal"
    >
      <ResponsiveContent>
        <GradientModalHeader headerCopy="Additional Info Required" closeModal={handleCloseModal} />
        <div className="modal-info-copy">
          <h3>{mainCopy}</h3>
          <p>{subTextCopy}</p>
        </div>
        <MissingRequiredFieldsForm
          {...{
            missingFields,
            companyFormModel,
            locationFormModel,
            fromLocationDetails,
            homeHasOwnTaxInfo
          }}
          address={locationData?.address}
        />
        <ModalFooter
          mainButtonText="Finish"
          disabled={!isFormValid}
          mainButtonFn={handleSave}
          secondaryButtonText="Cancel"
          secondaryButtonFn={handleCloseModal}
        />
      </ResponsiveContent>
      {missingFieldsSubmitted && <LoaderComponent />}
    </ModalOverlay>
  );
};

export default MissingFieldsModal;
