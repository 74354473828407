import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import AnnouncementItem from '../AnnouncementItem';

import {
  fetchCompanyMemorials,
  loadNext
} from '@evdy/web-redux/dist/actions/dash/fetchCompanyMemorials';
import { fetchDemoAnnouncements } from '@evdy/web-redux/dist/lib/demoMode';

import './RecentAnncs.scss';

const RecentAnncs = props => {
  const {
    client,
    fetchedCompany,
    companyMemorials,
    user,
    fetchCompanyMemorials,
    fetchDemoAnnouncements,
    sectionTitle = 'Recent Invites',
    companyIdFilter,
    locationFilter,
    directorFilter,
    isLimitedAccess = true,
    anncAmt = 3
  } = props;

  const { isDemoModeOn = false } = user || {};

  const { themes = [], backgrounds = [] } = client?.config?.data;

  const fetchedCompanyId = companyIdFilter || fetchedCompany?.company?.data?._id;
  const idToFetch = fetchedCompanyId === 'demo-company' ? user?.company?._id : fetchedCompanyId;

  // fetch company memorials that match search criteria
  useEffect(() => {
    if (companyMemorials.lastUpdated < Date.now() - 1e3) {
      isDemoModeOn
        ? fetchDemoAnnouncements()
        : fetchCompanyMemorials({ id: idToFetch, isLimitedAccess, locationFilter, directorFilter });
    }
  }, [
    idToFetch,
    isDemoModeOn,
    fetchDemoAnnouncements,
    fetchCompanyMemorials,
    companyMemorials.lastUpdated
  ]);

  const recentAnncs = props.companyMemorials?.memorials?.data?.slice(0, anncAmt) || [];

  return (
    <div className="recent-anncs-section">
      <h2>{sectionTitle}</h2>
      {recentAnncs?.map?.(memorial => (
        <AnnouncementItem key={memorial._id} {...{ backgrounds, memorial, themes }} />
      ))}
      {!!recentAnncs?.length && (
        <button className="btn">
          <Link to="/dash/a/announcements">View all Invites</Link>
        </button>
      )}
    </div>
  );
};

export default connect(
  ({ client, dash }) => ({
    client,
    fetchedCompany: dash.fetchedCompany,
    companyMemorials: dash.companyMemorials,
    user: dash.user.data
  }),
  { fetchCompanyMemorials, loadNext, fetchDemoAnnouncements }
)(RecentAnncs);
