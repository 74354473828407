import React from 'react';
import { bool } from 'prop-types';
import { browserHistory } from 'react-router';

import DashCard from '../../shared/DashCard';
import ProgressBar from '../../shared/DashCard/CardBuilder/ProgressBar';
import ActionItemRow from '../../shared/DashCard/CardBuilder/ActionItemRow';

import './CompleteSetupCard.scss';

const CompleteSetupCard = ({ hasMinLocations, hasMinUsers }) => {
  const actionRows = [
    {
      rowStatus: 'complete',
      rowCopy: 'Profile',
      complete: {
        secondaryButtonCopy: 'Edit',
        secondaryButtonAction: () => {
          browserHistory.push('/dash/a/profile');
        }
      }
    },
    {
      rowStatus: 'complete',
      rowCopy: 'Funeral Home Account',
      complete: {
        secondaryButtonCopy: 'Edit',
        secondaryButtonAction: () => browserHistory.push('/dash/a/company')
      }
    },
    {
      rowStatus: hasMinLocations ? 'complete' : 'notStartedOutline',
      rowCopy: hasMinLocations ? 'Funeral Home Locations' : 'Add Funeral Home Locations',
      notStartedOutline: {
        buttonCopy: 'Add a Location',
        buttonAction: () => browserHistory.push('/dash/a/locations')
      },
      complete: {
        secondaryButtonCopy: 'Edit',
        secondaryButtonAction: () => browserHistory.push('/dash/a/locations')
      }
    },
    {
      rowStatus: hasMinUsers ? 'complete' : 'notStartedOutline',
      rowCopy: hasMinUsers ? 'Team Members' : 'Add Team Members',
      notStartedOutline: {
        buttonCopy: 'Add Team',
        buttonAction: () => browserHistory.push('/dash/a/team-members')
      },
      complete: {
        secondaryButtonCopy: 'Edit',
        secondaryButtonAction: () => browserHistory.push('/dash/a/team-members')
      }
    }
  ];

  const totalSteps = actionRows.length;
  const completeSteps = actionRows
    .map(({ rowStatus }) => rowStatus)
    .filter(status => status === 'complete').length;

  return (
    <DashCard customClass="complete-setup-card">
      <div className="activity-card-header">
        <ProgressBar numOfSteps={totalSteps} numOfCompleteSteps={completeSteps} />
      </div>
      {actionRows.map(rowProps => {
        const { rowStatus, rowCopy } = rowProps || {};
        return (
          <ActionItemRow
            key={rowCopy}
            {...{ rowStatus, rowCopy }}
            buttonCopy={rowProps[rowStatus].buttonCopy}
            buttonAction={rowProps[rowStatus].buttonAction}
            secondaryButtonCopy={rowProps[rowStatus].secondaryButtonCopy}
            secondaryButtonAction={rowProps[rowStatus].secondaryButtonAction}
          />
        );
      })}
    </DashCard>
  );
};

CompleteSetupCard.propTypes = {
  hasMinLocations: bool.isRequired,
  hasMinUsers: bool.isRequired
};

export default CompleteSetupCard;
