import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import EditAccessorInfoForm from '../../forms/EditAccessorInfoForm';
import {
  ModalOverlay,
  ResponsiveContent,
  SolidColorModalHeader,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';
import { renderToast } from '@evdy/web-core/dist/lib/utils';

import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';

import './EditAccessorInfoModal.scss';

const { addAccessorAction, editAccessorAction, removeAccessorAction, resetAccessorErrorAction } =
  creationOperations;

const EditAccessorInfoModal = props => {
  const {
    model,
    modalVisible,
    closeModal,
    memorialId,
    memberId,
    reset,
    accessorFormData,
    accessorFormMetaData,
    addAccessorAction,
    editAccessorAction,
    removeAccessorAction,
    resetAccessorErrorAction,
    error
  } = props;
  const { valid: isValid } = accessorFormMetaData.$form;
  const editMode = memberId !== null;

  //render error toast
  useEffect(() => {
    if (!!error?.message?.length) {
      renderToast({ id: 'dupe-accessor-err-toast', type: 'error', message: error.message });
      resetAccessorErrorAction();
    }
  }, [error, resetAccessorErrorAction]);

  const handleCloseModal = () => {
    reset(model);
    closeModal();
  };

  const onSubmit = () => {
    if (!isValid) return;
    if (editMode) {
      const accessor = { ...accessorFormData, _id: memberId, type: 'manager' };
      editAccessorAction({ memorialId, accessor });
    } else {
      addAccessorAction({ memorialId, newAccessors: [{ ...accessorFormData, type: 'manager' }] });
    }
  };

  const handleRemove = () => {
    removeAccessorAction({ memorialId, accessorId: memberId });
  };

  const modalType = `${editMode ? 'Save' : 'Add'}`;

  return (
    <div className="builder-accessor-info">
      <ModalOverlay {...{ modalVisible }} closeModal={handleCloseModal}>
        <ResponsiveContent>
          <SolidColorModalHeader
            closeModal={handleCloseModal}
            headerCopy={`${editMode ? 'Edit' : 'Add a'} host`}
          />
          <EditAccessorInfoForm {...{ model, editMode, modalVisible }} />
          <ModalFooter
            mainButtonText={modalType}
            mainButtonFn={onSubmit}
            secondaryButtonText="Cancel"
            secondaryButtonFn={handleCloseModal}
            disabled={!isValid}
            tertiaryButtonText="Delete"
            tertiaryButtonFn={handleRemove}
            tertiaryButtonHide={!editMode}
          />
        </ResponsiveContent>
      </ModalOverlay>
    </div>
  );
};

export default connect(null, {
  reset: formActions.reset,
  addAccessorAction,
  editAccessorAction,
  removeAccessorAction,
  resetAccessorErrorAction
})(EditAccessorInfoModal);
