import React from 'react';
import classNames from 'classnames';
import { format, getYear, subYears } from 'date-fns';
import moment from 'moment';

import ChooseMonthInput from '../shared/ChooseMonthInput';
import DropDown from '@evdy/web-core/dist/components/shared/elements/DropDown';
import {
  Form,
  InputGroup,
  Typeahead,
  ErrorNotice,
  isRequired,
  isUrl
} from '@evdy/web-core/dist/components/shared/Input';

import locationIcon from '../../../../../images/location-icon.png';
import calIcon from '../DecedentInfoForm/calendar-icon-black.svg';

import './AddEventsForm.scss';

const AddEventsForm = ({
  currentEvent,
  formData,
  model,
  setLocationFormOpen,
  setLocationsModalOpen,
  validationFns,
  isMemorialFinished
}) => {
  const { eventName, eventDate, startTime, endTime, description, videoStreamUrl } = formData || {};
  const startingYear = getYear(subYears(new Date(), 5));
  const { eventTimeValidators } = validationFns;

  const dropdownClasses = date => classNames({ 'date-selected': !!date });

  const timeClasses = date => classNames({ 'time-selected': !!date });

  const handleValidation = ({ startTime, endTime }) => {
    const momentStartTime = moment.utc(startTime, 'h:mma');
    const momentEndTime = moment.utc(endTime, 'h:mma');
    eventTimeValidators({
      startTime,
      endTime,
      momentStartTime,
      momentEndTime
    });
  };

  return (
    <Form model={model} className="add-events-form">
      <div className="event-location-row">
        <InputGroup
          inline
          model=".eventName"
          labelText="Event name *"
          value={eventName}
          validators={{ isRequired }}
          messages={{ isRequired: 'Please enter an event name' }}
          onChange={name =>
            handleValidation({
              eventName: name.target.value,
              eventDate: eventDate === null ? 'initial' : eventDate,
              startTime,
              endTime
            })
          }
        />
        <InputGroup
          inline
          post={locationIcon}
          labelText="Location"
          model={'.location.name'}
          onFocus={() => {
            setLocationFormOpen(true);
            setLocationsModalOpen(true);
          }}
          customClass="location-name"
        />
      </div>
      <div className="event-date-row">
        <div className="dropdown-container">
          <label className="input-label">Event date *</label>
          <DropDown
            direction="left"
            header={
              <button type="button" className={dropdownClasses(eventDate)}>
                <img src={calIcon} alt="calendar icon" />
                {eventDate ? format(eventDate, 'MM-dd-yyyy') : 'MM-DD-YYYY'}
              </button>
            }
            pane={({ togglePane }) => (
              <ChooseMonthInput
                modelBase={model}
                model=".eventDate"
                initialdate={eventDate}
                onChange={date => {
                  togglePane();
                  handleValidation({ eventDate: date, eventName, startTime, endTime });
                }}
                yearsAddedToCurrentYear={5}
                endingMonth={11}
                unique="from-addEventsForm"
                startYear={startingYear}
              />
            )}
          />
        </div>
        <Typeahead
          inline
          medium
          time
          modelBase={model}
          model=".startTime"
          labelText="Start time"
          value={startTime}
          customClass={`start-time ${timeClasses(startTime)}`}
          onSelect={time => handleValidation({ startTime: time, eventName, eventDate, endTime })}
        />
        <Typeahead
          time
          inline
          medium
          model=".endTime"
          modelBase={model}
          labelText="End time"
          customClass={`end-time ${timeClasses(endTime)}`}
          value={endTime}
          onSelect={time => handleValidation({ endTime: time, eventName, eventDate, startTime })}
        />
        <ErrorNotice
          model={`${model}.endTime`}
          messages={{ validEventTimes: 'Must be after start time' }}
          show={field => field.errors?.validEventTimes}
          customClass="end-time-err"
        />
      </div>
      <div className="live-stream-row">
        <InputGroup
          model=".videoStreamUrl"
          labelText="Live stream link"
          placeholder="e.g. zoom.io/abc"
          defaultValue={videoStreamUrl}
          customClass="live-stream-link"
          validators={{ isUrl }}
          messages={{ isUrl: 'Stream link must be a valid URL' }}
        />
      </div>
      <div className="description-row">
        <InputGroup
          inline
          type="textarea"
          model=".description"
          labelText="Notes"
          defaultValue={description}
          customClass="description"
          placeholder="Add helpful details here. This may include a link to view a live stream of the services, important instructions for guests, or anything else you’d like to share."
        />
      </div>
      {/* notify family removed from form for time being 1/21/21
      {isMemorialFinished && (
        <div className="notify-community-container">
          <InputGroup
            type="checkbox"
            inline
            model=".notifyCommunity"
            labelText="Send text update to community"
            customClass="notify-checkbox"
          />
        </div>
      )} */}
    </Form>
  );
};

export default AddEventsForm;
