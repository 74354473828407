import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CompleteSetupCard from './CompleteSetupCard';
import GenerateSales from './GenerateSales';
import GetStartedCard from './GetStartedCard';
import PreneedTermsModal from '../PreneedTermsModal';

import { renderToast } from '@evdy/web-core/dist/lib/utils';
import { useURLParamSync } from '@evdy/web-core/dist/customHooks/index';

import { funeralHomeOperations } from '@evdy/web-redux/dist/lib/funeralHome';

import './AccountSetup.scss';

const AccountSetup = ({ location: routeLocation }) => {
  const { locations = [], users = [], fetchedCompany = {}, isExternalMode } = useSelector(
    ({ dash }) => ({
      locations: dash.fetchedCompany.company.data?.funeralHomes,
      users: dash.fetchedCompany.company.data?.users,
      fetchedCompany: dash.fetchedCompany,
      isExternalMode: dash.dashNav.isExternalMode
    })
  );
  const dispatch = useDispatch();

  const {
    isFetching: isCompanyFetching,
    lastUpdated: companyLastUpdated,
    company: { data: companyData },
    error: companyError,
    tosAcceptedStateChange
  } = fetchedCompany || {};
  const { preneedTOS = {} } = companyData || {};

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const companyLastUpdatedRef = useRef(companyLastUpdated || 0);

  // close modal after accepting tos
  useEffect(() => {
    if (
      !isCompanyFetching &&
      companyLastUpdated > companyLastUpdatedRef.current + 1000 &&
      tosAcceptedStateChange
    ) {
      setIsTermsModalOpen(false);
      companyLastUpdatedRef.current = companyLastUpdated;
      dispatch(funeralHomeOperations.resetPreneedTOS);
      renderToast({
        id: 'terms-success',
        message: 'Thank you for accepting the Terms and Conditions'
      });
    }
  }, [dispatch, tosAcceptedStateChange, isCompanyFetching, companyLastUpdated]);

  // display toast on error
  useEffect(() => {
    if (companyError?.message) {
      renderToast({ id: 'terms-error', message: companyError?.original?.message, type: 'error' });
    }
  }, [companyError]);

  const memoizedFiltersObj = useMemo(
    () => ({
      isExternalMode
    }),
    [isExternalMode]
  );

  useURLParamSync(memoizedFiltersObj, routeLocation);

  const hasMinLocations = locations.length >= 1;
  const hasMinUsers = users.length >= 2;

  const accountSetupComplete = hasMinLocations && hasMinUsers; // && other conditionals here

  const componentOrderArray = accountSetupComplete
    ? [
        <GenerateSales
          {...{ preneedTOS, locations, routeLocation }}
          handleOpenTermsModal={() => setIsTermsModalOpen(true)}
        />,
        <GetStartedCard />,
        <CompleteSetupCard {...{ hasMinLocations, hasMinUsers }} />
      ]
    : [
        <CompleteSetupCard {...{ hasMinLocations, hasMinUsers }} />,
        <GenerateSales
          {...{ preneedTOS, locations, routeLocation }}
          handleOpenTermsModal={() => setIsTermsModalOpen(true)}
        />,
        <GetStartedCard />
      ];

  return (
    <div className="account-setup-page">
      <header>Set Up Your Account</header>
      <div className="account-setup-cards">{componentOrderArray.map(c => c)}</div>
      <PreneedTermsModal
        modalVisible={isTermsModalOpen}
        closeModal={() => setIsTermsModalOpen(false)}
      />
    </div>
  );
};

export default AccountSetup;
