import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import zipObject from 'lodash-es/zipObject';

import AccLocOverview from './AccLocOverview';
import AccLocProfile from './AccLocProfile';
import AccLocPhotos from './AccLocPhotos';
import AccLocAgents from './AccLocAgents';
import PackageDetails from '../../../../Locations/LocationDetails/PackageDetails';
import SubNav from '@evdy/web-core/dist/components/shared/elements/SubNav';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import { renderToast } from '@evdy/web-core/dist/lib/utils';
import { useURLParamSync } from '@evdy/web-core/dist/customHooks/index';

import { fetchAccounts, fetchAccountsReset } from '@evdy/web-redux/dist/actions/dash/fetchAccounts';
import { funeralHomeOperations } from '@evdy/web-redux/dist/lib/funeralHome';
import { normalizeStateProvince, overFieldNames } from '@evdy/web-core/dist/lib/utils';

import chevron from '../../../assets/chevron-right-blue.svg';

import './AccLocationDetails.scss';

const {
  fetchFuneralHome,
  editFuneralHome,
  resetFuneralHome,
  resetAccLocUpdate
} = funeralHomeOperations;

const model = 'dash.accountDetails.forms';
const editHomeSuccess = 'home-edit-success-toast';
const editHomeFailure = 'home-edit-failure-toast';

const AccountLocationDetails = props => {
  const {
    isExternalMode,
    location,
    location: { query },
    account = {},
    home,
    isFuneralHomeFetching,
    funeralHomeLastUpdated,
    accountLocationDetailsUpdated,
    errorMessage,
    pdfUploaded,
    params: { accountId, locationId },
    fetchAccounts,
    fetchAccountsReset,
    form,
    change,
    reset,
    fetchFuneralHome,
    editFuneralHome,
    resetFuneralHome,
    resetAccLocUpdate,
    funeralHomeLicenseInfo
  } = props;

  const subNavRef = useRef(null);
  const prevLastUpdated = useRef(null);

  const { name: accountName = '', users = [] } = account;

  const {
    coords = [],
    radiusServed = 0,
    name: funeralHomeName,
    _id: funeralHomeId,
    priceListingGPLUrl,
    afps,
    name = '',
    profileImage = '',
    websiteUrl = '',
    type,
    address = {},
    phone,
    email,
    casesPerYear,
    placeId,
    yelpUrl,
    eFuneralHomeId,
    description,
    displayPublicly,
    facebookUrl,
    specialStateLicenseInfo = {},
    specialStateLicenseInfo2 = {},
    funeralDirectorNumber = '',
    insuranceLicenseNumber = '',
    representativeIsNotMe = false,
    taxID = '',
    taxClassification = '',
    homeHasOwnTaxInfo = false,
    representative = {},
    ownerInformedOfSpecialStateInfo = false
  } = home;
  const { name: repName = {}, phone: repPhone, email: repEmail } = representative || {};
  const { first = '', last = '' } = repName || {};
  const { value: stateLicenseInfoValue = '' } = specialStateLicenseInfo || {};
  const { value: stateLicenseInfoValue2 = '' } = specialStateLicenseInfo2 || {};
  const { street = '', street2 = '', city = '', state = '', zip = '' } = address || {};
  const { location: formData } = form;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = ({ selectedTabIndex }) => setTabIndex(selectedTabIndex);

  const params = useMemo(() => ({ tabIndex, isExternalMode }), [tabIndex, isExternalMode]);
  //TODO WebCleanUp2020 - memoizedAccountsFilter doesn't need isExternal, dashnav handling
  useURLParamSync(params, location);

  // page setup
  useEffect(() => {
    // scroll to top on mount
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
    if (query.tabIndex && subNavRef.current) subNavRef.current.handleTabSelect(+query.tabIndex);
  }, []);

  // fetch and clear funeral home data on load
  useEffect(() => {
    if (accountId) fetchAccounts(accountId);
    return () => {
      fetchAccountsReset();
      resetFuneralHome();
    };
  }, [accountId, fetchAccounts, fetchAccountsReset]);

  // fetch funeralhome data on load
  useEffect(() => {
    fetchFuneralHome(locationId);
    return () => {
      resetFuneralHome();
    };
  }, [locationId]);

  const mergeFormValues = useCallback(() => {
    name && change(`${model}.location.name`, name);
    profileImage && change(`${model}.location.profileImage`, profileImage);
    websiteUrl && change(`${model}.location.websiteUrl`, websiteUrl);
    type && change(`${model}.location.type`, type);
    street && change(`${model}.location.address.street`, street);
    street2 && change(`${model}.location.address.street2`, street2);
    city && change(`${model}.location.address.city`, city);
    state && change(`${model}.location.address.state`, normalizeStateProvince(state));
    zip && change(`${model}.location.address.zip`, zip);
    phone && change(`${model}.location.phone`, phone);
    email && change(`${model}.location.email`, email);
    casesPerYear && change(`${model}.location.casesPerYear`, casesPerYear);
    radiusServed && change(`${model}.location.radiusServed`, radiusServed);
    stateLicenseInfoValue &&
      change(`${model}.location.specialStateLicenseInfo`, stateLicenseInfoValue);
    stateLicenseInfoValue2 &&
      change(`${model}.location.specialStateLicenseInfo2`, stateLicenseInfoValue2);
    funeralDirectorNumber &&
      change(`${model}.location.funeralDirectorNumber`, funeralDirectorNumber);
    insuranceLicenseNumber &&
      change(`${model}.location.insuranceLicenseNumber`, insuranceLicenseNumber);
    representativeIsNotMe &&
      change(`${model}.location.representativeIsNotMe`, representativeIsNotMe);
    first && change(`${model}.location.representative.name.first`, first);
    last && change(`${model}.location.representative.name.last`, last);
    repPhone && change(`${model}.location.representative.phone`, repPhone);
    repEmail && change(`${model}.location.representative.email`, repEmail);
    taxID && change(`${model}.location.taxID`, taxID);
    taxClassification && change(`${model}.location.taxClassification`, taxClassification);
    homeHasOwnTaxInfo && change(`${model}.location.homeHasOwnTaxInfo`, homeHasOwnTaxInfo);
    facebookUrl && change(`${model}.location.facebookUrl`, facebookUrl);
    placeId && change(`${model}.location.placeId`, placeId);
    yelpUrl && change(`${model}.location.yelpUrl`, yelpUrl);
    eFuneralHomeId && change(`${model}.location.eFuneralHomeId`, eFuneralHomeId);
    description && change(`${model}.location.description`, description);
    displayPublicly && change(`${model}.location.displayPublicly`, displayPublicly);
    ownerInformedOfSpecialStateInfo &&
      change(`${model}.location.ownerInformedOfSpecialStateInfo`, ownerInformedOfSpecialStateInfo);
  }, [
    name,
    change,
    profileImage,
    websiteUrl,
    type,
    street,
    street2,
    city,
    state,
    zip,
    phone,
    email,
    casesPerYear,
    radiusServed,
    stateLicenseInfoValue,
    stateLicenseInfoValue2,
    funeralDirectorNumber,
    insuranceLicenseNumber,
    representativeIsNotMe,
    first,
    last,
    repPhone,
    repEmail,
    taxID,
    taxClassification,
    homeHasOwnTaxInfo,
    facebookUrl,
    placeId,
    yelpUrl,
    eFuneralHomeId,
    description,
    displayPublicly,
    ownerInformedOfSpecialStateInfo
  ]);

  // merge funeral home data with redux form
  useEffect(() => {
    mergeFormValues();
  }, [mergeFormValues]);

  const handleLocationUpdate = () => {
    let locationObj = {
      ...formData,
      funeralDirectorNumber: null,
      insuranceLicenseNumber: null,
      specialStateLicenseInfo: null,
      specialStateLicenseInfo2: null
    };

    // if state has special keys, use form values otherwise keep empty string
    const { requiredFields = [] } = funeralHomeLicenseInfo[formData.address.state] ?? {};
    const fieldKeys = funeralHomeLicenseInfo[formData.address.state];
    const fieldValues = formData;
    const fieldIsObject = [].includes.bind(['specialStateLicenseInfo', 'specialStateLicenseInfo2']);

    const mappedFieldValues = requiredFields.map(
      overFieldNames(fieldKeys, fieldValues, fieldIsObject)
    );

    editFuneralHome(locationId, {
      ...locationObj,
      ...zipObject(requiredFields, mappedFieldValues)
    });
  };

  useEffect(() => {
    const didUpdateLocation = prevLastUpdated.current < funeralHomeLastUpdated + 5e2;
    if (!isFuneralHomeFetching && accountLocationDetailsUpdated && didUpdateLocation) {
      if (!errorMessage) {
        renderToast({ id: editHomeSuccess, message: 'Location successfully updated' });
        resetAccLocUpdate();
      } else {
        renderToast({
          id: editHomeFailure,
          message: errorMessage,
          type: 'error'
        });
      }
    }
    prevLastUpdated.current = funeralHomeLastUpdated;
  }, [accountLocationDetailsUpdated, errorMessage, funeralHomeLastUpdated, isFuneralHomeFetching]);

  return (
    <div className="dash-acc-location-details-container">
      <div className="breadcrumbs">
        <Link className="back-link" to="/dash/a/accounts?isExternalMode=false">
          <img src={chevron} alt="" />
          Accounts List
        </Link>
        <Link
          className="back-link"
          to={`/dash/a/accounts/${accountId}?isExternalMode=false&tabIndex=3`}
        >
          <img src={chevron} alt="" />
          {accountName}
        </Link>
      </div>
      {isExternalMode ? (
        <div>This tab is not available in Professional Mode</div>
      ) : (
        <>
          <div className="title-container">
            <h1>{funeralHomeName || ''}</h1>
          </div>
          <SubNav
            ref={subNavRef}
            tabs={['Profile', 'Overview', 'Photos', 'Packages']}
            onChange={handleTabChange}
          >
            <AccLocProfile {...{ model, handleLocationUpdate, form }} />
            <AccLocOverview {...{ location: home, locationId }} />
            <AccLocPhotos {...{ model, handleLocationUpdate, form }} />
            {/* <AccLocAgents {...{ accountId, locationId, model, form, afps }} members={users} /> */}
            <PackageDetails
              {...{
                coords,
                funeralHomeName,
                funeralHomeId,
                priceListingGPLUrl,
                isFuneralHomeFetching,
                funeralHomeLastUpdated,
                pdfUploaded,
                isExternalMode,
                location
              }}
            />
          </SubNav>
        </>
      )}
      {isFuneralHomeFetching && <LoaderComponent />}
    </div>
  );
};

export default connect(
  ({ dash, funeralHome, client }) => ({
    isExternalMode: dash.dashNav.isExternalMode,
    account: dash.fetchAccounts.account.data,
    form: dash.accountDetails.forms,
    home: funeralHome.home.data,
    isFuneralHomeFetching: funeralHome.home.isFetching,
    funeralHomeLastUpdated: funeralHome.home.lastUpdated,
    accountLocationDetailsUpdated: funeralHome.home.accountLocationDetailsUpdated,
    errorMessage: funeralHome.home.error?.message,
    pdfUploaded: funeralHome.home.pdfUploaded,
    funeralHomeLicenseInfo: client.config.data.funeralHomeLicenseInfo
  }),
  {
    fetchAccounts,
    fetchAccountsReset,
    fetchFuneralHome,
    editFuneralHome,
    resetFuneralHome,
    resetAccLocUpdate,
    merge: formActions.merge,
    change: formActions.change,
    reset: formActions.reset
  }
)(AccountLocationDetails);
