export const getSpecialStatesArray = array =>
  array
    .filter(([_, data]) =>
      data?.requiredFields.some(field =>
        ['specialStateLicenseInfo', 'specialStateLicenseInfo2'].includes(field)
      )
    )
    .map(([state]) => state);

export const getDirectorNumberStatesArray = array =>
  array.filter(([_, data]) => data?.requiredFields?.includes('funeralDirectorNumber'));

export const getInsuranceLicenseNumberStatesArray = array =>
  array.filter(([_, data]) => data?.requiredFields?.includes('insuranceLicenseNumber'));

export const representativeIsNotMeStates = [
  'CT',
  'ME',
  'MD',
  'MA',
  'MT',
  'NJ',
  'NC',
  'OH',
  'PA',
  'RI',
  'SC',
  'UT',
  'VT',
  'VA',
  'ID',
  'IN',
  'KY',
  'LA',
  'WI',
  'MO'
];
