import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AddEventsModal from '../modals/AddEventsModal';
import EmptyEventRow from './EmptyEventRow';
import ServiceCard from './ServiceCard';

import Button from '@evdy/web-core/dist/components/Button';

import { useScrollToTopOfStepperPage } from '../CustomHook';

import './EventsPage.scss';

const EventsPage = ({ handleNext }) => {
  const {
    events = [],
    memorialId = '',
    funeralHomeId = '',
    eventFormData = {},
    eventFormMeta = {}
  } = useSelector(({ inviteCreation }) => ({
    events: inviteCreation.services.array,
    memorialId: inviteCreation.decedentDetails?.data?._id,
    funeralHomeId: inviteCreation.funeralInfo?.funeralHome?._id,
    eventFormData: inviteCreation.forms.eventInfo,
    eventFormMeta: inviteCreation.forms.formsMeta
  }));
  const [isAddEventModalVisible, setIsAddEventModalVisible] = useState(false);
  const [editEventId, setEditEventId] = useState(null);

  const titleCopy = 'Will there be any events or services?';
  const eventFormModel = 'inviteCreation.forms.eventInfo';

  const isEvents = !!events.length;

  // on mount, scroll to top
  useScrollToTopOfStepperPage('edit-decedent-details');

  const handleCloseModal = () => {
    setIsAddEventModalVisible(false);
    setEditEventId(null);
  };

  const openAddEventModal = () => {
    setIsAddEventModalVisible(true);
  };

  const handleOpenEdit = _id => {
    setIsAddEventModalVisible(true);
    setEditEventId(_id);
  };

  const EventDetailsUpcoming = () => {
    const btnCopy = 'Proceed to add family';

    return (
      <div className="event-details-upcoming">
        <div className="copy-container">
          <div className="no-service-title">No events planned</div>
          <div className="no-service-info">
            Whether there are services or not, the Digital Memorial instantly brings everyone
            together to share support, memories, and condolences.
          </div>
        </div>
        <Button copy={btnCopy} style="white-background-black-outline" action={handleNext} />
      </div>
    );
  };

  const addViewingClass = classNames({ 'events-added-footer': isEvents });

  return (
    <div className="events-page-wrapper">
      <div className="title-section">
        <h1 className="page-title">{titleCopy}</h1>
      </div>
      <Button
        copy="Add an event or service"
        action={openAddEventModal}
        style="standard-blue-solid-btn"
      />
      {isEvents &&
        [...events]
          ?.sort((a, b) => (a?.startDateISO > b?.startDateISO ? 1 : -1))
          .map((event, idx) => <ServiceCard {...{ ...event, handleOpenEdit }} key={idx} />)}
      <EmptyEventRow rowCopy="Funeral" btnCopy="Add funeral" btnAction={openAddEventModal} />
      <EmptyEventRow
        rowCopy="Viewing"
        btnCopy="Add viewing"
        btnAction={openAddEventModal}
        customClass={addViewingClass}
      />
      {!isEvents && <EventDetailsUpcoming />}
      <AddEventsModal
        {...{ memorialId, funeralHomeId, editEventId, eventFormMeta }}
        modalVisible={isAddEventModalVisible}
        model={eventFormModel}
        closeModal={handleCloseModal}
        servicesArray={events}
        formData={eventFormData}
      />
    </div>
  );
};

export default EventsPage;
