import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './CheckPhone.scss';

import {
  Form,
  InputGroup,
  isValidPhone,
  isRequired,
  parsePhone,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';

const CheckPhone = props => {
  const { dispatch, onboardUserData, userData } = props;
  const userPhone = onboardUserData?.phone || userData?.phone;

  useEffect(() => {
    if (userPhone) {
      dispatch(formActions.change('dash.onboarding.forms.checkPhone.phone', parsePhone(userPhone)));
    }
  }, [dispatch, userPhone]);

  return (
    <div className="check-phone-step">
      <Form model="dash.onboarding.forms.checkPhone">
        <div className="onboard-form-row">
          <InputGroup
            disabled={!!userPhone}
            type="tel"
            className="check-phone-input"
            model=".phone"
            labelText="Mobile Phone Number"
            parser={parsePhone}
            validators={{ isRequired, isValidPhone }}
            messages={{
              isRequired: 'Phone Number is required',
              isValidPhone: 'Please enter a valid phone number'
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default connect(({ dash }) => ({
  onboardUserData: dash.onboarding.onboardUser.data,
  userData: dash.user.data
}))(CheckPhone);
