import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';

import {
  Form,
  InputGroup,
  formActions,
  parsePhone,
  isValidPhone,
  isRequired
} from '@evdy/web-core/dist/components/shared/Input';
import { companyTypes } from '@evdy/web-etc/dist/lib/companyTypes';
import { fetchAccounts } from '@evdy/web-redux/dist/actions/dash/fetchAccounts';
import { states, abbreviatedStatesArray } from '@evdy/web-etc/dist/lib/repZones';
import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import { editAccountAction, editAccountReset } from '@evdy/web-redux/src/actions/dash/editAccount';
import { renderToast, renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import './AccountDetailsProfile.scss';

const model = 'dash.accountDetails.forms.profile';

const EditAccountProfile = ({
  merge,
  reset,
  editAccountAction,
  accountData,
  account,
  change,
  user,
  profile,
  editAccount,
  editAccountReset,
  accountDetailsMeta,
  fetchAccounts,
  isFetching
}) => {
  const {
    contactName,
    address,
    funeralHomes,
    _id: accountsId,
    name,
    phone,
    website,
    logo,
    users,
    type,
    sfRef,
    beta,
    taxID,
    taxClassification
  } = account || {};

  const { isDemoModeOn = false } = user || {};

  const accountsPersonalInfo = useMemo(
    () => ({
      name: name || '',
      phone: phone || '',
      logo: logo || '',
      funeralHomes: funeralHomes || [],
      address: address || {},
      website: website || '',
      type: type || '',
      contactName: contactName || {},
      sfRef: sfRef || '',
      beta: beta || {},
      taxID: taxID || '',
      taxClassification: taxClassification || ''
    }),
    [
      name,
      phone,
      logo,
      website,
      funeralHomes,
      address,
      contactName,
      type,
      sfRef,
      beta,
      taxID,
      taxClassification
    ]
  );

  const taxClasses = ['Sole Proprietor', 'Partnership', 'Corporation'];

  // merge loaded accounts data into form
  useEffect(() => {
    merge(model, accountsPersonalInfo);
    return () => {
      reset(model);
    };
  }, [merge, reset, accountsPersonalInfo]);

  // render toast upon successful update of account profile
  useEffect(() => {
    if (editAccount.lastUpdated && editAccount?.data?._id) {
      renderToast({ id: 'edit-profile-success', message: 'Account Successfully Updated!' });
      editAccountReset();
      fetchAccounts(accountsId);
    } else if (editAccount.lastUpdated && editAccount?.error?.message) {
      renderToast({ id: 'edit-profile-error', message: editAccount.error.message, type: 'error' });
      editAccountReset();
      fetchAccounts(accountsId);
    }
  }, [accountsId, editAccount, editAccountReset, fetchAccounts]);

  const handleSubmit = data => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-profile-demo' });
    const memberToSubmit = {
      company: { ...data },
      funeralHomes,
      users,
      delete: {}
    };
    editAccountAction(memberToSubmit, accountsId);
  };

  const handleUploadFinish = url => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-profile-demo' });
    const memberToSubmit = {
      company: { ...profile, logo: url },
      funeralHomes,
      users,
      delete: {}
    };
    editAccountAction(memberToSubmit, accountsId);
  };

  const handleRemoveImage = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-profile-remove-image-demo' });
    const memberToSubmit = {
      company: { ...profile, logo: '' },
      funeralHomes,
      users,
      delete: {}
    };
    editAccountAction(memberToSubmit, accountsId);
  };

  const currentAccntState = states[accountData?.address?.state]?.normalized || '';
  const dashImage = accountData.logo ? accountData.logo : account.logo;
  const dashBusinessType = accountData.type ? accountData.type : account.type;

  return (
    <div className="edit-accounts-tab">
      <div className="accounts-profile-data">
        <ImageHandler
          {...{ handleUploadFinish, handleRemoveImage }}
          currentImg={dashImage || ''}
          modalTitle="Profile Image"
        />
        <Form model={model} onSubmit={handleSubmit} className="account-info">
          <div className="input-row half-row">
            <InputGroup
              inline
              full
              model=".name"
              labelText="Organization Name*"
              validators={{ isRequired }}
              messages={{ isRequired: 'Organization Name is required' }}
            />
          </div>
          <div className="input-row">
            <InputGroup inline full model=".taxID" labelText="Tax ID" />
            <InputGroup
              inline
              full
              model=".taxClassification"
              type="select"
              labelText="Tax Classificiation"
            >
              <option />
              {taxClasses.map(c => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </InputGroup>
          </div>
          <div className="input-row">
            <InputGroup
              inline
              full
              type="select"
              model=".type"
              labelText="Type of Business"
              value={dashBusinessType}
            >
              <option />
              {Object.entries(companyTypes).map(([key, val]) => (
                <option key={key} value={val}>
                  {startCase(val)}
                </option>
              ))}
            </InputGroup>
            <InputGroup inline full model=".website" labelText="Business Website" />
          </div>
          <div className="input-row">
            <InputGroup
              inline
              full
              model=".address.street1"
              labelText="Address 1*"
              validators={{ isRequired }}
              messages={{ isRequired: 'Address 1 is required' }}
            />
            <InputGroup inline full model=".address.street2" labelText="Address 2" />
          </div>
          <div className="input-row">
            <InputGroup
              inline
              full
              model=".address.city"
              labelText="City*"
              validators={{ isRequired }}
              messages={{ isRequired: 'City is required' }}
            />
            <InputGroup
              inline
              full
              type="select"
              model=".address.state"
              labelText="State*"
              value={currentAccntState}
              validators={{ isRequired }}
              messages={{ isRequired: 'State/Province is required' }}
            >
              <option />
              {abbreviatedStatesArray.map((state, i) => (
                <option value={state} key={i}>
                  {state}
                </option>
              ))}
            </InputGroup>
          </div>
          <div className="input-row">
            <InputGroup
              inline
              full
              model=".address.zip"
              labelText="Zip Code*"
              validators={{ isRequired }}
              messages={{ isRequired: 'Zip Code is required' }}
            />
            <InputGroup
              inline
              full
              type="tel"
              parser={parsePhone}
              model=".phone"
              labelText="Phone Number*"
              validators={{ isValidPhone, isRequired }}
              messages={{
                isValidPhone: 'Please enter a valid phone number',
                isRequired: 'Please enter a valid phone number'
              }}
            />
          </div>
          <div className="input-row">
            <InputGroup full inline model=".sfRef" labelText="Sales Force ID" />
            <InputGroup
              type="checkbox"
              model=".beta.precoa"
              labelText="Precoa"
              customClass="checkbox"
              inline
              full
            />
          </div>

          <div className="controls">
            <button disabled={isFetching || !accountDetailsMeta?.$form?.valid} type="submit">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default connect(
  ({ funeral, dash }) => ({
    accountData: dash.accountDetails.forms.profile,
    user: dash.user.data,
    profile: dash.accountDetails?.forms?.profile,
    editAccount: dash.editAccount,
    accountDetailsMeta: dash.accountDetails?.forms?.accountDetailsMeta,
    isFetching: dash.fetchAccounts?.isFetching
  }),
  {
    merge: formActions.merge,
    reset: formActions.reset,
    change: formActions.change,
    fetchAccounts,
    editAccountAction,
    editAccountReset
  }
)(EditAccountProfile);
