import React from 'react';

import { Form, Input } from '@evdy/web-core/dist/components/shared/Input';

import { nameString } from '@evdy/web-core/dist/lib/utils';

import './EditProviderForm.scss';

const EditProviderForm = ({ directors, formData, funeralHomes, model }) => {
  const { funeralHome, director } = formData || {};

  return (
    <Form model={model} className="select-director-funeral-home">
      <div className="director-drop-down-container">
        <span>Director name *</span>
        <Input
          type="select"
          full
          inline
          model=".director"
          labeltext="Director Name *"
          value={director}
        >
          {directors.map(({ name, _id }) => (
            <option key={_id} value={_id}>
              {nameString(name)}
            </option>
          ))}
        </Input>
      </div>
      <div className="home-drop-down-container">
        <span>Funeral home *</span>
        <Input
          className="funeral-home-dropdown"
          full
          inline
          model=".funeralHome"
          labeltext="Funeral Home *"
          type="select"
          value={funeralHome}
        >
          {funeralHomes.map(({ name, _id }) => (
            <option key={_id} value={_id}>
              {nameString(name)}
            </option>
          ))}
        </Input>
      </div>
    </Form>
  );
};

export default EditProviderForm;
