import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Scroll from 'react-scroll/modules';
import moment from 'moment';
import classNames from 'classnames';
import qs from 'querystring';
import uniqBy from 'lodash-es/uniqBy';

import {
  Form,
  InputGroup,
  Typeahead,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';
import LeadListItem from './LeadListItem';
import NoLeads from './components/NoLeads';
import useLeadFilter from './useLeadFilter';
import UniversalDashModal from '@evdy/web-core/dist/components/shared/elements/UniversalDashModal';
import ClosedModalBody from './components/ClosedModalBody';
import MeetingSetModalBody from './components/MeetingSetModalBody';
import MeetingHeldModalBody from './components/MeetingHeldModalBody';

import renderPagination from '../renderPagination';
import { getMostRecentAttemptCount, lifecycleStages, modalTitles } from './shared/utils';
import { renderToast, renderDemoToast } from '@evdy/web-core/dist/lib/utils';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

import { filterHomesByUser, nameString } from '@evdy/web-core/dist/lib/utils';

import locationPlaceholder from '../Announcements/assets/funeral_inactive.svg';

import { fetchUser } from '@evdy/web-redux/dist/actions/internalDash/fetchUser';
import { fetchLeads } from '@evdy/web-redux/dist/lib/lead';
import {
  postLeadActivity,
  putLeadActivity,
  getLeadActivity,
  leadActivityReset
} from '@evdy/web-redux/dist/lib/leadActivity';
import { fetchDemoCrmLeads } from '@evdy/web-redux/dist/lib/demoMode';

import searchIcon from '../assets/search-icon-grey-small.svg';

import './crm.scss';

const locationCollapseAmt = 5;
const model = 'forms.dashLeads';

const CRM = ({
  fetchLeads,
  fetchedLeads,
  fetchDemoCrmLeads,
  location,
  allLocations = [],
  crmForms,
  dashLeadsFilter,
  formActionsChange,
  formActionsReset,
  postLeadActivity,
  leadActivityReset,
  companyId,
  user,
  searchText
}) => {
  const { filteredHomes: locations } = filterHomesByUser({
    homes: allLocations,
    user,
    parameters: ['crmAccess']
  });

  // gather all afps from all funeral homes in company
  const homeAfps = allLocations?.reduce((acc, current) => acc.concat(current.afps), []);
  // dedupe the afps
  const dedupedAfps = uniqBy(homeAfps, '_id');

  const { isDemoModeOn = false } = user;
  const { meetingSet, meetingHeld, closed } = crmForms;
  const currentLeads = fetchedLeads?.data?.leads;
  const leadsNext = fetchedLeads?.data?.next;
  const leadsPrev = fetchedLeads?.data?.prev;
  const totalLeads = fetchedLeads?.data?.leadsCount;
  const totalPrequals = fetchedLeads?.data?.totalPrequals;
  const { query } = location;
  const page = query.page ? query.page - 1 : 0;

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState('lifecycle-stage');
  const [currentLeadLatestActivity, setCurrentLeadLatestActivity] = useState({});
  const [currentLead, setCurrentLead] = useState({});

  const [leadFilter, setLeadFilter, filterType, filterImages] = useLeadFilter();
  const isQualified = leadFilter.qualified;
  let searchInput = React.createRef();

  const {
    sort,
    selectedStage: stage,
    searchQuery: leadName,
    locationFilter: funeralHomeId,
    qualified,
    afp
  } = leadFilter;
  let activeFilters = [];

  const handleClearFilters = () => {
    browserHistory.push({ ...location, query: { ...query, page: undefined } });
    setLeadFilter({ type: filterType.RESET });
    formActionsReset('forms.dashLeads');
  };

  const handleSearchSubmit = data => {
    //FILTER
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-search-filter-demo' });
    browserHistory.push({ ...location, query: { ...query, page: undefined } });
    setLeadFilter({ type: filterType.SEARCH, value: data.searchText });
    searchInput.current.changeFocus(false);
  };

  const handleSort = event => {
    //SORT
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-sort-demo' });
    browserHistory.push({ ...location, query: { ...query, page: undefined } });
    setLeadFilter({ type: filterType.SORT, value: event.target.value });
  };

  // add/remove this class to help with styling intercom
  useEffect(() => {
    document.body.classList.add('leads-list');
    return () => document.body.classList.remove('leads-list');
  }, []);

  // reset search/sort on unmount
  useEffect(() => {
    return () => formActionsReset('forms.dashLeads');
  }, [formActionsReset]);

  useEffect(() => {
    if (isDemoModeOn) {
      fetchDemoCrmLeads();
    } else
      companyId &&
        fetchLeads({
          funeralHomeId,
          companyId,
          page,
          sort,
          stage,
          leadName,
          qualified,
          isLimitedAccess: true,
          afp
        });
  }, [
    funeralHomeId,
    fetchLeads,
    leadName,
    sort,
    stage,
    qualified,
    page,
    companyId,
    fetchDemoCrmLeads,
    isDemoModeOn,
    afp
  ]);

  useEffect(() => {
    if (global.window) window.scrollTo(0, 0);
  });

  // reset sort dropdown if the reducer is reset
  useEffect(() => {
    if (leadFilter.sort !== dashLeadsFilter.sort) {
      formActionsChange(`${model}.sort`, leadFilter.sort);
    }
  }, [dashLeadsFilter.sort, formActionsChange, leadFilter.sort]);

  useEffect(() => {
    if (fetchedLeads.activitySuccess) {
      leadActivityReset();
      handleCloseModal();
    }
  }, [fetchedLeads.activitySuccess]);

  useEffect(() => {
    setLeadFilter({ type: 'PAGE', value: +page });
  }, [page, setLeadFilter]);

  const handleArrowClick = direction => {
    let newPage = parseInt(leadFilter.page);
    const scroll = Scroll.animateScroll;
    return () => {
      if (direction === 'prev') {
        newPage = newPage - 1;
      } else if (direction === 'next') {
        newPage = newPage + 1;
      }
      newPage = newPage + 1; // account for 0-indexed backend & 1-indexed query
      browserHistory.push({ ...location, query: { ...query, page: newPage } });
      scroll.scrollToTop({ duration: 400 });
    };
  };

  const renderModalBody = () => {
    switch (currentModal) {
      case 'closed':
        return (
          <ClosedModalBody
            {...{ latestStageActivity: currentLeadLatestActivity, activity: currentLead?.activity }}
          />
        );
      case 'meeting-set':
        return (
          <MeetingSetModalBody
            {...{ latestStageActivity: currentLeadLatestActivity, activity: currentLead?.activity }}
          />
        );
      case 'meeting-held':
        return (
          <MeetingHeldModalBody
            {...{ latestStageActivity: currentLeadLatestActivity, activity: currentLead?.activity }}
          />
        );
      default:
    }
  };

  const handleModalSave = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-list-modal-demo' });
    switch (currentModal) {
      case 'meeting-set':
        const mSetTask = meetingSet.selectedTask;
        const {
          date: mSetDate,
          time: mSetTime = '11:59pm',
          additionalNotes: mSetNotes,
          reason: mSetReason
        } = meetingSet[mSetTask];

        let mSetNextStage = 'new';
        if (mSetTask === 'success') mSetNextStage = 'meeting-set';
        if (mSetTask === 'close') mSetNextStage = 'closed';

        const mSetTimeFormatted = moment.utc(mSetTime || '11:59pm', 'h:ma');

        const mSetDueDate =
          mSetDate &&
          moment
            .utc(mSetDate)
            .hour(mSetTimeFormatted.hour())
            .minute(mSetTimeFormatted.minute())
            .valueOf();

        const meetingSetObj = {
          attempt: getMostRecentAttemptCount(currentLead.activity, mSetNextStage) + 1,
          type: 'stage',
          currentStage: mSetNextStage,
          dueDate: mSetDueDate,
          message: mSetNotes,
          closeReason: mSetReason
        };

        postLeadActivity({ leadId: currentLead?._id, activityData: meetingSetObj });
        break;
      case 'meeting-held':
        const mHeldTask = meetingHeld.selectedTask;
        const {
          date: mHeldDate,
          time: mHeldTime = '11:59pm',
          additionalNotes: mHeldNotes,
          reason: mHeldReason
        } = meetingHeld[mHeldTask];

        let mHeldNextStage = 'meeting-set';
        if (mHeldTask === 'success') mHeldNextStage = 'meeting-held';
        if (mHeldTask === 'close') mHeldNextStage = 'closed';

        const mHeldTimeFormatted = moment.utc(mHeldTime || '11:59pm', 'h:ma');

        const mHeldDueDate =
          mHeldDate &&
          moment
            .utc(mHeldDate)
            .hour(mHeldTimeFormatted.hour())
            .minute(mHeldTimeFormatted.minute())
            .valueOf();

        const meetingHeldObj = {
          attempt: getMostRecentAttemptCount(currentLead.activity, mHeldNextStage) + 1,
          type: 'stage',
          currentStage: mHeldNextStage,
          dueDate: mHeldDueDate,
          message: mHeldNotes,
          closeReason: mHeldReason
        };

        postLeadActivity({ leadId: currentLead?._id, activityData: meetingHeldObj });
        break;
      case 'closed':
        const closedTask = closed.selectedTask;
        const {
          date: closedDate,
          time: closedTime = '11:59pm',
          additionalNotes: closedNotes,
          reason: closedReason
        } = closed[closedTask];

        const closedNextStage = closedTask === 'retry' ? 'meeting-held' : 'closed';

        const closedTimeFormatted = moment.utc(closedTime || '11:59pm', 'h:ma');

        const closedDueDate =
          closedDate &&
          moment
            .utc(closedDate)
            .hour(closedTimeFormatted.hour())
            .minute(closedTimeFormatted.minute())
            .valueOf();

        const closedObj = {
          attempt: getMostRecentAttemptCount(currentLead.activity, closedNextStage) + 1,
          type: 'stage',
          currentStage: closedNextStage,
          dueDate: closedDueDate,
          message: closedNotes,
          closeReason: closedReason
        };

        postLeadActivity({ leadId: currentLead?._id, activityData: closedObj });
        break;
      default:
    }
  };

  const handleCloseModal = () => {
    formActionsReset('dash.crm.forms.meetingSet');
    formActionsReset('dash.crm.forms.meetingHeld');
    formActionsReset('dash.crm.forms.closed');
    setModalOpen(false);
    setCurrentLead({});
  };

  const handleModalBtnDisabled = () => {
    switch (currentModal) {
      case 'meeting-set':
        return !crmForms.crmMeta.meetingSet[meetingSet.selectedTask].$form.valid;
      case 'meeting-held':
        return !crmForms.crmMeta.meetingHeld[meetingHeld.selectedTask].$form.valid;
      case 'closed':
        return !crmForms.crmMeta.closed[closed.selectedTask].$form.valid;
      default:
        return false;
    }
  };

  const handleLeadCheckboxClick = (lead, latestActivity) => {
    setModalOpen(true);
    setCurrentLead(lead);
    setCurrentLeadLatestActivity(latestActivity);

    const nextModalStage =
      lifecycleStages[lifecycleStages.indexOf(latestActivity.currentStage) + 1];

    setCurrentModal(nextModalStage);
  };

  const handleSetPrequalifiedFilter = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-prequal-filter-demo' });
    if (qualified) {
      setLeadFilter({ type: filterType.PREQUALIFIED });
      browserHistory.push({ ...location, query: { ...query, page: undefined } });
    }
  };

  const renderButton = ({ type, value, id = '', image, component }) => {
    const key = `${type}-${id}`;
    const selectedFilters = [
      leadFilter.selectedFilterLocations,
      leadFilter.selectedFilterStage,
      leadFilter.selectedAfp
    ];
    const active =
      selectedFilters.includes(key) || (component === 'prequal' && !leadFilter.qualified);

    const buttonClasses = classNames('button-text', { active });

    const handleButtonClick = () => {
      if (isDemoModeOn) return renderDemoToast({ id: 'crm-filter-button-demo' });
      setLeadFilter({ type, value, id });
      browserHistory.push({ ...location, query: { ...query, page: undefined } });
    };

    let _image =
      image || (!!filterImages[type] && filterImages[type][`${active ? '' : 'in'}active`]);

    const ButtonComponent = () => {
      switch (component) {
        case 'prequal':
          return (
            <div
              className="filter prequal-filter"
              onClick={fetchedLeads?.isFetching ? () => {} : handleButtonClick}
            >
              <div className="title-count-wrapper">
                <p className={buttonClasses}>In qualification process</p>
                <span className="prequals-count">{totalPrequals || 0}</span>
              </div>
              <div className="sub-text">
                <em>If qualified, the leads will move into “New” and are ready to be contacted.</em>
              </div>
            </div>
          );

        default:
          return (
            <button className="filter" onClick={handleButtonClick}>
              {_image ? (
                <div
                  className="filter-icon"
                  style={{ backgroundImage: `url(${_image})`, borderRadius: id ? '10rem' : '0' }}
                />
              ) : (
                <CharacterProfileImage customClasses={['filter-icon']} name={value} />
              )}
              <span className={buttonClasses}>{value}</span>
            </button>
          );
      }
    };

    return <ButtonComponent key={key} />;
  };

  const renderFilterMenu = (
    <>
      <div className="filter-section">
        {renderButton({ type: filterType.CLEAR_STAGE, value: 'All' })}
        {renderButton({ type: filterType.STAGE_NEW, value: 'New' })}
        {renderButton({ type: filterType.STAGE_SET_MEETING, value: 'Meeting Set' })}
        {renderButton({ type: filterType.STAGE_HELD_MEETING, value: 'Meeting Held' })}
        {renderButton({ type: filterType.STAGE_CLOSED, value: 'Closed' })}
      </div>
      <div className="filter-section">
        {renderButton({ type: filterType.CLEAR_LOCATION, value: 'All Locations' })}
        {locations
          .sort((a, b) => a.name.localeCompare(b.name))
          .slice(0, leadFilter.locationFilterShowAll ? undefined : locationCollapseAmt)
          .map(({ _id, name, profileImage }) =>
            renderButton({
              type: filterType.LOCATION,
              value: name,
              id: _id,
              image: profileImage || locationPlaceholder
            })
          )}
        {locations.length > locationCollapseAmt && (
          <button onClick={() => setLeadFilter({ type: filterType.LOCATION_SHOWALL })}>
            Show {leadFilter.locationFilterShowAll ? 'Less' : 'All'}
          </button>
        )}
      </div>
      <div className="filter-section">
        {renderButton({ type: filterType.CLEAR_AFP, value: 'All Agents' })}
        {dedupedAfps
          .sort((a, b) => nameString(a.name).localeCompare(nameString(b.name)))
          .map(({ _id, name }) =>
            renderButton({
              type: filterType.AFP,
              value: nameString(name),
              id: _id
            })
          )}
      </div>
      <div className="filter-section">
        {renderButton({
          type: filterType.PREQUALIFIED,
          value: 'Prequalified Leads',
          component: 'prequal'
        })}
      </div>
    </>
  );
  const renderFilterStatus = () => {
    const {
      searchQuery,
      sortName,
      selectedFilterLocationsName,
      selectedFilterStageName,
      selectedAfpName
    } = leadFilter;
    const leadCount = qs.parse(leadsNext || leadsPrev)?.total || totalLeads;

    activeFilters = [selectedFilterLocationsName, selectedFilterStageName, selectedAfpName].filter(
      Boolean
    );

    const filterText = activeFilters.length && `Filtering by ${activeFilters.join(', ')}`;
    const sortText = sortName && `Sorting by ${sortName}`;
    const searchText = searchQuery && `Searching by "${searchQuery}"`;

    const statusText = [filterText, sortText, searchText].filter(Boolean).join(', ');

    const preQualText = leadFilter.qualified === false && 'In Qualification:';

    if (!filterText && !sortText && !searchText && !preQualText) return null;

    return (
      <div className="crm-filter-status">
        {preQualText} {statusText} ({leadCount} in List)
        <button className="crm-clear-filters-button" onClick={handleClearFilters}>
          Clear Filters
        </button>
      </div>
    );
  };
  // this feature will be expanded upon in the future to include other data, currently
  // it is used to display the user's current search text in a dropdown/typeahead with
  // the text 'Hit Enter'
  const suggestionArray = [searchText || '.']; // '.' is used to hide the suggestions on empty input

  const prequalToast = () => {
    renderToast({
      id: 'lead-list-prequal',
      message: 'Lead details not available for leads undergoing qualification.'
    });
  };

  const crmClasses = classNames('crm-wrapper');

  return (
    <div className={crmClasses}>
      <h3>Sales</h3>
      <div className="crm-content-container">
        <div className="crm-sidebar-container">{renderFilterMenu}</div>
        <div className="crm-search-list-container">
          <div className="crm-search-container">
            <Form className="crm-search-sort" model={model} onSubmit={handleSearchSubmit}>
              <Typeahead
                placeholder="Search by Name"
                customClass="crm-search-input"
                type="text"
                modelBase={model}
                model=".searchText"
                data={suggestionArray}
                pre={searchIcon}
                ref={searchInput}
                onSelect={() => handleSearchSubmit({ searchText: searchText })}
              />
              <div className="sort-container">
                <span>Sort by</span>
                <InputGroup
                  customClass="crm-sort-input"
                  type="select"
                  model=".sort"
                  onChange={handleSort}
                >
                  <option value="dueDate">Due Date</option>
                  <option value="searchName">A-Z</option>
                  <option value="-searchName">Z-A</option>
                </InputGroup>
              </div>
            </Form>
            {renderFilterStatus()}
          </div>
          <div className="crm-list-container">
            {fetchedLeads?.isFetching ? (
              <LeadListItem isSkeleton />
            ) : fetchedLeads?.success && !currentLeads?.length ? (
              <NoLeads {...{ handleSetPrequalifiedFilter, ...leadFilter }} />
            ) : currentLeads?.length ? (
              <>
                {!leadFilter.qualified && (
                  <div className="prequal-warning-container">
                    <strong>Do not call anyone on this list</strong>
                    <div>Once they qualify, you will be assigned a task to call.</div>
                  </div>
                )}
                {currentLeads.map((lead, idx) => (
                  <LeadListItem
                    {...{ lead, allLocations }}
                    key={idx + lead.createdAt}
                    onCheckboxClick={isQualified ? handleLeadCheckboxClick : prequalToast}
                    isQualified={isQualified}
                    renderToast={prequalToast}
                  />
                ))}
                {leadFilter?.qualified && (
                  <div className="looking-for-more">
                    <div className="icon-placeholder">?</div>
                    <div className="looking-for-more-content">
                      <div className="title">Can't find what you're looking for?</div>
                      <p>
                        If you're searching for someone who hasn't passed through lead qualification
                        yet, you won't find them here. Check the list of{' '}
                        <button onClick={handleSetPrequalifiedFilter}>
                          Leads Pending Qualification
                        </button>{' '}
                        to confirm.
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <LeadListItem isSkeleton />
            )}
            {currentLeads &&
              !!currentLeads.length &&
              renderPagination({
                currentPage: page,
                handleArrowClick,
                content: currentLeads,
                next: leadsNext,
                previous: leadsPrev,
                total: totalLeads,
                pageStep: 10,
                startsAtZero: true
              })}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <UniversalDashModal
          v3Styling
          headerTitle={modalTitles[currentModal]}
          mainButtonFn={handleModalSave}
          mainButtonText="Save"
          secondaryButtonFn={handleCloseModal}
          secondaryButtonText="Cancel"
          closeModal={handleCloseModal}
          disabled={handleModalBtnDisabled()}
        >
          {renderModalBody()}
        </UniversalDashModal>
      )}
    </div>
  );
};

export default connect(
  ({ dash, forms }) => ({
    fetchedLeads: dash.fetchedLeads,
    fetchedLeadActivity: dash.fetchedLeadActivity.data,
    user: dash.user.data,
    crmForms: dash.crm.forms,
    dashLeadsFilter: forms.dashLeads,
    allLocations: dash.fetchedCompany.company.data.funeralHomes,
    companyId: dash.fetchedCompany.company.data._id
  }),
  {
    fetchLeads,
    fetchDemoCrmLeads,
    postLeadActivity,
    putLeadActivity,
    getLeadActivity,
    fetchUser,
    formActionsChange: formActions.change,
    formActionsReset: formActions.reset,
    leadActivityReset
  }
)(CRM);
