"use strict";

// ID: 0-99 User available BGs/covers (randomly selectable).
// ID: 100-999 User available BGs/covers.
// ID: 1000-∞ FuneralHome / Company default BGs/Covers.
module.exports = [{
  id: 0,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_14.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_14_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_14_blur.jpg'
}, {
  id: 1,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/1.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/1_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/1_b.jpg'
}, {
  id: 2,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/2.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/2_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/2_b.jpg'
}, {
  id: 3,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/3.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/3_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/3_b.jpg'
}, {
  id: 4,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/4.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/4_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/4_b.jpg'
}, {
  id: 5,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/5.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/5_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/5_b.jpg'
}, {
  id: 6,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/6.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/6_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/6_b.jpg'
}, {
  id: 7,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_7.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_7_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_7_blur.jpg'
}, {
  id: 8,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_8.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_8_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_8_blur.jpg'
}, {
  id: 9,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_9.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_9_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_9_blur.jpg'
}, {
  id: 10,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_10.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_10_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_10_blur.jpg'
}, {
  id: 11,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_11.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_11_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_11_blur.jpg'
}, {
  id: 12,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_12.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_12_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_12_blur.jpg'
}, {
  id: 13,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_13.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_13_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_13_blur.jpg'
}, {
  id: 14,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_14.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_14_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_14_blur.jpg'
}, {
  id: 20,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_20.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_20_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_20_blur.jpg'
}, {
  id: 21,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_21.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_21_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_21_blur.jpg'
}, {
  id: 22,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_22.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_22_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_22_blur.jpg'
}, {
  id: 23,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_23.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_23_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_23_blur.jpg'
}, {
  id: 24,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_24.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_24_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_24_blur.jpg'
}, {
  id: 25,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_25.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_25_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_25_blur.jpg'
}, {
  id: 26,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_26.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_26_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_26_blur.jpg'
}, {
  id: 27,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_27.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_27_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_27_blur.jpg'
}, {
  id: 28,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_28.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_28_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_28_blur.jpg'
}, {
  id: 29,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_29.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_29_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_29_blur.jpg'
}, {
  id: 30,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_30.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_30_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_30_blur.jpg'
}, {
  id: 31,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_31.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_31_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_31_blur.jpg'
}, {
  id: 32,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_32.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_32_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_32_blur.jpg'
}, {
  id: 100,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_100.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_100_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_100_blur.jpg'
}, {
  id: 101,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_101.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_101_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_101_blur.jpg'
}, {
  id: 102,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_102.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_102_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_102_blur.jpg'
}, {
  id: 103,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_103.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_103_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_103_blur.jpg'
}, {
  id: 104,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_104.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_104_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_104_blur.jpg'
}, {
  id: 105,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_105.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_105_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_105_blur.jpg'
}, {
  id: 106,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_106.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_106_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_106_blur.jpg'
}, {
  id: 107,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_107.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_107_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_107_blur.jpg'
}, {
  id: 108,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_108.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_108_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_108_blur.jpg'
}, {
  id: 109,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_109.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_109_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_109_blur.jpg'
}, {
  id: 110,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_110.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_110_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_110_blur.jpg'
}, {
  id: 111,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_111.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_111_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_111_blur.jpg'
}, {
  id: 112,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_112.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_112_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_112_blur.jpg'
}, {
  id: 113,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_113.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_113_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_113_blur.jpg'
}, {
  id: 114,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_114.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_114_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_114_blur.jpg'
}, {
  id: 115,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_115.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_115_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_115_blur.jpg'
}, {
  id: 116,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_116.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_116_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_116_blur.jpg'
}, {
  id: 117,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_117.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_117_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_117_blur.jpg'
}, {
  id: 118,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_118.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_118_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_118_blur.jpg'
}, {
  id: 119,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_119.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_119_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_119_blur.jpg'
}, {
  id: 120,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_120.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_120_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_120_blur.jpg'
}, {
  id: 121,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_121.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_121_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_121_blur.jpg'
}, {
  id: 122,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_122.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_122_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_122_blur.jpg'
}, {
  id: 123,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_123.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_123_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_123_blur.jpg'
}, {
  id: 124,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_124.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_124_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_124_blur.jpg'
}, {
  id: 125,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_125.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_125_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_125_blur.jpg'
}, {
  id: 126,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_126.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_126_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_126_blur.jpg'
}, {
  id: 128,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_128.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_128_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_128_blur.jpg'
}, {
  id: 1000,
  url: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_5001.jpg',
  thumb: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_5001_t.jpg',
  blurUrl: 'https://s3.amazonaws.com/requiem-story-media/bgs/bg_5001_blur.jpg'
}];