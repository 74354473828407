import React from 'react';
import classNames from 'classnames';
import { useField } from 'formik';

import styles from './TextInput.module.scss';

const {
  'text-input': textInputClass,
  label: labelClass,
  input: inputClass,
  'has-error': hasErrorClass,
  focus: forceFocusClass,
  error: errorClass,
  footnote: footnoteClass
} = styles;

const TextInput = ({ textarea = false, label, footnote, storyClass, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>
  // https://formik.org/docs/api/useField
  // can remove this comment when we get more familiar with Formik
  const [field, meta] = useField(props);

  const labelClasses = classNames(labelClass, {
    [hasErrorClass]: meta.touched && meta.error
  });
  const inputClasses = classNames(inputClass, {
    [forceFocusClass]: storyClass === 'focus',
    [hasErrorClass]: meta.touched && meta.error
  });

  const renderInput = () =>
    textarea ? (
      <textarea className={inputClasses} {...field} {...props} />
    ) : (
      <input className={inputClasses} {...field} {...props} />
    );

  return (
    <div className={textInputClass}>
      <label className={labelClasses} htmlFor={props.id || props.name}>
        {label}
      </label>
      {renderInput()}
      {meta.touched && meta.error ? (
        <div className={errorClass}>{meta.error}</div>
      ) : (
        <div className={footnoteClass}>{footnote}</div>
      )}
    </div>
  );
};

export default TextInput;
