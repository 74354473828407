import React from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { browserHistory } from 'react-router';
import { differenceInYears } from 'date-fns';
import qs from 'querystring';

import { nameString, renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import searchedByFamCheck from '../../../images/searched-by-fam-check.svg';
import warningIcon from '../../../images/warning-icon-svg.svg';

import './AnnouncementItem.scss';

const AnnouncementItem = props => {
  const { backgrounds, isInternal = false, memorial, query = {}, themes, isDemoModeOn } = props;
  const {
    accessors,
    _id: memorialId,
    funeralDirectorId,
    funeralHomeDirector,
    funeralDirectorCreator,
    creationDate,
    funeralHome,
    apiUserId,
    isFinished,
    isArchived,
    isDraft,
    visitStats,
    image,
    name,
    createdByFuneralHome,
    dateOfBirth,
    dateOfDeath,
    coverIdx,
    traumatic,
    themeIdx,
    hasBeenShared
  } = memorial;
  const age = differenceInYears(dateOfDeath, dateOfBirth) || '';

  const createdDate = `${moment(creationDate).format('lll')}`;

  const accessorArray = accessors || [];

  const ownerAccessor = accessorArray.find(accessor => accessor.type === 'owner');

  let ownerName = '';

  if (ownerAccessor) {
    ownerName = nameString(ownerAccessor.name);
  }

  const directorPopulatedName = funeralDirectorId?.name || {};

  const directorName = funeralDirectorId ? nameString(directorPopulatedName) : funeralHomeDirector;

  const creator = createdByFuneralHome ? directorName : ownerName;

  const directorCreatorName =
    funeralDirectorCreator?.name?.first && funeralDirectorCreator?.name?.last
      ? nameString(funeralDirectorCreator.name)
      : undefined;

  const familyMemberCountArray = accessorArray.filter(acc =>
    ['owner', 'manager'].includes(acc.type)
  );

  const familyMemberCount = familyMemberCountArray.length;

  const familyOpenedPercentage = Math.round(
    (familyMemberCountArray.filter(fam => fam.viewedLink).length / familyMemberCount) * 100
  );

  const managersCount = accessorArray.filter(a => a.type !== 'viewer').length;
  const viewerCount = accessorArray.length - managersCount;

  const { total: { web = 0, app = 0 } = {} } = visitStats?.aggregate || {};
  const allViews = web + app;

  const currentTheme = themes[themeIdx || 0];
  const decedentImage =
    image ||
    backgrounds?.find(bg => bg.id === coverIdx)?.url ||
    'https://everdays-site.s3.amazonaws.com/shared/dash-list-placeholder.png';

  /*
    Current Tags - not all currently used
      SRS = memorial.apiUserId === 'srs'
      CC = memorial.apiUserId === 'continental:beth'
      Family = !memorial.createdByFuneralHome
      Dash = !memorial.apiUserId && memorial.createdByFuneralHome
      Sent = memorial.isFinished
      Draft = !memorial.isFinished
      Archived = memorial.isArchived
  */

  const tagClasses = x => classNames('tag', x);

  const Tag = ({ type = 'creator', children }) => (
    <div className={tagClasses(type)}>{children}</div>
  );

  const queryString = qs.stringify(query);

  const pathObj = {
    pathname: !isFinished
      ? `/dash/a/builder/${memorialId}`
      : `/dash/a/announcements/${memorialId}${queryString ? `?${queryString}` : ''}`,
    ...(isDraft && { state: { memorial } })
  };

  const renderBodyStats = () => {
    const familyCount = (
      <div className="body-stat">
        <span>{familyMemberCount}</span>
        <div className="stats-text">
          <span>Family </span>
          <span>Members</span>
        </div>
      </div>
    );

    const familyOpens = (
      <div className="body-stat">
        <span>{`${familyOpenedPercentage}%`}</span>
        <div className="stats-text">
          <span>Family </span>
          <span>Opens</span>
        </div>
      </div>
    );

    const totalViews = (
      <div className="body-stat">
        <span>{allViews}</span>
        <div className="stats-text">
          <span>Total </span>
          <span>Views</span>
        </div>
      </div>
    );

    return isInternal ? (
      <>
        {Number.isFinite(familyOpenedPercentage) && familyOpens}
        {totalViews}
      </>
    ) : (
      <>
        {familyCount}
        {Number.isFinite(familyOpenedPercentage) && familyOpens}
      </>
    );
  };

  const imgClasses = classNames('decedent-img', {
    'no-image': !decedentImage
  });

  const v3BodyClasses = classNames('annc-V3-body', { 'internal-card': isInternal });

  return (
    <div
      key={memorialId}
      className="annc-container"
      onClick={() => {
        isDemoModeOn
          ? renderDemoToast({ id: 'demo-toast-invite-details' })
          : browserHistory.push(pathObj);
      }}
    >
      <div className={v3BodyClasses}>
        <div className="annc-image-text">
          <div className="annc-image-container">
            <div
              className="theme-img"
              style={{ backgroundImage: `url('${currentTheme?.baseRoute}${currentTheme?.still}')` }}
            />
            <img className={imgClasses} src={decedentImage} alt="decedent-prof-img" />
          </div>
          <div className="annc-main-text">
            <div className="annc-name-age">
              {`${nameString(name) || 'No Name Entered'}${age && ', ' + age}`}
            </div>
            <div className="annc-sent-time">{`${isDraft ? 'Saved' : 'Sent'} ${createdDate}`}</div>
            <div className="annc-funeral-director">
              by{' '}
              <span>
                {directorCreatorName && directorCreatorName !== creator ? (
                  <>
                    {directorCreatorName} <span> for </span>
                  </>
                ) : (
                  ''
                )}
                {creator}
              </span>
            </div>
            <div className="annc-funeral-home">{funeralHome?.name}</div>
            {isInternal && <div>{familyMemberCount} Family</div>}
            {isInternal && <div>{viewerCount} Phone Contacts</div>}
            {isInternal && <div>Rep {nameString(funeralHome?.rep?.name)}</div>}
          </div>
        </div>
        <div className="annc-button-tags">
          <div className="annc-tags">
            {hasBeenShared && (
              <Tag type="has-been-shared">
                <img src={searchedByFamCheck} alt="green-check-img" />
                Shared by Family
              </Tag>
            )}
            {!isFinished && <Tag type="draft">Draft</Tag>}
            {traumatic && <Tag type="traumatic">Traumatic</Tag>}
            {isArchived && <Tag type="archived">Archived</Tag>}
          </div>
        </div>
      </div>
      {accessorArray.length < 3 && isFinished && (
        <div className="enable-family">
          <img src={warningIcon} alt="warning-lable-icon" />
          Add at least 3 family members to help surround everyone with support
        </div>
      )}
    </div>
  );
};

export default AnnouncementItem;
