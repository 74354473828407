"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadNext = exports.loadNextSuccess = exports.fetchAnnouncementsStatsByDate = exports.fetchAnnouncementsStatsFailure = exports.fetchAnnouncementsStatsSuccess = exports.fetchAnnouncementsStatsRequest = void 0;

require("core-js/modules/es6.array.filter.js");

var types = _interopRequireWildcard(require("../../constants/internalDash/fetchStats"));

var _constants = require("../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var fetchAnnouncementsStatsRequest = function fetchAnnouncementsStatsRequest() {
  return {
    type: types.FETCH_ANNOUNCEMENTS_STATS_REQUEST
  };
};

exports.fetchAnnouncementsStatsRequest = fetchAnnouncementsStatsRequest;

var fetchAnnouncementsStatsSuccess = function fetchAnnouncementsStatsSuccess(_ref) {
  var data = _ref.data;
  return {
    type: types.FETCH_ANNOUNCEMENTS_STATS_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.fetchAnnouncementsStatsSuccess = fetchAnnouncementsStatsSuccess;

var fetchAnnouncementsStatsFailure = function fetchAnnouncementsStatsFailure(error) {
  return {
    type: types.FETCH_ANNOUNCEMENTS_STATS_FAILURE,
    error: error,
    receivedAt: Date.now()
  };
};

exports.fetchAnnouncementsStatsFailure = fetchAnnouncementsStatsFailure;

var fetchAnnouncementsStatsByDate = function fetchAnnouncementsStatsByDate() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      creationFilter = _ref2.creationFilter,
      sortFilter = _ref2.sortFilter,
      completedFilter = _ref2.completedFilter,
      searchQuery = _ref2.searchQuery,
      fromDate = _ref2.fromDate,
      toDate = _ref2.toDate,
      page = _ref2.page,
      company = _ref2.company,
      home = _ref2.home,
      homeSearch = _ref2.homeSearch,
      directorSearch = _ref2.directorSearch,
      familySearch = _ref2.familySearch,
      stateSearch = _ref2.stateSearch,
      repSearch = _ref2.repSearch,
      limit = _ref2.limit;

  return function (dispatch) {
    dispatch(fetchAnnouncementsStatsRequest());
    var tagSearches = [homeSearch, directorSearch, familySearch, stateSearch];
    var ignoreSearchQuery = !!tagSearches.filter(Boolean).length;
    return instance.get("/stats/announcements", {
      params: {
        fromdate: fromDate,
        todate: toDate,
        dname: ignoreSearchQuery ? undefined : searchQuery,
        creation: creationFilter,
        completed: completedFilter,
        sort: sortFilter,
        page: page,
        company: company,
        home: home,
        homeSearch: homeSearch,
        directorSearch: directorSearch,
        familySearch: familySearch,
        stateSearch: stateSearch,
        repSearch: repSearch,
        limit: limit
      }
    }).then(function (res) {
      return dispatch(fetchAnnouncementsStatsSuccess(res));
    }).catch(function (error) {
      return dispatch(fetchAnnouncementsStatsFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.fetchAnnouncementsStatsByDate = fetchAnnouncementsStatsByDate;

var loadNextSuccess = function loadNextSuccess(_ref3) {
  var data = _ref3.data;
  return {
    type: types.LOAD_MORE_ANNOUNCEMENTS_STATS_SUCCESS,
    payload: data,
    receivedAt: Date.now()
  };
};

exports.loadNextSuccess = loadNextSuccess;

var loadNext = function loadNext(nextUrl) {
  return function (dispatch) {
    dispatch(fetchAnnouncementsStatsRequest());
    return instance.get(nextUrl).then(function (res) {
      return dispatch(loadNextSuccess(res));
    }).catch(function (error) {
      return dispatch(fetchAnnouncementsStatsFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.loadNext = loadNext;