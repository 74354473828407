"use strict";

var _ = require('lodash');

function objectOrString(props, propName, componentName) {
  var prop = props[propName];

  if (prop && !_.isObject(prop) && !_.isString(prop)) {
    return new Error('Invalid prop ' + propName + 'supplied to ' + componentName + ', expected string or object.');
  }
}

module.exports = {
  objectOrString: objectOrString
};