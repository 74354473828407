import React from 'react';
import classNames from 'classnames';

import './DisplayPackage.scss';

const DisplayPackage = ({
  packages,
  displayPackageName,
  handleCloseModal,
  packageNameType = 'director'
}) => {
  const { packagePrice = 0, packageServices = [] } =
    packages.find(({ packageName }) => {
      const packageNames = Object.values(packageName);
      return packageNames.includes(displayPackageName);
    }) ?? {};

  const renderPrice = price => {
    if (price === 0 || price === null) return '';
    return `$${price?.toLocaleString() ?? ''}`;
  };

  const sections = [
    {
      title: 'You’ll fulfill:',
      serviceType: 'funeralhome'
    },
    {
      title: 'Everdays provides:',
      serviceType: 'everdays'
    }
  ];

  const descriptionText = {
    'Live Digital Gathering':
      'Host a live, interactive Memorial for families who want a modern experience they can take anywhere.',
    'Visitation & Memorial Service':
      'Bring family and friends together with a visitation and memorial service to pay tribute to their loved one.',
    'Viewing & Funeral Service':
      'Welcome everyone to benefit from your home’s full services with a viewing and funeral to pay their final respects.'
  };

  const featureClasses = isParent => classNames('feature', { nested: isParent });

  return (
    <div className="display-package">
      <div className="display-package-details">
        <div className="price">${packagePrice?.toLocaleString() ?? ''}</div>
        <div className="description">{descriptionText[displayPackageName]}</div>
      </div>
      <div className="price-details">
        {sections.map(({ title, serviceType }, idx) => (
          <div className="price-group" key={title + idx}>
            <div className="group-title">{title}</div>
            {packageServices
              .filter(({ type, parentId }) => {
                if (parentId >= 0) return false;
                return type === serviceType;
              })
              .map(({ serviceName, description, isParent, price, serviceIndex }) => (
                <div className={featureClasses(isParent)}>
                  <div className="details">
                    <div className="title">{serviceName?.[packageNameType] || ''}</div>
                    <div className="description">{description?.[packageNameType] || ''}</div>
                  </div>
                  {isParent ? (
                    <div className="child-features">
                      {packageServices
                        .filter(({ parentId }) => parentId === serviceIndex)
                        .map(({ serviceName, price }) => (
                          <div className="child-feature">
                            <div className="title">{serviceName?.[packageNameType] || ''}</div>
                            <div className="price">{renderPrice(price) || 'Included'}</div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="cost">
                      <div className="price">{renderPrice(price) || 'Included'}</div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="footer-cta">
        <div className="intercom-cta">
          <a className="intercom-chat" href="mailto:wdj13bk6@intercom-mail.com">
            Chat with us
          </a>{' '}
          to make changes
        </div>
        <button onClick={handleCloseModal}>Close</button>
      </div>
    </div>
  );
};

export default DisplayPackage;
