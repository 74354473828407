import React from 'react';
import classNames from 'classnames';

import { capitalize } from '@evdy/web-core/dist/lib/utils';

import famAvatar from '../../../../InviteBuilder/components/Accessors/greyscale-avatar-icon.svg';

import './FamilyMemberRow.scss';

//to be put back in when accessors is fully worked out
//maybe only show for shell users?
const missingEmailAddress = () => (
  <div className="missing-email">
    <div className="yellow-indicator" />
    <p>Missing Email Address</p>
  </div>
);

const FamilyMemberRow = ({
  image,
  title,
  subtitle = 'test',
  relation,
  email,
  customClass,
  handleEdit
}) => {
  const famRowClasses = classNames({
    'person-row': true,
    [customClass]: customClass
  });

  return (
    <div className={famRowClasses}>
      <div className="name-info-container">
        <div className="image" style={{ backgroundImage: `url('${famAvatar}')` }} />
        <div className="name-details">
          <div className="title">{`${title} ${subtitle || ''}`}</div>
          <div className="relation">{capitalize(relation)}</div>
        </div>
        {/* {!email && missingEmailAddress()} */}
        <button onClick={handleEdit} className="fam-member-row-button evdy-button">
          Edit
        </button>
      </div>
    </div>
  );
};

export default FamilyMemberRow;
