export const inviteCreationDetails = {
  funeralHome: '',
  director: '',
  noServices: null
};

export const eventInfo = {
  eventName: '',
  location: { name: '', city: '', street: '', zip: '' },
  eventDate: null,
  startTime: '',
  endTime: '',
  description: '',
  noServices: null,
  videoStreamUrl: ''
};

export const decedentInfo = {
  image: '',
  name: {
    first: '',
    last: ''
  },
  displayName: '',
  decedentPhone: '',
  dateOfBirth: null,
  dateOfDeath: null,
  traumatic: false
};

export const decedentPhone = {
  decedentPhone: ''
};

export const accessor = {
  name: {
    first: '',
    last: ''
  },
  phone: '',
  email: '',
  relation: '',
  primarySurvivor: false
};

export const obituary = {
  message: ''
};
