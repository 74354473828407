import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import startCase from 'lodash-es/startCase';

import TruncatedText from '@evdy/web-core/dist/components/shared/elements/TruncatedText';

import penIcon from '../../LeadDetails/icon-pen@2x.svg';
import checkIcon from '../../LeadDetails/Timeline/icon-check@2x.svg';
import budIcon from '../../LeadDetails/Timeline/icon-bud@2x.svg';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import {
  getActivityCompletionCopy,
  getStageTitle,
  customCalendarFormatting
} from '../../shared/utils';

import whiteCheckmark from '../../images/icon-check-white.png';

import './LeadActivityCard.scss';

const NoteCardBody = props => {
  const { createdAt, createdBy, message } = props;
  return (
    <div className="lead-activity-card note-card-body">
      <img src={penIcon} alt="event icon" />
      <div className="lead-card-text">
        <div className="title">Note</div>
        <div className="timestamp">{moment(createdAt).format('MMM D, YYYY @h:mm a')}</div>
        {createdBy && <div className="description">{nameString(createdBy.name)} left a note</div>}
        <div className="details">{message}</div>
      </div>
    </div>
  );
};

const StageCardBody = props => {
  const { currentStage, createdAt, createdBy, message, closeReason } = props;

  return (
    <div className="lead-activity-card stage-card-body">
      <img src={checkIcon} alt="event icon" />
      <div className="lead-card-text">
        <div className="title">{getStageTitle(currentStage)}</div>
        <div className="timestamp">{moment(createdAt).format('MMM D, YYYY @h:mm a')}</div>
        {createdBy && currentStage !== 'closed' && (
          <div className="description">
            {nameString(createdBy.name)}
            {getActivityCompletionCopy(currentStage)}
          </div>
        )}
        {currentStage === 'closed' && <span>{startCase(closeReason)}</span>}
        <div className="details">{message}</div>
      </div>
    </div>
  );
};

const CheckboxStageCardBody = props => {
  const { attempt, closeReason, currentStage, handleCheckboxClick, message, dueDate } = props;
  const startOfDay = new Date().setHours(0, 0, 0, 0);
  const endOfDay = new Date().setHours(23, 59, 59, 999);

  const dueDateValue = new Date(dueDate).valueOf();
  const dueDateTzOffset = new Date(dueDate).getTimezoneOffset() * 1000 * 60;

  const checkboxCardClasses = classNames('lead-activity-card', 'checkbox-stage-card-body', {
    'due-today':
      dueDate &&
      moment.utc(dueDateValue + dueDateTzOffset).isBetween(startOfDay, endOfDay, null, '[]'),
    overdue: dueDate && moment.utc(dueDateValue + dueDateTzOffset).isBefore(moment.utc(startOfDay)),
    successful: currentStage === 'closed' && closeReason === 'purchased',
    unsuccessful: currentStage === 'closed' && closeReason !== 'purchased'
  });
  const successCheckboxClasses = classNames('checkbox', 'done', {
    successful: closeReason === 'purchased',
    unsuccessful: closeReason !== 'purchased'
  });

  const renderDueDateTime = dueDate => {
    if (!dueDate || (moment.utc(dueDate).hour() === 23 && moment.utc(dueDate).minute() === 59))
      return '';

    return ` @ ${moment.utc(dueDate).format('h:mm a')}`;
  };

  return (
    <div className={checkboxCardClasses} onClick={handleCheckboxClick}>
      {currentStage !== 'closed' ? (
        <div className="checkbox" />
      ) : (
        <div className={successCheckboxClasses}>
          <img src={whiteCheckmark} alt="white checkmark" />
        </div>
      )}
      <div className="lead-card-text">
        <div className="title">
          {getStageTitle(currentStage)}
          {currentStage !== 'closed' && (
            <span>
              {' '}
              - Due:{' '}
              {moment.utc(dueDate).calendar(moment.utc(startOfDay), customCalendarFormatting)}
              {renderDueDateTime(dueDate)}
            </span>
          )}
          {currentStage === 'closed' && <span> - {startCase(closeReason)}</span>}
        </div>
        <div className="attempt-count">Attempt #{attempt}</div>
        <TruncatedText lines={2} message={message} truncatedClass="details" />
      </div>
    </div>
  );
};

const CreatedCardBody = props => {
  const {
    createdAt,
    message,
    decedentName,
    isInformant,
    name,
    leadRelationship,
    isFamily,
    isCommunity,
    dueDate
  } = props;

  const startOfDay = new Date().setHours(0, 0, 0, 0);

  const closingParagraph = (
    <div className="closing-para">
      This call is your opportunity to set an appointment to meet and provide them with our home’s
      planning materials and sell them on the value and benefits of preplanning and purchasing
      preneed.
    </div>
  );

  const strongText = txt => (
    <u>
      <strong>{txt}</strong>
    </u>
  );

  const dueDateText = `${moment
    .utc(dueDate)
    .calendar(moment.utc(startOfDay), customCalendarFormatting)}`;

  const informantText = (
    <>
      {`${nameString(name)} is the informant ${leadRelationship &&
        `and ${leadRelationship}`} of ${nameString(
        decedentName
      )}'s Invite. They have received grief support information from our home. As part of our home’s services, they have been notified to expect a `}
      {strongText(
        `follow-up phone call from you by ${dueDateText} to answer questions on settling affairs.`
      )}
    </>
  );

  const titleText = (
    <>
      {`${nameString(name)} ${
        leadRelationship ? `is the ${leadRelationship}` : 'is family'
      } of ${nameString(decedentName)}. `}
    </>
  );

  const familyText = (
    <>
      {titleText}
      {`They have received grief support information from our home. As part of our home’s services, they have been notified to expect a `}
      {strongText(
        `follow-up phone call from you by ${dueDateText} on how you can support them now that services have ended.`
      )}
    </>
  );

  const extendedFamilyText = (
    <>
      {titleText}
      {`As a close friend or relative in the community, they have received a planning journal from our home. They have been notified to expect a `}
      {strongText(
        `follow-up phone call from you by ${dueDateText}, offering support to complete their planning journal and information on preneed.`
      )}
    </>
  );

  const communityText = (
    <>
      {`${nameString(name)} is a community member from ${nameString(
        decedentName
      )} memorial page. They have been notified to expect a `}
      {strongText(
        `follow-up phone call from you by ${dueDateText}, offering support to complete their planning journal and information on preneed.`
      )}
    </>
  );

  const otherText = (
    <>
      {`${nameString(
        name
      )} is from your funeral home's nearby community. They have interacted with pre-planning resources, demonstrated an interest in learning more, and have received a digital planning journal. They have been notified to expect a `}
      {strongText(
        `follow-up phone call from you by ${dueDateText} offering support to complete their planning journal and information on preneed.`
      )}
    </>
  );

  let cardCopy = isInformant
    ? informantText
    : isFamily
    ? familyText
    : !isFamily && leadRelationship
    ? extendedFamilyText
    : isCommunity
    ? communityText
    : otherText;

  return (
    <div className="lead-activity-card created-card-body">
      <img src={budIcon} alt="event icon" />
      <div className="lead-card-text">
        <div className="title">Lead added</div>
        <div className="timestamp">{moment(createdAt).format('MMM D, YYYY @h:mm a')}</div>
        <div className="description">
          <div className="lead-text-body">{cardCopy}</div>
          {closingParagraph}
        </div>
        <div className="details">{message}</div>
      </div>
    </div>
  );
};

const LeadActivityCard = props => {
  const { type } = props;

  switch (type) {
    case 'note':
      return <NoteCardBody {...props} />;
    case 'stage':
      return <StageCardBody {...props} />;
    case 'checkbox-stage':
      return <CheckboxStageCardBody {...props} />;
    case 'created':
      return <CreatedCardBody {...props} />;
    default:
  }
};

export default LeadActivityCard;
