import React from 'react';
import classNames from 'classnames';

import './NotificationDot.scss';

const NotificationDot = ({ displayAmt }) => {
  const notificationClasses = classNames('notification-dot', {
    'display-amt': displayAmt !== undefined
  });
  return <div className={notificationClasses}>{displayAmt?.toLocaleString()}</div>;
};

export default NotificationDot;
