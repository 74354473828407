import React from 'react';

import blankImg from '../../../assets/no-photo-placeholder.svg';
import { nameString } from '@evdy/web-core/dist/lib/utils';
import './MemberDetails.scss';

const MemberDetails = ({ user, setSaveType, handleOpenModal, handleMemberClick }) => {
  const { name, email, image, title, isCompanyAdmin, _id } = user;
  const displayTitle = title ? ' - ' + title : '';

  const handleEditBtnClick = event => {
    event.stopPropagation();
    setSaveType('edit-member');
    handleOpenModal({ type: 'edit-member', data: user });
  };

  return (
    <div className="accnt-member-list-item" onClick={() => handleMemberClick(_id)}>
      <div className="member-details">
        <img src={image ? image : blankImg} alt="member" />
        <div className="member-info-container">
          <div className="member-details-title">{nameString(name)}</div>
          <div className="member-details-email">
            {email}
            {displayTitle}
          </div>
          <div className="member-company-admin">{isCompanyAdmin ? 'Admin' : ''}</div>
        </div>
      </div>
      <button className="view-member-button">View</button>
      <button className="edit-modal-button" onClick={handleEditBtnClick}>
        Edit
      </button>
    </div>
  );
};

export default MemberDetails;
