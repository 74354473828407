import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ModalOverlay,
  GradientModalHeader,
  ResponsiveContent,
  CheckboxModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { funeralHomeOperations } from '@evdy/web-redux/dist/lib/funeralHome';

import './PreneedTermsModal.scss';

const PreneedTermsModal = ({ modalVisible, closeModal }) => {
  const dispatch = useDispatch();
  const { isTermsAccepted, fetchedCompany } = useSelector(({ dash, forms }) => ({
    fetchedCompany: dash.fetchedCompany,
    isTermsAccepted: forms.tosModal.isAccepted
  }));

  const { isFetching, company } = fetchedCompany || {};
  const companyData = company?.data || {};
  const { _id: companyId, name: companyName } = companyData || {};

  const handleSubmit = () => {
    dispatch(funeralHomeOperations.acceptPreneedTOS(companyId));
  };

  return (
    <div className="preneed-terms-modal">
      <ModalOverlay {...{ modalVisible, closeModal }}>
        <ResponsiveContent>
          <GradientModalHeader {...{ closeModal }} headerCopy="Terms and Conditions" />
          <div className="preneed-terms-modal-body">
            <h3>EVERDAYS INC.</h3>
            <p>
              All advanced funeral services sold on the Everdays platform, and via associated
              partners are funded by Homesteaders Life Corporation.
            </p>
            <p className="agreement-text">
              By clicking "Agree", {companyName} agrees to the following:
            </p>
            <p className="terms-privacy-links">
              <a href="/termsandconditions" target="_blank" rel="nofollow noreferrer">
                Terms and Conditions
              </a>{' '}
              |{' '}
              <a href="/clientprivacy" target="_blank" rel="nofollow noreferrer">
                Privacy Policy
              </a>
            </p>
          </div>
          <CheckboxModalFooter
            submitBtnDisabled={!isTermsAccepted}
            submitBtnText="Agree"
            submitBtnAction={handleSubmit}
            checkboxCopy={`I am an authorized representative of ${companyName}.`}
            checkboxInputModel="forms.tosModal.isAccepted"
          />
        </ResponsiveContent>
        {isFetching && <LoaderComponent />}
      </ModalOverlay>
    </div>
  );
};

const { bool, func } = PropTypes;

PreneedTermsModal.propTypes = {
  modalVisible: bool.isRequired,
  closeModal: func
};

export default PreneedTermsModal;
