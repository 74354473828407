import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';
import uniqBy from 'lodash-es/uniqBy';

import {
  Form,
  InputGroup,
  formActions,
  isValidPhone,
  parsePhone
} from '@evdy/web-core/dist/components/shared/Input';
import UniversalDashModal from '@evdy/web-core/dist/components/shared/elements/UniversalDashModal';
import AddNoteModalBody from '../components/AddNoteModalBody';
import ClosedModalBody from '../components/ClosedModalBody';
import MeetingSetModalBody from '../components/MeetingSetModalBody';
import MeetingHeldModalBody from '../components/MeetingHeldModalBody';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';
import AdditionalInformation from './AdditionalInformation';
import Timeline from './Timeline';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import NotPermitted from '../../shared/NotPermitted';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import { getMostRecentAttemptCount, modalTitles } from '../shared/utils';
import { renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import penIcon from './icon-pen@2x.svg';

import './LeadDetails.scss';

import { getLead, setLead } from '@evdy/web-redux/dist/lib/lead';
import { postLeadActivity, leadActivityReset } from '@evdy/web-redux/dist/lib/leadActivity';
import { fetchDemoLead } from '@evdy/web-redux/dist/lib/demoMode';

const model = 'forms.crmLeadDetails';

const tab = {
  TIMELINE: 'timeline',
  ADDITIONAL_INFO: 'additional info'
};

const LeadDetails = props => {
  const {
    params: { id: leadId },
    fetchedLeadActivity,
    currentLead,
    getLead,
    setLead,
    crmLeadDetails,
    formActionsChange,
    formActionsReset,
    crmForms,
    postLeadActivity,
    leadActivityReset,
    fetchedCompany,
    user,
    fetchDemoLead
  } = props;

  const { isDemoModeOn = false } = user || {};
  const { meetingSet, meetingHeld, closed } = crmForms;

  const {
    name: { first: firstName = '', last: lastName = '' } = {},
    leadImage,
    isInformant = false,
    email,
    phone,
    latestStageActivity = { currentStage: 'new', attempt: 1 },
    activity,
    afp
  } = useMemo(() => {
    const {
      memorials = [],
      userRef: { _id: leadUserId = '', image: leadImage = '' } = {},
      name,
      email = '',
      phone = '',
      activity = [],
      afp = ''
    } = currentLead.data;
    const { accessors = [] } = memorials[memorials.length - 1] || {};

    const { primarySurvivor: isInformant } =
      accessors.find(
        ({ userRef: accessorRef }) => accessorRef.toString() === leadUserId.toString()
      ) || {};

    const latestStageActivity = activity
      .filter(activity => activity.type === 'stage')
      .sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf())[0];

    return { name, leadImage, isInformant, email, phone, latestStageActivity, activity, afp };
  }, [currentLead.data]);

  const [tabName, setTab] = useState(tab.TIMELINE);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState('lifecycle-stage');
  const [isLimited, setIsLimited] = useState(false);

  // add/remove this class to help with styling intercom
  useEffect(() => {
    document.body.classList.add('lead-details');

    return () => document.body.classList.remove('lead-details');
  }, []);

  // scroll to top on mount
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  // fetch lead details on load
  useEffect(() => {
    if (isDemoModeOn) {
      fetchDemoLead(leadId);
    } else {
      getLead(leadId);
    }
  }, [fetchedLeadActivity, getLead, leadId, isDemoModeOn, fetchDemoLead]);

  // keep lead details in sync with with rrf
  useEffect(() => {
    firstName && formActionsChange(`${model}.name.first`, firstName);
    lastName && formActionsChange(`${model}.name.last`, lastName);
    email && formActionsChange(`${model}.email`, email);
    phone && formActionsChange(`${model}.phone`, phone);
    formActionsChange(`${model}.afp`, afp?._id || '');
    return () => {
      formActionsReset(model);
    };
  }, [firstName, lastName, email, phone, afp, formActionsChange, formActionsReset]);

  useEffect(() => {
    if (currentLead.activitySuccess) {
      leadActivityReset();
      handleCloseModal();
    }
  }, [currentLead.activitySuccess]);

  const handleBackBtn = () => {
    browserHistory.push('/dash/a/sales');
  };

  const handleInputSubmit = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-lead-info-onblur-demo' });
    setLead(leadId, { ...crmLeadDetails, afp: crmLeadDetails?.afp || null }); // if no afp, default to null to avoid CastError with empty string and ObjectId
  };

  const handleAfpChange = e => {
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-lead-info-onblur-demo' });
    const newAfpIdx = e.target.value;

    const newAfp = dedupedAfps[newAfpIdx];
    setLead(leadId, { ...crmLeadDetails, afp: newAfp._id });
    postLeadActivity({
      leadId,
      activityData: {
        type: 'note',
        message: `The agent for ${nameString({
          first: firstName,
          last: lastName
        })} was changed from ${nameString(afp.name)} to ${nameString(newAfp.name)}.`
      }
    });
  };

  const handleOpenModal = (modalType = 'lifecycle-stage', isLimited) => {
    setCurrentModal(modalType);
    setIsLimited(isLimited);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    formActionsChange('dash.crm.forms.addNote.noteText', '');
    formActionsReset('dash.crm.forms.meetingSet');
    formActionsReset('dash.crm.forms.meetingHeld');
    formActionsReset('dash.crm.forms.closed');
    setModalOpen(false);
  };

  const handleModalSave = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'crm-lead-modal-save-demo' });
    switch (currentModal) {
      case 'add-note':
        const activityData = {
          type: 'note',
          message: crmForms.addNote.noteText
        };
        postLeadActivity({ leadId, activityData });
        handleCloseModal();
        break;
      case 'meeting-set':
        const mSetTask = meetingSet.selectedTask;
        const {
          date: mSetDate,
          time: mSetTime = '11:59pm',
          additionalNotes: mSetNotes,
          reason: mSetReason
        } = meetingSet[mSetTask];

        let mSetNextStage = 'new';
        if (mSetTask === 'success') mSetNextStage = 'meeting-set';
        if (mSetTask === 'close') mSetNextStage = 'closed';

        const mSetTimeFormatted = moment.utc(mSetTime || '11:59pm', 'h:ma');

        const mSetDueDate =
          mSetDate &&
          moment
            .utc(mSetDate)
            .hour(mSetTimeFormatted.hour())
            .minute(mSetTimeFormatted.minute())
            .valueOf();

        const meetingSetObj = {
          attempt: getMostRecentAttemptCount(activity, mSetNextStage) + 1,
          type: 'stage',
          currentStage: mSetNextStage,
          dueDate: mSetDueDate,
          message: mSetNotes,
          closeReason: mSetReason
        };

        postLeadActivity({ leadId, activityData: meetingSetObj });
        break;
      case 'meeting-held':
        const mHeldTask = meetingHeld.selectedTask;
        const {
          date: mHeldDate,
          time: mHeldTime = '11:59pm',
          additionalNotes: mHeldNotes,
          reason: mHeldReason
        } = meetingHeld[mHeldTask];

        let mHeldNextStage = 'meeting-set';
        if (mHeldTask === 'success') mHeldNextStage = 'meeting-held';
        if (mHeldTask === 'close') mHeldNextStage = 'closed';

        const mHeldTimeFormatted = moment.utc(mHeldTime || '11:59pm', 'h:ma');

        const mHeldDueDate =
          mHeldDate &&
          moment
            .utc(mHeldDate)
            .hour(mHeldTimeFormatted.hour())
            .minute(mHeldTimeFormatted.minute())
            .valueOf();

        const meetingHeldObj = {
          attempt: getMostRecentAttemptCount(activity, mHeldNextStage) + 1,
          type: 'stage',
          currentStage: mHeldNextStage,
          dueDate: mHeldDueDate,
          message: mHeldNotes,
          closeReason: mHeldReason
        };

        postLeadActivity({ leadId, activityData: meetingHeldObj });
        break;
      case 'closed':
        const closedTask = closed.selectedTask;
        const {
          date: closedDate,
          time: closedTime = '11:59pm',
          additionalNotes: closedNotes,
          reason: closedReason
        } = closed[closedTask];

        const closedNextStage = closedTask === 'retry' ? 'meeting-held' : 'closed';

        const closedTimeFormatted = moment.utc(closedTime || '11:59pm', 'h:ma');

        const closedDueDate =
          closedDate &&
          moment
            .utc(closedDate)
            .hour(closedTimeFormatted.hour())
            .minute(closedTimeFormatted.minute())
            .valueOf();

        const closedObj = {
          attempt: getMostRecentAttemptCount(activity, closedNextStage) + 1,
          type: 'stage',
          currentStage: closedNextStage,
          dueDate: closedDueDate,
          message: closedNotes,
          closeReason: closedReason
        };

        postLeadActivity({ leadId, activityData: closedObj });
        break;
      default:
    }
  };

  const handleModalBtnDisabled = () => {
    switch (currentModal) {
      case 'add-note':
        return !crmForms.addNote.noteText;
      case 'meeting-set':
        return !crmForms.crmMeta.meetingSet[meetingSet.selectedTask].$form.valid;
      case 'meeting-held':
        return !crmForms.crmMeta.meetingHeld[meetingHeld.selectedTask].$form.valid;
      case 'closed':
        return !crmForms.crmMeta.closed[closed.selectedTask].$form.valid;
      default:
        return false;
    }
  };

  const tabClassNames = name => classNames({ active: name === tabName });

  const renderModalBody = isLimited => {
    switch (currentModal) {
      case 'add-note':
        return <AddNoteModalBody />;
      case 'closed':
        return <ClosedModalBody {...{ latestStageActivity, activity, isLimited }} />;
      case 'meeting-set':
        return <MeetingSetModalBody {...{ latestStageActivity, activity, isLimited }} />;
      case 'meeting-held':
        return <MeetingHeldModalBody {...{ latestStageActivity, activity, isLimited }} />;
      default:
    }
  };

  const renderLoader = propsToCheck => {
    const shouldRender = Object.values(propsToCheck)
      .filter(Boolean)
      .find(prop => prop.isFetching);
    return shouldRender && <LoaderComponent />;
  };

  const userHasPermission = currentLead?.data?.companies?.includes(
    fetchedCompany?.company?.data?._id
  );

  const backButtonClasses = classNames('back-btn', {
    'demo-mode-on': isDemoModeOn
  });

  const { funeralHomes = [] } = fetchedCompany?.company?.data || {};
  // gather all afps from all funeral homes in company
  const homeAfps = funeralHomes?.reduce((acc, current) => acc.concat(current.afps), []);

  // dedupe the afps; also include the current lead.afp in case it is not present in the company
  // if no afp, default to empty string as placeholder option
  const dedupedAfps = uniqBy([afp || '', ...homeAfps], '_id');

  return (
    <div className="lead-details">
      {userHasPermission || isDemoModeOn ? (
        <div className="lead-content">
          <button className={backButtonClasses} onClick={handleBackBtn}>
            &lt; Back to Sales Contacts
          </button>
          <div className="informant-info">
            <div className="side-view">
              <div className="profile">
                <CharacterProfileImage name={lastName} image={leadImage} />
                <div className="lead-name">{nameString({ first: firstName, last: lastName })}</div>
                {isInformant && <div className="tag-type">Informant</div>}
              </div>
              <div className="add-note">
                <button onClick={() => handleOpenModal('add-note')}>
                  <img src={penIcon} alt="add note icon" />
                  Add Note
                </button>
              </div>
            </div>
            <Form model={model} className="crm-lead-details">
              <InputGroup
                inline
                model=".name.first"
                labelText="First Name"
                autoComplete="off"
                onBlur={handleInputSubmit}
              />
              <InputGroup
                inline
                model=".name.last"
                labelText="Last Name"
                autoComplete="off"
                onBlur={handleInputSubmit}
              />
              <InputGroup
                inline
                model=".email"
                labelText="Email"
                autoComplete="off"
                onBlur={handleInputSubmit}
              />
              <InputGroup
                inline
                model=".phone"
                labelText="Cell Phone Number"
                autoComplete="off"
                onBlur={handleInputSubmit}
                validator={{ isValidPhone }}
                parser={parsePhone}
              />
              <InputGroup
                type="select"
                inline
                model=".afp"
                labelText="Agent"
                onChange={e => handleAfpChange(e)}
              >
                {dedupedAfps.map((afp, i) => (
                  <option key={afp._id} value={i}>
                    {nameString(afp.name)}
                  </option>
                ))}
              </InputGroup>
            </Form>
          </div>
          <div className="activity-tabs">
            <div className="navigation">
              <button className={tabClassNames(tab.TIMELINE)} onClick={() => setTab(tab.TIMELINE)}>
                Activity
              </button>
              <button
                className={tabClassNames(tab.ADDITIONAL_INFO)}
                onClick={() => setTab(tab.ADDITIONAL_INFO)}
              >
                Additional Information
              </button>
            </div>
            {tabName === tab.TIMELINE && (
              <Timeline {...{ handleOpenModal, latestStageActivity, isInformant }} />
            )}
            {tabName === tab.ADDITIONAL_INFO && <AdditionalInformation />}
          </div>
          {isModalOpen && (
            <UniversalDashModal
              v3Styling
              headerTitle={modalTitles[currentModal]}
              mainButtonFn={handleModalSave}
              mainButtonText="Save"
              secondaryButtonFn={handleCloseModal}
              secondaryButtonText="Cancel"
              closeModal={handleCloseModal}
              disabled={handleModalBtnDisabled()}
            >
              {renderModalBody(isLimited)}
            </UniversalDashModal>
          )}
          {renderLoader({ currentLead })}
        </div>
      ) : (
        <NotPermitted showContent={currentLead?.data?._id} />
      )}
    </div>
  );
};

export default connect(
  ({ dash, forms }) => ({
    currentLead: dash.currentLead,
    crmLeadDetails: forms.crmLeadDetails,
    crmForms: dash.crm.forms,
    fetchedLeadActivity: dash.fetchedLeadActivity.data,
    fetchedCompany: dash.fetchedCompany,
    user: dash.user.data
  }),
  {
    getLead,
    setLead,
    formActionsChange: formActions.change,
    formActionsReset: formActions.reset,
    postLeadActivity,
    leadActivityReset,
    fetchDemoLead
  }
)(LeadDetails);
