import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { nameString, getEpochDays } from '@evdy/web-core/dist/lib/utils';
import './AdditionalInformation.scss';

const binnedScore = ({ score, confidence } = {}) => {
  if (!score || !confidence) return 'N/A';
  const scoreXconf = score * confidence;
  switch (true) {
    case scoreXconf < 0.2:
      return 'Good';
    case scoreXconf < 0.5:
      return 'Better';
    case scoreXconf >= 0.5:
      return 'Best';
    default:
      return 'N/A';
  }
};

const getMinCallDate = (services = [], creationDate) => {
  const within60days = services
    .filter(({ startDate }) => startDate && startDate < creationDate + getEpochDays(60))
    .sort(({ startDate: sA }, { startDate: sB }) => (sA && sB ? sB - sA : 0));

  const lastService = within60days[0] || {};

  const minCallDate = lastService?.startDate
    ? lastService.startDate + getEpochDays(15)
    : creationDate + getEpochDays(22);

  return minCallDate ? moment.utc(minCallDate).format('MMM D, YYYY') : 'N/A';
};

const AdditionalDetails = ({ currentLead }) => {
  const {
    createdAt,
    memorials = [],
    scoreHistory = [],
    userRef: { _id: leadId = '' } = {},
    state = '',
    zip = '',
    ageRange = '',
    gender = '',
    demoInformant = '',
    demoReceptivityScore = '',
    demoUrl = '',
    demoDecedentAge = ''
  } = currentLead;
  const {
    name: decedentName = {},
    dateOfBirth = '',
    dateOfDeath = '',
    funeralHome: { name: locationName = '' } = {},
    shareUrl = '',
    accessors = [],
    creationDate,
    services = []
  } = memorials[memorials.length - 1] || {};

  const lead = useMemo(() => {
    const uploadDate = moment(createdAt).format('MMM D, YYYY @h:m a');
    const decedentFullName = nameString(decedentName);
    const decedentAge = moment(dateOfDeath).diff(moment(dateOfBirth), 'years');
    const { primarySurvivor = false, relation: decedentRelation = '' } =
      accessors.find(({ userRef: accessorRef }) => accessorRef.toString() === leadId.toString()) ||
      {};
    const isInformant = primarySurvivor ? 'Yes' : 'No';
    const { score, confidence } = scoreHistory[scoreHistory.length - 1] || {};
    const receptivityScore = binnedScore({ score, confidence });
    const minCallDate = getMinCallDate(services, creationDate);

    return {
      uploadDate,
      decedentFullName,
      decedentAge: isNaN(decedentAge) ? '' : `${decedentAge}`,
      locationName,
      shareUrl,
      isInformant,
      decedentRelation,
      receptivityScore,
      minCallDate,
      state,
      zip,
      ageRange,
      gender,
      demoInformant,
      demoReceptivityScore,
      demoUrl,
      demoDecedentAge
    };
  }, [
    accessors,
    ageRange,
    createdAt,
    dateOfBirth,
    dateOfDeath,
    decedentName,
    gender,
    leadId,
    locationName,
    scoreHistory,
    shareUrl,
    state,
    zip,
    demoInformant,
    demoReceptivityScore,
    demoUrl,
    demoDecedentAge
  ]);

  const pageUrl = lead.demoUrl || lead.shareUrl;

  return (
    <div className="lead-additional-info">
      <div className="info-section">
        <div className="info-section-title">Baseline</div>
        <div className="info-section-item">
          <p>Lead Added</p>
          <p>{lead.uploadDate || 'N/A'}</p>
        </div>
      </div>
      <div className="info-section">
        <div className="info-section-title">Related Invite</div>
        <div className="info-section-item">
          <p>Decedent Full Name</p>
          <p>{lead.decedentFullName || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Decedent Age</p>
          <p>{lead.demoDecedentAge || lead.decedentAge || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Associated FH</p>
          <p>{lead.locationName || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Invite URL</p>
          <p>
            {pageUrl ? (
              <a href={pageUrl} target="_blank" rel="noopener noreferrer">
                {pageUrl}
              </a>
            ) : (
              'N/A'
            )}
          </p>
        </div>
        <div className="info-section-item">
          <p>Informant</p>
          <p>{lead.demoInformant || lead.isInformant || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Relation to Decedent</p>
          <p>{lead.decedentRelation || 'N/A'}</p>
        </div>
      </div>
      <div className="info-section">
        <div className="info-section-title">Extra Information</div>
        <div className="info-section-item">
          <p>Receptivity Score</p>
          <p>{lead.demoReceptivityScore || lead.receptivityScore || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>State</p>
          <p>{lead.state || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Zip Code</p>
          <p>{lead.zip || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Estimated Age Range</p>
          <p>{lead.ageRange || 'N/A'}</p>
        </div>
        <div className="info-section-item">
          <p>Gender</p>
          <p>{lead.gender || 'N/A'}</p>
        </div>
      </div>
    </div>
  );
};

export default connect(({ dash }) => ({
  currentLead: dash.currentLead.data
}))(AdditionalDetails);
