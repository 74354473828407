import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import pick from 'lodash/pick';

import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import {
  Form,
  InputGroup,
  isRequired,
  parsePhone,
  isEmail,
  isValidPhone,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';

import './MemberProfileBody.scss';

const jobTitles = {
  administrativeAssistant: 'Administrative Assistant',
  apprentice: 'Apprentice',
  assistant: 'Assistant/Support',
  caregiver: 'Caregiver',
  celebrant: 'Celebrant',
  CEO: 'CEO',
  chaplain: 'Chaplain',
  clergy: 'Clergy',
  director: 'Director',
  embalmer: 'Embalmer',
  familyServicesCounselor: 'Family Services Counselor',
  funeralArranger: 'Funeral Arranger',
  funeralDirector: 'Funeral Director',
  generalManager: 'General Manager',
  griefCounselor: 'Grief Counselor',
  intern: 'Intern',
  manager: 'Manager',
  mortician: 'Mortician',
  nurse: 'Nurse',
  officeAdministrator: 'Office Administrator',
  other: 'Other',
  owner: 'Owner',
  preneedCounselor: 'Preneed Counselor',
  president: 'President',
  socialWorker: 'Social Worker',
  vicePresident: 'Vice President'
};

const MemberProfileBody = props => {
  const {
    dispatch,
    model,
    modalData: { member },
    forceAdmin = false, // used when adding via Administrators Card to hide Admin checkbox
    formData,
    editMode = false,
    user
  } = props;

  const memberFormData = useMemo(() => {
    if (member) {
      return pick(member, ['name', 'title', 'phone', 'email', 'image', 'bio', 'isCompanyAdmin']);
    } else {
      return {};
    }
  }, [member]);

  //set form data in editMode
  useEffect(() => {
    if (editMode) {
      dispatch(formActions.merge(model, memberFormData));
      dispatch(formActions.resetErrors(model));
    }
    return () => {
      dispatch(formActions.reset(model));
    };
  }, [dispatch, editMode, memberFormData, model]);

  const handleRemoveImage = () => {
    dispatch(formActions.change(`${model}.image`, ''));
  };

  const handleUploadFinish = url => {
    dispatch(formActions.change(`${model}.image`, url));
  };

  return (
    <div className="member-profile-body">
      <div className="member-profile-image-section">
        <ImageHandler {...{ handleRemoveImage, handleUploadFinish }} currentImg={formData.image} />
      </div>
      <Form model={model}>
        <div className="input-row">
          <InputGroup
            required
            model=".name.first"
            labelText="First Name *"
            validators={{ isRequired }}
            messages={{ isRequired: 'First Name is required' }}
          />
          <InputGroup
            required
            model=".name.last"
            labelText="Last Name *"
            validators={{ isRequired }}
            messages={{ isRequired: 'Last Name is required' }}
          />
        </div>
        <div className="input-row">
          <InputGroup
            required
            disabled={editMode}
            model=".email"
            labelText="Email Address *"
            validators={{ isRequired, isEmail }}
            messages={{
              isRequired: 'Email is required',
              isEmail: 'Please enter a valid email address'
            }}
          />
          <InputGroup model=".title" labelText="Job Title" type="select">
            <option />
            {Object.keys(jobTitles).map(title => (
              <option key={title} value={title}>
                {jobTitles[title]}
              </option>
            ))}
          </InputGroup>
        </div>
        <div className="input-row">
          <InputGroup
            required
            disabled={editMode}
            model=".phone"
            labelText="Cell Phone Number *"
            validators={{ isRequired, isValidPhone }}
            parser={parsePhone}
            messages={{
              isRequired: 'Phone Number is required',
              isValidPhone: 'Please enter a valid phone number'
            }}
          />
        </div>
        <div className="input-row single-full">
          <InputGroup type="textarea" model=".bio" labelText="Bio/About" />
        </div>
        {user.isCompanyAdmin && !forceAdmin && (
          <InputGroup
            model=".isCompanyAdmin"
            type="checkbox"
            customClass="authorize-admin-checkbox"
            labelText="Authorize user as Admin"
          />
        )}
      </Form>
    </div>
  );
};

export default connect(({ dash }) => ({
  user: dash.user.data
}))(MemberProfileBody);
