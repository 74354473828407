import React from 'react';

import './AccLocOverview.scss';
import RecentAnncs from '../../../../../shared/RecentAnncs';

const AccLocOverview = ({ location, locationId }) => {
  const { _id, phone = '---', crmRef = '---' } = location;

  const summary = {
    'Everdays ID': _id,
    'CRM ID': crmRef,
    'Phone Number': phone
  };

  return (
    <div className="dash-acc-loc-profile-container">
      <div className="summary">
        {Object.entries(summary).map(([title, value]) => (
          <div key={title} className="summary-item">
            <div className="item-title">{title}</div>
            <div className="item-value">{value}</div>
          </div>
        ))}
      </div>
      <RecentAnncs anncAmt={5} locationFilter={locationId} isLimitedAccess={false} />
    </div>
  );
};

export default AccLocOverview;
