import React from 'react';
import classNames from 'classnames';
import clamp from 'lodash/clamp';

import { formatTime } from '@evdy/web-core/dist/lib/utils';

import cameraIconGrey from './camera-grey.svg';
import everdaysLogo from '../../../images/shared/logos/white-everdays-logo.svg';

import './InviteExampleCard.scss';

// TODO: refactor/cleanup and add to HeroPreview
const InviteExampleCard = ({
  handleEditDecedent,
  image,
  displayName = '',
  dateOfBirth,
  dateOfDeath,
  editMode = false
}) => {
  const formattedDateOfBirth = formatTime(dateOfBirth, 'YYYY');
  const formattedDateOfDeath = formatTime(dateOfDeath, 'YYYY');
  const missingDodAndDob = !dateOfBirth && !dateOfDeath;
  //format strings
  const formattedDates = missingDodAndDob
    ? '' //No Dates Provided
    : !dateOfDeath
    ? `Born ${formattedDateOfBirth}` //No Birth Date Provided
    : !dateOfBirth
    ? `Passed ${formattedDateOfDeath}` //No Death Date Provided
    : `${formattedDateOfBirth} - ${formattedDateOfDeath}`; //Both Dates Provided

  const displayNameFontSize = (displayNameLength = 0) => {
    const maxFontSize = 44;
    const minFontSize = 15;
    const fullSizeCharatersAllowed = 12;

    return clamp(
      (fullSizeCharatersAllowed / displayNameLength) * maxFontSize,
      minFontSize,
      maxFontSize
    );
  };

  const cardClasses = classNames('invite-example-card', {
    editMode
  });

  return (
    <div className={cardClasses}>
      <div className="invite-card-decedent-img">
        {image ? (
          <div style={{ backgroundImage: `url('${image}')` }} />
        ) : (
          <button className="decedent-img-placeholder" onClick={handleEditDecedent}>
            <img src={cameraIconGrey} alt="placeholder camera" />
            <p>Add Photo</p>
          </button>
        )}
      </div>
      <div className="invite-card-decedent-info">
        <h3 className="invite-card-title">In Loving Memory Of</h3>
        <div className="invite-card-decedent-name">
          <span style={{ fontSize: displayNameFontSize(displayName.length) }}>{displayName}</span>
        </div>
        <div className="invite-card-dob-dob">{formattedDates}</div>
      </div>
      {!editMode && <div className="invite-card-divider" />}
      {!editMode && (
        <div className="invite-card-footer">
          <img src={everdaysLogo} />
          <span>Take care of your family long before you’re gone</span>
        </div>
      )}
      {!!editMode && (
        <button onClick={handleEditDecedent} className="edit-details-btn">
          Edit Details
        </button>
      )}
    </div>
  );
};

export default InviteExampleCard;
