import React from 'react';
import { Link } from 'react-router';

import LoginContainer from '@evdy/web-core/dist/components/LoginContainer';
import Trademark from '@evdy/web-core/dist/components/shared/elements/Trademark';

import '@evdy/web-core/dist/sass/Login.css';
import '../styles/SignupSuccess.scss';

import ccImage from '../images/cc_logo@2x.png';

export default class SignupSuccess extends React.Component {
  render() {
    const { email, software } = this.props.location.query;

    const isCC = software === 'directorsAssistant';
    let bgClass, containerClass, headerText, nextSteps;

    if (isCC) {
      bgClass = 'signup-success-cc';
      containerClass = 'login-dash-container cc';
      headerText =
        'Thank you for applying to be a partner. We will be in touch soon with next steps.';
      nextSteps = [
        'Everdays is currently processing your application, working with Continental Computers and their team to get you set up.',
        'Receive a call from the team at Continental Computers to walk through the service inside your arrangement software',
        'Email verifying completion after the walkthrough.',
        'Begin sending cases to families'
      ];
    } else {
      bgClass = 'signup-success';
      containerClass = 'login-dash-container';
      headerText = 'We look forward to partnering with you!';
      nextSteps = [
        'Our team will gather your account details.',
        'We will call you to help you get started.',
        'You are ready to create memorials for your client families.'
      ];
    }

    const content = {
      title: 'Thank you for \nregistering',
      description: ' '
    };

    return (
      <LoginContainer content={content}>
        <div className="signup-success">
          <h5 className="heading">{headerText}</h5>
          <p className="description">Here is what to expect next.</p>
          <div>
            {isCC && <img src={ccImage} alt="Continental Computers Logo" />}
            <ol>
              {nextSteps.map((s, idx) => (
                <li key={idx}>{s}</li>
              ))}
            </ol>
          </div>

          <div className="buttons-container">
            <Link className="back-button" to="/partners">
              Back to Everdays
            </Link>
            <div
              className="chat-button"
              onClick={() => {
                window.Intercom('show');
              }}
            >
              Chat with Us
            </div>
          </div>

          <Trademark />
        </div>
      </LoginContainer>
    );
  }
}
