import React, { useEffect, useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import qs from 'querystring';
import isEmpty from 'lodash-es/isEmpty';

import classNames from 'classnames';
import Scroll from 'react-scroll/modules';
import { fetchCompanies } from '@evdy/web-redux/dist/actions/internalDash/fetchCompanies';
import { useURLParamSync } from '@evdy/web-core/dist/customHooks/index';
import './Accounts.scss';
import useAccountsFilter from './useAccountsFilter';
import {
  Form,
  InputGroup,
  Typeahead,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';
import { ListSkeleton } from '../SkeletonLoader.jsx';
import searchIcon from '../assets/search-icon-grey-small.svg';
import renderPagination from '../renderPagination/index.jsx';
import AccountsList from './AccountList';

const Accounts = ({
  dash,
  companies,
  location,
  isExternalMode,
  searchText,
  filteredSearchText,
  inlineSearchTag,
  reset,
  change,
  fetchCompanies
}) => {
  const store = {
    filter: useAccountsFilter(),
    filterArgs: [],
    searchTags: [],
    accounts: companies?.data,
    totalAccounts: qs.parse(companies?.next || companies?.prev)?.total || companies?.data?.length,
    accountsNext: companies?.next,
    accountsPrev: companies?.prev,
    receivedAt: companies?.receivedAt || null
  };
  const { receivedAt } = store;

  const [
    accountsFilter = {},
    setAccountsFilter = () => {},
    getFilters = () => {},
    filterType = {},
    dropdownOptions = {}
  ] = store.filter;

  const { query } = location;
  const filters = getFilters(...store.filterArgs);
  const pageFilterValue = accountsFilter.page;

  const searchInput = useRef(null);
  const prevSearchInput = useRef('');
  const prevLastUpdated = useRef(null);

  let activeFilters = [];

  const [reducerIsLoaded, setReducerIsLoaded] = useState(false);

  const memoizedAccountsFilter = useMemo(
    () => ({
      ...accountsFilter,
      isExternalMode
    }),
    [accountsFilter, isExternalMode]
  );

  //TODO WebCleanUp2020 - memoizedAccountsFilter doesn't need isExternal, dashnav handling
  useURLParamSync(memoizedAccountsFilter, location, ['selected']);

  // Query is cleared if a user clicks "Accounts" while on the accounts tab
  // Effect resets the reducer and simulates filter/sort reset
  useEffect(() => {
    if (isEmpty(query)) setAccountsFilter({ type: filterType.RESET });
  }, [filterType.RESET, query, setAccountsFilter]);

  // restore page filters on refresh
  // scroll to top on mount
  // reset searchText and sort on unmount
  useEffect(() => {
    const { creationtypeFilter, creationtypeFilterName, searchQuery, sort, page } = query;

    setAccountsFilter({ type: filterType.SORT, value: query.sort });
    creationtypeFilter &&
      setAccountsFilter({
        type: filterType.CREATION_TYPE,
        id: creationtypeFilter,
        value: creationtypeFilterName
      });
    setAccountsFilter({
      type: filterType.ONLOAD,
      value: {
        creationtypeFilter,
        sort: sort || 'name',
        searchQuery,
        page: page || 1
      }
    });

    Scroll.animateScroll.scrollToTop({ duration: 400 });

    setReducerIsLoaded(true);

    return () => {
      reset('forms.dashAccounts.searchText');
      reset('forms.dashAccounts.sort');
    };
  }, []);

  // clear search query state when the user clears the search input
  useEffect(() => {
    if (
      searchText === '' && // search input is cleared
      !!prevSearchInput.current // there is previous input
    ) {
      setAccountsFilter({ type: filterType.SEARCH, value: '' });
    }
    prevSearchInput.current = searchText;
  }, [searchText]);

  // data fetching Effect
  // update companies when filter, search, or sort change
  useEffect(() => {
    change('forms.dashAccounts.searchText', accountsFilter.searchQuery);
    change('forms.dashAccounts.sort', accountsFilter.sort);
    if (reducerIsLoaded && receivedAt < Date.now() - 5e2)
      fetchCompanies('', {
        limit: 10,
        page: accountsFilter.page,
        cname: accountsFilter.searchQuery,
        creation: accountsFilter.creationtypeFilterName
          ? accountsFilter.creationtypeFilter
          : query.creationtypeFilter,
        sort: accountsFilter.sort
      });
  }, [fetchCompanies, accountsFilter, change, reducerIsLoaded]);

  // keep the page url param in sync with reducer hook when switching professional modes
  // feature is used when returning to account list from account details (comp. not done yet)
  useEffect(() => {
    const queryUpdate = {
      creationtypeFilter: accountsFilter.creationtypeFilter,
      creationtypeFilterName: accountsFilter.creationtypeFilterName,
      searchQuery: accountsFilter.searchQuery,
      sort: accountsFilter.sort,
      page: accountsFilter.page,
      isExternalMode
    };

    browserHistory.push({ ...location, query: { ...query, ...queryUpdate } });
  }, [accountsFilter, isExternalMode]);

  const renderButton = ({ type, value, id }) => {
    const key = `${type}-${id}`;
    const selectedFilters = Object.values(accountsFilter.selected).map(({ value }) => value);
    const active = selectedFilters.includes(key);
    const buttonClasses = classNames('button-text', { active });

    return (
      <button key={key} className="filter" onClick={() => setAccountsFilter({ type, value, id })}>
        <span className={buttonClasses}>{value}</span>
      </button>
    );
  };

  const renderFilterMenu = filters.map(({ header, options, collapsible }, idx) => {
    return (
      <div key={`filter-group-${idx}`} className="filter-section">
        {header && <div className="filter-section-header">{header}</div>}
        {options.map(renderButton)}
      </div>
    );
  });

  const handleSort = ({ target: { value } }) => {
    setAccountsFilter({ type: filterType.SORT, value });
  };

  const handleSearchSubmit = ({ searchText, filteredSearchText, inlinSearchTag }) => {
    setAccountsFilter({ type: filterType.SEARCH, value: searchText });
    searchInput.current.changeFocus();
  };

  const suggestionArray = [searchText || '.']; // -- used to hide empty suggestions on empty input

  const renderFilterStatus = () => {
    const { searchQuery, sortName, selected } = accountsFilter;
    const { totalAccounts } = store;

    activeFilters = Object.values(selected)
      .map(({ name }) => name)
      .filter(Boolean);

    const filterText = activeFilters.length && `Filtering by ${activeFilters.join(', ')}`;
    const sortText = sortName && `Sorting by ${sortName}`;
    const searchText = searchQuery && `Searching by "${searchQuery}"`;

    const statusText = [filterText, sortText, searchText].filter(Boolean).join(', ');

    if (!filterText && !sortText && !searchText) return null;
    return (
      <div className="accountstab-status" onClick={clearFilters}>
        {statusText} ({totalAccounts} in List)
      </div>
    );
  };

  const clearFilters = () => {
    setAccountsFilter({ type: filterType.RESET });
    browserHistory.push({ ...location, query: { ...query, page: undefined } });
  };

  const handleArrowClick = direction => {
    let newPage = 0;
    return () => {
      const page = parseInt(pageFilterValue);
      if (direction === 'prev') {
        newPage = page && page - 1;
      } else if (direction === 'next') {
        newPage = page + 1;
      }
      setAccountsFilter({ type: 'PAGE', value: newPage });
      Scroll.animateScroll.scrollToTop({ duration: 400 });
    };
  };

  const handleAccountClick = id => {
    const pathname = `${location.pathname}/${id}`;
    browserHistory.push({ ...location, pathname });
  };

  const { accounts, totalAccounts, accountsNext, accountsPrev } = store;
  const currentPage = parseInt(pageFilterValue);

  return (
    <div className="dash-accounts-container">
      <div className="title-container">
        <h1>Accounts</h1>
      </div>
      {isExternalMode ? (
        <div>This tab is not available in Professional Mode</div>
      ) : (
        <div className="dash-accounts-body">
          <div className="accountstab-sidebar">{renderFilterMenu}</div>
          <div className="accountstab-main">
            <div className="search-dropdown-container">
              <Form
                className="accountstab-dropdown"
                model="forms.dashAccounts"
                onSubmit={handleSearchSubmit}
              >
                <Typeahead
                  placeholder="Search Accounts or Locations"
                  customClass="accountstab-search-input"
                  modelBase="forms.dashAccounts"
                  model=".searchText"
                  data={suggestionArray}
                  pre={searchIcon}
                  type="text"
                  ref={searchInput}
                  onSelect={() => handleSearchSubmit({ searchText })}
                />
                <div className="dropdown-filter-menu">
                  {dropdownOptions.sortFilterOptions && (
                    <div className="dropdown-container">
                      <span>Sort by</span>
                      <InputGroup
                        customClass="accountstab-dropdown-input"
                        type="select"
                        model=".sort"
                        onChange={handleSort}
                      >
                        {dropdownOptions.sortFilterOptions.map(({ name, value }) => (
                          <option key={`sort-${value}`} {...{ value }}>
                            {name}
                          </option>
                        ))}
                      </InputGroup>
                    </div>
                  )}
                </div>
              </Form>
              {renderFilterStatus()}
            </div>
            <div className="accounts-list-container">
              <div className="accounts-list">
                {!accounts ? (
                  <ListSkeleton />
                ) : (
                  <AccountsList
                    {...{
                      accounts,
                      handleAccountClick,
                      searchText,
                      activeFilters
                    }}
                  />
                )}
                {accounts &&
                  !!accounts.length &&
                  renderPagination({
                    currentPage,
                    startsAtZero: accountsFilter.startsAtZero,
                    next: accountsNext,
                    previous: accountsPrev,
                    handleArrowClick,
                    content: accounts,
                    pageStep: 10,
                    total: totalAccounts
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  ({ dash, forms, internalDash }) => ({
    dash,
    companies: internalDash?.fetchedCompanies?.companies,
    isExternalMode: dash.dashNav.isExternalMode,
    searchText: forms.dashAccounts.searchText,
    filteredSearchText: forms.dashAccounts.filteredSearchText,
    inlineSearchTag: forms.dashAccounts.inlineSearchTags
  }),
  { fetchCompanies, reset: formActions.reset, change: formActions.change }
)(Accounts);
