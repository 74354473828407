import React from 'react';

import NoAnnouncements from './NoAnnouncements';
import AnnouncementItem from '../../shared/AnnouncementItem';

const AnnouncementList = ({
  backgrounds,
  themes,
  memorials,
  activeFilters,
  searchText,
  isExternalMode,
  query,
  isDemoModeOn
}) => {
  /*
    While loading memorials is null
    when memorials has loaded, memorials.data is an array (either empty or with announcements)
  */
  if (memorials === null) {
    // insert loading image here
    return null;
  }
  const memorialArray = memorials?.length ? memorials : [];

  if (!memorialArray.length) {
    return <NoAnnouncements {...{ activeFilters, searchText }} />;
  }

  return memorialArray.map(memorial => (
    <AnnouncementItem
      key={memorial._id}
      {...{ backgrounds, themes, memorial, isInternal: !isExternalMode, query, isDemoModeOn }}
    />
  ));
};

export default AnnouncementList;
