"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addHomeUser = exports.addHomeUserFailure = exports.addHomeUserSuccess = exports.addHomeUserRequest = exports.editHomeAction = exports.editHomeFailure = exports.editHomeSuccess = exports.editHomeRequest = exports.editHomeReset = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

var _constants = require("../../constants");

var types = _interopRequireWildcard(require("../../constants/dash/editHome"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var instance = _index.default.create({
  baseURL: _constants.API_URL
});

var editHomeReset = function editHomeReset() {
  return {
    type: types.EDIT_HOME_RESET
  };
};

exports.editHomeReset = editHomeReset;

var editHomeRequest = function editHomeRequest() {
  return {
    type: types.EDIT_HOME_REQUEST
  };
};

exports.editHomeRequest = editHomeRequest;

var editHomeSuccess = function editHomeSuccess(_ref) {
  var data = _ref.data;
  return {
    data: data,
    receivedAt: Date.now(),
    type: types.EDIT_HOME_SUCCESS
  };
};

exports.editHomeSuccess = editHomeSuccess;

var editHomeFailure = function editHomeFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.EDIT_HOME_FAILURE
  };
};

exports.editHomeFailure = editHomeFailure;

var editHomeAction = function editHomeAction(data, id) {
  return function (dispatch) {
    dispatch(editHomeRequest());
    return instance.put("/funeral-home/".concat(id), data).then(function (res) {
      return dispatch(editHomeSuccess(res.data));
    }).catch(function (error) {
      return dispatch(editHomeFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.editHomeAction = editHomeAction;

var addHomeUserRequest = function addHomeUserRequest() {
  return {
    type: types.ADD_HOME_USER_REQUEST
  };
};

exports.addHomeUserRequest = addHomeUserRequest;

var addHomeUserSuccess = function addHomeUserSuccess(_ref2) {
  var data = _ref2.data;
  return {
    data: data,
    receivedAt: Date.now(),
    type: types.ADD_HOME_USER_SUCCESS
  };
};

exports.addHomeUserSuccess = addHomeUserSuccess;

var addHomeUserFailure = function addHomeUserFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.ADD_HOME_USER_FAILURE
  };
};

exports.addHomeUserFailure = addHomeUserFailure;

var addHomeUser = function addHomeUser(homeId, userId, isAdmin) {
  return function (dispatch) {
    dispatch(addHomeUserRequest());
    return _index.default.put("/funeral-home/".concat(homeId, "/users/").concat(userId), {
      params: {
        isAdmin: isAdmin
      }
    }).then(function (res) {
      return dispatch(addHomeUserSuccess(res.data));
    }).catch(function (error) {
      return dispatch(addHomeUserFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.addHomeUser = addHomeUser;