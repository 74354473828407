import React from 'react';
import ContentLoader from 'react-content-loader';

const Content = props => (
  <ContentLoader
    height={110}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{
      height: '110px',
      width: '400px'
    }}
    {...props}
  >
    <rect x="125" y="10" rx="6" ry="3" width="200" height="10" />
    <rect x="125" y="36" rx="6" ry="3" width="150" height="10" />
    <rect x="0" y="0" rx="0" ry="0" width="110" height="110" />
    <rect x="125" y="64" rx="6" ry="3" width="100" height="10" />
    <rect x="125" y="90" rx="6" ry="3" width="100" height="10" />
  </ContentLoader>
);

const AnnouncementDetailsSkeleton = props => (
  <ContentLoader
    height={900}
    width={1170}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="180" rx="3" ry="3" width="245" height="35" />
    <rect x="329" y="28" rx="0" ry="0" width="0" height="0" />
    <rect x="454" y="282" rx="0" ry="0" width="0" height="0" />
    <rect x="788" y="82" rx="0" ry="0" width="381" height="381" />
    <rect x="271" y="23" rx="0" ry="0" width="0" height="0" />
    <rect x="154" y="9" rx="0" ry="0" width="4" height="0" />
    <rect x="272" y="13" rx="0" ry="0" width="0" height="0" />
    <rect x="177" y="30" rx="0" ry="0" width="0" height="0" />
    <rect x="0" y="276" rx="0" ry="0" width="355" height="18" />
    <rect x="-1" y="232" rx="0" ry="0" width="355" height="18" />
    <rect x="0" y="324" rx="0" ry="0" width="84" height="36" />
    <rect x="23" y="4" rx="0" ry="0" width="184" height="16" />
    <rect x="0" y="1" rx="0" ry="0" width="11" height="21" />
    <rect x="0" y="528" rx="0" ry="0" width="117" height="32" />
    <rect x="0" y="583" rx="0" ry="0" width="157" height="17" />
    <rect x="-1" y="623" rx="0" ry="0" width="105" height="17" />
    <rect x="-1" y="662" rx="0" ry="0" width="89" height="17" />
    <rect x="0" y="701" rx="0" ry="0" width="89" height="17" />
    <rect x="938" y="583" rx="0" ry="0" width="232" height="17" />
    <rect x="1016" y="621" rx="0" ry="0" width="155" height="17" />
    <rect x="928" y="662" rx="0" ry="0" width="243" height="17" />
    <rect x="1124" y="702" rx="0" ry="0" width="49" height="17" />
  </ContentLoader>
);

const ListSkeleton = props => (
  <>
    <div className="annc-skeleton">
      <Content />
    </div>
    <div className="annc-skeleton">
      <Content />
    </div>
    <div className="annc-skeleton">
      <Content />
    </div>
  </>
);

export { ListSkeleton, AnnouncementDetailsSkeleton };
