import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import DashCard from '../shared/DashCard';
import DashCardHeader from '../shared/DashCard/CardBuilder/DashCardHeader';
import LocationRow from '../shared/DashCard/CardBuilder/LocationRow';
import LocationModal from './components/LocationModal';

import { useScrollToTop, useURLParamSync } from '@evdy/web-core/dist/customHooks';

import './Locations.scss';

const Locations = ({ funeralHomes = [], location, isExternalMode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('add-location');
  const [currentHome, setCurrentHome] = useState({});

  // on mount, scroll to top of page
  useScrollToTop();

  const memoizedFiltersObj = useMemo(() => ({ isExternalMode }), [isExternalMode]);

  useURLParamSync(memoizedFiltersObj, location);

  const handleAddBtnClick = () => {
    setModalType('add-location');
    setIsModalOpen(true);
    setCurrentHome({});
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalType('add-location');
    setCurrentHome({});
  };

  const handleEdit = ({ _id }) => {
    browserHistory.push(`${location.pathname}/${_id}`);
  };

  return (
    <div className="locations-page">
      <h1>Locations</h1>
      <DashCard customClass="locations-card">
        <DashCardHeader
          headerCopy="All Locations"
          buttonCopy="Add a Location"
          headerBtnAction={handleAddBtnClick}
        />
        <div className="locations-list">
          {funeralHomes.map(home => (
            <LocationRow
              key={home._id}
              address={home.address}
              image={home.profileImage}
              name={home.name}
              handleEdit={() => handleEdit(home)}
              isSetupComplete={home.packageSetupComplete}
            />
          ))}
        </div>
      </DashCard>
      <LocationModal
        {...{
          currentHome,
          closeModal
        }}
        modalVisible={isModalOpen}
        modalType={modalType}
      />
    </div>
  );
};

export default connect(({ dash }) => ({
  funeralHomes: dash?.fetchedCompany?.company?.data?.funeralHomes,
  isExternalMode: dash.dashNav.isExternalMode
}))(Locations);
