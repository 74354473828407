import React from 'react';
import classNames from 'classnames';

import './DashCard.scss';

const DashCard = ({ children, inset, raised, customClass, clickHandler = () => {} }) => {
  const cardClasses = classNames('card-container', { inset, raised, [customClass]: customClass });

  return (
    <div className={cardClasses} onClick={clickHandler}>
      <div className="content-container">{children}</div>
    </div>
  );
};

export default DashCard;
