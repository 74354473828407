import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalOverlay,
  GradientModalHeader,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import EverdaysSpinner from '@evdy/web-core/dist/components/EverdaysSpinner';

import './YoutubeVideoModal.scss';

const YoutubeVideoModal = ({
  closeModal = () => {},
  headerCopy = '',
  height = 340,
  modalVisible,
  spinnerColor = 'grey',
  width = 665,
  youtubeVideoId = 'zOZmnEze91k'
}) => {
  return (
    <ModalOverlay {...{ modalVisible }} customClass="video-modal">
      <ResponsiveContent>
        <GradientModalHeader {...{ headerCopy }} closeModal={closeModal} />
        {modalVisible && (
          <iframe
            {...{ width, height }}
            title="Everdays Video"
            src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        <EverdaysSpinner color={spinnerColor} />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

const { bool, func, number, oneOfType, string } = PropTypes;

YoutubeVideoModal.propTypes = {
  closeModal: func,
  headerCopy: string,
  height: oneOfType([string, number]),
  modalVisible: bool,
  spinnerColor: string,
  width: oneOfType([string, number]),
  youtubeVideoId: string.isRequired
};

export default YoutubeVideoModal;
