"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

require("core-js/modules/es6.weak-map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

require("core-js/modules/es6.object.get-own-property-descriptor.js");

require("core-js/modules/es6.symbol.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitAddCompanyWithMisc = exports.resetError = exports.addCompanyWithMiscFailure = exports.addCompanyWithMiscSuccess = exports.addCompanyWithMiscRequest = void 0;

var _index = _interopRequireDefault(require("axios/index"));

var types = _interopRequireWildcard(require("../../constants/dash/addCompanyWithMisc"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var addCompanyWithMiscRequest = function addCompanyWithMiscRequest() {
  return {
    type: types.ADD_COMPANY_WITH_MISC_REQUEST
  };
};

exports.addCompanyWithMiscRequest = addCompanyWithMiscRequest;

var addCompanyWithMiscSuccess = function addCompanyWithMiscSuccess(_ref) {
  var data = _ref.data;
  return {
    payload: data,
    receivedAt: Date.now(),
    type: types.ADD_COMPANY_WITH_MISC_SUCCESS
  };
};

exports.addCompanyWithMiscSuccess = addCompanyWithMiscSuccess;

var addCompanyWithMiscFailure = function addCompanyWithMiscFailure(error) {
  return {
    error: error,
    receivedAt: Date.now(),
    type: types.ADD_COMPANY_WITH_MISC_FAILURE
  };
};

exports.addCompanyWithMiscFailure = addCompanyWithMiscFailure;

var resetError = function resetError() {
  return {
    type: types.RESET_ERROR
  };
};

exports.resetError = resetError;

var submitAddCompanyWithMisc = function submitAddCompanyWithMisc(data) {
  return function (dispatch) {
    dispatch(addCompanyWithMiscRequest());
    return _index.default.post('/company/with-misc', data).then(function (res) {
      return dispatch(addCompanyWithMiscSuccess(res.data));
    }).catch(function (error) {
      return dispatch(addCompanyWithMiscFailure((0, _transformAxiosError.default)(error)));
    });
  };
};

exports.submitAddCompanyWithMisc = submitAddCompanyWithMisc;