// Reset
export const RESET_INVITE_CREATION = 'RESET_INVITE_CREATION';

// Creation
export const START_INVITE_CREATION_REQUEST = 'START_INVITE_CREATION_REQUEST';
export const START_INVITE_CREATION_SUCCESS = 'START_INVITE_CREATION_SUCCESS';
export const START_INVITE_CREATION_FAILURE = 'START_INVITE_CREATION_FAILURE';

export const FETCH_UNFINISHED_INVITE_REQUEST = 'FETCH_UNFINISHED_INVITE_REQUEST';
export const FETCH_UNFINISHED_INVITE_SUCCESS = 'FETCH_UNFINISHED_INVITE_SUCCESS';
export const FETCH_UNFINISHED_INVITE_FAILURE = 'FETCH_UNFINISHED_INVITE_FAILURE';

export const FINISH_INVITE_CREATION_REQUEST = 'FINISH_INVITE_CREATION_REQUEST';
export const FINISH_INVITE_CREATION_SUCCESS = 'FINISH_INVITE_CREATION_SUCCESS';
export const FINISH_INVITE_CREATION_FAILURE = 'FINISH_INVITE_CREATION_FAILURE';

export const INVITE_SENT_RESET = 'INVITE_SENT_RESET';

export const SEND_MEMORIAL_LINK_REQUEST = 'SEND_MEMORIAL_LINK_REQUEST';
export const SEND_MEMORIAL_LINK_SUCCESS = 'SEND_MEMORIAL_LINK_SUCCESS';
export const SEND_MEMORIAL_LINK_FAILURE = 'SEND_MEMORIAL_LINK_FAILURE';
export const SEND_MEMORIAL_LINK_RESET = 'SEND_MEMORIAL_LINK_RESET';

// Decedent Details
export const EDIT_DECEDENT_DETAILS_REQUEST = 'EDIT_DECEDENT_DETAILS_REQUEST';
export const EDIT_DECEDENT_DETAILS_SUCCESS = 'EDIT_DECEDENT_DETAILS_SUCCESS';
export const EDIT_DECEDENT_DETAILS_FAILURE = 'EDIT_DECEDENT_DETAILS_FAILURE';
export const EDIT_DECEDENT_PHONE_REQUEST = 'EDIT_DECEDENT_PHONE_REQUEST';
export const EDIT_DECEDENT_PHONE_FAILURE = 'EDIT_DECEDENT_PHONE_FAILURE';
export const EDIT_DECEDENT_PHONE_SUCCESS = 'EDIT_DECEDENT_PHONE_SUCCESS';

// Accessors
export const ADD_NEW_ACCESSOR_REQUEST = 'ADD_NEW_ACCESSOR_REQUEST';
export const ADD_NEW_ACCESSOR_SUCCESS = 'ADD_NEW_ACCESSOR_SUCCESS';
export const ADD_NEW_ACCESSOR_FAILURE = 'ADD_NEW_ACCESSOR_FAILURE';
export const EDIT_ACCESSOR_REQUEST = 'EDIT_ACCESSOR_REQUEST';
export const EDIT_ACCESSOR_SUCCESS = 'EDIT_ACCESSOR_SUCCESS';
export const EDIT_ACCESSOR_FAILURE = 'EDIT_ACCESSOR_FAILURE';
export const DELETE_ACCESSOR_REQUEST = 'DELETE_ACCESSOR_REQUEST';
export const DELETE_ACCESSOR_SUCCESS = 'DELETE_ACCESSOR_SUCCESS';
export const DELETE_ACCESSOR_FAILURE = 'DELETE_ACCESSOR_FAILURE';
export const RESET_DUPE_ACCESSOR_ERROR = 'RESET_DUPE_ACCESSOR_ERROR';

// Services
export const ADD_NEW_SERVICE_REQUEST = 'ADD_NEW_SERVICE_REQUEST';
export const ADD_NEW_SERVICE_SUCCESS = 'ADD_NEW_SERVICE_SUCCESS';
export const ADD_NEW_SERVICE_FAILURE = 'ADD_NEW_SERVICE_FAILURE';
export const EDIT_SERVICE_REQUEST = 'EDIT_SERVICE_REQUEST';
export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS';
export const EDIT_SERVICE_FAILURE = 'EDIT_SERVICE_FAILURE';
export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';
export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';
export const ADD_NOSERVICE_OPTIONS_REQUEST = 'ADD_NOSERVICE_OPTIONS_REQUEST';
export const ADD_NOSERVICE_OPTIONS_SUCCESS = 'ADD_NOSERVICE_OPTIONS_SUCCESS';
export const ADD_NOSERVICE_OPTIONS_FAILURE = 'ADD_NOSERVICE_OPTIONS_FAILURE';

// Obituary
export const ADD_OBITUARY_REQUEST = 'ADD_OBITUARY_REQUEST';
export const ADD_OBITUARY_SUCCESS = 'ADD_OBITUARY_SUCCESS';
export const ADD_OBITUARY_FAILURE = 'ADD_OBITUARY_FAILURE';
export const EDIT_OBITUARY_REQUEST = 'EDIT_OBITUARY_REQUEST';
export const EDIT_OBITUARY_SUCCESS = 'EDIT_OBITUARY_SUCCESS';
export const EDIT_OBITUARY_FAILURE = 'EDIT_OBITUARY_FAILURE';

// Funeral Info
export const EDIT_FUNERAL_INFO_REQUEST = 'EDIT_FUNERAL_INFO_REQUEST';
export const EDIT_FUNERAL_INFO_SUCCESS = 'EDIT_FUNERAL_INFO_SUCCESS';
export const EDIT_FUNERAL_INFO_FAILURE = 'EDIT_FUNERAL_INFO_FAILURE';
export const EDIT_FUNERAL_INFO_RESET = 'EDIT_FUNERAL_INFO_RESET';

// Theme
export const EDIT_THEME_REQUEST = 'EDIT_THEME_REQUEST';
export const EDIT_THEME_SUCCESS = 'EDIT_THEME_SUCCESS';
export const EDIT_THEME_FAILURE = 'EDIT_THEME_FAILURE';

// Delete
export const DELETE_INVITE_REQUEST = 'DELETE_INVITE_REQUEST';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAILURE = 'DELETE_INVITE_FAILURE';

// Director Video
export const SELECT_DIRECTOR_VIDEO_REQUEST = 'SELECT_DIRECTOR_VIDEO_REQUEST';
export const SELECT_DIRECTOR_VIDEO_SUCCESS = 'SELECT_DIRECTOR_VIDEO_SUCCESS';
export const SELECT_DIRECTOR_VIDEO_FAILURE = 'SELECT_DIRECTOR_VIDEO_FAILURE';

// Upload Director Video
export const SET_UPLOAD_IN_PROGRESS = 'SET_UPLOAD_IN_PROGRESS';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_UPLOAD_THUMBNAIL = 'SET_UPLOAD_THUMBNAIL';
export const RESET_DIRECTOR_VIDEO_UPLOAD = 'RESET_DIRECTOR_VIDEO_UPLOAD';
export const INVALID_DIRECTOR_UPLOAD_DURATION = 'INVALID_DIRECTOR_UPLOAD_DURATION';

//Donation
export const ADD_DONATION_REQUEST = 'ADD_DONATION_REQUEST';
export const ADD_DONATION_SUCCESS = 'ADD_DONATION_SUCCESS';
export const ADD_DONATION_FAILURE = 'ADD_DONATION_FAILURE';
export const EDIT_DONATION_REQUEST = 'EDIT_DONATION_REQUEST';
export const EDIT_DONATION_SUCCESS = 'EDIT_DONATION_SUCCESS';
export const EDIT_DONATION_FAILURE = 'EDIT_DONATION_FAILURE';
export const DELETE_DONATION_REQUEST = 'DELETE_DONATION_REQUEST';
export const DELETE_DONATION_SUCCESS = 'DELETE_DONATION_SUCCESS';
export const DELETE_DONATION_FAILURE = 'DELETE_DONATION_FAILURE';
