import React from 'react';

import { Form, InputGroup } from '@evdy/web-core/dist/components/shared/Input';
import PackagesRow from '../../../../shared/PackagesRow';
import { ModalFooter } from '@evdy/web-core/dist/components/shared/Modal2020';

import { formatPrice, separateBasicServices } from '../../../helperFuncs';

import './PricesForm.scss';

const PricesForm = ({
  packages,
  model,
  services,
  isValid = true,
  handleUpdate = () => {},
  handleCloseModal = () => {},
  packageNameType = 'director',
  isPreneedInitialized,
  fullServiceCremation = {}
}) => {
  const renderPackageNames = parentPackage => {
    const totalPackages = packages?.length;
    if (totalPackages === parentPackage?.length) return `All ${totalPackages} Packages`;
    return parentPackage.map(({ packageName }) => packageName[packageNameType]).join(', ');
  };

  const fullServiceTitle = fullServiceCremation?.serviceName?.director || '';
  const fullServiceDescription = fullServiceCremation?.description?.director || '';
  //separate service arrays to give basic services prioroity over remaining
  //ig: [pkg1.basicServce, pkg2.basicService, pkg3.basicService, ...rest]
  const [basicServices, remainingServices] = separateBasicServices(services);

  return (
    <div className="prices-form">
      <PackagesRow detached {...{ packages, isPreneedInitialized }} />
      <div className="row-items">
        {fullServiceTitle && <div className="full-service-title">{fullServiceTitle}</div>}
        {fullServiceDescription && (
          <div className="full-service-desc">{fullServiceDescription}</div>
        )}
        <Form model={model}>
          {[...basicServices, ...remainingServices].map(
            ({ serviceName, parentPackage, serviceIndex }) => (
              <>
                {serviceName?.[packageNameType] === 'Visitation' && (
                  <div className="section-title">Additional Package Products</div>
                )}
                <div className="row-item" key={serviceIndex}>
                  <div className="details">
                    <div className="title">
                      <span className="common-name">{serviceName?.[packageNameType] || ''}</span> (
                      {renderPackageNames(parentPackage)})
                    </div>
                  </div>
                  <div className="pricing">
                    <div className="price">
                      <InputGroup
                        data-lpignore="true"
                        full
                        inline
                        model={`.service${serviceIndex}`}
                        labelText="Price"
                        type="tel"
                        parser={formatPrice}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </Form>
      </div>
      <ModalFooter
        mainButtonText="Save"
        mainButtonFn={handleUpdate}
        disabled={!isValid}
        secondaryButtonText="Update prices on all homes"
        secondaryButtonFn={() => handleUpdate({ updateAll: true })}
        tertiaryButtonText="Cancel"
        tertiaryButtonFn={handleCloseModal}
      />
    </div>
  );
};

export default PricesForm;
