import React from 'react';

import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import MuiUploader from '@evdy/web-core/dist/components/shared/elements/imageUpload/MuiUploader';
import Repeater from '@evdy/web-core/dist/components/shared/repeater/Repeater';
import RepeaterField from '@evdy/web-core/dist/components/shared/repeater/RepeaterField';

import { normalizeSelectEvent } from '@evdy/web-etc/dist/lib/eventNormalization';

const userIcon = require('../../images/icons/user.svg');
const svgIcon = <img src={userIcon} />;

export default class AddUsers extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddRowAndIncrement = this.handleAddRowAndIncrement.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.deleteRowAndDecrement = this.deleteRowAndDecrement.bind(this);

    this.inputFns = {
      makeKey: AddUsers.makeKey.bind(this),
      handleChange: this.handleChange.bind(this),
      handlePhoneChange: this.handlePhoneChange.bind(this),
      getValue: this.getValue.bind(this),
      getClass: this.getClass.bind(this),
      clearField: this.clearField.bind(this),
      getErrorText: this.getErrorText.bind(this)
    };
  }

  handleAddRowAndIncrement(e) {
    e.preventDefault();
    this.props.handleAddRow('users');
  }

  handleDeleteConfirm() {
    this.props.handleDelete('users');
  }

  deleteRowAndDecrement(destination, _idx) {
    const { delRow, handleSetRow, userIdx } = this.props;
    const idx = isFinite(_idx) ? _idx : userIdx;
    const newIdx = idx === 0 ? 0 : idx - 1;
    handleSetRow(newIdx, true, 'users')();
    delRow(idx, destination);
  }

  static makeKey(name, idx) {
    return `users[${idx}].${name}`;
  }

  handleChange(name, idx) {
    return this.props.handleInputChange(name, 'users', idx);
  }

  handlePhoneChange(name, idx) {
    return this.props.handlePhoneChange(name, 'users', idx, 'lookupUserPhone');
  }

  getValue(name, idx) {
    return this.props.getValue(name, 'users', idx);
  }

  getClass(name, idx) {
    return this.props.getInputClass(AddUsers.makeKey(name, idx), idx);
  }

  getErrorText(name, idx) {
    return this.props.getErrorText('users', `[${idx}].${name}`);
  }

  clearField(name, idx) {
    return this.props.clearField(name, 'users', idx);
  }

  render() {
    const {
      rows,
      userIdx,
      validatePage,
      isPageValid,
      handleSaveCompany,
      handleSetRow,
      analyticsPrefix,
      modifiedUsers: modifiedArray
    } = this.props;

    const userArray = rows || [{}];
    const shouldStartShut = !!(userArray.length && userArray[0].phone);

    return (
      <div className="dash-page-card-content">
        <Repeater
          title="User"
          titleFn={({ name = {} }, idx) => (
            <p className="expander-emphasis">
              {[name.first, name.last].filter(Boolean).join(' ') || `Manager ${idx + 1}`}
            </p>
          )}
          subTitleFn={({ isCompanyAdmin }) => (isCompanyAdmin ? 'Admin' : 'User')}
          addLabel="Add User/Admin"
          groupClass="admin-add-users-form"
          cornerImageKey="image"
          fns={this.inputFns}
          activeIdx={userIdx}
          setRowIdx={idx => handleSetRow(idx)()}
          saveFn={handleSaveCompany}
          addRowAndIncrement={this.handleAddRowAndIncrement}
          deleteRowAndDecrement={this.handleDeleteConfirm}
          analyticsObject={{
            addnew: `${analyticsPrefix}-adduser`,
            close: `${analyticsPrefix}-close`,
            view: `${analyticsPrefix}-view`,
            save: `${analyticsPrefix}-save`,
            delete: `${analyticsPrefix}-delete`
          }}
          startShut={shouldStartShut}
          {...{ rows, svgIcon, validatePage, isPageValid, modifiedArray }}
        >
          <MuiUploader
            label="Profile Photo"
            image={this.getValue('image', userIdx)}
            handleUpload={this.handleChange('image', userIdx)}
            gaKey="company-registration-addlocations-addfhimage"
            setCroppedImage={this.props.setCroppedImage}
            analyticsObject={{
              browse: `${analyticsPrefix}-browsephoto`,
              crop: `${analyticsPrefix}-cropphoto`,
              remove: `${analyticsPrefix}-removephoto`
            }}
          />
          <div className="form-two-fields">
            <RepeaterField required name="name.first" label="First Name" />
            <RepeaterField required name="name.last" label="Last Name" />
          </div>
          <div className="form-two-fields">
            <RepeaterField required name="email" type="email" label="Email" />
            <div className={this.getClass('title')}>
              <SelectField
                label="Job Title"
                id="title"
                type="text"
                className="form-input"
                maxHeight={300}
                floatingLabelFixed
                floatingLabelText="Job Title"
                hintText="e.g. Funeral Director, Owner"
                labelStyle={{ paddingLeft: 'none' }}
                style={{ flex: 1 }}
                selectedMenuItemStyle={{ color: '#3084FF' }}
                onChange={normalizeSelectEvent('title', this.handleChange('title', userIdx))}
                value={this.getValue('title', userIdx)}
              >
                <MenuItem value="owner" primaryText="Owner" />
                <MenuItem value="president" primaryText="President" />
                <MenuItem value="CEO" primaryText="CEO" />
                <MenuItem value="funeralDirector" primaryText="Funeral Director" />
                <MenuItem value="funeralArranger" primaryText="Funeral Arranger" />
                <MenuItem value="manager" primaryText="Manager" />
                <MenuItem value="embalmer" primaryText="Embalmer" />
                <MenuItem value="officeAdministrator" primaryText="Office Administrator" />
                <MenuItem value="assistant" primaryText="Assistant/Support" />
                <MenuItem value="celebrant" primaryText="Celebrant" />
                <MenuItem value="familyServicesCounselor" primaryText="Family Services Counselor" />
                <MenuItem value="preneedCounselor" primaryText="Preneed Counselor" />
                <MenuItem value="apprentice" primaryText="Apprentice" />
                <MenuItem value="other" primaryText="Other" />
              </SelectField>
            </div>
          </div>
          <RepeaterField required name="phone" type="tel" label="Cell Phone Number" />

          <div className="form-single-field-full">
            <RepeaterField name="about" type="text" label="About" multiline rows="4" />
          </div>

          <RepeaterField
            noArrow
            name="isCompanyAdmin"
            label="Authorize user as admin"
            onCheck={idx => this.handleChange('isCompanyAdmin', idx)}
            checked={rows[userIdx] && rows[userIdx].isCompanyAdmin}
            disabled={this.props.disableAdminCheckbox}
            component={Checkbox}
          />
          <p>Only admins have permission to change account information, add and delete users.</p>
        </Repeater>
      </div>
    );
  }
}
