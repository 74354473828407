import React from 'react';

import DashPage from '@evdy/web-core/dist/components/shared/elements/DashPage';
import ReviewSection from '@evdy/web-core/dist/components/shared/elements/dashCompanyRegistration/ReviewSection';

export default class ReviewSubmitUser extends React.Component {
  render() {
    const { userInfo, jumpToStep } = this.props;
    return (
      <DashPage
        customClass="stepper-page"
        headerText="Review and Submit"
        subHeaderText={
          <p>
            If you need to make changes, you can come back and edit any section from this screen.
          </p>
        }
      >
        <div className="review-submit-page">
          <ReviewSection
            title="Review Your Profile"
            type="standard"
            bodyEmphasis={`${userInfo.name.first} ${userInfo.name.last}`}
            body={
              <div>
                <p>Admin</p>
                {userInfo.title && <p>{userInfo.title}</p>}
                {userInfo.email && <p>{userInfo.email}</p>}
                {userInfo.phone && <p>{userInfo.phone}</p>}
              </div>
            }
            image={userInfo.image}
            editFunct={jumpToStep(0)}
          />
        </div>
      </DashPage>
    );
  }
}
