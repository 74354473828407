import { combineReducers } from 'redux';
import pick from 'lodash-es/pick';

import { combineForms } from 'react-redux-form';
import {
  inviteCreationDetails,
  decedentInfo,
  accessor,
  eventInfo,
  obituary,
  decedentPhone
} from './forms';

import * as types from './constants';
import * as detailsTypes from '../inviteDetails/constants';

// Decedent Section
const initialDecedentState = {
  data: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

const decedentDetailsKeys = [
  'cityOfBirth',
  'cityOfDeath',
  'coverIdx',
  'creationDate',
  'dateOfBirth',
  'dateOfDeath',
  '_id',
  'image',
  'isFollowing',
  'isManager',
  'isOwner',
  'name',
  'displayName',
  'shareUrl',
  'decedentPhone',
  'serviceStatus',
  'noServices',
  'traumatic',
  'condolences'
];

const decedentReducer = (state = initialDecedentState, action) => {
  switch (action.type) {
    case types.START_INVITE_CREATION_REQUEST:
    case types.FETCH_UNFINISHED_INVITE_REQUEST:
    case types.EDIT_DECEDENT_DETAILS_REQUEST:
    case types.ADD_NOSERVICE_OPTIONS_REQUEST:
    case types.SELECT_DIRECTOR_VIDEO_REQUEST:
    case types.EDIT_DECEDENT_PHONE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
    case types.ADD_NOSERVICE_OPTIONS_SUCCESS:
    case types.SELECT_DIRECTOR_VIDEO_SUCCESS:
      let decedentData = pick(action.payload.data, decedentDetailsKeys);
      decedentData = { ...decedentData, decedentPhone: `${decedentData.decedentPhone || ''}` };
      return {
        ...state,
        isFetching: false,
        data: decedentData,
        lastUpdated: action.receivedAt
      };
    case types.EDIT_DECEDENT_DETAILS_SUCCESS:
    case types.EDIT_DECEDENT_PHONE_SUCCESS:
      const updatedDecedent = {
        ...pick(action.payload.data, decedentDetailsKeys),
        decedentPhone: `${action.payload.data.decedentPhone || ''}`
      };

      return {
        ...state,
        isFetching: false,
        data: updatedDecedent,
        lastUpdated: action.receivedAt
      };
    case types.EDIT_DECEDENT_DETAILS_FAILURE:
    case types.EDIT_DECEDENT_PHONE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialDecedentState;
    default:
      return state;
  }
};

// Accessors
const initialAccessorsState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  error: {}
};

// nothing is finalized for this, feel free to update - Marc 2/27/20
const accessorsReducer = (state = initialAccessorsState, action) => {
  switch (action.type) {
    case types.FETCH_UNFINISHED_INVITE_REQUEST:
    case types.START_INVITE_CREATION_REQUEST:
    case types.ADD_NEW_ACCESSOR_REQUEST:
    case types.EDIT_ACCESSOR_REQUEST:
    case types.DELETE_ACCESSOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {}
      };
    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
    case types.DELETE_ACCESSOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        array: action.payload.data.accessors,
        lastUpdated: action.receivedAt,
        error: {}
      };
    case types.ADD_NEW_ACCESSOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        array: [...state.array, ...action.payload.data],
        lastUpdated: action.receivedAt,
        error: {}
      };
    case types.EDIT_ACCESSOR_SUCCESS:
      const editedAccessor = action.payload.data;
      return {
        ...state,
        isFetching: false,
        array: state.array.map(accessor =>
          accessor._id.toString() === editedAccessor._id.toString() ? editedAccessor : accessor
        ),
        lastUpdated: action.receivedAt,
        error: {}
      };
    case types.ADD_NEW_ACCESSOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case types.RESET_DUPE_ACCESSOR_ERROR:
      return {
        ...state,
        error: {}
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialAccessorsState;
    default:
      return state;
  }
};

// Events & Services
const initialServicesState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  error: {}
};

// nothing is finalized for this, feel free to update - Marc 2/27/20
const servicesReducer = (state = initialServicesState, action) => {
  switch (action.type) {
    case types.FETCH_UNFINISHED_INVITE_REQUEST:
    case types.START_INVITE_CREATION_REQUEST:
    case types.ADD_NEW_SERVICE_REQUEST:
    case types.EDIT_SERVICE_REQUEST:
    case types.DELETE_SERVICE_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
    case types.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        array: action.payload.data.services,
        lastUpdated: action.receivedAt
      };
    case types.ADD_NEW_SERVICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        array: [...state.array, action.payload.data],
        lastUpdated: action.receivedAt
      };
    case types.EDIT_SERVICE_SUCCESS:
      const editedService = action.payload.data;
      return {
        ...state,
        isFetching: false,
        array: state.array.map(service =>
          service._id.toString() === editedService._id.toString() ? editedService : service
        ),
        lastUpdated: action.receivedAt
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialServicesState;
    default:
      return state;
  }
};

// Locations for typeahead modal
const initialLocationState = {
  isFetching: false,
  locations: [],
  lastUpdated: null
};

const locationsReducer = (state = initialLocationState, action) => {
  switch (action.type) {
    case types.GET_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        locations: action.payload.data,
        lastUpdated: action.receivedAt
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialLocationState;
    default:
      return state;
  }
};

// Obituary
const initialObituaryState = {
  message: '',
  isFetching: false,
  lastUpdated: null,
  obitUpdated: null,
  obitCreated: null,
  error: {}
};

const obituaryReducer = (state = initialObituaryState, action) => {
  switch (action.type) {
    case types.ADD_OBITUARY_REQUEST:
    case types.EDIT_OBITUARY_REQUEST:
      return {
        ...state,
        isFetching: true,
        obitUpdated: null,
        obitCreated: null
      };
    case detailsTypes.FETCH_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        message: action.payload.obituary
      };
    case types.ADD_OBITUARY_SUCCESS:
      return {
        ...state,
        message: action.payload.data.obituary,
        lastUpdated: action.receivedAt,
        obitCreated: true,
        isFetching: false
      };
    case types.EDIT_OBITUARY_SUCCESS:
      return {
        ...state,
        message: action.payload.data.obituary,
        lastUpdated: action.receivedAt,
        obitUpdated: true,
        isFetching: false
      };
    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
      return {
        ...state,
        message: action.payload.data.obituary,
        lastUpated: action.receivedAt,
        isFetching: false
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialObituaryState;
    default:
      return state;
  }
};

// Provider / Funeral Home & Director
const initialFuneralInfoState = {
  agent: {},
  director: {},
  funeralHome: {},
  isFetching: false,
  lastUpdated: null,
  error: {}
};

// nothing is finalized for this, feel free to update - Marc 2/28/20
const funeralInfoReducer = (state = initialFuneralInfoState, action) => {
  const { funeralHomeInfo } = (action.payload && action.payload.data) || action.payload || {}; // TODO: refactor to avoid needing .data
  const { agent, director, funeralHome } = funeralHomeInfo || {};
  switch (action.type) {
    case types.FETCH_UNFINISHED_INVITE_REQUEST:
    case types.START_INVITE_CREATION_REQUEST:
    case types.EDIT_FUNERAL_INFO_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        agent,
        director,
        funeralHome
      };
    case types.EDIT_FUNERAL_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        agent,
        director,
        funeralHome
      };
    case types.EDIT_FUNERAL_INFO_RESET:
      return {
        ...state,
        lastUpdated: null
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialFuneralInfoState;
    default:
      return state;
  }
};

// Theme
const initialThemeState = {
  idx: null,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

const themeReducer = (state = initialThemeState, action) => {
  switch (action.type) {
    case types.FETCH_UNFINISHED_INVITE_REQUEST:
    case types.START_INVITE_CREATION_REQUEST:
    case types.EDIT_THEME_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        idx: action.payload.data.themeIdx
      };
    case types.EDIT_THEME_SUCCESS:
      return {
        ...state,
        idx: action.payload.data.themeIdx,
        isFetching: false,
        lastUpdated: action.receivedAt
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialThemeState;
    default:
      return state;
  }
};

// Sent Status reducer
const initialInviteSentState = {
  inviteSent: false,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

const inviteSentReducer = (state = initialInviteSentState, action) => {
  switch (action.type) {
    case types.FINISH_INVITE_CREATION_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.FINISH_INVITE_CREATION_SUCCESS:
      return {
        ...state,
        inviteSent: true,
        isFetching: false,
        lastUpdated: action.receivedAt
      };
    case types.FINISH_INVITE_CREATION_FAILURE:
      return {
        ...state,
        inviteSent: false,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialInviteSentState;
    default:
      return state;
  }
};

// Invite Preview SMS Sent Reducer
const initialInvitePreviewState = {
  previewSent: false,
  isFetching: false,
  lastUpdated: null,
  error: {}
};

const invitePreviewSentReducer = (state = initialInvitePreviewState, action) => {
  switch (action.type) {
    case types.SEND_MEMORIAL_LINK_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.SEND_MEMORIAL_LINK_SUCCESS:
      return {
        ...state,
        previewSent: true,
        isFetching: false,
        lastUpdated: action.receivedAt
      };
    case types.SEND_MEMORIAL_LINK_FAILURE:
      return {
        ...state,
        previewSent: false,
        isFetching: false,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    case types.RESET_INVITE_CREATION:
    case detailsTypes.INVITE_DETAILS_RESET: // TODO: decouple these resets and constant name overlaps in a future refactor
      return initialInvitePreviewState;
    default:
      return state;
  }
};

const initialDeleteInviteState = {
  receivedAt: null,
  error: {},
  data: {},
  isFetching: false
};

const inviteDeleteReducer = (state = initialDeleteInviteState, action) => {
  switch (action.type) {
    case types.DELETE_INVITE_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case types.DELETE_INVITE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        receivedAt: action.receivedAt
      };
    case types.DELETE_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payload: action.data,
        receivedAt: action.receivedAt
      };
    default:
      return {
        ...state
      };
  }
};

const initialDirectorVideoUploadState = {
  uploadInProgress: false,
  uploadProgress: null,
  thumbnailUrl: '',
  uploadError: {}
};

const inviteDirectorVideoUploadReducer = (state = initialDirectorVideoUploadState, action) => {
  switch (action.type) {
    case types.SET_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        uploadInProgress: action.payload
      };
    case types.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload
      };
    case types.SET_UPLOAD_THUMBNAIL:
      return {
        ...state,
        thumbnailUrl: action.payload
      };
    case types.INVALID_DIRECTOR_UPLOAD_DURATION:
      return {
        ...state,
        uploadError: {
          message: action.payload
        }
      };
    case types.RESET_DIRECTOR_VIDEO_UPLOAD:
      return initialDirectorVideoUploadState;
    default:
      return {
        ...state
      };
  }
};

// Donations
const initialDonationState = {
  array: [],
  isFetching: false,
  lastUpdated: null,
  donationAdded: false,
  donationUpdated: false,
  donationRemoved: false,
  error: {}
};

const donationReducer = (state = initialDonationState, action) => {
  switch (action.type) {
    case detailsTypes.FETCH_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        array: action.payload.donations || []
      };
    case types.ADD_DONATION_FAILURE:
    case types.EDIT_DONATION_FAILURE:
    case types.DELETE_DONATION_FAILURE:
      return {
        ...state,
        error: action.error,
        lastUpdated: action.receivedAt
      };
    case types.ADD_DONATION_REQUEST:
    case types.EDIT_DONATION_REQUEST:
    case types.DELETE_DONATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        donationAdded: false,
        donationUpdated: false,
        donationRemoved: false
      };
    case types.ADD_DONATION_SUCCESS:
      //state.array is an obj on older invites due to old mongoose schema
      //dev/prod memos have been etl'd
      return {
        ...state,
        array: [...state.array, action.payload.data],
        isFetching: false,
        donationAdded: true,
        lastUpdated: action.receivedAt
      };
    case types.EDIT_DONATION_SUCCESS:
      const updatedDonationIdx = state.array.findIndex(
        d => d._id.toString() === action.payload.data._id.toString()
      );
      return {
        ...state,
        array: [
          ...state.array.slice(0, updatedDonationIdx),
          action.payload.data,
          ...state.array.slice(updatedDonationIdx + 1)
        ],
        isFetching: false,
        donationUpdated: true,
        lastUpdated: action.receivedAt
      };
    case types.DELETE_DONATION_SUCCESS:
      return {
        ...state,
        array: action.payload.data.donations,
        isFetching: false,
        lastUpdated: action.receivedAt,
        donationRemoved: true
      };
    case types.FETCH_UNFINISHED_INVITE_SUCCESS:
    case types.START_INVITE_CREATION_SUCCESS:
      return {
        ...state,
        array: action.payload.data.donations,
        lastUpdated: action.receivedAt,
        isFetching: false
      };
    default:
      return state;
  }
};

export default combineReducers({
  decedentDetails: decedentReducer,
  accessors: accessorsReducer,
  services: servicesReducer,
  googleLocations: locationsReducer,
  obituary: obituaryReducer,
  funeralInfo: funeralInfoReducer,
  theme: themeReducer,
  inviteSent: inviteSentReducer,
  invitePreview: invitePreviewSentReducer,
  inviteDelete: inviteDeleteReducer,
  directorVideoUpload: inviteDirectorVideoUploadReducer,
  donations: donationReducer,
  forms: combineForms(
    { inviteCreationDetails, eventInfo, decedentInfo, accessor, obituary, decedentPhone },
    'inviteCreation.forms',
    {
      key: 'formsMeta'
    }
  )
});
