import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { browserHistory } from 'react-router';

import './EditUserDetails.scss';
import { Form, InputGroup, formActions } from '@evdy/web-core/dist/components/shared/Input';
import { editSpecificUser } from '@evdy/web-redux/dist/actions/dash/editUser';
import { renderToast, formatAddress } from '@evdy/web-core/dist/lib/utils';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';
import RecentAnncs from '../../../shared/RecentAnncs';

const model = 'dash.userDetails.forms.details';
const successToastId = 'crisp-toast';
const errorToastId = 'burnt-toast';

const EditUserDetails = props => {
  const {
    merge,
    fetchedUser,
    detailsData,
    editSpecificUser,
    lastUpdated,
    editErrorMessage,
    submitted,
    location
  } = props;

  const {
    _id: userId,
    prefs: { sms, push, email } = {},
    hasPassword,
    meta: { isRep, everdaysAdmin, everdaysAdminJourneys } = {},
    isAFP,
    isCompanyAdmin,
    isBanned,
    isArchived,
    title: userTitle = '',
    company,
    companies = []
  } = fetchedUser || {};

  const userDetails = {
    prefs: { sms, push, email },
    hasPassword,
    meta: { isRep, everdaysAdmin, everdaysAdminJourneys },
    isAFP,
    isCompanyAdmin,
    isBanned,
    isArchived
  };

  const companyList = companies.length ? companies : company ? [company] : [];

  const handleCheckboxToggle = ({ target: { id, checked } }) => {
    const path = id.replace(/^\./, '');
    editSpecificUser(set(cloneDeep(detailsData), path, checked), userId);
  };

  const handleAccountClick = companyId => () => {
    const userLinkParams = {
      isExternalMode: false,
      originDisplayName: 'User Details',
      originPathName: `/dash/a/users/${userId}?tabIndex=1`
    };

    const pathname = `/dash/a/accounts/${companyId}`;
    browserHistory.push({ ...location, pathname, query: userLinkParams });
  };

  useEffect(() => {
    merge(model, userDetails);
  }, [merge, userDetails]);

  // display toast on success/save confirmation
  useEffect(() => {
    if (submitted && lastUpdated >= Date.now() - 50 && !editErrorMessage) {
      renderToast({ id: successToastId, message: 'Successfully saved changes' });
    }
  }, [editErrorMessage, fetchedUser, lastUpdated, submitted]);

  // show toast if its burnt
  useEffect(() => {
    if (editErrorMessage) {
      renderToast({ id: errorToastId, message: editErrorMessage, type: 'error' });
    }
  }, [editErrorMessage]);

  return (
    <div className="edit-details-tab">
      <Form model={model} className="user-detail-section">
        <h1>Global Notifications</h1>
        <div className="input-row">
          <InputGroup
            inline
            model=".prefs.push"
            labelText="Push"
            type="checkbox"
            customClass="checkbox"
            onChange={handleCheckboxToggle}
          />
          <InputGroup
            inline
            model=".prefs.email"
            labelText="Email"
            type="checkbox"
            customClass="checkbox"
            onChange={handleCheckboxToggle}
          />
          <InputGroup
            inline
            model=".prefs.sms"
            labelText="Text Message"
            type="checkbox"
            customClass="checkbox"
            onChange={handleCheckboxToggle}
          />
        </div>
        <div className="user-detail-section">
          <h1>Settings</h1>
          <div className="input-row">
            <InputGroup
              inline
              model=".hasPassword"
              labelText="Has Password"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".meta.isRep"
              labelText="Rep"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".meta.everdaysAdmin"
              labelText="Everdays Admin"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".meta.everdaysAdminJourneys"
              labelText="Journeys Admin"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".isAFP"
              labelText="AFP"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".isCompanyAdmin"
              labelText="Company Admin"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".isBanned"
              labelText="Banned"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
            <InputGroup
              inline
              model=".isArchived"
              labelText="Archived"
              type="checkbox"
              customClass="checkbox"
              onChange={handleCheckboxToggle}
            />
          </div>
        </div>
      </Form>
      {!!companyList.length && (
        <div className="user-detail-section">
          <h1>Associated Accounts</h1>
          <div className="user-companies">
            {companyList.map(({ _id, name, logo, address }) => (
              <div key={_id} className="user-company">
                <div className="company-details">
                  <CharacterProfileImage {...{ name }} image={logo} />
                  <div className="account-details">
                    <p>{name}</p>
                    <p>{formatAddress(address)}</p>
                  </div>
                </div>
                <div className="edit-company">
                  <button onClick={handleAccountClick(_id)}>Edit</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <RecentAnncs
        sectionTitle="Associated Invites"
        directorFilter={userId}
        isLimitedAccess={false}
      />
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    detailsData: dash.userDetails.forms.details
  }),
  { editSpecificUser, merge: formActions.merge }
)(EditUserDetails);
