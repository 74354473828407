import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { editUserAction } from '@evdy/web-redux/dist/actions/dash/editUser';

import './DemoBar.scss';

const DemoBar = props => {
  const { editUserAction, user } = props;
  const demoBarClasses = classNames('nav-demo-bar', {
    'demo-mode-on': user?.isDemoModeOn
  });

  return (
    <div className={demoBarClasses}>
      <div className="demo-bar-action">
        Turn Demo Mode Off
        <button
          className="demo-bar-button"
          onClick={() => editUserAction({ isDemoModeOn: false })}
        />
      </div>
    </div>
  );
};

export default connect(
  ({ dash }) => ({ user: dash.user.data }),
  { editUserAction }
)(DemoBar);
