import React, { useState } from 'react';
import { connect } from 'react-redux';

import InvitePreviewCard from '../InvitePreviewCard';
import EventRow from '../../../../shared/DashCard/CardBuilder/EventRow';
import ChooseNoServicesModal from '../../modals/ChooseNoServicesModal/index';
import AddEventsModal from '../../modals/AddEventsModal';

import Button from '@evdy/web-core/dist/components/Button';

import emptyCal from '../../Events/EmptyEventRow/empty-cal.svg';

import './ServicesPreview.scss';

const ServicesPreviewCard = ({
  chooseServiceFormData,
  eventFormData,
  eventFormMeta,
  eventFormModel = 'inviteCreation.forms.eventInfo',
  funeralHomeId,
  memorialId,
  servicesArray = [],
  serviceStatus,
  serviceStatusCopy
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editEventId, setEditEventId] = useState(null);
  const [noServicesOptionsVisible, setNoServicesOptionsVisible] = useState(false);

  const model = eventFormModel;
  //when posting service in the past there is a render where servicesArray = [undefined]
  const noServices = !servicesArray.filter(Boolean).length;

  const closeModal = () => {
    setModalVisible(false);
    setEditEventId(null);
  };

  const lastEventIdx = servicesArray?.length - 1;
  const mapInviteEvents = eventsArr =>
    eventsArr?.map((event, idx) => {
      const {
        title = '',
        location = {},
        startDateISO = '',
        endDateISO = '',
        _id = '',
        startTimeTBD,
        videoStreamUrl = ''
      } = event || {};
      return (
        <EventRow
          key={_id}
          openEditModal={() => {
            setModalVisible(true);
            setEditEventId(_id);
          }}
          isLastEvent={idx === lastEventIdx}
          {...{
            title,
            location,
            startDateISO,
            endDateISO,
            _id,
            noServices,
            startTimeTBD,
            videoStreamUrl
          }}
        />
      );
    });

  const renderNoServiceRows = () => (
    <div className="no-event-container">
      <div className="add-events-row">
        <div className="add-event">
          <div className="event-status">
            <div className="calendar">
              <img src={emptyCal} alt="calendar-icon" />
            </div>
            <div className="row-copy">Add Event</div>
          </div>
          <Button
            copy="Add Event"
            action={() => setModalVisible(true)}
            style="standard-blue-solid-btn"
          />
        </div>
      </div>
    </div>
  );

  const sortedServices = [...servicesArray].sort((a, b) =>
    a?.startDateISO > b?.startDateISO ? 1 : -1
  );
  return (
    <InvitePreviewCard
      headerBtnCopy={noServices ? null : 'Add event'}
      customClass="events-card-container"
      headerBtnAction={noServices ? null : () => setModalVisible(true)}
      headerCopy="Events & services"
    >
      {noServices ? renderNoServiceRows() : mapInviteEvents(sortedServices)}
      <AddEventsModal
        {...{
          modalVisible,
          model,
          editEventId,
          closeModal,
          memorialId,
          servicesArray,
          formData: eventFormData,
          eventFormMeta,
          funeralHomeId
        }}
      />
      <ChooseNoServicesModal
        {...{
          memorialId,
          serviceStatus
        }}
        model={eventFormModel}
        formData={chooseServiceFormData}
        modalVisible={noServicesOptionsVisible}
        closeModal={() => {
          setNoServicesOptionsVisible(false);
        }}
      />
    </InvitePreviewCard>
  );
};

export default connect(({ client }) => ({
  serviceStatusCopy: client?.config?.data?.copy?.memorialServiceStatus
}))(ServicesPreviewCard);
