import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { doesMatch } from '@evdy/web-core/dist/components/shared/Input';

import { Form, formActions } from '@evdy/web-core/dist/components/shared/Input';
import PasswordInput from '@evdy/web-core/dist/components/shared/PasswordInput';

import { editUserPassword, editUserReset } from '@evdy/web-redux/dist/actions/dash/editUser';

import './PasswordTabBody.scss';

const PasswordTabBody = ({
  profile,
  editUserPassword,
  editedUser,
  validate,
  editUserReset,
  handleTabSwitch,
  reset
}) => {
  const { existingPassword, newPassword } = profile?.forms?.changePassword;

  const { confirmPassword: confirmMeta } = profile?.forms?.profileMeta?.changePassword;

  const model = 'dash.profile.forms.changePassword';

  const passwordSuccessToast = 'nice-toast';
  const passwordFailureToast = 'gross-toast';

  const renderToast = useCallback(({ id, message, type }) => {
    if (!toast.isActive(id)) {
      toast(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        type: type === 'error' ? toast.TYPE.ERROR : toast.TYPE.INFO,
        toastId: id
      });
    }
  }, []);

  useEffect(() => {
    if (
      editedUser.error.message &&
      editedUser.submitted === false &&
      editedUser.isFetching === false
    ) {
      renderToast({ id: passwordFailureToast, message: editedUser.error.message, type: 'error' });
      editUserReset();
    }
  }, [editedUser, editUserReset, renderToast]);

  useEffect(() => {
    if (
      !editedUser.error.message &&
      editedUser.submitted === true &&
      editedUser.isFetching === false
    ) {
      renderToast({ id: passwordSuccessToast, message: 'Password successfully changed' });
      editUserReset();
      handleTabSwitch();
      reset(model);
    }
  }, [editedUser, editUserReset, handleTabSwitch, reset, renderToast]);

  const handleChangePasswordSubmit = () => {
    editUserPassword({ dashPassword: existingPassword, newDashPassword: newPassword });
  };

  const revalidateConfirmPassword = ({ target: { value: password } }) => {
    if (confirmMeta.touched) {
      validate(`${model}.confirmPassword`, {
        doesMatch: doesMatch(password)
      });
    }
  };

  return (
    <div className="profile-change-password">
      <Form
        model={model}
        className="profile-change-password-form"
        onSubmit={handleChangePasswordSubmit}
      >
        <PasswordInput model=".existingPassword" labelText="Existing Password" />
        <PasswordInput
          model=".newPassword"
          labelText="New Password"
          onChange={revalidateConfirmPassword}
        />
        <PasswordInput
          model=".confirmPassword"
          labelText="New Password (Confirmation)"
          toMatchValue={newPassword}
        />
        <button disabled={!profile.forms.profileMeta.$form.valid} type="submit">
          Save
        </button>
      </Form>
    </div>
  );
};

export default connect(({ dash }) => ({ profile: dash.profile, editedUser: dash.editUser }), {
  editUserPassword,
  editUserReset,
  validate: formActions.validate,
  reset: formActions.reset
})(PasswordTabBody);
