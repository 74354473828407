import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import './UserListItem.scss';
import { nameString, formatPhoneNumber } from '@evdy/web-core/dist/lib/utils';
import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';

const UserListItem = ({
  _id,
  image,
  name,
  email,
  phone,
  updatedAt,
  company,
  isAFP,
  handleUserClick
}) => {
  const Tag = ({ type = 'creator', children }) => (
    <div className={classNames('tag', type)}>{children}</div>
  );

  return (
    <div className="dash-user-list-item" onClick={() => handleUserClick(_id)}>
      <CharacterProfileImage customClasses={['item-image']} {...{ image, name }} />
      <div className="item-body">
        <div className="item-profile-info">
          <h1>{nameString(name)}</h1>
          <p>{email}</p>
          <p>{formatPhoneNumber(phone)}</p>
          <p>Last Updated: {moment(updatedAt).format('lll')}</p>
        </div>
        <div className="item-tags">
          {!!company && <Tag type="director-tag">Director</Tag>}
          {!company && <Tag type="consumer-tag">Consumer</Tag>}
          {isAFP && <Tag type="agent-tag">Agent</Tag>}
        </div>
      </div>
    </div>
  );
};

export default UserListItem;
