import React from 'react';

import RecentAnncs from '../../../shared/RecentAnncs';
import './AccOverview.scss';
import { formatAddress } from '@evdy/web-core/dist/lib/utils';

const AccOverview = ({ account, accountId }) => {
  const { _id, phone, address } = account;

  const summary = {
    'Everdays ID': _id,
    'Phone Number': phone,
    Address: formatAddress(address)
  };

  return (
    <div className="dash-acc-details-container">
      <div className="summary">
        {Object.entries(summary).map(([title, value]) => (
          <div key={title} className="summary-item">
            <div className="item-title">{title}</div>
            <div className="item-value">{value}</div>
          </div>
        ))}
      </div>
      <RecentAnncs companyIdFilter={accountId} anncAmt={5} />
    </div>
  );
};

export default AccOverview;
