import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchCompanyMemorialsStats } from '@evdy/web-redux/dist/lib/dash/actions/fetchCompanyMemorialsStats';
import { fetchDemoMemorialStats } from '@evdy/web-redux/dist/lib/demoMode';

import Stats from './Stats';

const StatsSection = props => {
  const { dash, fetchedCompany, fetchCompanyMemorialsStats, fetchDemoMemorialStats, user } = props;

  const { isDemoModeOn = false } = user || {};
  const fetchedCompanyId = fetchedCompany?._id;
  const memorialStats = dash?.memorialStats;

  useEffect(() => {
    if (memorialStats.lastUpdated < Date.now() - 1e3 && !memorialStats.isFetching) {
      isDemoModeOn
        ? fetchDemoMemorialStats()
        : fetchCompanyMemorialsStats({ companyId: fetchedCompanyId });
    }
  }, [fetchedCompanyId, memorialStats, fetchCompanyMemorialsStats]);

  const data = props.dash.memorialStats?.memorials?.data || [];

  return data ? (
    <div className="stats-graph-section">
      <h2>Invites Report</h2>
      <Stats {...{ data }} />
    </div>
  ) : null;
};

export default connect(
  ({ dash }) => ({
    dash,
    fetchedCompany: dash?.fetchedCompany?.company?.data,
    user: dash.user.data
  }),
  { fetchCompanyMemorialsStats, fetchDemoMemorialStats }
)(StatsSection);
