import React from 'react';
import camelCase from 'lodash/camelCase';

import { InputGroup, isRequired } from '@evdy/web-core/dist/components/shared/Input';

import funeralHomeLicenseInfo from '@evdy/web-etc/lib/funeralHomeLicenseInfo';

const taxClasses = ['Sole Proprietor', 'Partnership', 'Corporation'];

export const mapRequiredCompanyFields = fieldsArray =>
  fieldsArray.map(field => {
    const model = (() => {
      switch (field) {
        case 'Tax ID':
          return '.taxID';
        case 'Tax Classification':
          return `.${camelCase(field)}`;
        default:
          break;
      }
    })();

    const labelText = field !== 'Tax ID' ? `${field} *` : 'Tax ID Number *';

    if (field === 'Tax Classification')
      return (
        <InputGroup
          validators={{ isRequired }}
          messages={{ isRequired: 'Field is required' }}
          model=".taxClassification"
          type="select"
          labelText="Tax Classificiation *"
        >
          <option />
          {taxClasses.map(c => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </InputGroup>
      );

    return (
      <InputGroup
        {...{ model, labelText }}
        validators={{ isRequired }}
        messages={{ isRequired: 'Field is required' }}
      />
    );
  });

export const mapRequiredAccountFields = (fieldsArray, address) => {
  const stateFields = funeralHomeLicenseInfo[address?.state] ?? {};
  const getSpecialFieldNames = Object.entries(stateFields).filter(
    ([_, val]) => typeof val === 'string'
  );

  return fieldsArray.map(field => {
    const model = (() => {
      if (field === 'Funeral Director Number') return `.${camelCase(field)}`;
      if (field === 'Insurance License Number') return `.${camelCase(field)}`;
      if (!!field) return `.${getSpecialFieldNames.find(([_, val]) => val === field)[0]}`;
    })();

    const labelText = `${field} *`;

    return (
      <InputGroup
        {...{ model, labelText }}
        validators={{ isRequired }}
        messages={{ isRequired: 'Field is required' }}
      />
    );
  });
};
