import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import startCase from 'lodash-es/startCase';

import {
  Form,
  InputGroup,
  isRequired,
  isValidPhone,
  parsePhone,
  formActions,
  isEmail
} from '@evdy/web-core/dist/components/shared/Input';

import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';

import { companyTypes } from '@evdy/web-etc/dist/lib/companyTypes';
import { abbreviatedStatesArray, abbreviatedProvincesArray } from '@evdy/web-etc/dist/lib/repZones';
import { normalizeStateProvince } from '@evdy/web-core/dist/lib/utils';

import './CompanyEditModalBody.scss';

const regionArray = [...abbreviatedStatesArray, ...abbreviatedProvincesArray];

const CompanyEditModalBody = ({
  // props
  handleUploadFinish,
  handleRemoveImage,
  // redux state
  fetchedCompanyData,
  formData,
  // redux actions
  change,
  reset
}) => {
  const model = 'dash.company.forms.overview';
  const compTypes = Object.entries(companyTypes);
  const taxClasses = ['Sole Proprietor', 'Partnership', 'Corporation'];

  const { address, name, phone, website, logo, type, email, taxID, taxClassification } =
    fetchedCompanyData || {};

  const { street1, street2, city, state, zip } = address || {};

  // populate inputs with Company info on mount/save
  useEffect(() => {
    name && change(`${model}.name`, name);
    website && change(`${model}.website`, website);
    street1 && change(`${model}.address.street1`, street1);
    typeof street2 === 'string' && change(`${model}.address.street2`, street2);
    city && change(`${model}.address.city`, city);
    state && change(`${model}.address.state`, normalizeStateProvince(state));
    zip && change(`${model}.address.zip`, zip);
    phone && change(`${model}.phone`, phone);
    typeof logo === 'string' && change(`${model}.logo`, logo);
    type && change(`${model}.type`, type);
    email && change(`${model}.email`, email);
    taxID && change(`${model}.taxID`, taxID);
    taxClassification && change(`${model}.taxClassification`, taxClassification);
  }, [
    change,
    name,
    website,
    street1,
    street2,
    city,
    state,
    zip,
    phone,
    logo,
    type,
    email,
    taxID,
    taxClassification
  ]);

  return (
    <Form className="company-edit-modal-body" model={model}>
      <ImageHandler
        v11Styling
        {...{ handleUploadFinish, handleRemoveImage }}
        currentImg={logo || ''}
        modalTitle="Account Logo"
        dropzoneTitle="Add an account logo"
        dropzoneSubtitle=""
      />
      <div className="input-row">
        <InputGroup
          required
          model=".name"
          labelText="Account Name *"
          validators={{ isRequired }}
          messages={{ isRequired: 'Account Name is required' }}
        />
        <InputGroup
          model=".email"
          labelText="Account Email"
          validators={{ isEmail }}
          messages={{ isEmail: 'Please enter a valid email' }}
        />
      </div>
      <div className="input-row">
        <InputGroup model=".taxID" labelText="Tax ID" />
        <InputGroup model=".taxClassification" type="select" labelText="Tax Classificiation">
          <option />
          {taxClasses.map(c => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </InputGroup>
      </div>
      <div className="input-row">
        <InputGroup model=".type" type="select" labelText="Type of Business">
          <option />
          {compTypes.map(([key, val]) => (
            <option key={key} value={val}>
              {startCase(key)}
            </option>
          ))}
        </InputGroup>
        <InputGroup model=".website" labelText="Business Website" />
      </div>
      <div className="input-row">
        <InputGroup
          required
          model=".address.street1"
          labelText="Address 1 *"
          validators={{ isRequired }}
          messages={{ isRequired: 'Address 1 is required' }}
        />
        <InputGroup model=".address.street2" labelText="Address 2" />
      </div>
      <div className="input-row">
        <InputGroup
          required
          model=".address.city"
          labelText="City *"
          validators={{ isRequired }}
          messages={{ isRequired: 'City is required' }}
        />
        <InputGroup
          inline
          full
          type="select"
          model=".address.state"
          labelText="State/Province *"
          value={formData.address.state}
          validators={{ isRequired }}
          messages={{ isRequired: 'State/Province is required' }}
        >
          <option />
          {regionArray.map((region, i) => (
            <option value={region} key={i}>
              {region}
            </option>
          ))}
        </InputGroup>
      </div>
      <div className="input-row">
        <InputGroup
          required
          model=".address.zip"
          labelText="Postal Code *"
          validators={{ isRequired }}
          messages={{ isRequired: 'Postal Code is required' }}
        />
        <InputGroup
          required
          model=".phone"
          type="tel"
          labelText="Phone Number *"
          parser={parsePhone}
          validators={{ isRequired, isValidPhone }}
          messages={{
            isRequired: 'Phone Number is required',
            isValidPhone: 'Please enter a valid phone number'
          }}
        />
      </div>
    </Form>
  );
};

export default connect(
  ({ dash }) => ({
    fetchedCompanyData: dash?.fetchedCompany?.company?.data,
    formData: dash.company.forms.overview
  }),
  {
    change: formActions.change,
    reset: formActions.reset
  }
)(CompanyEditModalBody);
