import React from 'react';
import { string } from 'prop-types';

import './AccountSetupHeader.scss';

const AccountSetupHeader = ({ headerCopy = '', subtitleCopy = '' }) => {
  return (
    <div className="setup-header">
      <p className="header-copy">{headerCopy}</p>
      <p className="time-to-complete">{subtitleCopy}</p>
    </div>
  );
};

AccountSetupHeader.propTypes = {
  headerCopy: string,
  subtitleCopy: string
};

export default AccountSetupHeader;
