import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import DashNav from './DashNav';
import DashSideNav from './DashSideNav';
import ResourcesColumn from './ResourcesColumn';
import YoutubeVideoModal from './YoutubeVideoModal';
import InvitePreviewGutter from './InvitePreviewGutter';

import { fetchCompany } from '@evdy/web-redux/dist/actions/dash/fetchCompany';
import { fetchDemoCompany } from '@evdy/web-redux/dist/lib/demoMode';
import { fetchClientConfig } from '@evdy/web-redux/dist/actions/fetchClientConfig';

import '../styles/Dash.scss';

const getPage = pathname => {
  let pageName = '';

  if (pathname.includes('/announcements')) pageName = 'invitations';
  if (pathname.includes('/announcements/')) pageName = 'invite-details';
  if (pathname.includes('/builder')) pageName = 'builder';
  if (pathname.includes('/dashboard')) pageName = 'dashboard';
  if (pathname.includes('/reports')) pageName = 'reports';
  if (pathname.includes('/accounts')) pageName = 'accounts';
  if (pathname.includes('/add-ons')) pageName = 'add-ons';
  if (pathname.includes('/dash/signup')) pageName = 'onboarding';
  if (pathname.includes('/dash/signup-success')) pageName = 'signup-success';
  if (pathname.includes('/dash/request-password')) pageName = 'request-password';
  if (pathname.includes('/dash/reset-password')) pageName = 'reset-password';
  if (pathname.includes('/dash/login')) pageName = 'dash-login';
  if (pathname.includes('/sales')) pageName = 'sales';
  if (pathname.includes('/users')) pageName = 'users';
  if (pathname.includes('/resource-center')) pageName = 'resource-center';
  if (pathname.includes('/dash/a/company')) pageName = 'company';
  if (pathname.includes('/dash/a/locations')) pageName = 'locations';
  if (pathname.includes('/dash/a/team-members')) pageName = 'team-members';
  if (pathname.includes('/dash/a/activity-feed')) pageName = 'activity-feed';
  if (pathname.includes('/dash/a/account-setup')) pageName = 'account-setup';

  return pageName;
};

const getPageTitle = pathname => {
  let title = '';

  if (pathname.includes('/builder')) title = 'Create Memorial';
  if (pathname.match(/\/(login|signup)/)) title = 'Everdays';
  if (pathname.includes('/dash/signup')) title = 'Welcome to Everdays';

  return title;
};

@connect(({ dash, inviteDetails, donations }) => ({
  user: dash.user?.data,
  fetchedCompany: dash.fetchedCompany,
  isEmployee: dash.dashNav?.isEmployee,
  fetchClientConfig,
  inviteMetaData: inviteDetails?.inviteMeta?.data,
  donationsData: inviteDetails?.donations
}))
export default class Dash extends React.Component {
  constructor(props) {
    super(props);
    this.switchCompany = this.switchCompany.bind(this);
    this.toggleIntroModal = this.toggleIntroModal.bind(this);

    this.state = {
      shouldShowIntroModal: false,
      shouldShowVideoModal: false
    };
  }

  componentWillMount() {
    const { dispatch, user, fetchedCompany } = this.props;

    const { _id: userId, isDemoModeOn = false, hasSeenV11Dash } = user || {};

    if (user && fetchedCompany.lastUpdated < Date.now() - 30e3) {
      isDemoModeOn ? dispatch(fetchDemoCompany()) : dispatch(fetchCompany(user?.company?._id));
    }
    dispatch(fetchClientConfig());

    if (userId && !hasSeenV11Dash) this.setState({ shouldShowIntroModal: true });
  }

  componentDidUpdate(prevProps) {
    const { dispatch, user, fetchedCompany } = this.props;
    const { fetchedCompany: prevCompany, user: prevUser } = prevProps;

    const { isDemoModeOn = false, hasSeenV11Dash = false } = user || {};

    // set current company when user logs in
    if (!prevUser && user) {
      isDemoModeOn ? dispatch(fetchDemoCompany()) : dispatch(fetchCompany(user?.company?._id));

      // on login, if user has not seen v11 Dash yet, show intro modal
      if (!hasSeenV11Dash) this.setState({ shouldShowIntroModal: true });
    }

    // fetch the user company if it hasn't been fetched yet, or the user
    // is switching companies
    if (
      user &&
      !fetchedCompany.lastUpdated &&
      !prevCompany.isFetching &&
      !fetchedCompany.isFetching
    ) {
      isDemoModeOn ? dispatch(fetchDemoCompany()) : dispatch(fetchCompany(user?.company?._id));
    } else if (!prevUser?.isDemoModeOn && user?.isDemoModeOn) {
      dispatch(fetchDemoCompany());
    } else if (prevUser?.isDemoModeOn && !user?.isDemoModeOn) {
      dispatch(fetchCompany(user?.company?._id));
    }
  }

  switchCompany(currentCompany) {
    this.props.dispatch(fetchCompany(currentCompany));
  }

  toggleIntroModal(state) {
    const shouldShowIntroModal =
      typeof state === 'boolean' ? state : !this.state.shouldShowIntroModal;

    this.setState({ shouldShowIntroModal });
  }

  render() {
    const { location, children, user, isEmployee, inviteMetaData = {}, donationsData } = this.props;
    const { pathname, state } = location;

    const { switchCompany } = this;
    const { isDemoModeOn = false } = user || {};
    const { _id: memorialId } = inviteMetaData || {};

    const currentPage = getPage(pathname);
    const pageTitle = getPageTitle(pathname);

    // helper vars
    const shouldShowTopNav = ![
      'dash-login',
      'signup-success',
      'request-password',
      'reset-password'
    ].includes(currentPage);
    const shouldShowSideNav = ![
      'builder',
      'onboarding',
      'dash-login',
      'signup-success',
      'request-password',
      'reset-password'
    ].includes(currentPage);
    const shouldShowResourcesColumn = [
      'invitations',
      'company',
      'team-members',
      'activity-feed',
      'account-setup'
    ].includes(currentPage);
    const shouldShowCardPreview = ['invite-details'].includes(currentPage);

    // classes
    const contentClasses = classNames('dash-content', {
      'demo-mode-on': isDemoModeOn,
      'no-top-nav': !shouldShowTopNav,
      'no-side-nav': !shouldShowSideNav,
      'has-resource-column': !!shouldShowResourcesColumn
    });

    const dashClassNames = classNames('dash', { 'invite-details-is-open': shouldShowCardPreview });

    return (
      <div className={dashClassNames}>
        {shouldShowTopNav && (
          <DashNav {...{ switchCompany, currentPage, pageTitle, location }} locationState={state} />
        )}
        <div className={contentClasses}>
          {shouldShowSideNav && (
            <DashSideNav
              {...{ switchCompany, currentPage, pageTitle, user, isEmployee, isDemoModeOn }}
            />
          )}
          {children}
          {shouldShowResourcesColumn && (
            <ResourcesColumn
              handleOpenVideoModal={() => this.setState({ shouldShowVideoModal: true })}
            />
          )}
          {shouldShowCardPreview && (
            <InvitePreviewGutter showQuickActions {...{ currentPage, memorialId, donationsData }} />
          )}
          {/*the video modal lives here in Dash, since having it live in the resources column will have the dash nav appear above the modal overlay*/}
          <YoutubeVideoModal
            closeModal={() => this.setState({ shouldShowVideoModal: false })}
            modalVisible={this.state.shouldShowVideoModal}
            headerCopy="How to Create a Memorial"
            youtubeVideoId="bOWp1chvAGY"
          />
        </div>
      </div>
    );
  }
}
