import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './DecedentOverviewModal.scss';

import { creationOperations } from '@evdy/web-redux/src/lib/inviteCreation';

const { editDecedentDetailsAction } = creationOperations;

import DecedentInfoForm from '../../forms/DecedentInfoForm';
import {
  ModalOverlay,
  ResponsiveContent,
  SolidColorModalHeader,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';
import { formatPhoneNumber, formatTime } from '@evdy/web-core/dist/lib/utils';

const DecedentOverviewModal = props => {
  const {
    modalVisible,
    handleCloseModal,
    model,
    decedentInfo,
    decedentFormData,
    decedentFormMetaData,
    merge,
    reset,
    editDecedentDetailsAction
  } = props;

  const { _id: memorialId } = decedentInfo || {};

  // merge previous form values
  useEffect(() => {
    merge(model, {
      ...decedentInfo,
      dateOfBirth: formatTime(decedentInfo?.dateOfBirth),
      dateOfDeath: formatTime(decedentInfo?.dateOfDeath),
      decedentPhone: decedentInfo?.decedentPhone
        ? formatPhoneNumber(decedentInfo.decedentPhone)
        : ''
    });
  }, [decedentInfo, merge, model, reset, modalVisible]);

  const handleSubmit = () => {
    editDecedentDetailsAction({ memorialId, decedent: decedentFormData });
  };

  return (
    <div className="decedent-overview-modal">
      <ModalOverlay {...{ modalVisible }} closeModal={handleCloseModal}>
        <ResponsiveContent>
          <SolidColorModalHeader
            closeModal={handleCloseModal}
            headerCopy="Edit decedent information"
          />
          <DecedentInfoForm
            {...{
              model,
              formData: decedentFormData,
              decedentInfo,
              formMetaData: decedentFormMetaData,
              memorialId,
              modalVisible
            }}
            withinModal
            uniqueTags={['from-decedentOverviewModal-first', 'from-decedentOverviewModal-second']}
          />
          <ModalFooter
            mainButtonText="Save"
            mainButtonFn={handleSubmit}
            secondaryButtonText="Cancel"
            secondaryButtonFn={handleCloseModal}
            disabled={!decedentFormMetaData.$form.valid}
          />
        </ResponsiveContent>
      </ModalOverlay>
    </div>
  );
};

export default connect(null, {
  merge: formActions.merge,
  reset: formActions.reset,
  editDecedentDetailsAction
})(DecedentOverviewModal);
