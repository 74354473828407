export const FETCH_INVITE_DETAILS_REQUEST = 'FETCH_INVITE_DETAILS_REQUEST';
export const FETCH_INVITE_DETAILS_SUCCESS = 'FETCH_INVITE_DETAILS_SUCCESS';
export const FETCH_INVITE_DETAILS_FAILURE = 'FETCH_INVITE_DETAILS_FAILURE';

export const INVITE_DETAILS_RESET = 'INVITE_DETAILS_RESET';

// Archive Invite
export const ARCHIVE_INVITE_REQUEST = 'ARCHIVE_INVITE_REQUEST';
export const ARCHIVE_INVITE_SUCCESS = 'ARCHIVE_INVITE_SUCCESS';
export const ARCHIVE_INVITE_FAILURE = 'ARCHIVE_INVITE_FAILURE';
