import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import qs from 'querystring';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import { useURLParamSync } from '@evdy/web-core/dist/customHooks/index';
import SubNav from '@evdy/web-core/dist/components/shared/elements/SubNav';
import { getSpecificUser, editUserReset } from '@evdy/web-redux/dist/actions/dash/editUser';
import EditUserProfile from './EditUserProfile';
import EditUserDetails from './EditUserDetails';
import EditUserPassword from './EditUserPassword';
import './UserDetails.scss';
import chevron from '@evdy/web-core/dist/images/chevron-right-blue.svg';

const UserDetails = props => {
  const {
    isExternalMode,
    getSpecificUser,
    editUserReset,
    params: { id: userId },
    editUser,
    location
  } = props;

  const {
    otherUser: fetchedUser,
    error: { message: editErrorMessage = '' } = {},
    lastUpdated,
    submitted
  } = editUser;

  const subNavRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);

  const tabProps = { fetchedUser, editErrorMessage, lastUpdated, submitted };
  const { query } = location;
  // scroll to top on mount
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const handleTabChange = ({ selectedTabIndex }) => setTabIndex(selectedTabIndex);

  const params = useMemo(() => ({ tabIndex, isExternalMode }), [tabIndex, isExternalMode]);
  //TODO WebCleanUp2020 - memoizedAccountsFilter doesn't need isExternal, dashnav handling
  useURLParamSync(params, location);

  // page setup
  useEffect(() => {
    // scroll to top on mount
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
    if (subNavRef.current && query.tabIndex) subNavRef.current.handleTabSelect(+query.tabIndex);
  }, []);

  // fetch specific user from backend
  useEffect(() => {
    if (userId) getSpecificUser(userId);

    return () => {
      editUserReset();
    };
  }, [editUserReset, getSpecificUser, userId]);

  const [originLocation, setOriginPath] = useState({
    originPathName: null,
    originDisplayName: null
  });
  // effect that checks the url params for 'originPathName' and if truthy then
  // breadcrumb back button will go to the previous page, otherwise defaults
  // to accounts
  useEffect(() => {
    const { originPathName, originDisplayName } = query;
    const currentPathname = `/dash/a/users?${qs.stringify({ ...query, tabIndex: undefined })}`;

    setOriginPath({
      originPathName: originPathName ? originPathName : currentPathname,
      originDisplayName: originDisplayName ? `Back to ${originDisplayName}` : 'Back to Users List'
    });
  }, []);

  // this relies on there being a query param of originPathName & originDisplayName in the url
  // ie: (&originPathName=${location.pathname}&originDisplayName='Accounts'`} )
  // needs to be in the location that the link is coming from so that we can push
  // the correct pathname into browserHistory
  const handleGoBack = () => {
    browserHistory.push(originLocation.originPathName);
  };

  return (
    <div className="dash-user-details-container">
      <div className="back-link" onClick={handleGoBack}>
        <img src={chevron} alt="" />
        {originLocation.originDisplayName}
      </div>
      {isExternalMode ? (
        <div>This tab is not available in Professional Mode</div>
      ) : (
        <>
          <div className="title-container">
            <h1>{nameString(fetchedUser?.name)}</h1>
          </div>
          <SubNav
            ref={subNavRef}
            tabs={['Profile', 'Details', 'Password']}
            onChange={handleTabChange}
          >
            <EditUserProfile {...tabProps} />
            <EditUserDetails {...tabProps} />
            <EditUserPassword {...tabProps} />
          </SubNav>
        </>
      )}
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    isExternalMode: dash.dashNav.isExternalMode,
    editUser: dash.editUser
  }),
  { getSpecificUser, editUserReset }
)(UserDetails);
