import * as types from './constants';

// Reset invite creation
export const resetInviteCreationAction = {
  type: types.RESET_INVITE_CREATION
};

// Start Invite Creation
export const startInviteCreationRequest = () => ({
  type: types.START_INVITE_CREATION_REQUEST
});
export const startInviteCreationSuccess = ({ data }) => ({
  type: types.START_INVITE_CREATION_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const startInviteCreationFailure = error => ({
  type: types.START_INVITE_CREATION_FAILURE,
  error,
  receivedAt: Date.now()
});

// Fetch Unfinished Invite
export const fetchUnfinishedInviteRequest = () => ({
  type: types.FETCH_UNFINISHED_INVITE_REQUEST
});
export const fetchUnfinishedInviteSuccess = ({ data }) => ({
  type: types.FETCH_UNFINISHED_INVITE_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const fetchUnfinishedInviteFailure = error => ({
  type: types.FETCH_UNFINISHED_INVITE_FAILURE,
  error,
  receivedAt: Date.now()
});

// Finish/Finalize Creation
export const finishInviteCreationRequest = () => ({
  type: types.FINISH_INVITE_CREATION_REQUEST
});
export const finishInviteCreationSuccess = ({ data }) => ({
  type: types.FINISH_INVITE_CREATION_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const finishInviteCreationFailure = error => ({
  type: types.FINISH_INVITE_CREATION_FAILURE,
  error,
  receivedAt: Date.now()
});

export const inviteReset = () => ({
  type: types.INVITE_SENT_RESET
});

// Send Invite Preview SMS
export const sendMemorialLinkRequest = () => ({
  type: types.SEND_MEMORIAL_LINK_REQUEST
});
export const sendMemorialLinkSuccess = ({ data }) => ({
  type: types.SEND_MEMORIAL_LINK_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const sendMemorialLinkFailure = error => ({
  type: types.SEND_MEMORIAL_LINK_FAILURE,
  error,
  receivedAt: Date.now()
});
export const sendMemorialLinkReset = () => ({
  type: types.SEND_MEMORIAL_LINK_RESET
});

// Decedent Details
export const editDecedentDetailsRequest = () => ({
  type: types.EDIT_DECEDENT_DETAILS_REQUEST
});
export const editDecedentDetailsSuccess = ({ data }) => ({
  type: types.EDIT_DECEDENT_DETAILS_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const editDecedentDetailsFailure = error => ({
  type: types.EDIT_DECEDENT_DETAILS_FAILURE,
  error,
  receivedAt: Date.now()
});
export const editDecedentPhoneRequest = () => ({
  type: types.EDIT_DECEDENT_PHONE_REQUEST
});
export const editDecedentPhoneFailure = error => ({
  type: types.EDIT_DECEDENT_PHONE_FAILURE,
  error,
  receivedAt: Date.now()
});
export const editDecedentPhoneSuccess = ({ data }) => ({
  type: types.EDIT_DECEDENT_PHONE_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

// Accessors
export const addAccessorRequest = () => ({
  type: types.ADD_NEW_ACCESSOR_REQUEST
});
export const addAccessorSuccess = ({ data }) => ({
  type: types.ADD_NEW_ACCESSOR_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const addAccessorFailure = error => ({
  type: types.ADD_NEW_ACCESSOR_FAILURE,
  error,
  receivedAt: Date.now()
});
export const editAccessorRequest = () => ({
  type: types.EDIT_ACCESSOR_REQUEST
});
export const editAccessorSuccess = ({ data }) => ({
  type: types.EDIT_ACCESSOR_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const editAccessorFailure = error => ({
  type: types.EDIT_ACCESSOR_FAILURE,
  error,
  receivedAt: Date.now()
});
export const removeAccessorRequest = {
  type: types.DELETE_ACCESSOR_REQUEST
};
export const resetDupeAccessorError = {
  type: types.RESET_DUPE_ACCESSOR_ERROR
};
export const removeAccessorSuccess = ({ data }) => ({
  type: types.DELETE_ACCESSOR_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const removeAccessorFailure = error => ({
  type: types.DELETE_ACCESSOR_FAILURE,
  error,
  receivedAt: Date.now()
});

// Services
export const addServiceRequest = () => ({
  type: types.ADD_NEW_SERVICE_REQUEST
});
export const addServiceSuccess = ({ data }) => ({
  type: types.ADD_NEW_SERVICE_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const addServiceFailure = error => ({
  type: types.ADD_NEW_SERVICE_FAILURE,
  error,
  receivedAt: Date.now()
});
export const editServiceRequest = () => ({
  type: types.EDIT_SERVICE_REQUEST
});
export const editServiceSuccess = ({ data }) => ({
  type: types.EDIT_SERVICE_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const editServiceFailure = error => ({
  type: types.EDIT_SERVICE_FAILURE,
  error,
  receivedAt: Date.now()
});
export const getLocationsRequest = () => ({
  type: types.GET_LOCATIONS_REQUEST
});
export const getLocationsSuccess = ({ data }) => ({
  type: types.GET_LOCATIONS_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const getLocationsFailure = error => ({
  type: types.GET_LOCATIONS_FAILURE,
  error,
  receivedAt: Date.now()
});
export const deleteServiceRequest = {
  type: types.DELETE_SERVICE_REQUEST
};
export const deleteServiceFailure = error => ({
  type: types.DELETE_SERVICE_FAILURE,
  error,
  receivedAt: Date.now()
});
export const deleteServiceSuccess = ({ data }) => ({
  type: types.DELETE_SERVICE_SUCCESS,
  receivedAt: Date.now(),
  payload: data
});
export const addNoServiceOptionsRequest = {
  type: types.ADD_NOSERVICE_OPTIONS_REQUEST
};
export const addNoServiceOptionsSuccess = ({ data }) => ({
  type: types.ADD_NOSERVICE_OPTIONS_SUCCESS,
  receivedAt: Date.now(),
  payload: data
});
export const addNoServiceOptionsFailure = error => ({
  type: types.ADD_NOSERVICE_OPTIONS_FAILURE,
  error,
  receivedAt: Date.now()
});

// Obituary
export const addObituaryRequest = () => ({
  type: types.ADD_OBITUARY_REQUEST
});
export const addObituarySuccess = ({ data }) => ({
  type: types.ADD_OBITUARY_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const addObituaryFailure = error => ({
  type: types.ADD_OBITUARY_FAILURE,
  error,
  receivedAt: Date.now()
});
export const editObituaryRequest = () => ({
  type: types.EDIT_OBITUARY_REQUEST
});
export const editObituarySuccess = ({ data }) => ({
  type: types.EDIT_OBITUARY_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const editObituaryFailure = error => ({
  type: types.EDIT_OBITUARY_FAILURE,
  error,
  receivedAt: Date.now()
});

// Provider / Funeral Info
export const editFuneralInfoRequest = () => ({
  type: types.EDIT_FUNERAL_INFO_REQUEST
});
export const editFuneralInfoSuccess = ({ data }) => ({
  type: types.EDIT_FUNERAL_INFO_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const editFuneralInfoFailure = error => ({
  type: types.EDIT_FUNERAL_INFO_FAILURE,
  error,
  receivedAt: Date.now()
});
export const editFuneralInfoReset = () => ({
  type: types.EDIT_FUNERAL_INFO_RESET
});

// Theme
export const editThemeRequest = () => ({
  type: types.EDIT_THEME_REQUEST
});
export const editThemeSuccess = ({ data }) => ({
  type: types.EDIT_THEME_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const editThemeFailure = error => ({
  type: types.EDIT_THEME_FAILURE,
  error,
  receivedAt: Date.now()
});

// Delete Invite
export const deleteInviteRequest = () => ({
  type: types.DELETE_INVITE_REQUEST
});
export const deleteInviteSuccess = ({ data }) => ({
  type: types.DELETE_INVITE_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const deleteInviteFailure = error => ({
  type: types.DELETE_INVITE_FAILURE,
  error,
  receivedAt: Date.now()
});

// select Director Video
export const selectDirectorVideoRequest = () => ({
  type: types.SELECT_DIRECTOR_VIDEO_REQUEST
});
export const selectDirectorVideoSuccess = ({ data }) => ({
  type: types.SELECT_DIRECTOR_VIDEO_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});
export const selectDirectorVideoFailure = error => ({
  type: types.SELECT_DIRECTOR_VIDEO_FAILURE,
  error,
  receivedAt: Date.now()
});

// Upload Director Video
export const setUploadInProgress = isInProgress => ({
  type: types.SET_UPLOAD_IN_PROGRESS,
  payload: isInProgress
});

export const setUploadProgress = progress => ({
  type: types.SET_UPLOAD_PROGRESS,
  payload: progress
});

export const setUploadThumbnail = thumbnailUrl => ({
  type: types.SET_UPLOAD_THUMBNAIL,
  payload: thumbnailUrl
});

export const resetDirectorVideoUpload = thumbnailUrl => ({
  type: types.RESET_DIRECTOR_VIDEO_UPLOAD,
  payload: thumbnailUrl
});

export const invalidUploadDuration = message => ({
  type: types.INVALID_DIRECTOR_UPLOAD_DURATION,
  payload: message
});

// Donation
export const addDonationRequest = () => ({
  type: types.ADD_DONATION_REQUEST
});

export const addDonationSuccess = ({ data }) => ({
  type: types.ADD_DONATION_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const addDonationFailure = error => ({
  type: types.ADD_DONATION_FAILURE,
  error,
  receivedAt: Date.now()
});

export const editDonationRequest = () => ({
  type: types.EDIT_DONATION_REQUEST
});

export const editDonationSuccess = ({ data }) => ({
  type: types.EDIT_DONATION_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const editDonationFailure = error => ({
  type: types.EDIT_DONATION_FAILURE,
  error,
  receivedAt: Date.now()
});

export const deleteDonationRequest = () => ({
  type: types.DELETE_DONATION_REQUEST
});

export const deleteDonationSuccess = ({ data }) => ({
  type: types.DELETE_DONATION_SUCCESS,
  payload: data,
  receivedAt: Date.now()
});

export const deleteDonationFailure = error => ({
  type: types.DELETE_DONATION_FAILURE,
  error,
  receivedAt: Date.now()
});
