import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Form, doesMatch, formActions } from '@evdy/web-core/dist/components/shared/Input';
import PasswordInput from '@evdy/web-core/dist/components/shared/PasswordInput';
import './EditUserPassword.scss';
import { editSpecificUserPassword } from '@evdy/web-redux/dist/actions/dash/editUser';
import { renderToast } from '@evdy/web-core/dist/lib/utils';

const model = 'dash.userDetails.forms.password';
const passwordSuccessToast = 'nice-toast';
const passwordFailureToast = 'gross-toast';

const EditUserPassword = ({
  fetchedUser,
  editErrorMessage,
  lastUpdated,
  submitted,
  newPassword,
  validate,
  reset,
  confirmPassTouched,
  isValid,
  editSpecificUserPassword
}) => {
  // display toast when edit fails to save
  useEffect(() => {
    if (editErrorMessage) {
      renderToast({ id: passwordFailureToast, message: editErrorMessage, type: 'error' });
    }
  }, [submitted, editErrorMessage]);

  // display toast on success/save confirmation
  useEffect(() => {
    if (submitted && lastUpdated >= Date.now() - 50 && !editErrorMessage) {
      renderToast({ id: passwordSuccessToast, message: 'Password successfully changed' });
      reset(model);
    }
  }, [editErrorMessage, lastUpdated, reset, submitted]);

  const handleChangePasswordSubmit = () => {
    editSpecificUserPassword({ newDashPassword: newPassword }, fetchedUser._id);
  };

  const revalidateConfirmPassword = ({ target: { value: password } }) => {
    if (confirmPassTouched) {
      validate(`${model}.confirmPassword`, {
        doesMatch: doesMatch(password)
      });
    }
  };

  return (
    <div className="edit-user-password-tab">
      <Form
        model={model}
        className="profile-change-password-form"
        onSubmit={handleChangePasswordSubmit}
      >
        <PasswordInput
          model=".newPassword"
          labelText="New Password"
          onChange={revalidateConfirmPassword}
        />
        <PasswordInput
          model=".confirmPassword"
          labelText="New Password (Confirmation)"
          toMatchValue={newPassword}
        />
        <button disabled={!isValid} type="submit">
          Save
        </button>
      </Form>
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    newPassword: dash.userDetails.forms.password.newPassword,
    confirmPassTouched: dash.userDetails.forms.userDetailsMeta.password.confirmPassword.touched,
    isValid: dash.userDetails.forms.userDetailsMeta.password.$form.valid
  }),
  {
    editSpecificUserPassword,
    validate: formActions.validate,
    reset: formActions.reset
  }
)(EditUserPassword);
