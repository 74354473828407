import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import DashCard from '../../../shared/DashCard';
import DashCardHeader from '../../../shared/DashCard/CardBuilder/DashCardHeader';
import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import BasicPhotoInput from '@evdy/web-core/dist/components/BasicPhotoInput';
import EverdaysSpinner from '@evdy/web-core/dist/components/EverdaysSpinner';
import HomeDetailsModal from './HomeDetailsModal';

import { renderToast } from '@evdy/web-core/dist/lib/utils';

import removeIcon from './remove-icon.svg';

import './BasicDetails.scss';

const model = 'funeralHome.forms.homeDetails';
const editHomeSuccess = 'add-home-success-toast';
const editHomeFailure = 'add-home-failure-toast';

const BasicDetails = ({
  homeLocation,
  uploadingPhotos,
  handleUploadLogo,
  handleRemoveLogo,
  handleUpdateLocation,
  handlePhotoUpload,
  handleRemovePhoto
}) => {
  const photoInputRef = useRef(null);
  const prevLastUpdated = useRef(null);

  const {
    profileImage = '',
    photos = [],
    name,
    websiteUrl,
    type,
    address = {},
    phone,
    email,
    casesPerYear,
    placeId
  } = homeLocation;

  const initalPhotoAmt = 3;
  const photosAmt = photos.length;
  const additionalPhotoAmt = photosAmt - initalPhotoAmt;
  const [photoAmtToShow, setPhotoAmtToShow] = useState(initalPhotoAmt);
  const showMorePhotosBtn = photosAmt > initalPhotoAmt && photoAmtToShow === initalPhotoAmt;
  const [modalVisible, setModalVisible] = useState(false);
  const { isValid, isFetching, errorMessage, lastUpdated, isInternal } = useSelector(
    ({ funeralHome, dash }) => ({
      isValid: funeralHome.forms.formsMeta.$form.valid,
      isFetching: funeralHome.home.isFetching,
      errorMessage: funeralHome.home.error?.message,
      lastUpdated: funeralHome.home.lastUpdated,
      isInternal: !dash?.dashNav?.isExternalMode
    })
  );

  const internalHomeDetails = {
    'Account Name': name,
    'Type of Business': type,
    'Phone Number': phone,
    Website: websiteUrl,
    'Address 1': address.street,
    'Address 2': address.street2,
    City: address.city,
    State: address.state,
    Zip: address.zip,
    'Estimated Cases/Year': casesPerYear,
    'Location Email': email,
    'Google ID': placeId
  };

  const externalHomeDetails = {
    'Account Name': name,
    'Type of Business': type,
    'Phone Number': phone,
    Website: websiteUrl,
    'Address 1': address.street,
    'Address 2': address.street2,
    City: address.city,
    State: address.state,
    Zip: address.zip,
    'Estimated Cases/Year': casesPerYear,
    'Location Email': email
  };

  const homeDetailsArray = isInternal ? internalHomeDetails : externalHomeDetails;

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleEditDetails = () => {
    setModalVisible(true);
  };

  // show toast and close modal after modal is submitted successfully
  useEffect(() => {
    const didUpdate = prevLastUpdated.current < lastUpdated;
    if (modalVisible && !isFetching && didUpdate) {
      handleCloseModal();
      if (!errorMessage)
        renderToast({ id: editHomeSuccess, message: 'Location successfully updated' });
      else
        renderToast({
          id: editHomeFailure,
          message: errorMessage,
          type: 'error'
        });
    }
    prevLastUpdated.current = lastUpdated;
  }, [errorMessage, handleCloseModal, isFetching, lastUpdated, modalVisible]);

  return (
    <div className="basic-details-tab">
      <div className="manage-home-logo">
        <ImageHandler
          v11Styling
          dropzoneTitle="Add logo"
          dropzoneSubtitle=""
          dropzoneBtnText="Upload Logo"
          currentImg={profileImage || ''}
          handleRemoveImage={handleRemoveLogo}
          handleUploadFinish={handleUploadLogo}
        />
      </div>
      <DashCard>
        <DashCardHeader headerCopy="Basics" buttonCopy="Edit" headerBtnAction={handleEditDetails} />
        <div className="home-details">
          {Object.entries(homeDetailsArray).map(([key, value]) => (
            <div className="home-detail" key={key}>
              <div className="title">{key}</div>
              <div className="value">{value}</div>
            </div>
          ))}
        </div>
      </DashCard>
      <DashCard>
        <DashCardHeader
          disabled={uploadingPhotos}
          headerCopy="Photos"
          buttonCopy="Add Photos"
          headerBtnAction={() => photoInputRef.current.click()}
        />
        <div className="photos">
          {uploadingPhotos && <EverdaysSpinner color="#0083db" />}
          {photos.slice(0, photoAmtToShow).map((photo, photoIndex) => (
            <div key={photoIndex} className="photo-item photo">
              <img src={photo} alt={photoIndex} />
              <button onClick={() => handleRemovePhoto(photo)}>
                <img src={removeIcon} alt="remove" />
              </button>
            </div>
          ))}
          {showMorePhotosBtn && (
            <div className="photo photo-more" onClick={() => setPhotoAmtToShow(Infinity)}>
              <p>
                <strong>+{additionalPhotoAmt}</strong>
              </p>
              <p>Photo{additionalPhotoAmt > 1 && 's'}</p>
            </div>
          )}
        </div>
      </DashCard>
      <BasicPhotoInput handlePhotoSelect={handlePhotoUpload} forwardedRef={photoInputRef} />
      <HomeDetailsModal
        {...{
          homeLocation,
          model,
          modalVisible,
          handleCloseModal,
          handleUpdateLocation,
          isValid,
          isInternal
        }}
      />
    </div>
  );
};

export default BasicDetails;
