import React from 'react';

import Start from './components/Start';
import RequestEmail from './components/RequestEmail';
import AccountExists from './components/AccountExists';
import CheckPhone from './components/CheckPhone';
import VerifyPhone from './components/VerifyPhone';
import UpdateEmail from './components/UpdateEmail';
import CompanyName from './components/CompanyName';
import CompanyExists from './components/CompanyExists';
import CompanyInfo from './components/CompanyInfo';
import Finish from './components/Finish';

export const onboardSteps = [
  {
    name: 'onboard_start',
    Component: Start,
    properties: {
      label:
        "Hi! I'm Lisa. I'll help you set up your Everdays account in just a few steps. Let's get started."
    }
  },
  {
    name: 'request_email',
    Component: RequestEmail,
    properties: {
      label: 'Thank you! Please confirm your email address to continue.'
    }
  },
  {
    name: 'account_exists',
    Component: AccountExists,
    properties: {
      label: 'You already have an account, please enter your existing account email and password.'
    }
  },
  {
    name: 'check_phone',
    Component: CheckPhone,
    properties: {
      label: 'Next, we will send you a text to your mobile phone to verify your account.'
    }
  },
  {
    name: 'verify_phone',
    Component: VerifyPhone,
    properties: {
      label:
        "Excellent. Before you're able to access Everdays, we need you to verify your number. Enter the code that was just sent to "
    }
  },
  {
    name: 'update_email',
    Component: UpdateEmail,
    properties: {
      label:
        "Your email address doesn't match the one we have on record for your phone number. Would you like us to update it now?"
    }
  },
  {
    name: 'company_name',
    Component: CompanyName,
    properties: {
      label: "You're almost there! Finish with a few details about where you work."
    }
  },
  {
    name: 'company_exists',
    Component: CompanyExists,
    properties: {
      label: 'The Company Exists'
    }
  },
  {
    name: 'company_info',
    Component: CompanyInfo,
    properties: {
      label: 'Thank you! Please provide your business details to complete your account set up.'
    }
  },
  {
    name: 'finish',
    Component: Finish,
    properties: {
      label: (
        <span>
          Welcome to Everdays! You're now setup to build Invites and communities for your client
          families. <br />
          <br />
          And, reach those engaged community members with educational messages about the products
          and services your home proudly offers.
        </span>
      )
    }
  }
];

export const onboardStepOrder = onboardSteps.map(step => step.name);
