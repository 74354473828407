import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, InputGroup, formActions } from '@evdy/web-core/dist/components/shared/Input';

import './MemberLocationsBody.scss';

const makeAddress = address => {
  const { city, state, street, zip } = address || {};

  return [street, city, state, zip].filter(Boolean).join(', ');
};

const MemberLocationsBody = props => {
  const { model, funeralHomes, merge, memberData } = props;

  useEffect(() => {
    funeralHomes.forEach(({ users, name }, homesIdx) => {
      const existingUser = users.filter(user => user.userRef === memberData?._id);

      // default locationSettings values (if they don't exist yet)
      const defaultLocationSettings = {
        userRef: memberData?._id,
        createView: true,
        display: true
      };

      /* if a user has preexisting data, then populate the form with it
      otherwise, insert default values */
      if (existingUser?.[0]) {
        merge(`${model}[${homesIdx}]`, { locationSettings: existingUser[0] });
      } else {
        merge(`${model}[${homesIdx}]`, { locationSettings: defaultLocationSettings });
      }
    });
  }, []);

  return (
    <>
      <div className="member-locations-body">
        {funeralHomes?.map(({ name, address, users }, i) => (
          <div key={`location-${i}-${name}`} className="member-location">
            <div className="member-name">{name}</div>
            <div className="member-address">{makeAddress(address)}</div>
            <Form model={`${model}[${i}]`}>
              <InputGroup
                model=".locationSettings.createView"
                type="checkbox"
                labelText="Create and view Memorials"
                strongerID={name + i + 'createView'}
              />
              <InputGroup
                model=".locationSettings.display"
                type="checkbox"
                labelText="Display on Locations Page"
                strongerID={name + i + 'display'}
              />
            </Form>
          </div>
        ))}
      </div>
    </>
  );
};

export default connect(({ dash }) => ({ memberModalForm: dash.company.forms.memberModal }), {
  merge: formActions.merge
})(MemberLocationsBody);
