"use strict";

module.exports = {
  default: {
    fontFamily: '"HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif',
    palette: {
      primary1Color: '#0072cd'
    },
    button: {
      minWidth: '16rem'
    },
    raisedButton: {
      fontSize: 18,
      textColor: '#A0A0A2',
      secondaryColor: '#D9D9D9',
      secondaryTextColor: '#383838'
    },
    stepper: {
      inactiveIconColor: 'none'
    },
    datePicker: {
      selectColor: '#0072cd',
      headerColor: '#0072cd'
    },
    timePicker: {
      headerColor: '#0072cd'
    }
  }
};