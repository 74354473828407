import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import ObituaryModal from '../InviteBuilder/components/modals/ObituaryModal';

import TruncatedText from '@evdy/web-core/dist/components/shared/elements/TruncatedText';
import { creationOperations } from '@evdy/web-redux/dist/lib/inviteCreation';
import { renderToast } from '@evdy/web-core/dist/lib/utils';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import styles from './ObitPreviewCard.module.scss';

const { addObituaryAction, editObituaryAction } = creationOperations;
const { merge, reset } = formActions;

const ObitPreviewCard = ({
  whiteBackground,
  memorialId,
  obitFormMessage = '',
  detailsObituary,
  creationObituary,
  currentPage
}) => {
  const dispatch = useDispatch();

  const {
    message: obitMessage,
    isFetching: isFetchingObit,
    lastUpdated: lastUpdatedObit,
    obitUpdated,
    obitCreated
  } = currentPage === 'invite-details' ? detailsObituary : creationObituary || {};

  const cardTitle = 'Obituary';
  const model = 'inviteDetails.forms.obituary.message';
  const hasObit = !!obitMessage;
  const cardBody = !!obitMessage ? obitMessage : 'The obituary has not been added yet.';
  const ctaCopy = !!obitMessage ? 'Edit obituary' : 'Add obituary';

  const cardClasses = classNames(styles.obitCard, {
    [styles.whiteBackground]: !!whiteBackground
  });

  const [isObitModalOpen, setObitModalOpen] = useState(false);

  const openObitModal = () => setObitModalOpen(true);
  const closeObitModal = () => setObitModalOpen(false);

  const handleObitSubmit = () => {
    if (hasObit) {
      dispatch(editObituaryAction({ memorialId, message: obitFormMessage }));
    } else {
      dispatch(addObituaryAction({ memorialId, message: obitFormMessage }));
    }
    setObitModalOpen(false);
  };

  // merge previous obit message into the form/reset formData on return
  useEffect(() => {
    if (obitMessage) dispatch(merge(model, { message: obitMessage }));
    return () => {
      dispatch(reset(model));
    };
  }, [dispatch, obitMessage]);

  //show success toast for obit on add/edit
  useEffect(() => {
    const hasObitBeenUpdated = lastUpdatedObit >= Date.now() - 5e2;
    const showAddObitSuccess = !isFetchingObit && obitCreated && hasObitBeenUpdated;
    const showEditObitSuccess = !isFetchingObit && obitUpdated && hasObitBeenUpdated;

    if (showAddObitSuccess)
      renderToast({ id: 'Add Obit Success', message: 'Obituary Added', type: 'success' });
    if (showEditObitSuccess)
      renderToast({ id: 'Edit Obit Success', message: 'Obituary Updated', type: 'success' });
  }, [isFetchingObit, lastUpdatedObit, obitCreated, obitUpdated]);

  return (
    <div className={cardClasses}>
      <h3 className={styles.title}>{cardTitle}</h3>
      <TruncatedText
        disableReadMore
        disableReadLess
        lines={2}
        message={cardBody}
        truncatedClass={styles.body}
      />
      <div onClick={openObitModal} className={styles.ctaCopy}>
        {ctaCopy}
      </div>
      <ObituaryModal
        modalVisible={isObitModalOpen}
        handleSubmit={handleObitSubmit}
        handleCloseModal={closeObitModal}
        placeholder={!hasObit ? 'Enter or paste obituary here' : ''}
        {...{ model, hasObit }}
      />
    </div>
  );
};

export default ObitPreviewCard;
