import { useReducer } from 'react';
import merge from 'lodash/merge';

const sortFilterOptions = [
  { name: 'Newest', value: '-createdAt' },
  { name: 'Oldest', value: 'createdAt' },
  { name: 'A-Z', value: 'searchName' },
  { name: 'Z-A', value: '-searchName' }
];

const dropdownOptions = { sortFilterOptions };

const filterType = {
  CLEAR_USER_TYPE: 'CLEAR_USER_TYPE',
  USER_TYPE: 'USER_TYPE',
  CLEAR_REG_TYPE: 'CLEAR_REG_TYPE',
  REG_TYPE: 'REG_TYPE',
  SORT: 'SORT',
  SEARCH: 'SEARCH',
  PAGE: 'PAGE',
  RESET: 'RESET'
};

const getFilters = () => {
  return [
    {
      header: 'Filter by',
      options: [
        {
          type: filterType.CLEAR_USER_TYPE,
          value: 'All',
          id: ''
        },
        {
          type: filterType.USER_TYPE,
          value: 'Director',
          id: 'director'
        },
        {
          type: filterType.USER_TYPE,
          value: 'Agent',
          id: 'agent'
        },
        {
          type: filterType.USER_TYPE,
          value: 'Consumer',
          id: 'consumer'
        }
      ]
    },
    {
      header: 'Registration Type',
      options: [
        {
          type: filterType.CLEAR_REG_TYPE,
          value: 'All',
          id: ''
        },
        {
          type: filterType.REG_TYPE,
          value: 'Not Shell',
          id: 'notshell'
        },
        {
          type: filterType.REG_TYPE,
          value: 'Facebook',
          id: 'facebook'
        },
        {
          type: filterType.REG_TYPE,
          value: 'Has Password',
          id: 'email'
        },
        {
          type: filterType.REG_TYPE,
          value: 'Downloaded App',
          id: 'downloaded'
        }
      ]
    }
  ];
};

const initialState = {
  usertypeFilter: '',
  usertypeFilterName: '',
  regtypeFilter: '',
  regtypeFilterName: '',
  selected: {
    userType: {
      value: `${filterType.CLEAR_USER_TYPE}-`,
      name: ''
    },
    regType: {
      value: `${filterType.CLEAR_REG_TYPE}-`,
      name: ''
    }
  },
  sort: '-createdAt',
  sortName: '',
  searchQuery: '',
  page: 0
};

const resetOverlapingUsertype = {
  selected: {
    userType: {
      value: `${filterType.CLEAR_USER_TYPE}-`,
      name: ''
    }
  },
  usertypeFilter: ''
};

const resetOverlapingRegtype = {
  selected: {
    regType: {
      value: `${filterType.CLEAR_REG_TYPE}-`,
      name: ''
    }
  },
  regtypeFilter: '',
  regtypeFilterName: ''
};

const resetSearch = {
  searchQuery: ''
};

const resetSort = {
  sort: '-createdAt',
  sortName: ''
};

const resetPage = {
  page: 0
};

const filterReducer = (state, { type, value, id }) => {
  switch (type) {
    case filterType.ONLOAD:
      return {
        ...merge(state, value)
      };

    case filterType.CLEAR_USER_TYPE:
      return { ...merge(state, resetOverlapingUsertype, resetPage) };

    case filterType.USER_TYPE: {
      const selectionProperty = {
        selected: {
          userType: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingUsertype, resetPage, selectionProperty),
        usertypeFilter: id,
        usertypeFilterName: value
      };
    }

    case filterType.CLEAR_REG_TYPE:
      return { ...merge(state, resetOverlapingRegtype, resetPage) };

    case filterType.REG_TYPE: {
      const selectionProperty = {
        selected: {
          regType: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingRegtype, resetPage, selectionProperty),
        regtypeFilter: id,
        regtypeFilterName: value
      };
    }

    case filterType.SEARCH:
      return {
        ...merge(state, resetPage),
        searchQuery: value
      };

    case filterType.SORT:
      let sortName;
      switch (value) {
        case '-createdAt':
          sortName = '';
          break;
        case 'createdAt':
          sortName = 'Oldest';
          break;
        case 'searchName':
          sortName = 'A-Z';
          break;
        case '-searchName':
          sortName = 'Z-A';
          break;
        default:
          sortName = '';
      }
      return {
        ...merge(state, resetPage),
        sort: value,
        sortName
      };

    case filterType.PAGE:
      return {
        ...merge(state, {
          page: value
        })
      };

    case filterType.RESET:
      return {
        ...merge(
          state,
          resetOverlapingUsertype,
          resetOverlapingRegtype,
          resetSearch,
          resetSort,
          resetPage
        )
      };

    default:
      return state;
  }
};

const useUserFilter = () => {
  const [userFilter, setUserFilter] = useReducer(filterReducer, initialState);
  return [userFilter, setUserFilter, getFilters, filterType, dropdownOptions];
};

export default useUserFilter;
