import React from 'react';

import UserListItem from './UserListItem';
import NoUsers from './NoUsers';

const UserList = ({ users, handleUserClick }) => {
  if (users === null) return null;
  const usersArray = users?.length ? users : [];

  if (!usersArray.length) return <NoUsers />;

  return usersArray.map(user => <UserListItem key={user._id} {...{ handleUserClick, ...user }} />);
};

export default UserList;
