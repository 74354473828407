import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  InputGroup,
  isRequired,
  isEmail,
  checkboxRequired,
  formActions
} from '@evdy/web-core/dist/components/shared/Input';
import PasswordInput from '@evdy/web-core/dist/components/shared/PasswordInput';

import './Start.scss';

const Start = props => {
  const model = 'dash.onboarding.forms.start';

  const { dispatch, location } = props;
  const { email: queryEmail, name: queryName } = location?.query || {};

  useEffect(() => {
    if (queryName) {
      const splitName = queryName.split(' ');
      const firstName = splitName[0] || '';
      const lastName = splitName[1] || '';

      dispatch(formActions.change(`${model}.firstName`, firstName));
      dispatch(formActions.change(`${model}.lastName`, lastName));
    }
    if (queryEmail) {
      dispatch(formActions.change(`${model}.email`, queryEmail));
    }
  }, [dispatch, queryName, queryEmail]);

  return (
    <div className="onboard-start">
      <Form model={model} className="onboard-start-form">
        <div className="onboard-form-row">
          <InputGroup
            required
            labelText="First Name"
            model=".firstName"
            validators={{ isRequired }}
            messages={{ isRequired: 'First Name is required' }}
          />
          <InputGroup
            required
            labelText="Last Name"
            model=".lastName"
            validators={{ isRequired }}
            messages={{ isRequired: 'Last Name is required' }}
          />
        </div>
        <div className="onboard-form-row">
          {!queryEmail && (
            <InputGroup
              required
              type="email"
              labelText="Email Address"
              model=".email"
              validators={{ isRequired, isEmail }}
              messages={{
                isRequired: 'Email is required',
                isEmail: 'Please enter a valid email address'
              }}
            />
          )}
          <PasswordInput model=".password" />
        </div>
        <div className="onboard-form-row">
          <InputGroup
            required
            model=".tosAgree"
            type="checkbox"
            customClass="terms-checkbox"
            labelText={
              <span>
                I agree to the
                <a href="/terms" target="_blank">
                  Terms
                </a>
                &
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>
              </span>
            }
            validators={{ checkboxRequired }}
            messages={{
              checkboxRequired: 'Please accept the Terms of Service to continue registration'
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default connect(({ dash }) => ({ start: dash.onboarding.forms.start }))(Start);
