import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import SubNav from '@evdy/web-core/dist/components/shared/elements/SubNav';

import MemberProfileBody from './components/MemberProfileBody';
import MemberLocationsBody from './components/MemberLocationsBody';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import './MemberModalBody.scss';

const MemberModalBody = props => {
  const {
    model,
    modalData,
    formData,
    editMode,
    funeralHomes,
    forceAdmin, // used when adding via Administrators Card to hide Admin checkbox
    setSaveType,
    /* from redux */
    user,
    reset
  } = props;

  const subNavRef = useRef(null);
  const memberModalBodyRef = useRef(null);

  // when the modalData changes, reset the tab back to Profile and scroll to top
  useEffect(() => {
    // use requestAnimationFrame to give the browser a frame to display the modal before scrolling,
    // otherwise it won't scroll at all
    window.requestAnimationFrame(() => {
      memberModalBodyRef.current && memberModalBodyRef.current.scrollTo({ top: 0 });
      subNavRef.current && subNavRef.current.handleTabSelect(0);
      if (editMode) setSaveType('edit-member');
    });
  }, [editMode, modalData, setSaveType]);

  // reset form data/model on unmount
  useEffect(() => {
    return () => reset(model);
  }, []);

  const ProfileTabString = 'Profile';
  const LocationTabString = 'Location Settings';

  const saveTypeSwitch = tabName => {
    switch (tabName) {
      case ProfileTabString:
        setSaveType('edit-member');
        return;
      case LocationTabString:
        setSaveType('edit-member-locations');
        return;
      default:
        break;
    }
  };

  const tabs = () => {
    if (editMode && user.isCompanyAdmin) {
      return [ProfileTabString, LocationTabString];
    } else {
      return [ProfileTabString];
    }
  };

  return (
    <>
      <div className="member-modal" ref={memberModalBodyRef}>
        <SubNav ref={subNavRef} tabs={tabs()} onChange={({ tabName }) => saveTypeSwitch(tabName)}>
          <MemberProfileBody
            key="profile-tab"
            {...{ model, modalData, formData, editMode, forceAdmin }}
          />
          <MemberLocationsBody memberData={modalData.member} {...{ model, funeralHomes }} />
        </SubNav>
      </div>
    </>
  );
};

export default connect(
  ({ dash }) => ({
    user: dash.user.data
  }),
  { reset: formActions.reset }
)(MemberModalBody);
