import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import ServicesPreview from '../InviteBuilder/components/InvitePreview/ServicesPreview';
import FamilyPreview from '../InviteBuilder/components/InvitePreview/FamilyPreview';
import ProviderPreview from '../InviteBuilder/components/InvitePreview/ProviderPreview';
import ArchiveCard from './components/ArchiveCard';

import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import { useScrollToTop } from '@evdy/web-core/dist/customHooks';

import { inviteDetailsOperations } from '@evdy/web-redux/dist/lib/inviteDetails';

import './InviteDetails.scss';

const { fetchInviteDetails, resetInviteDetails, archiveInvite } = inviteDetailsOperations;

const InviteDetails = ({
  // component props
  params,
  // redux props
  accessors,
  accessorFormData,
  accessorFormMetaData,
  client,
  chooseServiceFormData,
  decedentDetails,
  decedentFormData,
  decedentFormMetaData,
  eventFormData,
  eventFormMeta,
  funeralInfo,
  funeralInfoFormData,
  inviteMeta,
  isDemoModeOn,
  services,
  serviceStatus,
  themes,
  userData,
  obituary,
  formMessage,
  // redux actions
  fetchInviteDetails,
  resetInviteDetails,
  archiveInvite,
  // Decedent phone details
  decedentPhoneFormData,
  decedentPhoneFormMetaData,
  // Internal Mode
  isInternal
}) => {
  const { id: memorialId } = params;
  const { data: inviteMetaData, isFetching, lastUpdated } = inviteMeta || {};
  const { data: decedentInfo, isFetching: isFetchingDecedent, lastUpdated: lastUpdatedDecedent } =
    decedentDetails || {};
  const { array: servicesArray = [], isFetching: isFetchingServices } = services || {};
  const { array: accessorsArr = [], isFetching: isFetchingAccessors } = accessors || {};
  const { director, funeralHome, isFetching: isFetchingFuneralInfo } = funeralInfo || {};
  const { _id: funeralDirectorId } = director || {};
  const { _id: funeralHomeId, premiumProgram } = funeralHome || {};
  const sortedServices = [...servicesArray].sort((a, b) =>
    a?.startDateISO > b?.startDateISO ? 1 : -1
  );
  const { name } = decedentInfo || {};
  const { isArchived } = inviteMetaData || {};

  const [isDecedentModalOpen, setDecedentModalOpen] = useState(false);

  const prevDecedentLastUpdated = useRef(null);

  // strip memorial id in case it includes a query string
  const strippedMemorialId = memorialId?.split('?')[0];

  // on mount, scroll to top
  useScrollToTop();

  // on unmount, reset invite details state
  useEffect(() => {
    return () => {
      resetInviteDetails();
    };
  }, []);

  // handle fetching invite details
  useEffect(() => {
    if (!lastUpdated && !isFetching) {
      fetchInviteDetails({ memorialId: strippedMemorialId, userData, showAllEvents: true });
    }
  }, [fetchInviteDetails, isFetching, lastUpdated, strippedMemorialId, userData]);

  // close modal once decedent data has been updated
  useEffect(() => {
    if (prevDecedentLastUpdated.current < lastUpdatedDecedent - 100 && !isFetchingDecedent) {
      setDecedentModalOpen(false);
    }
    prevDecedentLastUpdated.current = lastUpdatedDecedent;
  }, [isFetchingDecedent, lastUpdatedDecedent]);

  const shouldShowLoader =
    isFetching ||
    isFetchingDecedent ||
    isFetchingAccessors ||
    isFetchingFuneralInfo ||
    isFetchingServices;

  return (
    <div className="invite-details-page">
      <div className="details-container">
        <h2>
          <strong>In memory of {nameString(name) || '---'}</strong>
        </h2>
        <FamilyPreview
          {...{
            accessorFormData,
            accessorFormMetaData,
            funeralDirectorId,
            premiumProgram,
            accessors,
            isInternal
          }}
          memorialId={strippedMemorialId}
          accessorFormModel="inviteDetails.forms.accessor"
        />
        <ServicesPreview
          {...{
            chooseServiceFormData,
            eventFormData,
            eventFormMeta,
            funeralHomeId,
            serviceStatus
          }}
          servicesArray={sortedServices}
          eventFormModel="inviteDetails.forms.eventInfo"
          memorialId={strippedMemorialId}
        />
        <ProviderPreview
          {...{ funeralInfo, accessorsArr, isDemoModeOn }}
          formData={funeralInfoFormData}
          providerFormModel="inviteDetails.forms.funeralInfo"
          memorialId={strippedMemorialId}
        />
        <ArchiveCard
          {...{ archiveInvite, isArchived, inviteMeta }}
          memorialId={strippedMemorialId}
        />
      </div>
      {shouldShowLoader && <LoaderComponent />}
    </div>
  );
};

export default connect(
  ({ authUser, client, inviteDetails, dash }) => ({
    client,
    themes: client.config.data.themes,
    inviteMeta: inviteDetails?.inviteMeta,
    decedentDetails: inviteDetails?.decedentDetails,
    decedentFormData: inviteDetails.forms.decedentInfo,
    decedentFormMetaData: inviteDetails.forms.formsMeta.decedentInfo,
    eventFormData: inviteDetails.forms.eventInfo,
    eventFormMeta: inviteDetails.forms.formsMeta,
    accessors: inviteDetails?.accessors,
    accessorFormData: inviteDetails.forms.accessor,
    accessorFormMetaData: inviteDetails.forms.formsMeta.accessor,
    services: inviteDetails?.services,
    serviceStatus: inviteDetails.decedentDetails?.data?.serviceStatus,
    chooseServiceFormData: inviteDetails?.forms,
    userData: authUser?.data,
    all: inviteDetails,
    funeralInfo: inviteDetails?.funeralInfo,
    funeralInfoFormData: inviteDetails?.forms?.funeralInfo,
    isDemoModeOn: authUser?.data?.isDemoModeOn,
    // obituary
    obituary: inviteDetails?.obituary,
    formMessage: inviteDetails?.forms?.obituary?.message,
    // Decedent Phone Details
    decedentPhoneFormData: inviteDetails.forms.decedentPhone,
    decedentPhoneFormMetaData: inviteDetails.forms.formsMeta.decedentPhone,
    // Internal Mode
    isInternal: !dash?.dashNav?.isExternalMode
  }),
  {
    fetchInviteDetails,
    resetInviteDetails,
    archiveInvite
  }
)(InviteDetails);
