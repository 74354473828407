"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initUserAction = exports.initUserFailure = exports.initUserSuccess = exports.initUserRequest = void 0;

require("core-js/modules/es6.function.name.js");

var _onboarding = require("../../../constants/dash/onboarding");

var _constants = require("../../../constants");

var _index = _interopRequireDefault(require("axios/index"));

var _transformAxiosError = _interopRequireDefault(require("@evdy/web-etc/dist/lib/transformAxiosError"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initUserRequest = function initUserRequest() {
  return {
    type: _onboarding.ONBOARD_INIT_USER_REQUEST
  };
};

exports.initUserRequest = initUserRequest;

var initUserSuccess = function initUserSuccess(_ref) {
  var data = _ref.data;
  return {
    type: _onboarding.ONBOARD_INIT_USER_SUCCESS,
    receivedAt: Date.now(),
    payload: data
  };
};

exports.initUserSuccess = initUserSuccess;

var initUserFailure = function initUserFailure(error) {
  return {
    type: _onboarding.ONBOARD_INIT_USER_FAILURE,
    receivedAt: Date.now(),
    error: error
  };
};

exports.initUserFailure = initUserFailure;

var initUserAction = function initUserAction(_ref2) {
  var name = _ref2.name,
      email = _ref2.email,
      password = _ref2.password,
      _ref2$tosAgree = _ref2.tosAgree,
      tosAgree = _ref2$tosAgree === void 0 ? true : _ref2$tosAgree;
  return function (dispatch) {
    dispatch(initUserRequest());
    return _index.default.post("".concat(_constants.API_URL, "/onboard/start"), {
      name: name,
      email: email,
      password: password,
      tosAgree: tosAgree
    }).then(function (res) {
      return dispatch(initUserSuccess(res.data));
    }).catch(function (err) {
      return dispatch(initUserFailure((0, _transformAxiosError.default)(err)));
    });
  };
};

exports.initUserAction = initUserAction;