import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import CharacterProfileImage from '@evdy/web-core/dist/components/shared/elements/CharacterProfileImage';
import { InputGroup } from '@evdy/web-core/dist/components/shared/Input';
import { nameString } from '@evdy/web-core/dist/lib/utils';

import './AddNoteModalBody.scss';

const AddNoteModalBody = props => {
  const { currentLead } = props;

  const { name = {}, leadImage, isInformant = false } = useMemo(() => {
    const {
      memorials = [],
      userRef: { _id: leadId = '', image: leadImage = '' } = {},
      name
    } = currentLead;
    const { accessors = [] } = memorials[memorials.length - 1] || {};

    const { primarySurvivor: isInformant } =
      accessors.find(({ userRef: accessorRef }) => accessorRef.toString() === leadId.toString()) ||
      {};
    return { name, leadImage, isInformant };
  }, [currentLead]);

  return (
    <div className="add-note-modal-body">
      <div className="family-member-card">
        <div className="member-img">
          <CharacterProfileImage {...{ name }} image={leadImage} />
        </div>
        <p className="member-name">{nameString(name)}</p>
        {isInformant && <p className="member-status">Informant</p>}
      </div>
      <InputGroup
        customClass="add-note-textarea"
        model="dash.crm.forms.addNote.noteText"
        type="textarea"
        placeholder="Start typing to leave a note..."
      />
    </div>
  );
};

export default connect(({ dash }) => ({
  currentLead: dash.currentLead.data
}))(AddNoteModalBody);
