import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './CompanyExists.scss';
import { emailSupport } from '@evdy/web-redux/dist/actions/onboarding/emailSupport';

const CompanyExists = props => {
  const { homeId } = props;

  useEffect(() => {
    emailSupport(homeId);
  }, [homeId]);

  return (
    <div className="company-exists-step">
      <a href={'mailto:support@everdays.com'}>Email Support</a>
      <a className="intercom-chat" href="mailto:wdj13bk6@intercom-mail.com">
        Live Chat
      </a>
      <span>
        <strong>Or call us: </strong>(877) 400-3297
      </span>
    </div>
  );
};

export default connect(({ dash }) => ({
  homeId: dash.onboarding.forms.companyName.save
}))(CompanyExists);
