import moment from 'moment/moment';
import { useReducer } from 'react';
import merge from 'lodash/merge';
import {
  filterHomesByUser,
  userHasAccess,
  nameString,
  getStartDate
} from '@evdy/web-core/dist/lib/utils';
import locationPlaceholder from './assets/funeral_inactive.svg';

import completedActive from './assets/completed_active.svg';
import completedInactive from './assets/completed_inactive.svg';
import upcomingActive from './assets/upcoming_active.svg';
import upcomingInactive from './assets/upcoming_inactive.svg';
import directorShowAllActive from './assets/director-showall_active.svg';
import directorShowAllInactive from './assets/director-showall_inactive.svg';
import locationShowAllActive from './assets/location-showall_active.svg';
import locationShowAllInactive from './assets/location-showall_inactive.svg';
import clearCompletionActive from './assets/clear-time-period_active.svg';
import clearCompletionInactive from './assets/clear-time-period_inactive.svg';

const getDirectorList = (directors, dash) => {
  const you = dash?.user?.data?._id?.toString();
  //alphabetize
  const _directors = directors.slice(0).sort((a, b) => a.name.first.localeCompare(b.name.first));
  //pull "you" to the front
  return [
    ..._directors.filter(({ _id }) => _id.toString() === you),
    ..._directors.filter(({ _id }) => _id.toString() !== you)
  ];
};

const sortFilterOptions = [
  { name: 'Newest', value: '-creationDate' },
  { name: 'Oldest', value: 'creationDate' },
  { name: 'A-Z', value: 'searchName' },
  { name: 'Z-A', value: '-searchName' }
];

const dateFilterOptions = [
  { name: 'All Dates', value: 'all-dates' },
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Last 7 Days', value: '7-days' },
  { name: 'Last 30 Days', value: '30-days' },
  { name: 'Last 12 Months', value: '12-months' },
  { name: 'Custom', value: 'custom' }
];

const dropdownOptions = {
  sortFilterOptions,
  dateFilterOptions
};

const filterType = {
  ONLOAD: 'ONLOAD',
  COMPLETION: 'COMPLETION',
  DIRECTOR: 'DIRECTOR',
  LOCATION: 'LOCATION',
  DIRECTOR_SHOWALL: 'DIRECTOR_SHOWALL',
  LOCATION_SHOWALL: 'LOCATION_SHOWALL',
  SEARCH: 'SEARCH',
  SORT: 'SORT',
  DATE: 'DATE',
  PAGE: 'PAGE',
  CLEAR_COMPLETION: 'CLEAR_COMPLETION',
  CLEAR_DIRECTOR: 'CLEAR_DIRECTOR',
  CLEAR_LOCATION: 'CLEAR_LOCATION',
  RESET: 'RESET'
};

const getFilters = (unfilteredDirectors, allLocations, dash) => {
  const directors = unfilteredDirectors.filter(d =>
    userHasAccess({ user: d, funeralHomes: allLocations, accessString: 'createView' })
  );

  const { filteredHomes: locations = [] } = filterHomesByUser({
    homes: allLocations,
    user: dash?.user?.data,
    parameters: ['createView']
  });

  return [
    {
      header: '',
      options: [
        {
          type: filterType.CLEAR_COMPLETION,
          value: 'All',
          images: {
            active: clearCompletionActive,
            inactive: clearCompletionInactive
          }
        },
        {
          id: 'completed',
          type: filterType.COMPLETION,
          value: 'Completed',
          images: {
            active: completedActive,
            inactive: completedInactive
          }
        },
        {
          id: 'upcoming',
          type: filterType.COMPLETION,
          value: 'Upcoming Events',
          images: {
            active: upcomingActive,
            inactive: upcomingInactive
          }
        }
      ]
    },
    {
      header: '',
      collapsible: {
        type: filterType.DIRECTOR_SHOWALL,
        amt: 6,
        state: 'directorFilterShowAll'
      },
      options: [
        {
          type: filterType.CLEAR_DIRECTOR,
          value: 'All Directors',
          images: {
            active: directorShowAllActive,
            inactive: directorShowAllInactive
          }
        },
        ...getDirectorList(directors, dash).map(({ _id, name, image }) => ({
          id: _id,
          type: filterType.DIRECTOR,
          value: nameString(name),
          image,
          showCharacterProfileImage: true
        }))
      ]
    },
    {
      header: '',
      collapsible: {
        type: filterType.LOCATION_SHOWALL,
        amt: 6,
        state: 'locationFilterShowAll'
      },
      options: [
        {
          type: filterType.CLEAR_LOCATION,
          value: 'All Locations',
          images: {
            active: locationShowAllActive,
            inactive: locationShowAllInactive
          }
        },
        ...locations
          .slice(0)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ _id, name, profileImage }) => ({
            id: _id,
            type: filterType.LOCATION,
            value: name,
            image: profileImage || locationPlaceholder
          }))
      ]
    }
  ];
};

const initialState = {
  searchQuery: '',
  rawSearchQuery: '',
  displayedSearchQuery: '',
  searchClicked: false,
  directorFilter: '',
  directorFilterName: '',
  directorFilterShowAll: false,
  locationFilter: '',
  locationFilterName: '',
  locationFilterShowAll: false,
  completion: '',
  completionName: '',
  selected: {
    completion: {
      value: `${filterType.CLEAR_COMPLETION}-`,
      name: ''
    },
    director: {
      value: `${filterType.CLEAR_DIRECTOR}-`,
      name: ''
    },
    location: {
      value: `${filterType.CLEAR_LOCATION}-`,
      name: ''
    }
  },
  sort: '-creationDate',
  dateFrom: '',
  dateTo: '',
  customDateRange: false,
  dateValue: 'all-dates',
  page: 0,
  pageStartsAtZero: true,
  sortName: ''
};

const resetPage = {
  page: 0
};

const resetOverlapingCompletion = {
  completion: '',
  completionName: '',
  selected: {
    completion: {
      value: `${filterType.CLEAR_COMPLETION}-`,
      name: ''
    }
  },
  page: 0
};

const resetOverlapingDirector = {
  directorFilter: '',
  directorFilterName: '',
  selected: {
    director: {
      value: `${filterType.CLEAR_DIRECTOR}-`,
      name: ''
    }
  },
  page: 0
};

const resetOverlapingLocations = {
  locationFilter: '',
  locationFilterName: '',
  selected: {
    location: {
      value: `${filterType.CLEAR_LOCATION}-`,
      name: ''
    }
  },
  page: 0
};

const resetSearch = {
  rawSearchQuery: '',
  searchQuery: ''
};

const resetSort = {
  sort: '',
  sortName: ''
};

const resetDate = {
  dateFrom: '',
  dateTo: '',
  customDateRange: false,
  dateValue: 'all-dates'
};

const filterReducer = (state, { type, value, id, name }) => {
  const today = moment();

  switch (type) {
    case filterType.ONLOAD:
      return {
        ...merge(state, value)
      };

    case filterType.PAGE:
      return {
        ...merge(state, {
          page: value
        })
      };

    case filterType.CLEAR_COMPLETION:
      return { ...merge(state, resetOverlapingCompletion) };

    case filterType.COMPLETION: {
      const selectionProperty = {
        selected: {
          completion: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingCompletion, selectionProperty),
        completion: id,
        completionName: value
      };
    }

    case filterType.CLEAR_DIRECTOR:
      return { ...merge(state, resetOverlapingDirector) };

    case filterType.DIRECTOR: {
      const selectionProperty = {
        selected: {
          director: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingDirector, selectionProperty),
        directorFilter: id,
        directorFilterName: value
      };
    }

    case filterType.DIRECTOR_SHOWALL:
      return {
        ...state,
        directorFilterShowAll: !state.directorFilterShowAll
      };

    case filterType.CLEAR_LOCATION:
      return { ...merge(state, resetOverlapingLocations) };

    case filterType.LOCATION: {
      const selectionProperty = {
        selected: {
          location: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlapingLocations, selectionProperty),
        locationFilter: id,
        locationFilterName: value
      };
    }

    case filterType.LOCATION_SHOWALL:
      return {
        ...state,
        locationFilterShowAll: !state.locationFilterShowAll
      };

    case filterType.SEARCH:
      return {
        ...merge(state, resetPage),
        searchQuery: value,
        rawSearchQuery: value
      };

    case filterType.SORT:
      let sortName;
      switch (value) {
        case '-creationDate':
          sortName = '';
          break;
        case 'creationDate':
          sortName = 'Oldest';
          break;
        case 'searchName':
          sortName = 'A-Z';
          break;
        case '-searchName':
          sortName = 'Z-A';
          break;
        default:
          sortName = '';
      }
      return {
        ...merge(state, resetPage),
        sort: value,
        sortName
      };

    case filterType.DATE:
      let date;
      switch (value) {
        case 'all-dates':
          date = {
            dateFrom: '',
            dateTo: '',
            customDateRange: false,
            dateValue: value
          };
          break;
        case 'today':
          date = {
            dateFrom: today.startOf('day').valueOf(),
            dateTo: today.endOf('day').valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case 'yesterday':
          date = {
            dateFrom: getStartDate('day').valueOf(),
            dateTo: getStartDate('day')
              .endOf('day')
              .valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case '7-days':
          date = {
            dateFrom: getStartDate('day', 7).valueOf(),
            dateTo: today.endOf('day').valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case '30-days':
          date = {
            dateFrom: getStartDate('month').valueOf(),
            dateTo: today.valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case '12-months':
          date = {
            dateFrom: getStartDate('month', 12).valueOf(),
            dateTo: today.valueOf(),
            customDateRange: false,
            dateValue: value
          };
          break;
        case 'custom':
          date = {
            dateFrom: state.dateFrom,
            dateTo: state.dateTo,
            customDateRange: true,
            dateValue: value
          };
          break;
        default:
          // value from custom range selector
          date = {
            dateFrom: moment(value.from)
              .startOf('days')
              .valueOf(),
            dateTo: moment(value.to)
              .endOf('days')
              .valueOf(),
            customDateRange: true
          };
      }
      return {
        ...merge(state, resetPage),
        ...date
      };

    case filterType.RESET:
      return {
        ...merge(
          state,
          resetOverlapingCompletion,
          resetOverlapingDirector,
          resetOverlapingLocations,
          resetSearch,
          resetSort,
          resetDate,
          resetPage
        )
      };

    default:
      return state;
  }
};

const useAnncFilter = () => {
  const [anncFilter, setAnncFilter] = useReducer(filterReducer, initialState);
  return [anncFilter, setAnncFilter, getFilters, filterType, dropdownOptions];
};

export default useAnncFilter;
