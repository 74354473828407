import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { connect } from 'react-redux';

import {
  Form,
  InputGroup,
  isRequired,
  formActions,
  ErrorNotice
} from '@evdy/web-core/dist/components/shared/Input';
import { useFocusInput } from '@evdy/web-core/dist/customHooks/index';

import ChooseMonthInput from '../shared/ChooseMonthInput';
import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import DropDown from '@evdy/web-core/dist/components/shared/elements/DropDown';
import InfoWindow from '@evdy/web-core/dist/components/shared/elements/InfoWindow';

import './DecedentInfoForm.scss';

import calIcon from './calendar-icon-black.svg';
import clearInput from './clear-input.svg';

import { creationOperations } from '@evdy/web-redux/src/lib/inviteCreation';

const { editDecedentDetailsAction } = creationOperations;

const DecedentInfoForm = ({
  // component props
  model,
  formData,
  formMetaData,
  decedentInfo,
  memorialId,
  handleSubmit,
  uniqueTags,
  withinModal, // quick fix for handling saving images within modal
  // redux state
  decedentDetails,
  // redux actions
  change,
  reset,
  editDecedentDetailsAction,
  validate,
  modalVisible
}) => {
  const {
    name: { first: firstName, last: lastName },
    dateOfBirth,
    dateOfDeath,
    image
  } = formData;

  // focus first name input field
  useFocusInput({ model, inputModel: 'name.first', modalVisible });

  const handleClearDate = type => {
    reset(`${model}.${type}`);
  };

  const handleUploadFinish = url => {
    withinModal
      ? change(`${model}.image`, url)
      : editDecedentDetailsAction({ memorialId, decedent: { ...formData, image: url } });
  };

  const handleRemoveImage = () => {
    withinModal
      ? change(`${model}.image`, '')
      : editDecedentDetailsAction({ memorialId, decedent: { ...formData, image: '' } });
  };

  const dropdownClasses = date => classNames('dropdown-input', { 'date-selected': !!date });

  const nameValidator = type => ({
    isRequired,
    controlledByName: val => {
      if (!formMetaData.displayName.touched && !decedentDetails.displayName) {
        const first = type === 'first' ? val : firstName;
        const last = type === 'last' ? val : lastName;

        change(`${model}.displayName`, `${first} ${last}`.trim());
      }
      return true;
    }
  });

  const dateValidators = (val, type) => {
    const strToDateObj = dateVal => (!!dateVal ? new Date(dateVal) : dateVal);
    const birth = type === 'birth' ? val : strToDateObj(dateOfBirth);
    const death = type === 'death' ? val : strToDateObj(dateOfDeath);
    const incompleteRange = !birth || !death;
    const validRange = birth <= death;

    validate(`${model}.dateOfDeath`, {
      validDeathDate: () => incompleteRange || validRange
    });
  };

  const scrollToCalendar = () =>
    document.getElementsByClassName('DayPicker-Months')[1]?.scrollIntoView();

  return (
    <Form model={model} className="builder-decedent-info-form">
      <div className="decedent-form-top">
        <ImageHandler
          v11Styling
          {...{ handleUploadFinish, handleRemoveImage }}
          currentImg={image || ''}
          modalTitle="Decedent Image"
          dropzoneTitle="Decedent photo"
          dropzoneSubtitle=""
          dropzoneBtnText="Upload photo"
        />
        <div className="input-row">
          <InputGroup
            labelText="First name *"
            model=".name.first"
            validators={nameValidator('first')}
            messages={{
              isRequired: 'This field is required'
            }}
          />
          <InputGroup
            labelText="Last name *"
            model=".name.last"
            validators={nameValidator('last')}
            messages={{
              isRequired: 'This field is required'
            }}
          />
        </div>
        <div className="input-row">
          <div className="input-with-tooltip">
            <InputGroup
              labelText="Display name *"
              model=".displayName"
              validators={{ isRequired }}
              messages={{ isRequired: 'This field is required' }}
            />
            <InfoWindow noClose hoverEvents outsideText="?">
              Edit this to show how you’d like the decedent’s name to appear on the Memorial
            </InfoWindow>
          </div>
          {/* <div className="input-with-tooltip"> */}
          {/* <InputGroup
          labelText="Decedent’s cell phone #"
          type="tel"
          model=".decedentPhone"
          parser={parsePhone}
          validators={{
            isValidPhone
          }}
          messages={{
            isValidPhone: 'Please enter a valid phone number'
          }}
        /> */}
          {/* <InfoWindow noClose hoverEvents outsideText="?">
              Include this so the family can send invites to important contacts in their loved one’s
              phone
            </InfoWindow>
          </div> */}
        </div>
        <div className="input-row" onClick={scrollToCalendar}>
          <div className="date-dropdown">
            <div className="dropdown-label">Date of birth</div>
            <div className={dropdownClasses(dateOfBirth)}>
              <DropDown
                direction="left"
                header={
                  <button type="button" className="dropdown-button">
                    <img src={calIcon} alt="calendar icon" />
                    {dateOfBirth ? format(new Date(dateOfBirth), 'MM-dd-yyyy') : 'MM-DD-YYYY'}
                  </button>
                }
                pane={({ togglePane }) => (
                  <ChooseMonthInput
                    disableFuture
                    modelBase={model}
                    model=".dateOfBirth"
                    initialDate={dateOfBirth}
                    onChange={date => {
                      togglePane();
                      dateValidators(date, 'birth');
                    }}
                    unique={uniqueTags[0]}
                  />
                )}
              />
              {dateOfBirth && (
                <button className="clear-input" onClick={() => handleClearDate('dateOfBirth')}>
                  <img src={clearInput} alt="clear input" />
                </button>
              )}
            </div>
          </div>
          <div className="date-dropdown">
            <div className="dropdown-label">Date of death</div>
            <div className={dropdownClasses(dateOfDeath)}>
              <DropDown
                direction="left"
                header={
                  <button type="button" className="dropdown-button">
                    <img src={calIcon} alt="calendar icon" />
                    {dateOfDeath ? format(new Date(dateOfDeath), 'MM-dd-yyyy') : 'MM-DD-YYYY'}
                  </button>
                }
                pane={({ togglePane }) => (
                  <ChooseMonthInput
                    disableFuture
                    modelBase={model}
                    model=".dateOfDeath"
                    initialDate={dateOfDeath}
                    onChange={date => {
                      togglePane();
                      dateValidators(date, 'death');
                    }}
                    unique={uniqueTags[1]}
                  />
                )}
              />
              {dateOfDeath && (
                <button className="clear-input" onClick={() => handleClearDate('dateOfDeath')}>
                  <img src={clearInput} alt="clear input" />
                </button>
              )}
            </div>
            <ErrorNotice
              model={`${model}.dateOfDeath`}
              messages={{ validDeathDate: 'Date of death must be after date of birth' }}
              show={field => field.errors?.validDeathDate}
            />
          </div>
        </div>
      </div>
      <div className="decedent-form-bottom">
        <div className="input-row">
          <div className="checkbox-input">
            <div className="dropdown-label">
              <p>
                <b>Was this a traumatic death?</b> (e.g; child, sudden)
              </p>
              <p className="sub-label">
                Including this detail will support a highly sensitive experience tailored to these
                delicate circumstances
              </p>
            </div>
            <InputGroup
              inline
              model=".traumatic"
              type="checkbox"
              customClass="checkbox everdaysCheckbox"
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default connect(
  ({ inviteCreation, inviteDetails }) => ({
    decedentDetails: inviteCreation.decedentDetails.data._id
      ? inviteCreation.decedentDetails.data
      : inviteDetails.decedentDetails.data
  }),
  {
    change: formActions.change,
    reset: formActions.reset,
    validate: formActions.validate,
    editDecedentDetailsAction
  }
)(DecedentInfoForm);
