import React from 'react';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';

import ImageHandler from '@evdy/web-core/dist/components/ImageHandler';
import {
  Form,
  InputGroup,
  isRequired,
  formActions,
  isEmail,
  isValidPhone,
  isValidZip,
  parsePhone
} from '@evdy/web-core/dist/components/shared/Input';

import './LocationAboutBody.scss';

import premiumPrograms from '@evdy/web-etc/dist/lib/premiumPrograms';
import { companyTypes } from '@evdy/web-etc/dist/lib/companyTypes';
import { abbreviatedStatesArray, abbreviatedProvincesArray } from '@evdy/web-etc/dist/lib/repZones';
import {
  getSpecialStatesArray,
  getDirectorNumberStatesArray,
  getInsuranceLicenseNumberStatesArray,
  representativeIsNotMeStates
} from '../../../../Locations/LocationDetails/BasicDetails/HomeDetailsModal/helperFuncs';

const regionArray = [...abbreviatedStatesArray, ...abbreviatedProvincesArray];
const taxClasses = ['Sole Proprietor', 'Partnership', 'Corporation'];

const LocationAboutBody = ({
  model,
  change,
  isAddHome,
  isInternal,
  form,
  funeralHomeLicenseInfo,
  user = {}
}) => {
  const { address, photos, representativeIsNotMe: repIsNotMeFormData, homeHasOwnTaxInfo } =
    form || {};
  const { state: currentFormState } = address || {};
  const { isCompanyAdmin } = user || {};

  // client config data
  const clientHomeLicenseInfoArray = Object.entries(funeralHomeLicenseInfo);

  // specialStateLicense info visible conditionals && vars
  const specialStates = getSpecialStatesArray(clientHomeLicenseInfoArray);
  const isSpecialState = specialStates.includes(address?.state);
  const labelText = `${funeralHomeLicenseInfo[address?.state]?.specialStateLicenseInfo} *`;
  const labelText2 =
    funeralHomeLicenseInfo[address?.state]?.specialStateLicenseInfo2 &&
    `${funeralHomeLicenseInfo[address?.state]?.specialStateLicenseInfo2} *`;

  // funeralDirectorNum visible conditionals && vars
  const directorNumberStates = getDirectorNumberStatesArray(clientHomeLicenseInfoArray);
  const isFuneralDirectorStateSelected = !!directorNumberStates.find(
    ([state]) => state === currentFormState
  );
  const isFuneralDirectorNumberVisible =
    (currentFormState && isFuneralDirectorStateSelected && isCompanyAdmin) ||
    (isInternal && currentFormState && isFuneralDirectorStateSelected);

  // insuranceLicenseNumber visible conditionals && vars
  const insuranceLicenseNumberStates = getInsuranceLicenseNumberStatesArray(
    clientHomeLicenseInfoArray
  );
  const insuranceLicenseStateSelected = insuranceLicenseNumberStates.find(
    ([state]) => state === currentFormState
  );
  const isInsuranceLicenseNumberVisible =
    (currentFormState && insuranceLicenseStateSelected && isCompanyAdmin) ||
    (isInternal && currentFormState && insuranceLicenseStateSelected);

  // repIsNotMe visible conditionals && vars
  const isRepresentativeState = representativeIsNotMeStates.includes(currentFormState);
  const isRepresentativeNotMeVisible =
    (currentFormState && isRepresentativeState && isCompanyAdmin) ||
    (currentFormState && isRepresentativeState && isInternal);

  const compTypes = Object.entries(companyTypes);

  return (
    <div className="location-about-body">
      <div className="location-about-image-section">
        <ImageHandler
          currentImg={form.profileImage || ''}
          handleRemoveImage={url => {
            change(`${model}.profileImage`, '');
            const removeIdx = photos.findIndex(photo => photo === url);
            const removeArray = [...photos.slice(0, removeIdx), ...photos.slice(removeIdx + 1)];
            change(`${model}.photos`, removeArray);
          }}
          handleUploadFinish={url => {
            change(`${model}.profileImage`, url);
            const homePhotos = isAddHome ? [url] : [url, ...photos];
            change(`${model}.photos`, homePhotos);
          }}
        />
      </div>
      <Form model={model}>
        <div className="input-row">
          <InputGroup
            required
            model=".name"
            labelText="Location's Public Name *"
            validators={{ isRequired }}
            messages={{ isRequired: 'Name is required' }}
          />
          <InputGroup model=".websiteUrl" labelText="Location's Website" />
        </div>
        <div className="input-row">
          <InputGroup model=".type" type="select" labelText="Type of Business">
            <option />
            {compTypes.map(([key, val]) => (
              <option key={key} value={val}>
                {startCase(key)}
              </option>
            ))}
          </InputGroup>
        </div>
        <div className="input-row">
          <InputGroup
            required
            model=".address.street"
            labelText="Address 1 *"
            validators={{ isRequired }}
            messages={{ isRequired: 'Address 1 is required' }}
          />
          <InputGroup model=".address.street2" labelText="Address 2" />
        </div>
        <div className="input-row">
          <InputGroup
            required
            model=".address.city"
            labelText="City *"
            validators={{ isRequired }}
            messages={{ isRequired: 'City is required' }}
          />
          <InputGroup
            inline
            full
            type="select"
            model=".address.state"
            labelText="State/Province *"
            value={address.state}
            validators={{ isRequired }}
            messages={{ isRequired: 'State/Province is required' }}
          >
            <option />
            {regionArray.map((region, i) => (
              <option value={region} key={i}>
                {region}
              </option>
            ))}
          </InputGroup>
        </div>
        <div className="input-row">
          <InputGroup
            required
            model=".address.zip"
            labelText="Postal Code *"
            validators={{ isRequired, isValidZip }}
            messages={{
              isRequired: 'Postal Code is required',
              isValidZip: 'Please enter a valid zip code'
            }}
          />
          {isSpecialState && (
            <InputGroup
              {...{ labelText }}
              model=".specialStateLicenseInfo"
              validators={{ isRequired }}
              messages={{ isRequired: 'Required in your state' }}
            />
          )}
        </div>
        {isSpecialState && labelText2 && (
          <div className="input-row">
            <InputGroup
              model=".specialStateLicenseInfo2"
              labelText={labelText2}
              validators={{ isRequired }}
              messages={{ isRequired: 'Required in your state' }}
            />
          </div>
        )}
        <div className="input-row">
          <InputGroup
            required
            type="tel"
            model=".phone"
            labelText="Location's Phone Number *"
            validators={{ isRequired, isValidPhone }}
            parser={parsePhone}
            messages={{
              isRequired: 'Phone Number is required',
              isValidPhone: 'Please enter a valid phone number'
            }}
          />
          <InputGroup
            required
            type="email"
            model=".email"
            labelText="Location's General Email *"
            validators={{ isRequired, isEmail }}
            messages={{
              isRequired: 'Email is required',
              isEmail: 'Please enter a valid email address'
            }}
          />
        </div>
        {(isFuneralDirectorNumberVisible || isInsuranceLicenseNumberVisible) && (
          <div className="input-row">
            {isFuneralDirectorNumberVisible && (
              <InputGroup
                model=".funeralDirectorNumber"
                labelText="Funeral Director Number *"
                validators={!isInternal ? { isRequired } : {}}
                messages={{
                  isRequired: 'Required in your state'
                }}
              />
            )}
            {isInsuranceLicenseNumberVisible && (
              <InputGroup
                model=".insuranceLicenseNumber"
                labelText="Insurance License Number *"
                validators={!isInternal ? { isRequired } : {}}
                messages={{
                  isRequired: 'Required in your state'
                }}
              />
            )}
          </div>
        )}
        <div className="input-row">
          <InputGroup model=".casesPerYear" labelText="Estimated Cases Per Year" />
        </div>
        {isRepresentativeNotMeVisible && (
          <div className="input-row">
            <InputGroup
              type="checkbox"
              model=".representativeIsNotMe"
              labelText="My Funeral Home Representative is not me"
            />
          </div>
        )}
        {isCompanyAdmin && (
          <div className="input-row">
            <InputGroup
              type="checkbox"
              model=".homeHasOwnTaxInfo"
              labelText="This Location uses a different Tax ID than my Account"
            />
          </div>
        )}
        {isInternal && (
          <div className="input-row">
            <InputGroup
              type="checkbox"
              model=".ownerInformedOfSpecialStateInfo"
              labelText="I informed the owner of state regulations and forms"
            />
          </div>
        )}
        {repIsNotMeFormData && (
          <>
            <div className="input-row">
              <InputGroup
                model=".representative.name.first"
                labelText="Funeral Representative First Name *"
                validators={{ isRequired }}
                messages={{
                  isRequired: 'Required'
                }}
              />
              <InputGroup
                model=".representative.name.last"
                labelText="Funeral Representative Last Name *"
                validators={{ isRequired }}
                messages={{
                  isRequired: 'Required'
                }}
              />
            </div>
            <div className="input-row">
              <InputGroup
                model=".representative.phone"
                labelText="Funeral Representative Mobile Phone *"
                parser={parsePhone}
                validators={{ isRequired, isValidPhone }}
                messages={{
                  isRequired: 'Required',
                  isValidPhone: 'Please enter a valid phone number'
                }}
              />
              <InputGroup
                model=".representative.email"
                labelText="Funeral Representative Email *"
                validators={{ isRequired, isEmail }}
                messages={{
                  isRequired: 'Required',
                  isEmail: 'Please enter a valid email address'
                }}
              />
            </div>
          </>
        )}
        {homeHasOwnTaxInfo && (
          <div className="input-row">
            <InputGroup
              model=".taxID"
              labelText="Tax ID Number *"
              validators={{ isRequired }}
              messages={{ isRequired: 'Fields is required' }}
            />
            <InputGroup
              validators={{ isRequired }}
              messages={{ isRequired: 'Field is required' }}
              model=".taxClassification"
              type="select"
              labelText="Tax Classificiation *"
            >
              <option />
              {taxClasses.map(c => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </InputGroup>
          </div>
        )}
        {isInternal && (
          <div className="input-row">
            <InputGroup model=".facebookUrl" labelText="Facebook Page URL" />
            <InputGroup model=".placeId" labelText="Google Place ID" />
          </div>
        )}
        {isInternal && (
          <div className="input-row">
            <InputGroup model=".yelpUrl" labelText="Yelp Page URL" />
            <InputGroup model=".eFuneralHomeId" labelText="eFuneral Home ID" />
          </div>
        )}
        {isInternal && (
          <div className="input-row single-full">
            <InputGroup type="textarea" model=".description" labelText="Bio/About" />
          </div>
        )}
        {isInternal && (
          <InputGroup
            model=".displayPublicly"
            type="checkbox"
            customClass="display-public-checkbox"
            labelText="Display Publicly"
          />
        )}
        {isInternal && (
          <div className="input-row">
            <InputGroup model=".premiumProgram" type="select" labelText="Premium Program">
              <option />
              {premiumPrograms.map(val => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </InputGroup>
          </div>
        )}
      </Form>
    </div>
  );
};

export default connect(
  ({ client, authUser }) => ({
    funeralHomeLicenseInfo: client.config.data.funeralHomeLicenseInfo,
    user: authUser.data
  }),
  { change: formActions.change }
)(LocationAboutBody);
