import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import DashPage from '@evdy/web-core/dist/components/shared/elements/DashPage';

import { googleAnalytics } from '@evdy/web-core/dist/lib/utils';

const logo = require('../../images/large-logo-beta@2x.png');

require('./styles/RegistrationStart.scss');

export default class RegistrationStart extends React.Component {
  render() {
    const { firstName, startRegistration } = this.props;
    const firstNameCapped = firstName?.substring(0, 1).toUpperCase() + firstName?.substring(1);
    return (
      <DashPage
        customClass="registration-start-page"
        footerText={
          <div className="sign-up-footer">
            <p className="copyright-terms-paragraph">
              © 2017 Everdays, Inc. All rights reserved. The Everdays mark and the Everdays logo are
              trademarks of Everdays, Inc.&nbsp;
              <a href="https://everdays.com/privacy" target="_blank">
                Privacy
              </a>
              &nbsp;and&nbsp;
              <a href="https://everdays.com/terms" target="_blank">
                Terms
              </a>
              .
            </p>
          </div>
        }
      >
        <div className="start-page-content">
          <img
            src={logo}
            alt="The clean, well-designed logo of Everdays."
            className="start-page-logo"
          />
          <p className="start-page-header">Hi {firstNameCapped}! Welcome to Everdays.</p>
          <p className="start-page-text">
            After we walk through a few steps, you will be ready to start using Everdays.
          </p>
          <RaisedButton
            label="Setup My Account"
            labelStyle={{ textTransform: 'none' }}
            className="dash-standard-button"
            primary={true}
            onClick={() => {
              startRegistration();
              googleAnalytics('company-registration-start-start');
            }}
          />
        </div>
      </DashPage>
    );
  }
}
