import React from 'react';
import { connect } from 'react-redux';
import { fetchCompanies } from '@evdy/web-redux/dist/actions/internalDash/fetchCompanies';

import classNames from 'classnames';

import './SwitchCompanyModalBody.scss';

const SwitchCompanyModalBody = props => {
  const { user, switchCompany, fetchedCompany, resetRequiredFieldsOnSwitch = () => {} } = props;

  const userCompanies = user.companies;

  const buttonClasses = currentId =>
    classNames({
      'switch-company-button': true,
      'active-switch-button': fetchedCompany._id === currentId
    });
  const containerClasses = currentId =>
    classNames({
      'company-container': true,
      'active-company-container': fetchedCompany._id === currentId
    });

  const addressString = ({ street1 = '', city = '', state = '' }) =>
    [street1, city, state].filter(Boolean).join(', ');

  return (
    <div className="company-switch-modal">
      {userCompanies.map((company, idx) => {
        const isActiveCompany = company._id === fetchedCompany._id;
        return (
          <div
            key={company.name + idx}
            className={containerClasses(company._id)}
            onClick={() => {
              if (!isActiveCompany) {
                switchCompany(company._id);
                resetRequiredFieldsOnSwitch();
              }
            }}
          >
            <div className="company-text">
              <p className="company-name">{company.name}</p>
              <p className="company-address">{addressString(company?.address || {})}</p>
            </div>
            <button className={buttonClasses(company._id)}>
              {isActiveCompany ? 'Active' : 'Switch'}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default connect(
  ({ dash, internalDash }) => ({
    user: dash.user.data,
    fetchedCompany: dash?.fetchedCompany?.company?.data
  }),
  { fetchCompanies }
)(SwitchCompanyModalBody);
