// TODO: Delete if not needed - WebCleanup2020 (tag for searching)
// TODO: Currently used in old Onboarding
import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ArrowLeftC from 'react-icons/lib/io/arrow-left-c';
import ArrowRightC from 'react-icons/lib/io/arrow-right-c';

import './Stepper.scss';

import backgrounds from '@evdy/web-etc/dist/lib/backgrounds';
import { nameString } from '@evdy/web-core/dist/lib/utils';

const Stepper = props => {
  const {
    stepIdx,
    stepOrder,
    decedent: { name, dob, dod },
    photos: { coverIdx, image },
    handleFinish,
    setStep,
    type,
    isBackDisabled,
    isNextDisabled,
    aboveHeader,
    checkPhone,
    start,
    companyName,
    user,
    finishBtnText = 'Send',
    hideNext = false,
    forcePercentage
  } = props;

  const containers = props.children.filter(Boolean);
  const container = containers[stepIdx];

  const { children: containerChildren, step } = container.props;

  const percentageComplete = forcePercentage || (stepIdx + 1) / containers.length;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepIdx]);

  let { label, subtitle } = step;

  // handle step labels with dynamic strings
  // Creation
  if (stepOrder[stepIdx] === 'image_select')
    label = `Upload a Photo of ${name?.first || 'the Decedent'}`;

  // Onboarding
  const onboardFirstName = start.firstName || user?.name?.first || '';
  if (stepOrder[stepIdx] === 'check_phone') {
    label = (
      <span>
        Great to meet you{` ${onboardFirstName}`}. Next, we will send a text to your mobile phone to
        verify your account.
      </span>
    );
  }
  if (stepOrder[stepIdx] === 'verify_phone')
    label = (
      <span>
        Thanks! Enter the code that was just sent to <strong>{checkPhone}</strong> to verify your
        number and secure your account.
      </span>
    );
  if (stepOrder[stepIdx] === 'company_exists')
    label = (
      <div>
        <p>
          {`${onboardFirstName}, `}I see you work for {companyName.name}, who is already setup with
          Everdays. Before adding you to the account, we need to confirm that you're part of the
          team.
        </p>
        <p>
          We have begun the verification process and will follow up with you shortly. If you believe
          you've received this message in error, please contact us.
        </p>
      </div>
    );
  if (stepOrder[stepIdx] === 'finish')
    label = (
      <div>
        <p>You're all set{` ${onboardFirstName}`}. Welcome to Everdays!</p>
        <p>
          Start creating Memorial Invites for your client families today so you empower them with a
          way to reach everyone they need. With Everdays, you can help to gather a community of
          support around each family you serve.
        </p>
      </div>
    );

  const { month: dobMonth, day: dobDay, year: dobYear } = dob;
  const formattedDob = useMemo(() => {
    if (![dobMonth, dobDay, dobYear].every(Boolean)) return '';
    return [1 + parseInt(dobMonth), dobDay, dobYear].join('/');
  }, [dobMonth, dobDay, dobYear]);

  const { month: dodMonth, day: dodDay, year: dodYear } = dod;
  const formattedDod = useMemo(() => {
    if (![dodMonth, dodDay, dodYear].every(Boolean)) return '';
    return [1 + parseInt(dodMonth), dodDay, dodYear].join('/');
  }, [dodMonth, dodDay, dodYear]);

  const decedentImg = useMemo(() => {
    const { url } = backgrounds.find(({ id }) => id === coverIdx) || {};
    return image || url;
  }, [image, coverIdx]);

  const titleClasses = classNames('page-title', {
    'no-subtitle': !subtitle
  });

  return (
    <div className="v3-stepper-page-content">
      <div className="v3-stepper-body v3-stepper-fullscreen">
        <div className="stepper-page-left">
          {aboveHeader || ''}
          <p className={titleClasses}>{container && label}</p>
          {subtitle && <p className={'page-subtitle'}>{subtitle}</p>}
          {container && containerChildren}
        </div>
      </div>
      <div className="v3-stepper-progress-and-button">
        {!!stepIdx && type === 'builder' && (
          <div className="stepper-context-bar">
            <div className="decedent-img" style={{ backgroundImage: `url(${decedentImg})` }} />
            <span className="decedent-name">{nameString(name)}</span>
            <span>{[formattedDob, formattedDod].join(' - ')}</span>
          </div>
        )}
        <div className="stepper-progress-line">
          <div
            className="progress"
            style={{
              background: '#0072cd',
              width: `${percentageComplete * 100}%`
            }}
          />
        </div>
        <div className="stepper-buttons">
          {stepIdx && !isBackDisabled ? (
            <button
              className="stepper-button previous"
              onClick={() => {
                setStep('back', true);
              }}
            >
              <ArrowLeftC />
              <span>Back</span>
            </button>
          ) : (
            <div />
          )}
          {!hideNext && (
            <>
              {stepIdx < containers.length - 1 ? (
                <button
                  id="evdy-test-next"
                  className="stepper-button next"
                  onClick={() => setStep('forward', false)}
                  disabled={isNextDisabled()}
                >
                  Next <ArrowRightC />
                </button>
              ) : (
                <button
                  onClick={handleFinish}
                  className="stepper-button next send"
                  disabled={isNextDisabled()}
                >
                  {finishBtnText} <ArrowRightC />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(({ dash: { createMemorial, annc, onboarding, user } }) => ({
  createMemorial,
  forms: annc.forms,
  decedent: annc.forms.decedent,
  members: annc.members,
  events: annc.events,
  photos: annc.photos,
  serviceStatus: annc.forms.serviceStatus,
  checkPhone: onboarding.forms.checkPhone.phone,
  start: onboarding.forms.start,
  companyName: onboarding.forms.companyName,
  user: user.data
}))(Stepper);
