import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { browserHistory } from 'react-router';

import {
  GradientModalHeader,
  ModalFooter,
  ModalOverlay,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import ConfirmationModal from '@evdy/web-core/dist/components/shared/elements/ConfirmationModal';
import MemberDetails from './AccMemberDetails/MemberDetails';
import MemberModalBody from '../../../shared/MemberModalBody';

import { nameString, renderToast, renderDemoToast } from '@evdy/web-core/dist/lib/utils';

import { editAccountAction, editAccountReset } from '@evdy/web-redux/dist/actions/dash/editAccount';
import { editSpecificUser } from '@evdy/web-redux/dist/actions/dash/editUser';
import { fetchAccounts } from '@evdy/web-redux/dist/actions/dash/fetchAccounts';

import { editManyHomes } from '@evdy/web-redux/src/lib/manyHomes';

import './AccMembers.scss';

const EditAccountsMembers = ({
  account,
  user,
  merge,
  memberFormValid,
  memberModal,
  editAccountAction,
  editAccountReset,
  editAccount,
  editManyHomesData,
  editManyHomes,
  editSpecificUser,
  fetchAccounts,
  location
}) => {
  const users = account?.users ? account.users : [];
  const { isDemoModeOn = false } = user || {};

  const { lastUpdated: editAccountLastUpdated, error: editAccountError } = editAccount;
  const { lastUpdated: editManyLastUpdated, error: editManyError } = editManyHomesData;

  const [modalVisible, setModalVisible] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalType, setModalType] = useState('add-member');
  const [modalData, setModalData] = useState({});
  const [saveType, setSaveType] = useState(undefined);

  // display toast on success/save confirmation
  useEffect(() => {
    if (editAccountLastUpdated >= Date.now() - 100 && isEmpty(editAccountError)) {
      const toastCopy =
        modalType === 'add-member'
          ? `A Memorial was sent to ${memberModal?.name?.first}`
          : 'Successfully saved changes';
      renderToast({ id: 'member-success-toast', message: toastCopy });
      closeModal();
      editAccountReset();
      fetchAccounts(account._id);
    }
  }, [editAccountError, editAccountLastUpdated]);

  // display toast when edit fails to save
  useEffect(() => {
    if (!isEmpty(editAccountError)) {
      renderToast({ id: 'member-failure-toast', message: editAccountError, type: 'error' });
    }
  }, [editAccountError]);

  useEffect(() => {
    if (editManyLastUpdated >= Date.now() - 100 && isEmpty(editManyError)) {
      const toastCopy = "Member's location settings updated";
      renderToast({ id: 'member-success-toast', message: toastCopy });
      fetchAccounts(account._id);
    }
  }, [editManyError, editManyLastUpdated]);

  useEffect(() => {
    if (!isEmpty(editManyError)) {
      renderToast({ id: 'member-failure-toast', message: editManyError, type: 'error' });
    }
  }, [editManyError]);

  const handleOpenModal = ({ type, data: member = {} }) => {
    setModalVisible(true);
    setModalType(type);
    setModalData({ member });
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalType('');
    setModalData({});
    setSaveType(undefined);
  };

  if (isDemoModeOn) return renderDemoToast({ id: 'company-member-modal-save-demo' });
  const handleModalSave = () => {
    switch (saveType) {
      case 'add-member':
        editAccountAction(
          {
            company: account,
            users: [
              ...account.users,
              {
                ...memberModal,
                image: memberModal.image ? memberModal.image : undefined
              }
            ]
          },
          account._id
        );

        return;

      case 'edit-member':
        const editedMemberId = modalData.member._id;
        const editedMembers = account.users.map(member => {
          const memberId = member._id.toString ? member._id.toString() : member._id;

          if (memberId === editedMemberId) {
            return { ...member, ...memberModal };
          }
          return member;
        });

        editAccountAction(
          {
            company: account,
            users: editedMembers
          },
          account._id
        );
        return;

      case 'edit-member-locations':
        if (memberModal.locationSettings) {
          let data = [];
          let isCurrentAFP = false;
          let editedMember = '';
          account.funeralHomes.forEach((home, homeIdx) => {
            const modalLocationSettings = memberModal[homeIdx].locationSettings;
            const AFPSetting = memberModal[homeIdx].isHomeAFP;

            if (AFPSetting) {
              isCurrentAFP = true;
              editedMember = modalLocationSettings.userRef;
            }

            //reduce afps to an array of ids for parsing in the backend
            const currentAFPs = home?.afps.reduce((acc, cur) => [...acc, cur._id], []);

            const userExistsInAFPs = currentAFPs.find(afp => afp === modalLocationSettings.userRef);

            let newAFPs = currentAFPs;
            //add user to afp array if they are not in it
            if (AFPSetting && !userExistsInAFPs) {
              newAFPs = [...currentAFPs, modalLocationSettings.userRef];
            }

            //remove user from afp array if they are in it
            if (!AFPSetting && userExistsInAFPs) {
              newAFPs = currentAFPs.filter(afp => afp !== modalLocationSettings.userRef);
            }

            const filteredUsers = home?.users?.filter(
              user => user.userRef !== modalLocationSettings.userRef
            );

            const newUsers = [...filteredUsers, modalLocationSettings];

            if (modalLocationSettings) {
              data.push({
                _id: home._id,
                data: { users: newUsers, afps: newAFPs }
              });
            }
          });
          const homesUpdate = () => {
            return editManyHomes(data).then(() => {
              fetchAccounts(account._id);
            });
          };

          if (isCurrentAFP) {
            editSpecificUser({ isAFP: true }, editedMember).then(() => homesUpdate());
          } else {
            homesUpdate();
          }
          closeModal();
        }
        return;
      default:
        break;
    }
  };

  const handleConfirmDeletion = () => {
    setIsConfirmModalOpen(true);
  };

  const handleModalDelete = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-member-modal-delete-demo' });
    editAccountAction(
      {
        company: account,
        delete: {
          users: [modalData.member._id]
        }
      },
      account._id
    );
  };

  const handleMemberClick = id => {
    const memberLinkParams = {
      isExternalMode: false,
      originDisplayName: 'Account Member List',
      originPathName: `/dash/a/accounts/${account._id}?tabIndex=2`
    };

    const pathname = `/dash/a/users/${id}`;
    browserHistory.push({ ...location, pathname, query: memberLinkParams });
  };

  const confirmSubject = modalData?.member?._id ? nameString(modalData.member.name) : 'this Member';
  const confirmCopy = `Are you sure you want to delete ${confirmSubject}?`;

  return (
    <div className="edit-accounts-tab">
      <button
        className="accounts-add-member-btn"
        onClick={() => {
          handleOpenModal({ type: 'add-member' });
          setSaveType('add-member');
        }}
      >
        Add a Team Member
      </button>
      <div className="accounts-members-list">
        {users.map((user, i) => (
          <MemberDetails
            {...{ user, handleMemberClick, handleOpenModal, setSaveType }}
            key={user?._id}
          />
        ))}
      </div>
      <ModalOverlay {...{ modalVisible, closeModal }} customClass="accnt-members-modal">
        <ResponsiveContent>
          <GradientModalHeader
            {...{ closeModal }}
            headerCopy={modalType === 'add-member' ? 'Invite a Member' : 'Edit Member'}
          />
          <MemberModalBody
            {...{ modalData }}
            model="dash.company.forms.memberModal"
            formData={memberModal || {}}
            editMode={modalType === 'edit-member'}
            funeralHomes={account?.funeralHomes}
            setSaveType={setSaveType}
          />
          <ModalFooter
            mainButtonText="Save"
            mainButtonFn={handleModalSave}
            disabled={!memberFormValid}
            secondaryButtonText="Cancel"
            secondaryButtonFn={closeModal}
            tertiaryButtonText="Delete"
            tertiaryButtonFn={handleConfirmDeletion}
            tertiaryButtonHide={modalType === 'add-member'}
          />
        </ResponsiveContent>
      </ModalOverlay>
      <ConfirmationModal
        confirmCopy={confirmCopy}
        display={isConfirmModalOpen}
        onConfirm={handleModalDelete}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};

export default connect(
  ({ dash }) => ({
    memberFormValid: dash.company.forms.companyMeta.memberModal.$form.valid,
    editAccount: dash.editAccount,
    memberModal: dash.company.forms.memberModal,
    editManyHomesData: dash.editManyHomes,
    user: dash.user.data
  }),
  {
    editAccountAction,
    editAccountReset,
    editManyHomes,
    fetchAccounts,
    editSpecificUser
  }
)(EditAccountsMembers);
