import React from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';

import './DecedentNavPreview.scss';

const datePreviewFormat = 'MMM d, y';

const DecedentNavPreview = ({ displayName, dateOfBirth, dateOfDeath, image = '' }) => {
  const renderName = !!displayName.trim() ? (
    <span className="decedent-name">{displayName}</span>
  ) : (
    <div className="decedent-name-placeholder" />
  );

  const formattedDateOfBirth = dateOfBirth && format(new Date(dateOfBirth), datePreviewFormat);
  const formattedDateOfDeath = dateOfDeath && format(new Date(dateOfDeath), datePreviewFormat);
  const missingDodAndDob = !dateOfBirth && !dateOfDeath;
  //format strings
  const formattedDates = missingDodAndDob
    ? '' //No Dates Provided
    : !dateOfDeath
    ? `Born ${formattedDateOfBirth}` //No Birth Date Provided
    : !dateOfBirth
    ? `Passed ${formattedDateOfDeath}` //No Death Date Provided
    : `${formattedDateOfBirth} - ${formattedDateOfDeath}`; //Both Dates Provided

  const renderDobDod =
    dateOfBirth || dateOfDeath ? (
      <span className="decedent-dob-dod">{formattedDates}</span>
    ) : (
      <div className="decedent-dob-dod-placeholder" />
    );

  const navPreviewClassname = classNames('decedent-nav-preview', { 'has-image': !!image });

  return (
    <div className={navPreviewClassname}>
      <div className="decedent-preview-img" style={{ backgroundImage: `url('${image}')` }} />
      <div className="decedent-preview-info">
        {renderName}
        {renderDobDod}
      </div>
    </div>
  );
};

export default DecedentNavPreview;
