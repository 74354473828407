import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import './UpdateEmail.scss';

const UpdateEmail = props => {
  const { dispatch, onboardUser, selectedEmail, start, userData } = props;
  const { email: onboardAddedEmail, onboardInfo } = onboardUser || {};
  const { emailMismatch: onboardMismatch } = onboardInfo || {};
  const { shellEmail: userShell, email: _userEmail, fbEmail, onboardInfo: userOnboardInfo } =
    userData || {};
  const { emailMismatch } = userOnboardInfo || {};
  const { email: startEmail } = start;

  // handle finding the correct emails based on the different ways a user
  // can reach this step.
  // case 1: user creates/loads a shell from start step (non auth, use startEmail & onboardMismatch)
  // case 2: user is logged in and comes from check/verify phone (userData is
  //   not updated so use onboardMismatch & userShell)
  // case 3: user is logged in a continues directly from this step (onboardUserInfo
  //   is empty so use emailMismatch & userShell)
  const onboardEmail = onboardMismatch || emailMismatch;
  const userEmail = userShell || startEmail || _userEmail || fbEmail || onboardAddedEmail;

  const EmailCard = props => {
    const { email, selected, handleSelect } = props;

    const cardClasses = classNames('user-email-card', {
      selected
    });

    return (
      <div className={cardClasses}>
        <div className="email-content">
          <span className="title">Email Address</span>
          <span className="email-address">{email}</span>
        </div>
        <button className="use-email-button" onClick={handleSelect}>
          {selected ? 'Use' : 'Switch'}
        </button>
      </div>
    );
  };

  return (
    <div className="update-email-step">
      <EmailCard
        selected={selectedEmail === onboardEmail}
        email={onboardEmail}
        handleSelect={() => {
          dispatch(
            formActions.change('dash.onboarding.forms.updateEmail.selectedEmail', onboardEmail)
          );
        }}
      />
      <EmailCard
        selected={selectedEmail === userEmail}
        email={userEmail}
        handleSelect={() => {
          dispatch(
            formActions.change('dash.onboarding.forms.updateEmail.selectedEmail', userEmail)
          );
        }}
      />
    </div>
  );
};

export default connect(({ dash }) => ({
  onboardUser: dash.onboarding.onboardUser.data,
  userData: dash.user.data,
  start: dash.onboarding.forms.start,
  selectedEmail: dash.onboarding.forms.updateEmail.selectedEmail
}))(UpdateEmail);
