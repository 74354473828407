import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';

import {
  GradientModalHeader,
  ModalFooter,
  ModalOverlay,
  ResponsiveContent
} from '@evdy/web-core/dist/components/shared/Modal2020';
import MemberModalBody from '../../../shared/MemberModalBody';
import ConfirmationModal from '@evdy/web-core/dist/components/shared/elements/ConfirmationModal';
import LoaderComponent from '@evdy/web-core/dist/components/shared/elements/LoaderComponent';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import { nameString, renderDemoToast, renderToast } from '@evdy/web-core/dist/lib/utils';

import { editCompanyAction, editCompanyReset } from '@evdy/web-redux/dist/actions/dash/editCompany';
import { editSpecificUser, editUserReset } from '@evdy/web-redux/dist/actions/dash/editUser';
import { fetchCompany } from '@evdy/web-redux/dist/actions/dash/fetchCompany';
import { editManyHomes } from '@evdy/web-redux/dist/lib/manyHomes';

import './TeamMemberModal.scss';

const TeamMemberModal = ({
  // component props
  closeModal,
  forceAdmin = false, // for use in Administrators card to force admin flag when adding
  hideToast = false, // used for when on company page to avoid a double toast
  modalVisible,
  modalType = 'add-member',
  modalData, // rename probably
  // redux state
  editCompany,
  editManyHomesData,
  fetchedCompany,
  funeralHomes,
  memberModal,
  memberFormValid,
  userData,
  // redux actions
  editCompanyAction,
  editCompanyReset,
  editManyHomes,
  editSpecificUser,
  fetchCompany,
  reset
}) => {
  const { isDemoModeOn = false } = userData || {};
  const { data: fetchedCompanyData = {} } = fetchedCompany?.company || {};
  const { lastUpdated: editCompanyLastUpdated, error: editCompanyError } = editCompany;
  const { lastUpdated: editManyLastUpdated, error: editManyError } = editManyHomesData;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [editType, setEditType] = useState(undefined);

  const model = 'dash.company.forms.memberModal';

  // When modal type is 'add-member', set the edit type to 'add-member'
  useEffect(() => {
    if (modalType === 'add-member') setEditType('add-member');
  }, [modalType]);

  // display toast on success/save confirmation
  useEffect(() => {
    if (modalVisible && editCompanyLastUpdated >= Date.now() - 100 && isEmpty(editCompanyError)) {
      const toastCopy =
        modalType === 'add-member'
          ? `A Memorial was sent to ${memberModal?.name?.first}`
          : 'Successfully saved changes';
      !hideToast && renderToast({ id: 'member-success-toast', message: toastCopy });
      closeModal();
      reset(model);
      editCompanyReset();
    }
  }, [
    closeModal,
    editCompanyError,
    editCompanyLastUpdated,
    editCompanyReset,
    hideToast,
    memberModal,
    modalType,
    modalVisible,
    reset
  ]);

  // display toast when edit fails to save
  useEffect(() => {
    if (!isEmpty(editCompanyError)) {
      !hideToast &&
        renderToast({ id: 'member-failure-toast', message: editCompanyError, type: 'error' });
    }
  }, [editCompanyError, hideToast]);

  // display toast after editing member locations success
  useEffect(() => {
    if (editManyLastUpdated >= Date.now() - 100 && isEmpty(editManyError)) {
      const toastCopy = "Member's location settings updated";
      renderToast({ id: 'member-success-toast', message: toastCopy });
    }
  }, [editManyError, editManyLastUpdated, hideToast]);

  // display toast for editing member locations error
  useEffect(() => {
    if (!isEmpty(editManyError)) {
      renderToast({ id: 'member-failure-toast', message: editManyError, type: 'error' });
    }
  }, [editManyError, hideToast]);

  const handleModalSave = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-member-modal-save-demo' });
    switch (editType) {
      case 'add-member':
        editCompanyAction(
          {
            company: fetchedCompanyData,
            users: [
              ...fetchedCompanyData.users,
              {
                ...memberModal,
                image: memberModal.image ? memberModal.image : undefined,
                ...(forceAdmin && { isCompanyAdmin: true }) // force admin when adding
              }
            ]
          },
          fetchedCompanyData._id
        );

        return;

      case 'edit-member':
        const editedMemberId = modalData.member._id;
        const editedMembers = fetchedCompanyData.users.map(member => {
          const memberId = member._id.toString ? member._id.toString() : member._id;

          if (memberId === editedMemberId) {
            return { ...member, ...memberModal };
          }
          return member;
        });

        editCompanyAction(
          {
            company: fetchedCompanyData,
            users: editedMembers
          },
          fetchedCompanyData._id
        );
        return;

      case 'edit-member-locations':
        if (memberModal.locationSettings) {
          let data = [];
          fetchedCompanyData.funeralHomes.forEach((home, homeIdx) => {
            const modalLocationSettings = memberModal[homeIdx].locationSettings;

            const filteredUsers = home?.users?.filter(
              user => user.userRef !== modalLocationSettings.userRef
            );

            const newUsers = [...filteredUsers, modalLocationSettings];

            if (modalLocationSettings) {
              data.push({
                _id: home._id,
                data: { users: newUsers }
              });
            }
          });

          const homesUpdate = () =>
            editManyHomes(data).then(() => {
              fetchCompany(fetchedCompanyData._id);
            });

          homesUpdate();
          closeModal();
        }
        return;
      default:
        break;
    }
  };

  const handleConfirmDeletion = () => {
    setIsConfirmModalOpen(true);
  };

  const handleModalDelete = () => {
    if (isDemoModeOn) return renderDemoToast({ id: 'company-member-modal-delete-demo' });
    editCompanyAction(
      {
        company: fetchedCompanyData,
        delete: {
          users: [modalData.member._id]
        }
      },
      fetchedCompanyData._id
    );
  };

  const showLoader =
    editManyHomesData?.isFetching ||
    editCompany?.isFetching ||
    fetchedCompany?.isFetching ||
    editSpecificUser?.isFetching;

  const modalHeader = modalType === 'add-member' ? 'Add a Member' : 'Edit Member';

  const confirmSubject = modalData?.member?._id ? nameString(modalData.member.name) : 'this Member';
  const confirmCopy = `Are you sure you want to delete ${confirmSubject}?`;

  return (
    <ModalOverlay {...{ modalVisible, closeModal }} customClass="team-member-modal">
      <ResponsiveContent>
        <GradientModalHeader {...{ closeModal }} headerCopy={modalHeader} />
        <MemberModalBody
          {...{ modalData, model }}
          forceAdmin={forceAdmin}
          formData={memberModal || {}}
          editMode={modalType === 'edit-member'}
          funeralHomes={funeralHomes}
          setSaveType={setEditType} /*TODO: consider updating this prop name later on*/
        />
        <ModalFooter
          mainButtonText="Save"
          mainButtonFn={handleModalSave}
          disabled={!memberFormValid}
          secondaryButtonText="Cancel"
          secondaryButtonFn={closeModal}
          tertiaryButtonText="Delete"
          tertiaryButtonFn={handleConfirmDeletion}
          tertiaryButtonHide={modalType === 'add-member'}
        />
        <ConfirmationModal
          confirmCopy={confirmCopy}
          display={isConfirmModalOpen}
          onConfirm={handleModalDelete}
          onClose={() => setIsConfirmModalOpen(false)}
        />
        {showLoader && <LoaderComponent />}
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(
  ({ authUser, dash }) => ({
    editCompany: dash.editCompany,
    editManyHomesData: dash.editManyHomes,
    fetchedCompany: dash.fetchedCompany,
    funeralHomes: dash?.fetchedCompany?.company?.data?.funeralHomes,
    memberModal: dash.company.forms.memberModal,
    memberFormValid: dash.company.forms.companyMeta.memberModal.$form.valid,
    userData: authUser.data
  }),
  {
    editCompanyAction,
    editCompanyReset,
    editManyHomes,
    editSpecificUser,
    fetchCompany,
    reset: formActions.reset
  }
)(TeamMemberModal);
