import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';

import { nameString } from '@evdy/web-core/dist/lib/utils';
import { Form, Typeahead, formActions } from '@evdy/web-core/dist/components/shared/Input';

import blankImg from '../../../../../assets/no-photo-placeholder.svg';
import searchIcon from '@evdy/web-core/dist/images/shared/icons/search-icon-black.svg';

import './ReplaceAgentModal.scss';

const ReplaceAgentModal = props => {
  const {
    model,
    agents,
    agentsForm,
    agentsMeta,
    handleModalSelection,
    change,
    setValidity
  } = props;
  const [currentAgent, setCurrentAgent] = useState({});
  const [replacementAgent, setReplacementAgent] = useState({});

  const getAgent = useCallback(agentKey => ({ _id }) => _id.toString() === agentsForm[agentKey], [
    agentsForm
  ]);

  const memorialKeys = useMemo(
    () =>
      agents
        .filter(({ _id }) => _id.toString() !== replacementAgent?._id?.toString())
        .map(({ _id, name }) => ({
          displayKey: nameString(name),
          saveKey: _id.toString()
        })),
    [agents, replacementAgent]
  );

  const agentList = useMemo(
    () =>
      agents
        .filter(({ _id }) => _id.toString() !== replacementAgent?._id?.toString())
        .map(({ name, email }) => (
          <div className="list-item">
            <div className="name">{nameString(name)}</div>
            <div>{email}</div>
          </div>
        )),
    [agents, replacementAgent]
  );

  const replacementMemorialKeys = useMemo(
    () =>
      agents
        .filter(({ _id }) => _id.toString() !== currentAgent?._id?.toString())
        .map(({ _id, name }) => ({
          displayKey: nameString(name),
          saveKey: _id.toString()
        })),
    [agents, currentAgent]
  );

  const replacementAgentList = useMemo(
    () =>
      agents
        .filter(({ _id }) => _id.toString() !== currentAgent?._id?.toString())
        .map(({ name, email }) => (
          <div className="list-item">
            <div className="name">{nameString(name)}</div>
            <div>{email}</div>
          </div>
        )),
    [agents, currentAgent]
  );

  // set typeaheads to invalid on page load
  useEffect(() => {
    setValidity(`${model}.current`, false);
    setValidity(`${model}.replaceWith`, false);
  }, []);

  // reset typeahead after selecting an agent
  useEffect(() => {
    if (agentsForm.current) {
      const currentAgent = agents.find(getAgent('current'));
      setCurrentAgent(currentAgent);
      change(`${model}.current`, '');
      setValidity(`${model}.current`, true);
      handleModalSelection('current', currentAgent._id);
    }
    if (agentsForm.replaceWith) {
      const replacementAgent = agents.find(getAgent('replaceWith'));
      setReplacementAgent(replacementAgent);
      change(`${model}.replaceWith`, '');
      setValidity(`${model}.replaceWith`, true);
      handleModalSelection('replaceWith', replacementAgent._id);
    }
  }, [agents, agentsForm, change, getAgent, handleModalSelection, model, setValidity]);

  return (
    <Form model={model} className="dash-acc-loc-agents-form">
      <div className="selection">
        <h1>Find Agent</h1>
        <Typeahead
          full
          anyWord
          showUnfilteredAtFirst
          model=".current"
          modelBase={model}
          placeholder="Funeral Agent Name"
          pre={searchIcon}
          jsxKeys={memorialKeys}
          data={agentList}
          limit={10}
          autoComplete="pleaseDisableThis"
        />
        {!isEmpty(currentAgent) && (
          <div className="selected-item">
            <img src={currentAgent.image || blankImg} alt="agent profile" />
            <div className="agent-info">
              <div className="name">{nameString(currentAgent.name)}</div>
              <div>{currentAgent.email}</div>
              <div>{currentAgent.phone}</div>
              <div className="funeral-home">Funeral Home</div>
            </div>
          </div>
        )}
      </div>
      <div className="selection">
        <h1>Replace with</h1>
        <Typeahead
          full
          anyWord
          showUnfilteredAtFirst
          model=".replaceWith"
          modelBase={model}
          placeholder="Funeral Agent Name"
          pre={searchIcon}
          jsxKeys={replacementMemorialKeys}
          data={replacementAgentList}
          limit={10}
          autoComplete="pleaseDisableThis"
          disabled={!agentsMeta.currentIsValid}
        />
        {!isEmpty(replacementAgent) && (
          <div className="selected-item">
            <img src={replacementAgent.image || blankImg} alt="agent profile" />
            <div className="agent-info">
              <div className="name">{nameString(replacementAgent.name)}</div>
              <div>{replacementAgent.email}</div>
              <div>{replacementAgent.phone}</div>
              <div className="funeral-home">Funeral Home</div>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};

export default connect(
  null,
  { change: formActions.change, setValidity: formActions.setValidity }
)(ReplaceAgentModal);
