import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NotificationDot from '../NotificationDot';

import './TabbedNav.scss';

const TabbedNav = ({ renderTitle = null, tabs = [], onChange = () => {}, children = [] }, ref) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabClassNames = name => classNames('tab', { active: name === selectedTab });

  useImperativeHandle(ref, () => ({
    handleTabSelect
  }));

  const handleTabSelect = useCallback(
    (selectedTabIndex, tabName) => {
      onChange({ selectedTabIndex, tabName });
      setSelectedTab(selectedTabIndex);
    },
    [onChange]
  );

  return (
    <div className="tabbed-nav">
      <div className="header">
        {renderTitle()}
        <div className="tabs">
          {tabs.map((tab, i) => {
            const { tabName, showIndicator, indicatorCount } = tab || {};

            return (
              <button
                key={i}
                className={tabClassNames(i)}
                onClick={() => handleTabSelect(i, tabName)}
              >
                {tabName}
                {showIndicator && <NotificationDot displayAmt={indicatorCount} />}
              </button>
            );
          })}
        </div>
      </div>
      {children[selectedTab]}
    </div>
  );
};

const forwardedSubNav = forwardRef(TabbedNav);

const { string, func, element, arrayOf, oneOf } = PropTypes;

TabbedNav.propTypes = {
  renderTitle: oneOf([func, element, arrayOf(element)]),
  tabs: arrayOf(string),
  onChange: func,
  children: arrayOf(element)
};

export default forwardedSubNav;
