import { useReducer } from 'react';
import merge from 'lodash/merge';

const sortFilterOptions = [
  { name: 'A-Z', value: 'name' },
  { name: 'Sign Up Recency', value: '-id' },
  { name: 'Memorials Total', value: '-total' },
  { name: 'Most Recent', value: '-mostRecentDate' }
];

const dropdownOptions = { sortFilterOptions };

const filterType = {
  CLEAR_CREATION_TYPE: 'CLEAR_CREATION_TYPE',
  CREATION_TYPE: 'CREATION_TYPE',
  SORT: 'SORT',
  SEARCH: 'SEARCH',
  PAGE: 'PAGE',
  RESET: 'RESET'
};

const getFilters = () => {
  return [
    {
      header: 'Creation Types',
      options: [
        {
          type: filterType.CLEAR_CREATION_TYPE,
          value: 'All',
          id: '',
          images: {}
        },
        {
          type: filterType.CREATION_TYPE,
          value: 'Smart Director',
          id: 'Smart_Director_Integration',
          images: {}
        },
        {
          type: filterType.CREATION_TYPE,
          value: 'Dash',
          id: 'Dashboard'
        },
        {
          type: filterType.CREATION_TYPE,
          value: 'Passare',
          id: 'Passare'
        },
        {
          type: filterType.CREATION_TYPE,
          value: 'Precoa',
          id: 'Precoa'
        },
        {
          type: filterType.CREATION_TYPE,
          value: 'SRS',
          id: 'SRS'
        }
      ]
    }
  ];
};

const initialState = {
  creationtypeFilter: '',
  creationtypeFilterName: '',
  selected: {
    creationType: {
      value: `${filterType.CLEAR_CREATION_TYPE}-`,
      name: ''
    }
  },
  sort: 'name',
  sortName: '',
  searchQuery: '',
  page: 1,
  startsAtZero: false
};

const resetOverlappingCreationType = {
  selected: {
    creationType: {
      value: `${filterType.CLEAR_CREATION_TYPE}-`,
      name: ''
    }
  },
  creationtypeFilter: ''
};

const resetSearch = {
  searchQuery: ''
};

const resetSort = {
  sort: 'name',
  sortName: ''
};

const resetPage = {
  page: 1
};

const filterReducer = (state, { type, value, id }) => {
  switch (type) {
    case filterType.ONLOAD:
      return {
        ...merge(state, value)
      };

    case filterType.CLEAR_CREATION_TYPE:
      return { ...merge(state, resetOverlappingCreationType, resetPage) };

    case filterType.CREATION_TYPE:
      const selectionPropertyCreation = {
        selected: {
          creationType: {
            value: `${type}-${id}`,
            name: value
          }
        }
      };
      return {
        ...merge(state, resetOverlappingCreationType, resetPage, selectionPropertyCreation),
        creationtypeFilter: id,
        creationtypeFilterName: value
      };

    case filterType.SEARCH:
      return {
        ...merge(state, resetPage),
        searchQuery: value
      };

    case filterType.SORT:
      let sortName;
      switch (value) {
        case 'name':
          sortName = '';
          break;
        case '-id':
          sortName = 'Sign Up Recency';
          break;
        case '-total':
          sortName = 'Memorials Total';
          break;
        case '-mostRecentDate':
          sortName = 'Most Recent';
          break;
        default:
          sortName = '';
      }
      return {
        ...merge(state, resetPage),
        sort: value,
        sortName
      };

    case filterType.PAGE:
      return {
        ...merge(state, {
          page: value
        })
      };

    case filterType.RESET:
      return {
        ...merge(state, resetOverlappingCreationType, resetSearch, resetSort, resetPage)
      };

    default:
      return state;
  }
};

const useAccountsFilter = () => {
  const [accountsFilter, setAccountsFilter] = useReducer(filterReducer, initialState);
  return [accountsFilter, setAccountsFilter, getFilters, filterType, dropdownOptions];
};

export default useAccountsFilter;
