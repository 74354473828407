import React from 'react';

import NoAccounts from './NoAccounts';
import AccountListItem from './AccountListItem';

const AccountsList = ({ accounts, handleAccountClick, searchText, activeFilters }) => {
  if (accounts === null) return null;
  const accountsArray = accounts?.length ? accounts : [];

  if (!accountsArray.length) return <NoAccounts {...{ activeFilters, searchText }} />;

  return accountsArray.map(account => (
    <AccountListItem key={account._id} {...{ ...account, handleAccountClick }} />
  ));
};

export default AccountsList;
