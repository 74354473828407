import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { array } from 'prop-types';

import './Stats.scss';
import {
  groupData,
  chartSetup,
  chartReset,
  updateChart,
  formatSum,
  chooseChartSize,
  useBarchartResize
} from './utils';

const Stats = ({ data }) => {
  const chartAnchor = useRef();
  const [dataset, setDataset] = useState({});
  const [group, setGroup] = useState('threeMnAgo');
  const [chartWidth, setChartWidth] = useBarchartResize();
  const [memorialStats, setMemorialStats] = useState({ sum: 0, avg: 0, totalMonths: 0 });

  const calculateStats = useCallback(() => {
    const {
      amtPerMonth,
      [group]: { key }
    } = dataset;

    const monthsInSet = amtPerMonth.slice(0, formatSum[key]);
    const total = monthsInSet.length;

    const sum = monthsInSet.reduce((sum, val) => (sum += val), 0);
    const avg = Math.round(sum / total) || 0;

    setMemorialStats({ sum, avg, totalMonths: amtPerMonth.length });
  }, [dataset, group]);

  useEffect(() => {
    const today = new Date();

    setChartWidth(chooseChartSize(window.innerWidth));
    chartSetup({ chartAnchor, height: 400, width: chartWidth });
    setDataset(groupData(data, today));

    return () => {
      chartReset({ chartAnchor });
    };
  }, [chartWidth, data, setChartWidth]);

  useEffect(() => {
    if (!dataset[group]) return;

    updateChart({ chartAnchor, data: dataset[group], height: 400, width: chartWidth });
    calculateStats();
  }, [group, dataset, chartWidth, calculateStats]);

  const renderButton = (groupName, buttonText) => {
    const buttonClass = (groupName, group) => classNames({ active: group === groupName });

    return (
      <button className={buttonClass(groupName, group)} onClick={() => setGroup(groupName)}>
        {buttonText}
      </button>
    );
  };

  const { sum, avg, totalMonths } = memorialStats;

  return (
    <div className="dash-barchart">
      <div className="stats">
        <div className="general">
          {dataset[group] && (
            <div className="stat">
              <div className="description">Total for {dataset[group].description}</div>
              <div className="number">{sum.toLocaleString()}</div>
            </div>
          )}
          <div className="stat">
            <div className="description">Avg. Per Month</div>
            <div className="number">{avg.toLocaleString()}</div>
          </div>
        </div>
      </div>
      <div className="chart">
        <svg width={chartWidth} height="400" ref={chartAnchor} />
        <div className="barchart-controls">
          {totalMonths > 3 && renderButton('threeMnAgo', '3 Months')}
          {totalMonths > 3 && renderButton('sixMnAgo', '6 Months')}
          {totalMonths > 6 && renderButton('oneYrAgo', '1 Year')}
          {totalMonths > 12 && renderButton('twoYrAgo', '2 Years')}
          {totalMonths > 3 && renderButton('allTime', 'All Time')}
        </div>
      </div>
    </div>
  );
};

Stats.propTypes = {
  data: array
};

Stats.defaultProps = {
  data: []
};

export default Stats;
