import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import EditProviderForm from '../../forms/EditProviderForm';
import {
  ModalOverlay,
  SolidColorModalHeader,
  ResponsiveContent,
  ModalFooter
} from '@evdy/web-core/dist/components/shared/Modal2020';
import { formActions } from '@evdy/web-core/dist/components/shared/Input';

import './EditProviderModal.scss';

const EditProviderModal = ({
  // component props
  closeModal,
  formData,
  buttonText = 'Save',
  modalTitle = 'Provider',
  modalVisible,
  model,
  onSubmitAction,
  handleGoBack,
  // redux props
  authedUserId,
  change,
  directors = [],
  funeralHomes = [],
  lastUsedHomeId = '',
  reset
}) => {
  const directorsDefault =
    directors.find(({ _id }) => _id.toString() === authedUserId.toString())?._id ||
    directors[0]?._id;
  const funeralHomesDefault =
    funeralHomes.find(({ _id }) => _id.toString() === lastUsedHomeId.toString())?._id ||
    funeralHomes[0]?._id;

  // map default drop down values to RRF
  // logged user and last used home
  useEffect(() => {
    change(`${model}.director`, directorsDefault);
    change(`${model}.funeralHome`, funeralHomesDefault);
  }, [change, directorsDefault, funeralHomesDefault, model]);

  const handleCloseModal = () => {
    // reset(model); // don't think this needs to reset on close, keeping just in case
    closeModal();
  };

  return (
    <ModalOverlay
      {...{ modalVisible, handleGoBack }}
      customClass="edit-provider-container"
      closeModal={handleCloseModal}
    >
      <ResponsiveContent>
        <SolidColorModalHeader
          {...{ handleGoBack }}
          headerCopy={modalTitle}
          closeModal={handleCloseModal}
        />
        <EditProviderForm {...{ directors, funeralHomes, formData, model }} />
        <ModalFooter mainButtonText={buttonText} mainButtonFn={onSubmitAction} />
      </ResponsiveContent>
    </ModalOverlay>
  );
};

export default connect(
  ({ authUser, dash }) => ({
    authedUserId: authUser.data._id,
    directors: dash.fetchedCompany.company.data.users,
    funeralHomes: dash.fetchedCompany.company.data.funeralHomes,
    lastUsedHomeId: authUser.data.lastFuneralHome
  }),
  {
    change: formActions.change,
    reset: formActions.reset
  }
)(EditProviderModal);
