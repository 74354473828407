import {
  ONBOARD_FINISH_REQUEST,
  ONBOARD_FINISH_SUCCESS,
  ONBOARD_FINISH_FAILURE
} from '../../../constants/dash/onboarding';
import { API_URL } from '../../../constants';

import axios from 'axios/index';
import transAxiosErr from '@evdy/web-etc/dist/lib/transformAxiosError';

export const finishOnboardRequest = () => ({
  type: ONBOARD_FINISH_REQUEST
});

export const finishOnboardSuccess = ({ data }) => ({
  type: ONBOARD_FINISH_SUCCESS,
  receivedAt: Date.now(),
  payload: data
});

export const finishOnboardFailure = error => ({
  type: ONBOARD_FINISH_FAILURE,
  receivedAt: Date.now(),
  error
});

export const finishOnboardAction = () => dispatch => {
  dispatch(finishOnboardRequest());
  return axios
    .post(`${API_URL}/onboard/finish`)
    .then(res => dispatch(finishOnboardSuccess(res.data)))
    .catch(err => dispatch(finishOnboardFailure(transAxiosErr(err))));
};
