import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { formatAddress, timeWithZoneOffset } from '@evdy/web-core/dist/lib/utils';
import Button from '@evdy/web-core/dist/components/Button';

import './EventRow.scss';

import calIcon from '../../../../InviteBuilder/components/Events/EmptyEventRow/empty-cal.svg';

const EventRow = ({
  title,
  location,
  startDateISO,
  endDateISO,
  _id,
  handleEditEvent,
  openEditModal,
  startTimeTBD,
  videoStreamUrl,
  isLastEvent = false
}) => {
  // safety check if there are past events - undefined gets passed into events array before b.e. can filter
  if (!_id) return null;

  const isAllDayEvent = !!startTimeTBD;
  const dateWithYear = isAllDayEvent ? 'MMMM D, Y' : 'MMM D, YYYY - @h:mma';
  const startTimeTzOffset = timeWithZoneOffset(new Date(startDateISO));
  const endTimeTzOffset = timeWithZoneOffset(new Date(endDateISO));

  const eventRowContainerClasses = classNames('event-row-container', { 'last-event': isLastEvent });

  return (
    <div className={eventRowContainerClasses}>
      <div className="event-row">
        <img className="cal-icon" alt="event-cal-icon" src={calIcon} />
        <div className="event-details">
          <div className="title">{title}</div>
          <div className="date-and-time-wrapper">
            <div className="start-date">{`${moment(startTimeTzOffset).format(dateWithYear)}`}</div>
            {!!endDateISO && (
              <div className="end-time">{`- ${moment(endTimeTzOffset).format('h:mma')}`}</div>
            )}
          </div>
          {!!videoStreamUrl && <div className="live-stream-label">Live Stream Added</div>}
          {location?.address && <div className="location">{formatAddress(location)}</div>}
        </div>
        <Button copy="Edit" action={openEditModal} style="white-background-black-outline" />
      </div>
    </div>
  );
};

export default EventRow;
